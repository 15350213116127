import React from 'react';
import {
  Box,
  Button,
  CardContent,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getUserView } from '../../services/loginUserServices';
import { blue, red, green, purple, yellow } from '@mui/material/colors';
import GetUserHome from './Home';
import GetUserAbout from './About';
import {
  amIFollowing,
  postFollowAPI,
  postUnFollowAPI,
} from '../../services/SocialMediaFollow';
import { FollowRequest } from '../../model/FollowModel';
import { Utility } from '../../utility/Utility';
import { Notification } from '../../model/Notification';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import { MobileAdvert } from '../Layout/MobileAdvert';
import Website from './Website';
import { getUserWebsiteUrl } from '../../services/UserService';
import { PathNames } from '../../appConfig/PathNames';
import CircularIndeterminate from '../../components/Loading/Loading';

const getRandomColor = () => {
  const colors = [blue, red, green, purple, yellow];
  return colors[Math.floor(Math.random() * 5 + 0)];
};

const UserOverview = () => {
  const currentUser = useSelector((state: ReduxStore) => state.userData.user);
  const [mainTab, setMainTab] = React.useState<string>('home');
  const [color, setColor] = React.useState<any>(null);
  const [logoBox, setLogoBox] = React.useState<any>(null);
  const [userView, setUserView] = React.useState<any>({});
  const [isFollow, setFollow] = React.useState<boolean>(false);
  const [websiteURL, setWebsiteURL] = React.useState('');
  const [viewReady, setViewReady] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const [hasWebsite, setHasWebSite] = React.useState<boolean>(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const userid: string | null = searchParams.get('userid');

  React.useLayoutEffect(() => {
    if (userid && userid?.length > 0) {
      getUserWebsiteUrl(userid)
        .then((data) => {
          if (data && data.website_url?.length > 0) {
            setWebsiteURL(data?.website_url || '');
            setHasWebSite(true);
          } else {
            setHasWebSite(false);
          }
        })
        .catch((ex) => {
          setHasWebSite(false);
          console.error(ex);
        });
    }
  }, [userid]);

  React.useEffect(() => {
    getUserView(userid).then((user) => {
      if (user && user.companyUser === true && user.companyData) {
        if (user.companyData.type === 'UnlistedCompany') {
          navigate(`${PathNames.UnlistedCompanyOverview}?code=${user.companyData.id}`);
        }
        else if (user.companyData.type === 'Company') {
          navigate(`${PathNames.CompanyOverview.replace(':code', user.companyData.code?.toString()).replace(':name', user.companyData.searchId)}`);
        }
      }
      else {
        setFollow(false);
        setUserView(user);
        setColor(getRandomColor()[900]);
        ValidateLogo(user);
        amIFollowing(user.id, 'User').then((follow) => {
          if (follow && follow.length > 0) {
            setFollow(true);
          }
        });
        setViewReady(true);
      }
    });
  }, [userid]);

  const handleMainTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setMainTab(newValue);
  };

  const ValidateLogo = async (user_view) => {
    if (user_view && user_view.profilepic) {
      setLogoBox(
        <Box
          component="img"
          alt="logo"
          src={user_view.profilepic}
          sx={{
            padding: '10px',
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            width: '100px',
            height: '100px',
            position: 'absolute',
            top: '70px',
            left: '20px',
            color: color,
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></Box>
      );
    } else {
      setLogoBox(
        <Box
          sx={{
            padding: '10px',
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            width: '100px',
            height: '100px',
            position: 'absolute',
            top: '70px',
            left: '20px',
            color: color,
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            title={user_view.name}
            variant={user_view.name && user_view.name.length > 7 ? 'h2' : 'h6'}
            sx={{ fontWeight: 'bold', overflow: 'hidden' }}
          >
            {user_view.name
              ? user_view.name.length > 7
                ? user_view.name[0]
                : user_view.name
              : user_view?.name?.[0]}
          </Typography>
        </Box>
      );
    }
  };

  const getContent = (subTab: string): JSX.Element => {
    if (subTab === mainTab) {
      switch (subTab) {
        case 'home':
          return GetUserHome(userView);
        case 'about':
          return GetUserAbout(userView);
        default:
          return <React.Fragment></React.Fragment>;
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const toggleFollow = async () => {
    setFollow(!isFollow);
    const followingData: FollowRequest = {
      following_id: userView.id,
      following_type: 'User',
    };
    isFollow
      ? await postUnFollowAPI(followingData)
      : await postFollowAPI(followingData);
    const message: string = isFollow
      ? "You've unfollowed " + userView.name
      : "You've followed " + userView.name;
    const notification: Notification = { message, type: 'success' };
    Utility.notify(notification);
  };

  return (
    viewReady === true ?
    <Box className="page" style={{ position: 'relative' }}>
      <Card classes={{ root: 'informationBlockGray' }}>
        {userView && userView.coverpic ? (
          <Box
            component="img"
            alt="cover"
            src={userView.coverpic}
            sx={{
              height: '140px',
              width: '100%',
              color: '#FFFFFF',
              bgcolor: color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          ></Box>
        ) : (
          <Box
            sx={{
              height: '140px',
              width: '100%',
              color: '#FFFFFF',
              bgcolor: color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          ></Box>
        )}
        {logoBox}
        <Box sx={{ marginTop: '50px' }}>
          <CardContent sx={{ paddingBottom: "16px !important" }}>
            <Typography variant="h5" component="div">
              {userView?.name}
            </Typography>
            <Typography fontSize={"14px"} variant="caption" color="text.secondary">
              {userView?.company}
            </Typography>
            <Typography fontSize={"12px"} sx={{ display: "block" }} variant="caption" color="text.secondary">
              {userView?.designation}
            </Typography>
          </CardContent>

            <Box sx={{ paddingLeft: '20px' }}>
            {!(currentUser?._id?.trim() == userid?.trim()) && (
              <Button
                sx={{
                  border: '2px solid',
                  fontWeight: 700,
                  wordWrap: 'normal',
                }}
                variant="outlined"
                onClick={toggleFollow}
              >
                {isFollow ? '- UNFOLLOW' : '+ FOLLOW'}
              </Button>
              )}
              { userView && userView.showStore && <Button sx={{
                border: '2px solid',
                fontWeight: 700,
                wordWrap: 'normal',
                marginRight: "10px",
                float: "right",
                letterSpacing: 1.1
              }} variant='outlined' onClick={() => navigate(`${PathNames.MARKETPLACE}/seller/${userid}`)}>
                Virtual Store
              </Button>}
            </Box>
          
          {/* <Divider />
                <CardActions>
                    <Button size="small" variant="contained">COMPANY OVERVIEW</Button>
                    <Button size="small" variant="outlined">FUNDAMENTALS</Button>
                </CardActions> */}
        </Box>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={mainTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                textColor="secondary"
                indicatorColor="secondary"
                onChange={handleMainTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="main-tabs"
              >
                <Tab label="Home" value="home" />
                <Tab label="About" value="about" />
                {hasWebsite === true && <Tab label="Website" value="website" />}
                {/* <Tab label="Posts" value="posts" />
                            <Tab label="Jobs" value="jobs" />                            
                            <Tab label="People" value="people" />
                            <Tab label="Videos" value="videos" /> */}
              </TabList>
            </Box>
          </TabContext>
        </Box>
      </Card>
      <Card
        // classes={{ root: 'informationBlockGray' }}
        sx={{ marginTop: '10px', boxShadow: "none" }}
      >
        <Box>
          <TabContext value={mainTab}>
            <TabPanel sx={{ padding: '0px' }} value="home">
              {getContent('home')}
            </TabPanel>
            <TabPanel sx={{ padding: '0px' }} value="about">
              {getContent('about')}
              <MobileAdvert />
            </TabPanel>
            <TabPanel
              sx={{
                padding: '0px',
                width: '100%',
                marginBottom: { xs: '25px', md: '0px' },
              }}
              value="website"
            >
              <Website websiteURL={websiteURL} />
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
    </Box>
    :
    <CircularIndeterminate />
  );
};

export default UserOverview;
