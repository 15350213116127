import draftToHtml from "draftjs-to-html";
import "./CommentViewer.css";
import { Box, Typography } from "@mui/material";

export function CommentViewer(props: IProps) {
    try {
        let rawContentState = JSON.parse(props.rawContent);

        const hashtagConfig = {
            trigger: "#",
            separator: "",
        };
        const markup = draftToHtml(
            rawContentState,
            hashtagConfig,
            void 0,
            (entity, originalText) => {
                if (entity.type == "mention") {
                    var mention = entity.data.mention;
                    let link = "";
                    if (mention.type == "User") {
                        link = `/viewuser?userid=${mention.id}`;
                    } else if (mention.type == "Company") {
                        link = `/company/${mention.id}/companyname/${mention.name.replaceAll(" ","-")}`;
                    } else {
                        link = `/unlistedcompany?code=${mention.id}`;
                    }
                    return `<a class='post-viewer-mention' href='${link}' >${originalText}</a>`;
                }
            }
        );

        return (
            <Box sx={{ fontFamily: "Oxygen", fontSize: "14px", "& p": { marginBlock: "0px" } }}>
                {(markup.length < 300) ? (<div
                    dangerouslySetInnerHTML={{ __html: markup }}
                ></div>) : (
                    (<div className='news-block'><div className="short-news" dangerouslySetInnerHTML={{ __html: markup.substring(0, 300).trim() + `<a href='javascript:void(0)' style="color:#0F9764;" onclick="{this.closest('.news-block').querySelector('.short-news').style.display='none';this.closest('.news-block').querySelector('.full-news').style.display='block';}"> ...Read more ▼</a>` }} />
                        <div className="full-news" style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: markup + ` <a href='javascript:void(0)' style="color:#0F9764;" onclick="{this.closest('.news-block').querySelector('.full-news').style.display='none';this.closest('.news-block').querySelector('.short-news').style.display='block';}"> Read less ▲</a>` }} /></div>)
                )
                }
            </Box>
        );
    } catch (e) {
        return <Typography sx={{ fontSize: "14px" }}>{props.rawContent}</Typography>;
    }
}

interface IProps {
    rawContent: string
}