import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CompaniesPlans from './CompaniesPlans';
import UsersPlans from './UsersPlans';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import { RoleUtility } from '../../utility/RoleUtility';

const Plans = () => {
  const companyUser = useSelector((state: ReduxStore) =>
    RoleUtility.isPremiumCompanyUserOrPendingCompanyUser(
      state.userData.user?.role
    )
  );
  const [alignment, setAlignment] = useState<string | null>(
    companyUser ? 'left' : 'right'
  );

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment === null ? alignment : newAlignment);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: { xs: '20px', md: '0px' },
      }}
    >
      {/* Header Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: 'url("/static/pricing-page/Main.png")',
          height: { md: '700px', xs: '550px' },
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { md: '55%', xs: '90%' },
              marginLeft: '4%',
              marginTop: { md: '3.5%', xs: '-3.5%' },
              textAlign: { md: 'left', xs: 'centre' },
              justifyContent: { md: 'left', xs: 'centre' },
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                color: '#FFF',
                fontFamily: 'Oxygen',
                fontSize: { md: '60px', xs: '30px' },
                fontWeight: '700',
                lineHeight: { md: '70px', xs: '40px' },
                textAlign: { md: 'left', xs: 'center' },
              }}
            >
              Pricing Options for Company Profiling
            </Typography>
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: 'Oxygen',
                fontSize: { md: '60px', xs: '30px' },
                fontWeight: '700',
                lineHeight: { md: '70px', xs: '40px' },
                textAlign: { md: 'left', xs: 'center' },
              }}
            >
              on Stock Knocks
            </Typography>
            <Box
              sx={{
                width: { md: '345px', xs: '150px' },
                height: { md: '24px', xs: '13px' },
                marginLeft: { md: '80px', xs: '120px' },
                padding: '0px',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src="/static/pricing-page/Vector(5).png"
                alt="Vector"
              />
            </Box>

            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFF',
                fontFamily: 'Oxygen',
                fontSize: { md: '18px', xs: '14px' },
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.3px',
                textAlign: { md: 'left', xs: 'center' },
                marginTop: { md: '30px', xs: '24px' },
              }}
            >
              At Stock Knocks, we offer three distinct options for profiling
              your company, each tailored to meet your specific needs and
              budget. Our goal is to provide an immersive experience for
              investors, showcasing your company's operations through compelling
              factory walkthrough videos.
            </Typography>
          </Box>
          <Box
            sx={{
              display: { md: 'flex', xs: 'none' },
              flexDirection: 'row',
              width: { md: '45%', xs: '100%' },
              marginRight: '2%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                marginLeft: '2%',
                marginTop: '4%',
              }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  color: '#FFF',
                  fontSize: '32px',
                  fontWeight: '500',
                  lineHeight: '42px',
                  letterSpacing: '-0.32px',
                  textAlign: 'center',
                }}
              >
                Vishnu Agarwal
              </Typography>
              <Typography
                sx={{
                  color: '#FFF',
                  fontSize: '20px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  letterSpacing: '-0.32px',
                  textAlign: 'center',
                }}
              >
                CEO Stock Knocks
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: { md: '-70px', xs: '-20px' },
                textAlign: 'center',
              }}
            >
              <img
                style={{ width: '100%', height: 'auto', maxWidth: '100%' }}
                src="/static/pricing-page/vishnu.png"
                alt="vishnu"
              />
            </Box>
          </Box>
        </Box>
        {/* Toggle Button */}
        <Box
          sx={{
            display: 'flex',
            marginTop: { md: '-5%', xs: '-3%' },
            padding: { md: '30px', xs: '20px' },
          }}
        >
          <Box
            sx={{
              width: '200px',
              height: '52.88px',
              background: '#FFFFFF',
              border: '1px solid #E4E4E7',
              boxShadow: '0px 12.0312px 18.0469px rgba(24, 59, 86, 0.2)',
              borderRadius: '32.4844px',
            }}
          >
            <ToggleButtonGroup
              sx={{
                height: '34px',
                display: 'flex',
                padding: '10px',
                justifyContent: 'center',
                gap: 1,
              }}
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton
                sx={{
                  border: 'none',
                  color:
                    alignment === 'left'
                      ? '#FFFFFF !important'
                      : '#2CAB8D !important',
                  backgroundColor:
                    alignment === 'left'
                      ? '#2CAB8D !important'
                      : '#FFFFFF !important',
                  borderRadius: '30px !important',
                }}
                value="left"
                aria-label="left aligned"
              >
                Companies
              </ToggleButton>
              <ToggleButton
                sx={{
                  border: 'none',
                  color:
                    alignment === 'right'
                      ? '#FFFFFF !important'
                      : '#2CAB8D !important',
                  backgroundColor:
                    alignment === 'right'
                      ? '#2CAB8D !important'
                      : '#FFFFFF !important',
                  borderRadius: '30px !important',
                }}
                value="right"
                aria-label="centered"
              >
                Users
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>
      {/* Card Box */}
      <Box>{alignment === 'left' ? <CompaniesPlans /> : <UsersPlans />}</Box>
    </Box>
  );
};

export default Plans;
