import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getAdvertisementByUser } from "../../services/AdvertisementService";
import { Creatives } from "../../components/Advertisement/Creatives";
import * as _ from "lodash"

export function MobileAdvert() {
    const isMobile = useMediaQuery<boolean>('(max-width: 600px)', { noSsr: true });
    return isMobile ? <Advertise /> : <></>
}

function Advertise() {
    const [advertisements, setAdvertisements] = useState<IMobileAdvertType[]>([]);

    useEffect(() => {
        getAdvertisementByUser().then((x: IMobileAdvertType[]) => {
            let creatives = _.cloneDeep(x.filter(y => y.type == "CREATIVE"));
            creatives.forEach(c => {
                c.creatives.forEach(x => {
                    x.image = `https://imgproxy-jkk4otkhtq-el.a.run.app/preset:sharp/resize:force:360:100:0/gravity:sm/plain/${x.image}@jpg`
                })
            })
            setAdvertisements(creatives);
        });
        return () => {
            setAdvertisements([]); // This worked for me
        };
    }, []);

    const getRandom = () => {
        return Math.floor(Math.random() * advertisements.length);
    }

    return advertisements.length > 0 ? <Box sx={{ marginTop: "16px" }}>
        <Creatives creatives={advertisements[getRandom()].creatives} />
    </Box> : <></>
}



interface IMobileAdvertType {
    type: "CREATIVE";
    creatives: {
        image: string;
        actionLink: string;
    }[]
}