export class PathExtensions {
    static getBasePathName(location: any): string {
        let pathName = '';
        if (location) {
            let nameArray = location?.pathname?.split("/")?.filter(elm => { return !(elm === undefined || elm === null || elm?.trim() === "") });
            if (nameArray?.length > 0) {
                if (nameArray[0]?.toLowerCase() === 'marketplace') {
                    if (nameArray?.length >= 2) {
                        if (nameArray[1]?.toLowerCase() === 'seller') {
                            pathName = nameArray[1]?.toLowerCase();
                        }
                    }
                }
                else if (nameArray[0]?.toLowerCase() === 'company') {
                    let cno = nameArray[1];
                    if (isNaN(cno)) {
                        pathName = 'unlistedcompany';
                    }
                    else {
                        // obsolete, must use UnlistedCompany
                        pathName = 'company';
                    }
                }
                else {
                    pathName = nameArray[0]?.toLowerCase();
                }
            }
        }
        return pathName;
    };

    static getRecordId(location: any) {
        let recId = '';
        try {
            if (location) {
                let type = PathExtensions.getBasePathName(location);
                switch (type) {
                    case 'company':
                        // obsolete, must use UnlistedCompany
                        {
                            let pathArray = location.pathname?.split("/")?.filter(elm => { return !(elm === undefined || elm === null || elm?.trim() === "") });
                            if (pathArray?.length >= 2) {
                                recId = pathArray[1]?.trim();
                            }
                            break;
                        }
                    case 'unlistedcompany':
                        {
                            if (location?.pathname?.toLowerCase()?.startsWith('/company/')) {
                                recId = location.pathname.split('/').filter(elm => { return !(elm === undefined || elm === null || elm?.trim() === "") })[2];
                                break;
                            }
                            else {
                                let searchString = location.search?.replace('?', '')?.split("&")?.filter(elm => { return elm?.toLowerCase()?.startsWith('code=') });
                                if (searchString?.length > 0) {
                                    recId = searchString[0]?.split("=")[1]?.trim();
                                }
                                break;
                            }
                        }
                    case 'viewuser':
                        {
                            let searchString = location.search?.replace('?', '')?.split("&")?.filter(elm => { return elm?.toLowerCase()?.startsWith('userid=') });
                            if (searchString?.length > 0) {
                                recId = searchString[0]?.split("=")[1]?.trim();
                            }
                            break;
                        }
                    case 'seller':
                        {
                            let nameArray = location?.pathname?.split("/")?.filter(elm => { return !(elm === undefined || elm === null || elm?.trim() === "") });
                            if (nameArray?.length > 0 && nameArray.includes('seller')) {
                                let index = nameArray.indexOf('seller');
                                if (index > -1 && (index + 1) <= nameArray.length && nameArray[index + 1]?.trim().length > 0) {
                                    recId = nameArray[index + 1].trim();
                                }
                            }
                            break;
                        }
                    default:
                        break;
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        return recId;
    };

    static getRefType(pathName: string): 'User' | 'Company' | 'UnlistedCompany' | 'Creative' {
        let refType: 'User' | 'Company' | 'UnlistedCompany' | 'Creative';
        if (pathName === 'company') {
            // obsolete, must use UnlistedCompany
            refType = 'Company';
        }
        else if (pathName === 'unlistedcompany') {
            refType = 'UnlistedCompany';
        }
        else if (pathName === 'viewuser') {
            refType = 'User';
        }
        else if (pathName === 'seller') {
            refType = 'User';
        }
        else {
            refType = 'User';
        }

        return refType;
    };
}