import { useDispatch, useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import { Notification } from "../../model/Notification";
import Box from "@mui/material/Box";
import { Alert, SxProps, Theme } from "@mui/material";
import React, { Dispatch } from "react";
import { IReduxAction } from "../../model/ReduxAction";
import { Actions } from "../../redux/Actions";
import { INotifierConfig } from "../../model/AppState";

export const getStyle = (position): SxProps<Theme> => {
    let style: SxProps<Theme> = {};
    if (position === "topLeft") {
        style = {
            top: '0px',
            left: '0px',
            zIndex: 2050
        }
    }
    else if (position === "topRight") {
        style = {
            top: '0px',
            right: '0px',
            zIndex: 2050
        }
    }
    else if (position === "bottomLeft") {
        style = {
            bottom: '0px',
            left: '0px',
            zIndex: 2050
        }
    }
    else if (position === "bottomRight") {
        style = {
            bottom: '0px',
            right: '0px',
            zIndex: 2050
        }
    }
    else if (position === "topCenter") {
        style = {
            left: '0px',
            right: '0px',
            top: '0px',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 2050
        }
    }
    else if (position === "bottomCenter") {
        style = {
            left: '0px',
            right: '0px',
            bottom: '0px',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 2050
        }
    }
    else {
        style = {
            top: '0px',
            left: '0px',
            zIndex: 2050
        }
    }
    style = { ...style, ...{ position: 'fixed', margin: '5px' } }
    return style;
}

export const Notifier = () => {
    let notifications: Array<Notification> = useSelector((state: ReduxStore) => state.app.notifications);
    let notifierConfig: INotifierConfig = useSelector((state: ReduxStore) => state.app.notifierConfig);
    let dispatch: Dispatch<IReduxAction> = useDispatch();
    return <Box sx={{ ...getStyle(notifierConfig.position) }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            {
                notifications.map((notification) => <Alert
                    key={notification.identifier}
                    sx={{ width: 'auto', margin: '5px', boxShadow: '1px 1px 3px 2px rgb(0 0 0 / 20%)', display: 'flex' }}
                    severity={notification.type}
                    variant={notification.variant}
                    onClose={!notification.closable ? undefined : () => dispatch({ type: Actions.UNSETNOTIFICATION, payload: notification.identifier })}
                >
                    {notification.message}
                </Alert>)
            }

        </Box>
    </Box>
}