export function getMentions(content: string) {
    let mentions: any[] = [];
    try {
        const entities = JSON.parse(content).entityMap;
        for (let key in entities) {
            let entity = entities[key];
            if (entity.type == "mention") {
                mentions.push(
                    `${entity.data.mention.type}/${entity.data.mention.id}`
                );
            }
        }
    } catch (e) { }
    return mentions;
}

export function getHashtags(content: string) {
    return content.match(/#\w*/g);
}