import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent, CardHeader, Grid, Tab, Tabs, ToggleButton, ToggleButtonGroup, useMediaQuery, Typography, IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ApexOptions } from 'apexcharts';
import { Dictionary } from 'lodash';
import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DateExtensions } from "../../utility/DateExtensions";
import useStyle from "./Styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 



function GetFinancialsCard(props: { keys: string[], columns: string[], financials_s: any, financials_c: any, type: number, loading: boolean, updated_on?: string }) {
    const TabDisplayName = {
        'revenue_from_operation': 'Net Sales',
        'ebitda': 'EBDITA',
        'operating_profit': 'EBIT',
        'pbt': 'PBT',
        'pat': 'PAT',
        'eps': 'EPS',
        'paid_up_capital': 'Paid Up Capital',
        'net_worth': 'Net Worth',
        'total_income': 'Total Income',
        'total_expense': 'Total Expenses',
        'income_tax': 'Income Tax'
    }

    const masterClasses = useStyle();

    const VerticalTab = withStyles((theme: any) => ({
        root: {
            backgroundColor: '#EBEBEB',
            border: '1px solid #F9F9F9',
            [theme.breakpoints.down('md')]: {
                width: '40%'
            },
            [theme.breakpoints.up('md')]: {
                width: '150px',
                height: "40px"
            },
            // lineHeight: '5px',
            // height: '5px',
            // paddingY: '5px',
            textTransform: "capitalize"
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    }))(Tab);

    const CustomTabs = withStyles((theme: any) => ({
        root: {
            borderRight: 'none', borderColor: 'divider',
            display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
            backgroundColor: '#EBEBEB',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: '48px',
            },
            [theme.breakpoints.up('md')]: {
                width: '140px',
                maxHeight: `${48 * (keys?.length)}px`
                // maxHeight: '240px'
            },
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    }))(Tabs);

    const CustomTabPanel = withStyles((theme: any) => ({
        root: {
            width: '100%', backgroundColor: '#F9F9F9',
            [theme.breakpoints.down('md')]: {
                height: '350px',
                padding: '0px',
            },
            [theme.breakpoints.up('md')]: {
                height: '300px',
                padding: '20px',
                paddingBottom: '0px'
            },
        }
    }))(TabPanel);

    const [tabValue, setTabValue] = React.useState<string>((props.keys && props.keys.length > 0) ? props.keys[0] : 'net_worth');

    const [keys, setKeys] = React.useState<string[]>(props.keys);

    const [columns, setColumns] = React.useState<string[]>(props.columns);

    const [showContent, setShowContent] = React.useState<boolean>((props.columns && props.columns.length > 0));

    const [balanceSheetSummary, setBalanceSheetSummary] = React.useState<any>(null);

    const [updatedOn, setUpdatedOn] = React.useState<string>('');

    const [alignment, setAlignment] = React.useState<'S' | 'C'>('S');

    const isSM = useMediaQuery("(min-width:600px)");

    const [link, setLink] = React.useState(false);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue && !(tabValue === newValue)) {
            setTabValue(newValue);
        }
    };

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'S' | 'C',
    ) => {
        if (!(alignment === newAlignment) && (newAlignment === 'S' || newAlignment === 'C')) {
            setAlignment(newAlignment);
        }
    };

    useEffect(() => {
        if (props.keys?.toString() != keys.toString()) {
            setKeys(props.keys);
        }
    }, [props.keys]);

    useEffect(() => {
        if (props.columns?.toString() != columns.toString()) {
            setColumns(props.columns);
        }
    }, [props.columns]);

    useEffect(() => {
        if (props.updated_on && props.updated_on.length > 0) {
            let dateString = DateExtensions.getDateStringUppercaseMonthFromString(props.updated_on, ' ', '');
            if (dateString?.trim()?.length > 0) {
                setUpdatedOn(`Updated On: ${dateString}`);
            }
        }
    }, [props.updated_on]);

    useEffect(() => {
        if (props.type === 9) {
            setBalanceSheetSummary(props.financials_s);
        }
        else {
            if (alignment === 'S') {
                setBalanceSheetSummary(props.financials_s);
            }
            if (alignment === 'C') {
                setBalanceSheetSummary(props.financials_c);
            }
        }
    }, [alignment, props.financials_c, props.financials_s, props.type]);

    useEffect(() => {
        if (props.loading === false) {
            if (props.financials_s) {
                setAlignment('S');
                setBalanceSheetSummary(props.financials_s);
            }
            else if (props.financials_c) {
                setAlignment('C');
                setBalanceSheetSummary(props.financials_c);
            }
            setShowContent(showDiv());
        }
    }, [props.loading]);

    const getChart = (tab: string) => {
        let categories: string[] = columns?.sort();
        let series: any[] = [];

        if (balanceSheetSummary && balanceSheetSummary[tab]) {
            let summary: Dictionary<any> = balanceSheetSummary[tab];

            categories?.forEach(x => {
                series.push(Math.trunc(Number(summary[x]?.trim()) / 100000));
            })
        }

        const chartData: ApexOptions = {
            chart: {
                type: "bar",
                id: tab,
                toolbar: {
                    show: false
                },
                parentHeightOffset: 0,
                redrawOnParentResize: true,
                background: '#F9F9F9'
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                categories: categories,
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true
                },
            },
            yaxis: {
                min: 0
            },
            fill: {
                colors: ['#0F9764', '#0F9764', '#0F9764']
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '60%',
                    colors: {
                        ranges: [{
                            from: -0.01,
                            to: -1.7976931348623157e+308,
                            color: '#FF0000'
                        }, {
                            from: 0,
                            to: 1.7976931348623157e+308,
                            color: '#0F9764'
                        }]
                    }
                }
            },
            colors: ['#F9F9F9'],
            series: [
                {
                    name: "Figures (In Lacs)",
                    type: "bar",
                    data: series
                }
            ]
        };

        try {
            return <ReactApexChart options={chartData} series={chartData.series} height='100%' width='100%' style={{ paddingY: '0px' }} />;
        }
        catch (error) {
            console.error("Chart generation failed", error);
        }
    }

    const showDiv = (): boolean => {
        if ((props.financials_s || props.financials_c)
            && (props.columns && Array.isArray(props.columns) && props.columns.length > 0)
            && (props.keys && Array.isArray(props.keys) && props.keys.length > 0)) {
            return true;
        }
        else {
            return false;
        }
    }

    try {
        return (
            <React.Fragment>
                {showContent &&
                    <Box className={masterClasses.root} sx={{ marginTop: '24px' }} id='tabdetail-financials'>
                        <Card elevation={0} sx={{ paddingY: '24px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '8px' }}>
                            <CardHeader
                                classes={{ action: masterClasses.action, title: masterClasses.cardHeaderTitle, subheader: masterClasses.cardSubHeaderTitle }}
                                sx={{ marginX: { sm: "24px", xs: "24px" }, textAlign: 'left', paddingLeft: '0px', paddingRight: '8px', paddingTop: '0px', paddingBottom: '9px' }}
                                title={<Typography
                                    sx={{ fontSize: { xs: '16px', md: '24px' } }}
                                    // className={masterClasses.cardHeaderTitle}
                                >Financials</Typography>}
                                subheader={updatedOn}
                                // action={
                                //     <DownloadButton variant="contained" color="primary" sx={{ height: '39px', width: '128px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none' }} />
                                // }
                                action={
                                    <>
                                        <ToggleButtonGroup
                                        value={alignment}
                                        exclusive
                                        size='small'
                                        onChange={handleChange}
                                        className={masterClasses.toggleBtnGroup}
                                    >
                                        {(props.financials_s) &&
                                            <ToggleButton value="S"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: alignment === 'S' ? '#FFFFFF !important' : '#0F9764 !important',
                                                    backgroundColor: alignment === 'S' ? '#0F9764 !important' : '#FFFFFF !important',
                                                    paddingLeft: "15px",
                                                    paddingRight: "10px",
                                                    textTransform: "none",
                                                    fontSize: {xs:'10px', md:'14px'}
                                                }}>Standalone</ToggleButton>}
                                        {(props.financials_c) &&
                                            <ToggleButton value="C"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: alignment === 'C' ? '#FFFFFF !important' : '#0F9764 !important',
                                                    backgroundColor: alignment === 'C' ? '#0F9764 !important' : '#FFFFFF !important',
                                                    paddingLeft: "10px",
                                                    paddingRight: "15px",
                                                    textTransform: "none",
                                                    fontSize: {xs:'10px', md:'14px'}
                                                }}>Consolidated</ToggleButton>}
                                    </ToggleButtonGroup><Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                                            <IconButton
                                                onClick={() => {
                                                    window.location.hash = 'tabdetail-financials';
                                                    navigator.clipboard.writeText(window.location.href);
                                                    setLink(true);
                                                } }
                                            >
                                                <ContentCopyIcon
                                                    sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            >
                            </CardHeader>
                            <Typography sx={{
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontFamily: 'Oxygen',
                                fontSize: '13px',
                                lineHeight: '13px',
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "right",
                                width: 'calc(100% - 24px)',
                                marginRight: "12px",
                                paddingBottom: '10px'
                            }}>
                                {"* Figures are in lakhs (₹)"}
                            </Typography>
                            <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }} className={masterClasses.cardBodyText}>
                                <Box
                                    sx={{ flexGrow: 1, bgcolor: '#F9F9F9', display: 'flex', paddingY: '0px', paddingLeft: '24px' }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item container order={isSM ? 0 : 1} xs={12} md={3}>
                                            <CustomTabs
                                                orientation={isSM ? 'vertical' : 'horizontal'}
                                                value={tabValue}
                                                onChange={handleTabChange}
                                                aria-label="financials-card"
                                                sx={{
                                                    borderRight: 'none', borderColor: 'divider',
                                                    display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
                                                    backgroundColor: '#EBEBEB',
                                                }}
                                                scrollButtons="auto"
                                                allowScrollButtonsMobile={isSM ? false : true}
                                                variant={isSM ? 'fullWidth' : 'scrollable'}
                                            >
                                                {
                                                    keys.map((item, i) => {
                                                        return <VerticalTab label={TabDisplayName[item]} value={item} key={`${item}_${i}`} />;
                                                    })
                                                }
                                            </CustomTabs>
                                        </Grid>
                                        <Grid item container order={isSM ? 1 : 0} xs={12} md={9}>
                                            <TabContext value={tabValue}>
                                                {
                                                    keys.map((item, i) => {
                                                        return <CustomTabPanel value={item} key={`${item}_${i}`}>
                                                            {getChart(item)}
                                                        </CustomTabPanel>;
                                                    })
                                                }
                                            </TabContext>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </ React.Fragment>
        );
    }
    catch (error) {
        console.error(error);
        return (<React.Fragment></React.Fragment>);
    }
}

export default GetFinancialsCard;