import { Box, Typography } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { SellerHome } from "./SellerHome";

export function MarketPlace() {
    return <Box>
        <Routes>
            <Route path="/" element={<Typography>Please select a seller to see their offerings</Typography>}></Route>
            <Route path="/seller/:sellerCode/*" element={<SellerHome />} />
        </Routes>
    </Box>
}