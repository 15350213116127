export const routeUrls = {
    "SignIn": "/SignIn",
    "SignUp": "/SignUp",
}

export const Indexes = {
    "DomesticIndex": "Domestic Index",
    "InternationIndex": "International Index",
    "SectorIndex": "Sector",
    // "best_performer_ipo":"Best Performer IPO",
    // "draft_prospectus": "Draft Prospectus",
    // "new_listing": "New Listing",
    "fii_dii_activity": "FII/DII Activity",
    "deals": "Deal",
    "top_losers": "Top Loser",
    "top_gainer": "Top Gainer", 
    "out_performer": "Out Performer",
    "under_performer": "Under Performer",
    "heatmap" : "Heatmap" ,
    "active_companies_by_volume": "Active Companies By Volume",
    "advance_decline": "Advance /Decline",
    "52_week_high": "52 Week High",
    "52_week_low": "52 Week Low",
    "arbitrage_opportunities": "Arbitrage Opportunities",
    "announcements" : "Announcements",
    "exchange_holiday": "Exchange Holidays",
    "credit_rating": "Credit Rating",   
    "change_of_name" : "Change Of Name",
    "adr_prices": "ADR Prices",
    "adr_master": "ADR Master",
    "upcoming_events": "Upcoming Events",
    // "book_closer" : "Book Closer",
    "forex": "Forex",
    "delisted_shares" : "Delisted Shares",
    "mf_investment" : "MF Investment",
    "low_price_high_volume": "Low Price High Volume",
    "all_time_low": "All Time Low",
    "all_time_high": "All Time High",
    // "investor_portfolios": "Investor Portfolios",
}

export const Events = {
    "Channel_Message_Received": "Channel_Message_Received"
}

export const UnListedIndexes = {
    "BestPerformerIPO": "Best Performing IPOs",
    "TradableCompanies": "Tradable Companies",
    "CompaniesWithFinData" :"Companies With Fin Data",
    "FORTHCOMING": "Forth Coming IPOs",
    "OpenIPO": "Open IPOs",
    "ClosedIPO": "Closed IPOs",
    "NewListing": "New Listing",
    "StateWiseCompanies": "State Wise Companies",
    // "ActivityWiseCompanies": "Activity Wise Companies",
    "UnlistedFilingIPO": "Unlisted Companies Filing For IPO",
    // "ClassWiseCompanies": "Class Wise Companies",
    "StatusWiseCompanies": "Status Wise Companies",
    // "MostViewdCompanies": "Most Viewed Companies"
};

export const ListedExploreCardMarkets = [
    'Nifty 50',
    'SENSEX',
    'India VIX'
  ];

export const Roles = {
    "Company_User_Request": "co_prem_request",
    "Company_User": "co_prem_1",
    "Admin": "admin",
    "User": "member",
    "Premium_User_1": "member_prem_1"
};

export const CompanyTypeNames = {
    "Company": "Company",
    "UnlistedCompany": "UnlistedCompany"
};