import { AxiosResponse } from "axios";
import { socialNetworkService } from "../appConfig/AxiosConfig";
import { CommentCreateResult, CommentData, CommentRequest, CommentUpdateRequest } from "../model/CommentDataModel";
import { Utility } from "../utility/Utility"
import { refreshRewardInfo } from "./UserService";
import { getHashtags, getMentions } from "../utility/EditorUtility";

let client = socialNetworkService;
client.defaults.headers.common = { "Authorization": `bearer ${Utility.getAuthToken()}`, "Content-Type": "application/x-www-form-urlencoded" };

const getCommentsAPI = async (postType: string, postId: string, page: number, pagesize: number): Promise<CommentData[]> => {
    let response: AxiosResponse<CommentData[]> = await client.get<any, AxiosResponse<CommentData[]>>(`/comments/${postType}/${postId}?page=${page}&pagesize=${pagesize}`);
    return response.data;
}

const createCommentAPI = async (commentData: CommentRequest): Promise<CommentData> => {
    commentData.hashtags = getHashtags(commentData.content)?.map(x => x)!;
    commentData.mentions = getMentions(commentData.content);
    let response: AxiosResponse<CommentCreateResult> = await client.post<any, AxiosResponse<CommentCreateResult>>(`/comments`, commentData);
    refreshRewardInfo()
    return response.data.data;
}

const deleteCommentAPI = async (commentId: string): Promise<Boolean> => {
    let response: AxiosResponse<any> = await client.delete<any, AxiosResponse<any>>(`/comments/${commentId}`);
    return response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204;
}

const updateCommentAPI = async (commentId: string, commentData: CommentUpdateRequest): Promise<Boolean> => {
    commentData.hashtags = getHashtags(commentData.content)?.map(x => x)!;
    commentData.mentions = getMentions(commentData.content);
    let response: AxiosResponse<any> = await client.put<any, AxiosResponse<any>>(`/comments/${commentId}`, commentData);
    return response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204;
}
export { getCommentsAPI, createCommentAPI, deleteCommentAPI, updateCommentAPI }
