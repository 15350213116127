const handleImageError = (e) => {

    console.log(e?.nativeEvent?.srcElement?.localName);
    console.log(e.target?.src);
    console.log(e.target);
    if (e?.nativeEvent?.srcElement?.localName?.toLowerCase() === "img") {
        e.target.src = "./static/images/resolve-images.jpg";
    }
    // if (e?.nativeEvent?.srcElement?.localName?.toLowerCase() === "video") {
    //     e.target.src = "./static/images/resolve-images.jpg";
    //     e.target.type = "img";
    // }
    e.target.onerror = () => { };
}

export default handleImageError;