import { AxiosResponse } from "axios";
import { socialNetworkService } from "../appConfig/AxiosConfig";
import { FollowRequest, RemoveFollowRequest } from "../model/FollowModel";
import { LikeRequest, likeData } from "../model/LikeModel";
import { Utility } from "../utility/Utility"

const client = socialNetworkService;
client.defaults.headers.common = {"Authorization": `bearer ${Utility.getAuthToken()}`, "Content-Type": "application/x-www-form-urlencoded"};

const postFollowAPI = async (followData: FollowRequest): Promise<Boolean> => {
    const response: AxiosResponse<any> = await client.post<any, AxiosResponse<any>>(`/follow`, followData);
    let resp = response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204;
    if (resp && (followData.following_type === "User" || followData.following_type === "UnlistedCompany")) {
        const event = new CustomEvent("followingCountUpdate", {
            bubbles: true,
            detail: null
        });
        document.dispatchEvent(event);
    }
    return resp;
}

const postUnFollowAPI = async (unfollowData: FollowRequest): Promise<Boolean> => {
    const response: AxiosResponse<any> = await client.post<any, AxiosResponse<any>>(`/unfollow`, unfollowData);
    let resp = response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204;
    if (resp && (unfollowData.following_type === "User" || unfollowData.following_type === "UnlistedCompany")) {
        const event = new CustomEvent("followingCountUpdate", {
            bubbles: true,
            detail: null
        });
        document.dispatchEvent(event);
    }
    return resp;
}

const postRemoveFollowAPI = async (unfollowData: RemoveFollowRequest): Promise<Boolean> => {
    const response: AxiosResponse<any> = await client.post<any, AxiosResponse<any>>(`/removefollower`, unfollowData);
    let resp = response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204;
    if (resp && (unfollowData.follower_type === "User" || unfollowData.follower_type === "UnlistedCompany")) {
        const event = new CustomEvent("followingCountUpdate", {
            bubbles: true,
            detail: null
        });
        document.dispatchEvent(event);
    }
    return resp;
}
const getFollowCountData = async (recordId: string, recordType: string, likePage: number, pagesize: number): Promise<likeData> => {
    const response: AxiosResponse<any> = await client.get<any, AxiosResponse<any>>(`/like/${recordType}/${recordId}?page=${likePage}&pagesize=${pagesize}`);
    return response.data;
}
const amIFollowing = async (userId: string, userType: string): Promise<any> => {
    const response: AxiosResponse<any> = await client.get<any, AxiosResponse<any>>(`/amifollowing/${userType}/${userId}`);
    return response.data;
}
const followCountAll = async (userType: string, userId: string): Promise<any> => {
    const response: AxiosResponse<any> = await client.get<any, AxiosResponse<any>>(`/followCountAll/${userType}/${userId}`);
    return response.data;
}


export { postFollowAPI, getFollowCountData, postUnFollowAPI, amIFollowing, followCountAll, postRemoveFollowAPI }
