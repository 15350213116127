import React from 'react'
import ListedCompanyVideo from './ListedCompanyVideo'

const VideoComponent = (companyShort:any) => {
  return (
    <>
      <ListedCompanyVideo companyShort={companyShort}></ListedCompanyVideo>
    </>
  )
}

export default VideoComponent;