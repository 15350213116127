import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { ISellerProduct } from "./Models/SellerProduct";
import { getProduct } from "../../services/MarketPlaceService";
import { Utility } from "../../utility/Utility";
import { SellerProductList } from "./SellerProductList";
import { addEntry, updateElapsedTime } from "../../services/UserImpressionService";

export function SellerProduct() {
    const params = useParams();
    const { sellerCode, productId } = params;
    const [product, setProduct] = useState<ISellerProduct>();
    const [pageRefTrackId, setPageRefTrackId] = useState<string>('');
    const [pageRefStartTime, setPageRefStartTime] = useState<number>(Date.now());

    useEffect(() => {
        Utility.setLoading(true);
        getProduct(sellerCode!, productId!).then(x => { setProduct(x); Utility.setLoading(false); });
    }, [sellerCode, productId])

    // Tracker
    useEffect(() => {
        function addImpression(product_id: string | null | undefined, pagereftrack_id: string | null | undefined) {
            if (product_id && product_id.length > 0) {
                if (pagereftrack_id && pagereftrack_id.length > 0) {
                    updateElapsedTime(pagereftrack_id, Math.floor((Date.now() - pageRefStartTime) / 1000));
                    setPageRefTrackId(() => '');
                }
                setPageRefStartTime(Date.now());
                addEntry('Product', product_id, 'View')
                    .then((data) => {
                        setPageRefTrackId(() => data);
                    });
            }
        }

        // async function updateImpression(product_id: string | null | undefined, pagereftrack_id: string | null | undefined) {
        //     if (product_id && product_id.length > 0 && pagereftrack_id && pagereftrack_id.length > 0) {
        //         await updateElapsedTime(pagereftrack_id, Math.floor((Date.now() - pageRefStartTime) / 1000));
        //         setPageRefTrackId(() => '');
        //     }
        // }

        addImpression(productId, pageRefTrackId);

        // return () => {
        //     updateImpression(productId, pageRefTrackId)
        // };
    }, [productId])

    return <> <Box sx={{ marginTop: "20px", marginBottom: "56px", padding: "22px", backgroundColor: "#FDFDFD", border: "1px solid #EBEEEF", borderRadius: "20px", display: "flex", gap: "34px", flexDirection: { md: "row", xs: "column" } }}>
        {product && <>
            <Box sx={{ width: { md: "60%", xs: "100%" }, height: "530px" }}>
                <Carousel autoPlay={true} infiniteLoop={true} showStatus={true} showThumbs={true} >
                    {product!.media!.map(x => <Box sx={{ height: "400px" }}><img style={{ borderRadius: "10px", height: "100%" }} src={x.link} /></Box>)}
                </Carousel>
                {product!.media?.length == 0 && <Box sx={{ height: "400px", background: "grey", borderRadius: "8px" }}><Typography sx={{
                    marginTop: "auto", color: "#FFFFFF", textAlign: "center",
                    paddingTop: "200px",
                    display: "block",
                    fontSize: "18px"
                }}>No Image</Typography></Box>}
            </Box>
            <Box sx={{ width: { md: "40%", xs: "100%" } }}>
                {/* <Typography sx={{ color: "#99A2A5", fontWeight: 400, fontSize: "14px", display: "flex" }}>SKU# {product?.sku}</Typography> */}
                <Typography sx={{ color: "#00171F", fontWeight: 400, fontSize: "24px", display: "flex" }}>{product?.name}</Typography>
                {/* <Typography sx={{ color: "#002A48", fontSize: "20px", fontWeight: 600 }}>₹  {product?.price}</Typography> */}
                <Box>
                    {
                        product?.details && product?.details.map(attr => (<Box sx={{ borderBottom: "1px solid #EBEEEF", "& span": { padding: "8px", fontSize: "14px", color: "#667479", width: "calc(50% - 16px)", marginTop: "10px", display: "inline-flex", textTransform: "capitalize" } }}>
                            <Typography>{attr.name}</Typography>
                            <Typography>{attr.value}</Typography>
                        </Box>))
                    }
                </Box>
            </Box>
        </>
        }
    </Box>

        <SellerProductList />
    </>
}