import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogTitle, IconButton, Paper, Typography, useMediaQuery, Tooltip, } from '@mui/material';
import useStyle from '../Styles';
import { styled } from '@mui/material/styles';
import { HistoricalData } from '../../../model/HistoricalDataModel';
import { getHistoricalData } from '../../../services/CompanyService';
import { DateExtensions } from '../../../utility/DateExtensions';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CloseIcon from '@mui/icons-material/Close';
import PopupIcon from '../../../components/Icons/PopupIcon';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../../model/ReduxStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 

export interface IEventCardProps {
    co_code: string | number | undefined;
    exchange?: 'NSE' | 'BSE';
    historicalData?: HistoricalData[];
    advancedTabCallback: Function;
    dataAvailable: boolean;
}

const ListedVolumeCard = (props: IEventCardProps) => {
    const classes = useStyle();
    const [historicalData, setHistoricalData] = React.useState<HistoricalData[] | undefined>(undefined);
    const [historicalDataMonth, setHistoricalDataMonth] = React.useState<JSX.Element | undefined>(undefined);
    const [showDialog, setShowDialog] = React.useState(false);
    const currentUser = useSelector( ( state: ReduxStore ) => state.userData.user );
    const [link,setLink]= React.useState(false)

    const CustomPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: '#F9F9F9',
        ...theme.typography.body2,
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'left',
    }));

    useEffect(() => {
        if (props.dataAvailable === true) {
            setHistoricalData(props.historicalData);

            if (showDialog === true && !(historicalDataMonth)) {
                setMonthlyDialogData();
            }
        }

        return () => {
            setHistoricalData(undefined);
            setHistoricalDataMonth(undefined);
        }
    }, [props.dataAvailable, showDialog])

    const setMonthlyDialogData = () => {
        if (props && props.exchange && props.co_code) {
            var d = new Date()
            d.setMonth(d.getMonth() - 1);
            getHistoricalData(props.exchange, props.co_code, d, new Date()).then(periodicStockData => {
                setHistoricalDataMonth(getChart(periodicStockData, 30));
            }).catch((ex) => {
                console.error(ex);
            });
        }
    };

    const getChart = (historicalData: HistoricalData[] | undefined, showCount: number) => {
        let categories: string[] = [];
        let volSeries: any[] = [];
        let delSeries: any[] = [];

        if (historicalData && historicalData.length > 0) {

            historicalData.sort(function (a, b) {
                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime();
            });

            let tempdata = historicalData;

            if (historicalData.length > showCount) {
                tempdata = historicalData.slice(0, showCount);
            }

            tempdata.forEach((x) => {
                volSeries.push(x.Volume.toFixed(2));
                delSeries.push(x.Del_Vol.toFixed(2));
                categories.push(DateExtensions.getDateOnlyString(new Date(x.date)));
            });
        }

        let series = [{ name: "Volume", data: volSeries }, { name: "Delivered Volume", data: delSeries }];

        const apexData: ApexOptions = {
            series: series,
            chart: {
                type: "bar",
                height: 320,
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: false,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: false,
                        delay: 0
                    },
                    dynamicAnimation: {
                        enabled: false,
                        speed: 800
                    }
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: "top"
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: "12px",
                    colors: ["#fff"]
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ["#fff"]
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (val, opts): any {
                        try {
                            if (opts.seriesIndex == 1) {
                                return `${val} (${((opts.series[1][opts.dataPointIndex] / opts.series[0][opts.dataPointIndex]) * 100).toFixed(2)}%)`;
                            }
                            else {
                                return val;
                            }
                        }
                        catch (error) {
                            console.error(error);
                            return val;
                        }
                    }
                }
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true
                },
                labels: {
                    style: {
                        colors: "#686868",
                        fontSize: "14px",
                        fontWeight: 700
                    }
                }
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
            },
            colors: ['#0F9764', '#12626C']
        };

        try {
            return <ReactApexChart options={apexData} series={apexData.series} type='bar' height='100%' width='100%' style={{ paddingY: '0px' }} />;
        }
        catch (error) {
            console.error("Chart generation failed", error);
        }
    }

    return props.dataAvailable ?
        <React.Fragment>
            <Box className={classes.root}>
                <Box className={classes.header}>
                    <Typography className={classes.headerTypography}>
                        Volume
                    </Typography>
                    <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                        <IconButton
                            onClick={() => {
                                window.location.hash = 'tabdetail-shareholding'
                                navigator.clipboard.writeText(window.location.href)
                                setLink(true)
                            }}
                        >
                            <ContentCopyIcon
                                sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                        </IconButton>
                    </Tooltip>
                    {currentUser && <Button className={classes.iconButton} startIcon={<PopupIcon style={{ height: "16px" }} />} variant="contained" color="primary" onClick={() => { setShowDialog(true); }}
                        sx={{ height: '35px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none', float: "right"}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pop Up&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button> }
                </Box>
                <Paper elevation={0}>
                    <Box className={classes.cardBody}>
                        <CustomPaper sx={{ width: "100%", height: "320px" }}>
                            {getChart(historicalData, 5)}
                        </CustomPaper>
                    </Box>
                </Paper>
            </Box>
            <Dialog onClose={() => setShowDialog(false)} open={showDialog} fullScreen>
                <DialogTitle>
                    <Typography className={classes.headerTypography}>
                        Volume
                    </Typography>
                    <IconButton
                        onClick={() => { setShowDialog(false); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#12626C'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Box className={classes.cardBody}>
                    <CustomPaper sx={{ width: "100%", height: "1200px" }}>
                        {historicalDataMonth}
                    </CustomPaper>
                </Box>
            </Dialog>
        </React.Fragment>
        :
        <React.Fragment />;
}

export default ListedVolumeCard;