export enum PathNames {
    // "HOME" = "/home",
    "PAYMENTSUCCESS" = '/payment-success',
    "PAYMENTFAILURE" = '/payment-failure',
    "PAYMENTREFUND" = '/payment-refund-policy',
    "COMUNITYFAQ" = "/investor-community-channel-india",
    "LISTEDFAQ" = "/listed-shares-research-india",
    "UNLISTEDFAQ"="/unlisted-shares-research-india",
    "NEWSBLOCK" = "/newsblock/:id",
    "PROFILE" = "/profile",
    "LISTED" = "/listed",
    "UNLISTED" = "/unlisted",
    "STARTUP" = "/listed",
    "SIGNIN" = "/signin",
    "SIGNUP" = "/signup",
    "SIGNOUT" = "/signout",
    "ACCOUNT" = "/account",
    "DeploymentHistory" = "/history",
    "CompanyOverviewWithCode" = "/company/:code",
    "CompanyOverview" = "/company/:code/companyname/:name",
    "AskMeListed" = "/askme/company/:code/",
    "CompanyOverviewWithName" = "/company/:coname/:cocode",
    "UnlistedCompanyOverview" = "/unlistedcompany",
    "ABOUT" = "/about",
    "ResetPassword" = "/password_reset",
    "FAQ_PAGE" = "/faq",
    "ADMIN_PANEL" = "/adminPanel",
    "Dashboard" ="/dashboard",
    "FEED" = "/community",
    "VIEW_USER" = "/viewuser",
    "CHANNELS" = "/channels",
    "CHANNELPREVIEW" = "/cp/*",
    // "WATCH_LIST" = "/watchlist",
    "USER_PREVIEW" = "/userpreview/*",
    "UNLISTED_PREVIEW"="/preview/unlisted/:companyName",
    "UNLISTED_DIRECTORY"="/company/unlisted-directory/*",
    "BLOGS" = "/blogs/*",
    "OUR_BLOGS" = "/blogs",
    "CAREERS" = "/careers",
    "TRENDING_USERS" = "/trendingUsers",
    "NOTIFICATION" = "/notifications",
    "PORTFOLIO" = "/portfolio",
    "PREMIUM" ="/premium-purchase",
    "PLANS" ="/premium-plans",
    "TRANSACT" ="/transact",
    "CONTACTUS" = "/contact-us",
    "NOT_FOUND" = "*",
    "NOT_FOUND_PAGE" = "/not-found",
    //bidhan 18th jan 2023//
    "JOIN" = "/join",
    "MARKETPLACE"="/marketplace",
    "DEVELOPERSUI"="/developer-ui",
    "UNLISTED_SCREENER" = "/unlisted-screener",
    "CREDIT_HISTORY" = "/credit-history"
}