import React, { useEffect, useState } from 'react';
import Story from "./Story";
import { Grid, Card, Typography, InputAdornment, OutlinedInput, CardActions, IconButton, Avatar, Button, CircularProgress } from '@mui/material';
import { getUserWall, postFeed, deleteFeed, updateFeed, getFeedById } from '../../services/SocialMediaService';
import { getCommentsAPI, createCommentAPI, deleteCommentAPI, updateCommentAPI } from '../../services/SocialMediaComment';
import { postLikeAPI, getLikesData } from '../../services/SocialMediaLike';
import { PostData } from '../../model/FeedDataModel';
import { LikeRequest } from '../../model/LikeModel';
import { CommentRequest, CommentUpdateRequest } from '../../model/CommentDataModel';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import { useSearchParams } from 'react-router-dom';
import { User } from '../../model/User';
import NewsFeed from '../NewsFeed/NewsFeed';

function UserPosts({user}:any) {


    const currentUser = useSelector((state: ReduxStore) => state.userData.user);
    const currentUserId = currentUser?._id || ""
    let viewUserId = user.id;   
    const [searchParams, setSearchParams] = useSearchParams();
    const code: string | null = searchParams.get('feedkey');
    viewUserId = searchParams.get('userid') ?? "";

    return <NewsFeed userId={viewUserId} feedApi={getUserWall} />
}

export default UserPosts;


