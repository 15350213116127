import { Box } from "@mui/material";
import react from "react";

const spinner = {
    margin: 0,
    width: "30px",
    textAlign: "center",
    padding: "10px",
    "& div": {
        "width": "10px",
        "height": "10px",
        borderRadius: "100%",
        display: "inline-block",
        "-webkit-animation": "chatbouncedelay 1.4s infinite ease-in-out both",
        animation: "chatbouncedelay 1.4s infinite ease-in-out both",
        background: "grey"
    },
    "& .bounce1": {
        "-webkit-animation-delay": "-0.32s",
        animationDelay: "-0.32s"
    },
    "& .bounce2": {
        "-webkit-animation-delay": "-0.16s",
        animationDelay: "-0.16s"
    }
}
export function ChatTypingBubbles() {
    return <Box sx={spinner}>
        <div className="bounce1" ></div>
        <div className="bounce2" ></div>
        <div></div>
    </Box>
}