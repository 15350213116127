import React from 'react';
import { Box, CardHeader, Card, CardContent, Paper, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TVChartContainer } from '../../../../components/TVChartContainer';
import { Company } from '../../../../model/CompaniesResult';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../../../model/ReduxStore';
import useStyle from '../../Styles';

export interface TechnicalsTVProps {
    companyShort: Company;
    load: boolean;
}

const TechnicalsTV = (props: TechnicalsTVProps) => {

    const currentUser = useSelector((state: ReduxStore) => state.userData.user);
    const currentUserId = currentUser ? currentUser._id : 'anonymous';

    const classParent = useStyle();

    return <React.Fragment>
        <Box className={classParent.root}>
            <Box className={classParent.header}>
                <Typography className={classParent.headerTypography}>
                    Chart
                </Typography>
            </Box>
            <Paper elevation={0} className={classParent.card}>
                <Divider />
                <TVChartContainer
                    symbol={props.companyShort.NSEListed === "Yes" ? `NSE:${props.companyShort.co_code}` : `BSE:${props.companyShort.co_code}`}
                    userId={currentUserId}
                />
                <Divider />
            </Paper>
        </Box>
    </React.Fragment>
}

export default TechnicalsTV

