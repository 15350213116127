import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme: any) => ({
    root: {
        padding: '24px',
        backgroundColor: '#F9F9F9',
        border: '1px solid #EBEBEB', borderRadius: '8px',
        marginBottom: "24px"
    },
    header: {
        marginTop: '0px',
        marginBottom: '10px'
    },
    headerTypography: {
        fontWeight: '700', fontSize: '24px', fontStyle: 'normal',
        lineHeight: "20px",
        color: '#12626C'
    },
    card: {
        paddingTop: '16px',
        paddingBottom: '18px',
        marginLeft: '0px',
        marginRight: '0px'
    },
    cardHeader: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginRight: '20px',
        },
        [theme.breakpoints.up('md')]: {
            // marginLeft: '45px',
            // marginRight: '45px',
        }
    },
    cardBody: {       
        backgroundColor: '#F9F9F9',
        '& .white-rectangle .MuiGrid-item .MuiPaper-root': {
            background: "#FFFFFF"
        }
    },
    iconButton: {
        '& .MuiButton-startIcon': {
            marginRight: "0px"
        }
    }
}));

export default useStyle;