import axios, { AxiosInstance } from 'axios';
import { Utility } from "../utility/Utility";
export function getAxioInstance(baseUrl: string): AxiosInstance {
    const instance = axios.create({
        baseURL: baseUrl
    });

    instance.interceptors.request.use(
        async config => {
            (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
            return config;
        },
        error => {
            Promise.reject(error)
        });

    // instance.interceptors.response.use((response) => {
    //     return response
    // }, async function (error) {
    //     const originalRequest = error.config;
    //     if (error.response.status === 401 && !originalRequest._retry) {
    //         console.log('Token has expired.');
    //         originalRequest._retry = true;
    //         const access_token = await Utility.getRefreshToken();
    //         axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    //         console.log('Token renewed. Retrying.');
    //         return instance(originalRequest);
    //     }
    //     return Promise.reject(error);
    // });
    return instance;
}