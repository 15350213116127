// Copyright 2023 vamsikrishnabakkamunthala
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Box, Typography } from '@mui/material'
import React from 'react'

type Props = {}

const PaymentRefund = (props: Props) => {
    return (
        <div className="container">
            <Box sx={{
                display: "flex",
                padding: { md: "0 18em", xs: "0 1em" },
                flexDirection: "column"
            }}>
                <p style={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "30px"
                }}
                >Refund and Cancellation Policy</p>
                <p style={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "20px"
                }}>
                    <p>Thanks for shopping at ​www.stockknocks.com.​</p>
                    <p>If you are not entirely satisfied with your purchase, you can sent us a request to cancel the
                        subscription at <a href="mailto:support@stockknocks.com">support@stockknocks.com</a> within 24hrs of
                        your purchase and we're here to
                        help.​</p>
                    <p>You have 48hrs to claim the refund. Once we receive your request, we will inspect it and notify
                        you that we have received your request.. We will immediately notify you on the status of your
                        refund. </p>
                    <p>
                        If your refund is approved, we will initiate a refund to your credit card (or original method of
                        payment).
                    </p>
                    <p>
                        You will receive the credit within a certain amount of days, depending on your card issuer's
                        policies.
                    </p>
                    <p>
                        If you receive a refund, the cost of processing (if any) will be deducted from your refund.
                    </p>
                    <p>For additional information and any queries,. <span style={{
                        fontStyle: "normal",
                        fontWeight: 700,
                    }}>Contact Us at <a
                        href="mailto:support@stockknocks.com">support@stockknocks.com</a></span>
                    </p>
                </p>
            </Box>
        </div>
    )
}

export default PaymentRefund