import { Autocomplete, Box, Checkbox, CircularProgress, FormControlLabel, IconButton, InputBase, Paper, Tooltip, Typography, debounce } from "@mui/material";
import MicIcon from "../../components/Icons/MicIcon";
import { useEffect, useState } from "react";


interface IOption {
    title: string; value: number;
}

interface IProps {
    options: IOption[];
    values: IOption[];
    onChange: (selectedValue: IOption[]) => void;
    label: string;
    hint?: string;
}

export function MultiselectControl(props: IProps) {
    const [searchText, setSearchText] = useState("");
    const [options, SetOptions] = useState<IOption[]>([]);

    const performSearch = async (event: any, changedValue: any) => {
        setSearchText(changedValue);
    }

    useEffect(() => {
        if (props.options) {
            const list = props.options.filter(x => x != undefined && x.title != undefined);
            SetOptions(list.filter(x => x.title.toLowerCase().indexOf(searchText.toLowerCase()) != -1 || searchText == '').slice(0, 5));
        }
        //SetOptions(props.options);       
    }, [props.options, searchText]);

    return <Box sx={{
        padding: "10px 28px"
    }}>
        <Typography sx={{ fontSize: "14px", marginBottom: "5px", color: "#393939", fontWeight: 700 }} component={"div"}>{props.label}</Typography>
        <Autocomplete
            multiple={true}
            options={options}
            getOptionLabel={option => option.title}
            onChange={(e, v) => { props.onChange(v) }}
            value={props.values}
            onInputChange={debounce(performSearch, 50)}
            openOnFocus={true}
            autoHighlight={true}
            sx={{ fontSize: "12px" }}
            renderOption={(props, options, state) => (<li {...props}><Typography sx={{ fontSize: "12px" }}>{options.title}</Typography></li>)}
            renderInput={(params) => <Paper
                component="div"
                ref={params.InputProps.ref}
                elevation={0}
                sx={{
                    borderRadius: '8px',
                    width: { md: "260px", xs: "100%" },
                    display: 'flex',
                    flexDirection: "row",
                    border: "1px solid #D0D5DD",
                    height: "45px"
                }}
            >
                <Tooltip title="Search" placement="left">
                    <IconButton
                        //onClick={}
                        aria-label="Search"
                        sx={{ marginLeft: "5px" }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.5 18.5L13.875 14.875M15.8333 10.1667C15.8333 13.8486 12.8486 16.8333 9.16667 16.8333C5.48477 16.8333 2.5 13.8486 2.5 10.1667C2.5 6.48477 5.48477 3.5 9.16667 3.5C12.8486 3.5 15.8333 6.48477 15.8333 10.1667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </IconButton>
                </Tooltip>
                <InputBase
                    placeholder={(props.hint && props.hint.length > 0) ? props.hint : "Search...."}
                    sx={{
                        ml: 1,
                        display: "flex",
                        flexGrow: 1,
                        paddingX: '2px',
                        fontSize: "14px",
                        marginLeft: 0,
                        paddingTop: "2px",
                        "& input::placeholder": {
                            fontSize: "12px"
                        }
                    }}
                    inputProps={{ ...params.inputProps, value: searchText }}
                />

            </Paper>
            } />
        {props.values && props.values.length > 0 && <Box sx={{
            marginTop: "10px", overflowY: "auto",
            maxHeight: "150px",
            paddingBottom: "5px",
            paddingTop: "10px",
            background: "#fff"
        }}>
            {
                props.values.map(v => (<FormControlLabel sx={{
                    display: "flex",
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginBottom: "5px",
                    '& .MuiFormControlLabel-label': { fontSize: "11px" },
                    '& .MuiCheckbox-root': { paddingY: "0px" }

                }} control={<Checkbox checked={true} onChange={(e, checked) => { props.onChange(props.values.filter(x => x.value != v.value)) }} />} label={v.title} />))
            }
        </Box>}
    </Box>
}