import { Button, Dialog, DialogActions, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
  
});


export default function PageRedirectionDialog({ isOpen, message, btnText, handleClose, handleConfirm }) {
    return (
        <>
            <Dialog open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-handle-confirm"
                
            >
                <DialogTitle sx ={{fontSize:{ md:'20px', xs:'14px'}, marginTop:'16px', width: { md:'480px', xs:'300px'}, height:'auto' }} >
                    {message}
                </DialogTitle>
                <DialogActions>
                    <Button
                        sx={{
                            marginBottom: '27px',
                            marginRight: { md: "100px", xs: "50px" }
                        }}
                        variant="outlined"
                        onClick={handleClose}>Cancel
                    </Button>
                    <Button
                        sx={{
                            marginBottom: '27px',
                            marginRight: { md: "100px", xs: "50px" }
                        }}
                        onClick={handleConfirm}
                        variant="contained">
                        {btnText}
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
}