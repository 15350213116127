export class StringExtensions {
    static humanize(str: string) {
        if (str?.trim()?.length > 0) {
            return str.trim()
                .replace(/^[\s_]+|[\s_]+$/g, '')
                .replace(/[_\s]+/g, ' ')
                .replace(/^[a-z]/, function (m) { return m.toUpperCase(); });
        }
        else {
            return str;
        }
    };

    static getSearchIdFromCompanyName(val: string) {
        let sid = '';
        let splittedSid = val?.trim()?.toLowerCase()
            .replaceAll('.', '')
            .replaceAll('&', ' ')
            .replaceAll('-', ' ')
            .replaceAll("'", ' ')
            .replaceAll('(', ' ')
            .replaceAll(')', ' ')
            .split(' ');
        splittedSid.forEach(curr => {
            let currStr = curr?.trim();
            if (currStr?.length > 0) {
                switch (currStr) {
                    case "-":
                        sid += currStr;
                        break;
                    case "co":
                        sid += ((sid.length > 0 && currStr.length > 0) ? '-' : '') + 'company';
                        break;
                    case "ltd":
                        sid += ((sid.length > 0 && currStr.length > 0)  ? '-' : '') + 'limited';
                        break;
                    default:
                        sid += ((sid.length > 0 && currStr.length > 0)  ? '-' : '') + currStr;
                        break;
                }
            }
        });

        return sid?.length > 0 ? sid : undefined;
    }
}