import { Switch, ToggleButton, ToggleButtonGroup, Box, Typography, SwitchProps, styled } from "@mui/material";

interface IProps {
    value: any;
    options: { title: string, value: any }[],
    onChange: (value: any) => void;
    label: string;
}
export const ToogleSwitch = (props: IProps) => {
    return <Box sx={{
        borderRadius: "5px",
        padding: "5px 28px",
        display: "flex"
    }}>
        <Typography sx={{ fontSize: "14px", marginBottom: "5px", color: "#393939", marginTop: "5px", fontWeight: 700 }} component={"div"}>{props.label}</Typography>
        <IOSSwitch sx={{ marginLeft: "auto" }} checked={props.value == true} color="primary" onChange={(e, v) => { props.onChange(v) }} />

        {/* <ToggleButtonGroup
            color="primary"
            value={props.value}
            exclusive
            onChange={(e, v) => props.onChange(v)}
            aria-label="Platform"
        >
            {
                props.options.map((x) => (<ToggleButton value={x.value}>{x.title}</ToggleButton>))
            }
        </ToggleButtonGroup> */}
    </Box>;
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 50,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : theme.palette.background || '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));