import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    CardActions,
    IconButton,
    Link,
    Paper,
    styled,
    Tabs,
    Typography,
    useMediaQuery,
    ToggleButtonGroup,
    ToggleButton,
    Tooltip
} from "@mui/material";
import {
    buttonUnstyledClasses,
    tabUnstyledClasses,
    TabUnstyled,
    TabsListUnstyled,
    TabPanelUnstyled
} from '@mui/base';
import Card from "@mui/material/Card";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import {
    useLocation,
    useNavigate,
    useParams,
    // Link, 
    // useLocation, 
    useSearchParams
} from "react-router-dom";
import { getBoardMeetings, getCompanyOwner, getHistoricalData, getPLStatement, getQuoteDetails, getScoreBoard, getShareHoldingPattern } from "../../services/CompanyService";
import { blue, red, green, purple, yellow } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import Grid from '@mui/material/Grid';
import ManagementDetails from "./Fundamentals/ManagementDetails";
import Financials from "./Fundamentals/Financials/Financials";
import ShareHoldings from "./Fundamentals/ShareHoldingDetails/ShareHoldings";
import { isLogoAvailable } from "../../services/FileService";
import PeerComparison from "./Fundamentals/PeerComparison";
import Ratios from "./Fundamentals/Ratios";
import TechnicalsTV from "./Technicals/TradingView/TechnicalsTV";
// import { WatchlistSelector } from "../../components/Watchlist/WatchlistSelector";
import ListedEventsCard from "./Home/EventsCard";
import ListedShareHoldingCard from "./Home/ShareHoldingCard";
import ListedScoreCard from "./Home/ScoreCard";
import { Price, Score } from "../../model/CompanyIndividualSummaryModel";
import ListedPriceCard from "./Home/PriceCard";
import ListedVolumeCard from "./Home/VolumeCard";
import useStyle from "./Styles";
import ListedFinancialsCard from "./Home/FinancialsCard";
import AddIcon from '@mui/icons-material/Add';
import StarIcon from "../../components/Icons/StarIcon";
import { DateExtensions } from "../../utility/DateExtensions";
import { HistoricalData } from "../../model/HistoricalDataModel";
import { ShareHoldingPatternInfo } from "../../model/ShareHoldingPatternsResult";
import SignupDialog from '../../components/SignupDialog/SignupDialog'
import { UnfoldMore } from "@mui/icons-material";
// import SCSecuritiesClient, { BuySellType } from '../../components/Smallcase/SCSecuritiesClient'
import { MobileAdvert } from "../Layout/MobileAdvert";
import ListedOwnCompanyDialog from "../../components/QuetionDialog/ListedOwnCompanyDialog";
import PostSectionComponent from "../../components/CompanyPosts/PostSection";
import { CompanyOwner } from "../../model/UnlistedCompanyBasicDetails";
import { FollowRequest } from "../../model/FollowModel";
import { amIFollowing, postFollowAPI, postUnFollowAPI } from "../../services/SocialMediaFollow";
import { Utility } from "../../utility/Utility";
import { Notification } from "../../model/Notification";
import VerifiedIcon from '@mui/icons-material/Verified';
import { PathNames } from "../../appConfig/PathNames";
import WebsiteComponent from "./Website/WebsiteComponent";
import VideoComponent from "./Videos/VideoComponent";


const TabStyled = styled(TabUnstyled)`
cursor: pointer;
font-size: 0.8rem;
background-color: #EBEBEB;
width: 100%;
height: 100%;
justifyContent: center;
padding: 10px;
border: none;
display: flex;
color: #000;

&:hover {
  background-color: #FBFAFA;
}

&:focus {
  color: #fff;
}

&.${tabUnstyledClasses.selected} {
  background-color: ${"#FFFFFF"};
  color: ${"#000000"};
}

&.${buttonUnstyledClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
}
`;

const TabPanelStyled = styled(TabPanelUnstyled)`
width: 100%;
background-color: "darkgray"
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
`;

const TabsListStyled = styled(TabsListUnstyled)`
min-width: 320px;
background-color: gray;
margin-bottom: 16px;
display: flex;
align-items: center;
justifyContent: center;
align-content: space-between;
`;

const getRandomColor = () => {
    const colors = [blue, red, green, purple, yellow];
    return colors[Math.floor(Math.random() * 5 + 0)];
}

export interface QuoteData {
    co_code: string;
    exchange: string;
    real_time_price: number;
    real_time_date: string;
    change_in_point: number;
    change_in_percent: number;
    isin: string;
    open_price: number;
}

const CompanyOverview = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { code } = useParams();
    const tab: string | null = searchParams.get('tab');
    const secondaryTab: string | null = searchParams.get('secondaryTab');

    const companyShort = useSelector((store: ReduxStore) => store.app.companiesData.companies.find(u => u.co_code == code as any));
    const [owner, setOwner] = React.useState<CompanyOwner | null>(null);

    const [exchange, setExchange] = React.useState<'NSE' | 'BSE'>(companyShort?.NSEListed == 'No' ? 'BSE' : 'NSE');

    const [isFollow, setFollow] = React.useState<boolean>(false);

    const [rootTab, setRootTab] = React.useState<string>('overview');
    const [mainTab, setMainTab] = React.useState<string>('score');
    const [color, setColor] = React.useState<any>(null);
    const currentUser = useSelector((state: ReduxStore) => state.userData.user);
    const [scoreData, setScoreData] = React.useState<Score | undefined>(undefined);
    const [priceData, setPriceData] = React.useState<Price | undefined>(undefined);
    const [historicalData, setHistoricalData] = React.useState<HistoricalData[] | undefined>(undefined);
    const [pnlData, setPnlData] = React.useState<any | undefined>(undefined);
    const [shareholdingData, setShareholdingData] = React.useState<ShareHoldingPatternInfo[] | undefined>(undefined);
    const [boardMeetings, setBoardMeetings] = React.useState<any | undefined>(undefined);
    const [showSignupDialog, setShowSignupDialog] = React.useState<boolean>(false);
    const [showOwnCompanyDialog, setShowOwnCompanyDialog] = React.useState<boolean>(false);

    const [quote, setQuote] = React.useState<QuoteData | undefined>(undefined);

    const [logoBox, setLogoBox] = React.useState<any>(null);

    //#region SmallCase
    // const [SCBuySellEnabled, setSCBuySellEnabled] = React.useState<boolean>(false);
    // const [BuySell, setBuySell] = React.useState<BuySellType>(BuySellType.NONE);
    // const [openBuySell, setOpenBuySell] = React.useState<boolean>(false);
    //#endregion

    const isSM = useMediaQuery("(min-width:600px)");
    const classes = useStyle();
    const navigate = useNavigate();
    const path = useLocation();

    // const [showWatchlistSelector, setShowWatchlistSelector] = useState(false)

    const toggleFollow = async () => {
        // if (companyShort) {
        //     setFollow(!isFollow);
        //     const followingData: FollowRequest = { following_id: companyShort.id, following_type: "Company" }
        //     isFollow ? await postUnFollowAPI(followingData) : await postFollowAPI(followingData);
        //     const message: string = isFollow ? "You've unfollowed " + companyShort.CompanyName : "You've followed " + companyShort.CompanyName;
        //     const notification: Notification = { message, type: "success" }
        //     Utility.notify(notification)
        // }
    }

    //#region SmallCase
    // const buysellClickHandler = (buysellType: BuySellType) => {
    //     if (buysellType === BuySellType.BUY || buysellType === BuySellType.SELL) {
    //         setBuySell(buysellType);
    //     }

    //     // setOpenBuySell(true);
    // }

    // useEffect(() => {
    //     if ((BuySell === BuySellType.BUY || BuySell === BuySellType.SELL) && SCBuySellEnabled) {
    //         setOpenBuySell(true);
    //     }
    // }, [BuySell, SCBuySellEnabled])

    // useEffect(() => {
    //     if (companyShort && companyShort.NSESymbol && companyShort.NSESymbol?.length > 0 && code) {
    //         setSCBuySellEnabled(true);
    //     }
    //     else {
    //         setSCBuySellEnabled(false);
    //     }
    // }, [companyShort, companyShort?.NSESymbol, code])
    //#endregion



    const handleSignupDialogClose = () => {
        setShowSignupDialog(false)
    }

    const onOwnCompanyClick = () => {
        setShowOwnCompanyDialog(true);
    }

    const handleOwnCompanyDialogClose = () => {
        setShowOwnCompanyDialog(false);
    }

    const handleSignupClick = () => {
        navigate(`/signup?from=${path.pathname}${path.search}`)
    }

    const fetchScore = (co_code: string | number) => {
        getScoreBoard(co_code).then(scoreResp => {
            if (scoreResp) {
                let tempScore: Score = {
                    date: scoreResp.SCR_DT,
                    equity: scoreResp.EQC,
                    face_value: scoreResp.FV,
                    reserves: scoreResp.RES,
                    dividend: scoreResp.DIV,
                    sales_turnover: scoreResp.STO,
                    net_profit: scoreResp.NP,
                    full_year_cps: scoreResp.CPS,
                    eps: scoreResp.EPS,
                    t12mnth_yrc: scoreResp.RLFYYRC,
                    t12mnth_sto: scoreResp.RLFYSTO,
                    t12mnth_op: scoreResp.RLFYOP,
                    t12mnth_opm: scoreResp.RLFYOPM,
                    t12mnth_gp: scoreResp.RLFYGP,
                    t12mnth_gpm: scoreResp.RLFYGPM,
                    t12mnth_np: scoreResp.RLFYNP,
                    t12mnth_npv: scoreResp.RLFYNPV.trim(),
                    lq_opm: scoreResp.LATHYOPM,
                    fy_nsv: scoreResp.stovar,
                    lq_nsv: scoreResp.vstovar,
                    pv_to_bv: scoreResp.PVBV,
                    ttm_dy: scoreResp.DivYield,
                    ttm_roe: scoreResp.ROE_TTM,
                    ttm_ind_pe: scoreResp.IndustryPE_TTM,
                    out_standing_shares: scoreResp.OutStandingShares,
                };
                setScoreData(tempScore);
            }
        }).catch((ex) => {
            console.error(ex);
        });
    }

    const fetchHistoricalData = (co_code) => {
        const date = new Date();
        const curDate = new Date(DateExtensions.getDateString(date));
        const oldDate = new Date(DateExtensions.getDateString(DateExtensions.addDays(date, -9)));
        getHistoricalData(exchange, co_code, oldDate, curDate).then(periodicStockData => {
            setHistoricalData(periodicStockData);
        }).catch((ex) => {
            console.error(ex);
        });
    }

    const fetchPnLData = (co_code) => {
        getPLStatement(co_code, 'S').then(pnlData => {
            setPnlData(pnlData);
        }).catch((ex) => {
            console.error(ex);
        });
    }

    const fetchShareholdingData = (co_code) => {
        getShareHoldingPattern(co_code).then(shareholdingData => {
            setShareholdingData(shareholdingData);
        }).catch((ex) => {
            console.error(ex);
        });
    }

    const fetchBoardMeetings = (co_code) => {
        getBoardMeetings(co_code).then(boardMeetings => {
            setBoardMeetings(boardMeetings);
        }).catch((ex) => {
            console.error(ex);
        });
    }

    const fetchCompanyOwner = (co_code) => {
        getCompanyOwner(co_code).then((ownerdata) => {
            if (ownerdata.hasOwner === true && ownerdata.owner) {
                setOwner(ownerdata.owner);
                if (ownerdata.logo && ownerdata.logo.length > 0) {
                    ValidateLogo(companyShort?.isin, ownerdata.logo);
                }
            }
            else {
                ValidateLogo(companyShort?.isin);
            }
        }).catch((ex) => {
            console.error(ex);
            ValidateLogo(companyShort?.isin);
        });
    }

    const navigateTo = (tab: string, hash: string | null, secondaryTab = "") => {
        navigate(
            `/company/${code}/companyname/${companyShort?.CompanyName.replaceAll(' ', '-')}?${tab ? "tab=" + tab : ""}${secondaryTab ? ('&secondaryTab=' + secondaryTab) : ""}${hash ? hash : path.hash}`)
    }

    const onRootTabClick = (event: React.SyntheticEvent | null, newValue: string) => {
        if (newValue && !(rootTab === newValue)) {
            setRootTab(newValue);
            if (newValue === "overview") {
                setMainTab("score");
                navigateTo(newValue, `#tabdetail-score`)
            }
            else if (newValue === "fundamentals") {
                if (currentUser) {
                    setMainTab("financials");
                    navigateTo(newValue, `#tabdetail-financials`);
                }
                else
                    setShowSignupDialog(true)
            }
            else if (newValue === "technicals") {
                if (currentUser) {
                    setMainTab("technicals");
                    navigateTo(newValue, `#tabdetail-technicals`)
                }
                else
                    setShowSignupDialog(true)
            }
            else if (newValue === "posts") {
                if (currentUser) {
                    setMainTab("posts");
                    navigateTo(newValue, `#tabdetail-posts`)
                }
                else
                    setShowSignupDialog(true)
            }
            else if (newValue === "website") {
                if (currentUser) {
                    setMainTab("website");
                    navigateTo(newValue, `#website`)
                }
                else
                    setShowSignupDialog(true)
            }
            else if (newValue === "video") {
                if (currentUser) {
                    setMainTab("video");
                    navigateTo(newValue, `#video`)
                }
                else
                    setShowSignupDialog(true)
            }

        }
    };

    const handleMainTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setMainTab(newValue);
        navigateTo(tab as string, `#tabdetail-${newValue}`)
    };

    const ValidateLogo = async (isin: string | undefined, logo: string | undefined | null = null) => {
        if (logo && logo.trim()?.length > 0) {
            await setLogoBox(
                <Box
                    component="img"
                    alt="logo"
                    src={logo}
                    sx={{
                        padding: '10px',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                        color: color,
                        backgroundColor: "#FFFFFF",
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                </Box>
            );
        }
        else {
            //co_view && fetch(co_view.logo_url, { mode: 'no-cors', method: 'HEAD' })
            let logo_url = `https://storage.googleapis.com/sockknocks-stock-assets/logos/${isin?.toUpperCase()}.png`;
            isin && isin.length && isLogoAvailable(logo_url)
                .then(async (res) => {
                    if (res) {
                        //if (res.status === 200 || res.status === 0) {
                        await setLogoBox(
                            <Box
                                component="img"
                                alt="logo"
                                src={logo_url}
                                sx={{
                                    padding: '10px',
                                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                    width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    color: color,
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                            </Box>
                        );
                    }
                    else {
                        await setLogoBox(
                            <Box
                                sx={{
                                    padding: '10px',
                                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                    width: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    height: { xl: "70px", lg: "70px", md: "70px", sm: "70px", xs: "50px" },
                                    color: color,
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography title={companyShort?.NSESymbol} variant={companyShort?.NSESymbol && companyShort?.NSESymbol?.length > 7 ? "h2" : "h6"} sx={{ fontWeight: 'bold', overflow: 'hidden' }}>
                                    {companyShort?.NSESymbol ? (companyShort?.NSESymbol?.length > 7 ? companyShort?.NSESymbol[0] : companyShort?.NSESymbol) : companyShort?.CompanyName?.[0]}
                                </Typography>
                            </Box>
                        );
                    }

                }).catch(ex => {
                    setLogoBox(
                        <Box
                            sx={{
                                padding: '10px',
                                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                                width: '100px',
                                height: '100px',
                                position: 'absolute',
                                top: '70px',
                                left: '20px',
                                color: color,
                                backgroundColor: "#FFFFFF",
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography title={companyShort?.NSESymbol} variant={companyShort?.NSESymbol && companyShort?.NSESymbol?.length > 7 ? "h2" : "h6"} sx={{ fontWeight: 'bold', overflow: 'hidden' }}>
                                {companyShort?.NSESymbol ? (companyShort?.NSESymbol?.length > 7 ? companyShort?.NSESymbol[0] : companyShort?.NSESymbol) : companyShort?.CompanyName?.[0]}
                            </Typography>
                        </Box>
                    );
                });
        }
    };

    useEffect(() => {
        if (companyShort) {
            amIFollowing(companyShort.id, "Company").then(follow => {
                if (follow && follow.length > 0) {
                    setFollow(true);
                }
            });
        }

        if (companyShort?.NSEListed == 'No') {
            if (!(exchange === 'BSE')) {
                setExchange('BSE');
            }
        }
        else {
            if (!(exchange === 'NSE')) {
                setExchange('NSE');
            }
        }
    }, [companyShort])

    const changeExchangeEvent = () => {
        let tempExchange = exchange?.trim()?.toUpperCase();
        if (companyShort?.NSEListed === 'No') {
            // Not Supported
        }
        else {
            if (tempExchange === 'NSE') {
                setExchange('BSE');
            }
            else if (tempExchange === 'BSE') {
                setExchange('NSE');
            }
        }
    }

    useEffect(() => {
        if (code != null) {
            setColor(getRandomColor()[900]);
            // ValidateLogo(companyShort?.isin);
            fetchCompanyOwner(code);
            setRootTab("overview");
            setMainTab("score");

            fetchScore(code);
            fetchHistoricalData(code);
            fetchPnLData(code);
            fetchShareholdingData(code);
            fetchBoardMeetings(code);
        }

        return () => {
            setOwner(null);
            setColor(getRandomColor()[900]);
        }
    }, [code]);

    useEffect(() => {
        if (path?.hash) {
            const tab = path.hash.replace('#tabdetail-', '')
            setMainTab(tab)
            window.location.href = path.hash
        }
        if (tab) {
            setRootTab(tab)
            navigateTo(tab, path?.hash)
        }
        if (secondaryTab && tab) {
            setRootTab(tab);
            navigateTo(tab, path?.hash, secondaryTab);
        }
    }, []);

    const getQuoteData = useCallback((co_code: string, exc: string) => {
        if (co_code) {
            getQuoteDetails(co_code, exc.toLowerCase())
                .then((value) => {
                    if (value) {
                        if (exc?.toLowerCase() === "nse") {
                            let tqd: QuoteData | any = {
                                real_time_date: value.Upd_Time,
                                real_time_price: value.Price,
                                change_in_point: value.PriceDiff,
                                change_in_percent: value.change,
                                open_price: value.open_Price,
                                co_code: co_code,
                                exchange: exc,
                                isin: value.isin
                            }
                            setQuote(tqd);
                        } else {
                            let tqd: QuoteData | any = {
                                real_time_date: value.Upd_Time,
                                real_time_price: value.price,
                                change_in_point: value.Pricediff,
                                change_in_percent: value.change,
                                open_price: value.Open_Price,
                                co_code: co_code,
                                exchange: exc,
                                isin: value.isin
                            }
                            setQuote(tqd);
                        }

                        let tp: Price | any = {
                            date: value.Upd_Time,
                            high_day: value.High_Price,
                            low_day: value.Low_Price,
                            high_52_wk: value.HI_52_WK,
                            low_52_wk: value.LO_52_WK,
                            open: exc.toUpperCase() === "BSE" ? value.Open_Price : value.open_Price,
                            price: exc.toUpperCase() === "BSE" ? value.price : value.Price,
                            prev_close: exc.toUpperCase() === "BSE" ? value.OldPrice : value.Oldprice,
                            bbuy_price: exc.toUpperCase() === "BSE" ? value.bbuy_price : value.BBuy_Price,
                            bbuy_qty: exc.toUpperCase() === "BSE" ? value.bbuy_qty : value.BBuy_Qty,
                            bsell_price: exc.toUpperCase() === "BSE" ? value.bsell_price : value.BSell_Price,
                            bsell_qty: exc.toUpperCase() === "BSE" ? value.bsell_qty : value.BSell_Qty,
                            price_diff: exc.toUpperCase() === "BSE" ? value.Pricediff : value.PriceDiff,
                            price_change: value.change,
                        };
                        setPriceData(tp);
                    }

                });
        }
    }, []);

    useEffect(() => {
        if (code != null) {
            getQuoteData(code, exchange);
        }

        const interval = setInterval(() => {
            if (code != null) {
                getQuoteData(code, exchange);
            }
        }, 900000);

        return () => {
            clearInterval(interval);
            setQuote(undefined);
        }
    }, [code, exchange, getQuoteData])


    function renderSharePrice() {
        return (
            <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "right", padding: '0px' }}>
                <Box sx={{ display: "flex", justifyContent: { md: 'right', xs: 'right', sm: 'right' } }}>
                    <IconButton
                        color="primary"
                        onClick={changeExchangeEvent}
                        sx={{ fontSize: { xs: "12px", md: "16px" }, padding: { xs: '0px' } }}
                        disableRipple
                        disableFocusRipple size="small">
                        {exchange?.toUpperCase()}
                        <UnfoldMore />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: "12px", md: "24px" }, fontWeight: 'bold', color: "#12626C", marginTop: { xs: '3px', md: '0px' } }}>
                        {/* {cmpOverview?.close_price && `₹ ${cmpOverview?.close_price}`} */}
                        {quote?.real_time_price && `₹ ${quote.real_time_price?.toFixed(2)}`}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: { md: 'right', xs: 'right' } }}>
                    <Typography
                        sx={{
                            fontWeight: '400',
                            fontSize: { md: "16px", xs: "12px" },
                            color: (quote?.change_in_point && (quote.change_in_point.valueOf() | 1) > 0) ? '#0f9764' : '#FF0202'
                        }}
                    >
                        {quote?.change_in_point?.toFixed(2) || "0.00"}&nbsp;({quote?.change_in_percent?.toFixed(2) || "0.00"}&nbsp;%)
                    </Typography>
                </Box>
                {
                    //#region SmallCase
                    // SCBuySellEnabled &&
                    // <Box sx={{
                    //     display: "flex", justifyContent: 'right', alignItems: 'right',
                    //     height: '34px', minHeight: '34px', maxHeight: '34px', paddingX: '0px'
                    // }}>
                    //     <Button
                    //         sx={{
                    //             paddingX: '0px',
                    //             minWidth: '58px',
                    //             width: '58px !important',
                    //             color: '#FFFFFF',
                    //             backgroundColor: '#0F9764',
                    //             border: 'none',
                    //             borderRadius: '15px 0px 0px 15px',
                    //             '&:hover': {
                    //                 backgroundColor: "#0F9764",
                    //                 color: '#FFFFFF',
                    //             }
                    //         }}
                    //         onClick={() => { buysellClickHandler(BuySellType.BUY) }}
                    //     >
                    //         {"Buy"}
                    //     </Button>
                    //     <Button
                    //         sx={{
                    //             paddingX: '0px',
                    //             minWidth: '58px',
                    //             width: '58px',
                    //             color: '#FFFFFF',
                    //             backgroundColor: '#EB4335',
                    //             border: 'none',
                    //             borderRadius: '0px 15px 15px 0px',
                    //             '&:hover': {
                    //                 backgroundColor: "#EB4335",
                    //                 color: '#FFFFFF',
                    //             }
                    //         }}
                    //         onClick={() => { buysellClickHandler(BuySellType.SELL) }}
                    //     >
                    //         {"Sell"}
                    //     </Button>
                    // </Box>
                    //#endregion
                }
            </Box>);
    }

    return (
        <React.Fragment>
            <Box className="page" style={{ position: "relative" }}>
                <Paper id='company-header' elevation={0}
                    sx={{
                        marginY: '0px', marginX: '0px',
                        backgroundColor: '#F9F9F9',
                        border: '1px solid #EBEBEB', borderRadius: '8px',
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '24px' }}>
                        <Grid container spacing={0.1}>
                            <Grid item container md={9} xs={12} sx={{ display: 'flex', flexDirection: "row" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '5px' }}>
                                    {logoBox}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', padding: { md: "0px 24px", xs: "0px 8px" }, maxWidth: { xs: "calc(100% - 100px)", md: "auto" } }}>
                                    <Box>
                                        <Box flexDirection="row" display="flex">
                                            <Typography color="#12626C" sx={{ fontSize: { xs: "16px", md: '24px' }, fontWeight: '700' }} variant="h6">
                                                {companyShort?.CompanyName?.split(' ')[0]} {companyShort?.CompanyName?.split(' ').slice(1).join(' ')}
                                            </Typography>
                                            {/* <IconButton onClick={() => setShowWatchlistSelector(true)} sx={{ marginLeft: "5px", paddingTop: "2px" }}> <StarIcon /></IconButton> */}
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", width: { xs: "110%", md: "auto" } }}>
                                            <Typography component="div" sx={{ color: "#686868", fontSize: "14px", maxWidth: { xs: "135px", md: "100%" } }}>
                                                {companyShort?.SectorName}
                                            </Typography>
                                            <Box sx={{ display: { xs: "flex", md: "none", justifyContent: "right", width: "120px" } }}>
                                                {renderSharePrice()}
                                            </Box>
                                        </Box>
                                        <CardActions style={{ padding: "8px 0px 0px 0px" }}>
                                            {/* <Box >
                                        <Button
                                            sx={{ border: '2px solid', fontWeight: 700, wordWrap: 'normal' }}
                                            variant="outlined"
                                            href={cmpSnap?.website || ''}
                                            target="_blank" rel="noopener noreferrer">
                                            VISIT WEBSITE
                                            <OpenInNewIcon sx={{ paddingX: '2px' }} fontSize="small" />
                                        </Button>
                                    </Box> */}
                                            {(!((owner?.id || '') === (currentUser?._id || ''))) && <Box>
                                                <Button
                                                    onClick={toggleFollow}
                                                    sx={{ border: '1px solid', paddingLeft: "20px", fontWeight: 700, wordWrap: 'normal', fontSize: "14px" }}
                                                    variant="outlined"
                                                    startIcon={<AddIcon />}>
                                                    {isFollow ? 'Unfollow' : 'Follow'}
                                                </Button>
                                            </Box>}
                                            {(!(owner && owner.id?.length > 0)) && <Box >
                                                <Button
                                                    // onClick={onOwnCompanyClick}
                                                    sx={{ fontSize: { md: '14px', xs: '10px' }, padding: "1px 20px", fontWeight: "700", height: "36px" }}
                                                    size="small"
                                                    variant="contained">
                                                    Own This Page
                                                </Button>
                                            </Box>}
                                            {(owner && owner.id?.length > 0) && <Tooltip title={"Stockknocks Verified"}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #0f9764', borderRadius: '24px',
                                                        // paddingLeft: "20px", 
                                                        fontWeight: 700, wordWrap: 'normal', fontSize: "14px",
                                                        height: '35px', width: '100px',
                                                        display: 'flex', justifyContent: 'center', alignItems: "center",
                                                    }}>
                                                    <VerifiedIcon color="primary" />
                                                    <Typography sx={{
                                                        fontSize: '14px', fontWeight: "700", height: "35px", wordWrap: 'normal',
                                                        paddingLeft: "8px",
                                                        color: '#0f9764',
                                                        display: 'flex', justifyContent: 'center', alignItems: 'center'
                                                    }}>Verified</Typography>
                                                </Box>
                                            </Tooltip>}
                                            {/* <Box >
                                                <Button
                                                    sx={{ border: '2px solid', fontWeight: 700, wordWrap: 'normal' }}
                                                    variant="outlined"
                                                    onClick={() => setShowWatchlistSelector(true)}>
                                                    + ADD TO WATCHLIST
                                                </Button>
                                            </Box> */}
                                        </CardActions>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item container md={3} xs={12} sx={{ justifyContent: "right", display: { xs: "none", md: "flex" } }}>
                                {renderSharePrice()}
                            </Grid>
                        </Grid>
                    </Box >
                </Paper >
                <Paper
                    id='company-section-header'
                    className={classes.card} elevation={0}
                    sx={{ paddingTop: "10px!important", marginTop: '24px', marginBottom: '5px', border: '1px solid #EBEBEB', overflowX: isSM ? 'hidden' : 'auto', overflowY: isSM ? 'hidden' : 'auto' }}>
                    <Box id="root-tab" sx={{ marginX: '5px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <CardActions sx={{ paddingY: "0px", display: "block", "& button": { marginTop: "14px" } }}>
                            <Button
                                sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    padding: "10px 16px",
                                    lineHeight: "normal",
                                    fontWeight: "700",
                                    height: "40px",
                                    width: { xs: 'auto', md: '160px' },
                                }}
                                size="small"
                                variant={rootTab === "overview" ? "contained" : "outlined"}
                                onClick={(e) => onRootTabClick(e, "overview")}
                            >{isSM ? 'Company Overview' : 'Overview'}</Button>
                            <Button
                                sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    height: "40px",
                                    padding: { xs: '10px 24px' },
                                    fontWeight: "700",
                                    width: { xs: 'auto', md: 'auto' },
                                }}
                                size="small"
                                variant={rootTab === "fundamentals" ? "contained" : "outlined"}
                                onClick={(e) => onRootTabClick(e, "fundamentals")}
                            >Fundamentals</Button>
                            <Button
                                sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    height: "40px",
                                    padding: "10px 16px",
                                    fontWeight: "700"
                                }}
                                size="small"
                                variant={rootTab === "technicals" ? "contained" : "outlined"}
                                onClick={(e) => onRootTabClick(e, "technicals")}
                            >Technicals</Button>
                            <Button
                                sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    height: "40px",
                                    padding: "10px 16px",
                                    fontWeight: "700"
                                }}
                                size="small"
                                variant={rootTab === "posts" ? "contained" : "outlined"}
                                onClick={(e) => onRootTabClick(e, "posts")}
                            >Posts</Button>
                            {(owner && owner.id?.length > 0) &&
                                <Button
                                    sx={{
                                        fontSize: { xs: '12px', md: '14px' },
                                        height: "40px",
                                        padding: "10px 16px",
                                        fontWeight: "700"
                                    }}
                                    size="small"
                                    variant={rootTab === "website" ? "contained" : "outlined"}
                                    onClick={(e) => onRootTabClick(e, "website")}
                                >Website</Button>}
                            <Button
                                sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    height: "40px",
                                    padding: "10px 16px",
                                    fontWeight: "700"
                                }}
                                size="small"
                                variant={rootTab === "video" ? "contained" : "outlined"}
                                onClick={(e) => onRootTabClick(e, "video")}
                            >Videos</Button>
                            {/* <Button
                                sx={{ fontSize: { xs: '12px', md: '14px' }, height: "40px", padding: "10px 16px", fontWeight: "700", width: "80px" }}
                                size="small"
                                variant={rootTab === "Askme" ? "contained" : "outlined"}
                                onClick={(e) => navigate(PathNames.AskMeListed.replace(':code', companyShort!.co_code.toString()))}
                            >Ask me</Button> */}

                        </CardActions>
                    </Box>
                    <Box id="mainTab" sx={{ width: '100%', typography: 'body1', display: 'flex', marginX: '5px' }}>
                        <TabContext value={mainTab}>
                            <Box sx={{ borderBottom: 0, borderColor: 'divider', display: 'inline-grid' }}>
                                {
                                    (rootTab === "overview") &&
                                    <Tabs
                                        sx={{ marginBottom: -2.5 }}
                                        value={mainTab}
                                        variant="scrollable"
                                        scrollButtons={false}
                                        textColor="inherit"
                                        indicatorColor={"primary"}
                                        TabIndicatorProps={{ style: { height: "3px" } }}
                                        allowScrollButtonsMobile
                                        onChange={handleMainTabChange}
                                        aria-label="main-tabs">
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-score">Score</Link>} value="score" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-price">Price</Link>} value="price" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-volume">Volume</Link>} value="volume" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-fincard">Financials</Link>} value="fincard" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-shareholding">Share Holdings</Link>} value="shareholding" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" href="#tabdetail-keyevents">Events</Link>} value="keyevents" />
                                    </Tabs>
                                }
                                {
                                    (rootTab === "fundamentals") && currentUser &&
                                    <Tabs
                                        sx={{ marginBottom: -2.5 }}
                                        value={mainTab}
                                        variant="scrollable"
                                        scrollButtons={false}
                                        textColor="inherit"
                                        indicatorColor="primary"
                                        allowScrollButtonsMobile
                                        onChange={handleMainTabChange}
                                        aria-label="main-tabs">
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" >Financials</Link>} value="financials" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" >Ratios</Link>} value="ratios" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" >Peer Comparison</Link>} value="peercomparison" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" >Shareholdings Pattern</Link>} value="shareholdingspattern" />
                                        {/* <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px" }} label="Corporate Actions" value="corporateactions" />
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px" }} label="Deals" value="deals" /> */}
                                        <Tab sx={{ textTransform: 'capitalize', fontWeight: 700, fontSize: "14px", paddingBottom: "0px" }} label={<Link color={"#686868"} underline="none" >Management</Link>} value="management" />
                                    </Tabs>
                                }
                            </Box>
                        </TabContext>
                    </Box>
                </Paper>
                <Card id='company-section-body' classes={{ root: 'informationBlockWhite' }} sx={{ marginTop: '24px', border: 'none', boxShadow: 'none' }}>
                    <Box>
                        <TabContext value={mainTab}>
                            {
                                ['score', 'price', 'volume', 'fincard', 'shareholding', 'keyevents'].includes(mainTab) &&
                                <React.Fragment>
                                    <Box id='tabdetail-score'>
                                        {companyShort && companyShort.co_code &&
                                            <ListedScoreCard companyShort={companyShort} score={scoreData}></ListedScoreCard>
                                        }
                                    </Box>
                                    <Box id="tabdetail-price">
                                        <MobileAdvert />
                                        <ListedPriceCard co_code={companyShort?.co_code} exchange={exchange} historicalData={historicalData} price={priceData} advancedTabCallback={() => { onRootTabClick(null, "technicals"); }}></ListedPriceCard>
                                    </Box>
                                    <Box id="tabdetail-volume">
                                        <ListedVolumeCard co_code={companyShort?.co_code} exchange={exchange} historicalData={historicalData} dataAvailable={historicalData ? true : false} advancedTabCallback={() => { onRootTabClick(null, "technicals"); }} />
                                    </Box>
                                    <Box id="tabdetail-fincard">
                                        <ListedFinancialsCard co_code={companyShort?.co_code} displayType={companyShort?.DisplayType || ""} recordType={"S"} exchange={exchange} pnl={pnlData} dataAvailable={pnlData ? true : false} advancedTabCallback={() => { onRootTabClick(null, "fundamentals"); }} />
                                    </Box>
                                    <Box id="tabdetail-shareholding">
                                        <MobileAdvert />
                                        <ListedShareHoldingCard co_code={companyShort?.co_code} companyShort={companyShort} share_holding_pattern={shareholdingData} advancedTabCallback={() => { onRootTabClick(null, "fundamentals"); setMainTab("shareholdingspattern"); }}></ListedShareHoldingCard>
                                    </Box>
                                    <Box id="tabdetail-keyevents">
                                        {/* {companyShort && companyShort.co_code && corporateActions && GetKeyEventsContents(companyShort.co_code.toString(), corporateActions.board_meetings)} */}
                                        {companyShort && companyShort.co_code &&
                                            <ListedEventsCard companyShort={companyShort} boardMeetings={boardMeetings}></ListedEventsCard>
                                        }
                                    </Box>
                                </React.Fragment>
                            }
                            <TabPanel value="financials" sx={{ padding: "0px" }}>
                                {companyShort &&
                                    companyShort.co_code && companyShort.DisplayType &&
                                    Financials(companyShort.co_code.toString(), companyShort.DisplayType, (companyShort.NSEListed?.toLowerCase() === "yes"), mainTab === "financials", (tab2) => {
                                        tab && navigateTo(tab, path?.hash, tab2);
                                    })}
                            </TabPanel>
                            <TabPanel value="ratios" sx={{ padding: "0px" }}>
                                {companyShort && companyShort.co_code && Ratios(companyShort.co_code, companyShort.DisplayType, mainTab === "ratios")}
                            </TabPanel>
                            <TabPanel value="peercomparison" sx={{ padding: "0px" }}>
                                {companyShort && companyShort.co_code && PeerComparison(companyShort.co_code, companyShort.DisplayType, mainTab === "peercomparison")}
                            </TabPanel>
                            <TabPanel value="shareholdingspattern" sx={{ padding: "0px" }}>
                                {companyShort && companyShort.co_code && <ShareHoldings co_code={companyShort.co_code.toString()} load={mainTab === "shareholdingspattern"} />}
                            </TabPanel>
                            {/* <TabPanel value="corporateactions" sx={{ paddingX: "4px" }}>
                                {companyShort && companyShort.co_code && CorporateActionTab(companyShort.co_code, corporateActions)}
                            </TabPanel> */}
                            {/* <TabPanel value="deals" sx={{ padding: "0px" }}>
                                {companyShort && companyShort.co_code && Deals(companyShort.co_code.toString(), (companyShort.NSEListed?.toLowerCase() === "yes"))}
                            </TabPanel> */}
                            <TabPanel value="management" sx={{ padding: "0px" }}>
                                {companyShort && companyShort.co_code && ManagementDetails(companyShort.co_code.toString(), mainTab === "management")}
                            </TabPanel>
                            <TabPanel value="technicals" sx={{ padding: "0px" }}>
                                {companyShort && <TechnicalsTV companyShort={companyShort} load={mainTab === "technicals"} />}
                            </TabPanel>
                            <TabPanel value="posts" sx={{ padding: "0px" }}>
                                {/* {(companyShort && mainTab === "posts") && <PostSectionComponent co_id={companyShort?.id || ''} co_userid={owner?.id || ''} />} */}
                                <PostSectionComponent co_id={companyShort?.id || ''} co_userid={owner?.id || ''} />
                            </TabPanel>
                            <TabPanel value="website" sx={{ padding: "0px" }}>
                                {companyShort && <WebsiteComponent companyShort={companyShort} load={mainTab === "website"} />}
                            </TabPanel>
                            <TabPanel value="video" sx={{ padding: "0px" }}>
                                {companyShort && <VideoComponent companyShort={companyShort} load={mainTab === "video"} />}
                            </TabPanel>
                        </TabContext>
                        <MobileAdvert />
                    </Box>
                </Card>
            </Box >
            {/* <WatchlistSelector companyCode={`Company/${code}`} visiblilty={showWatchlistSelector ? "visible" : "hidden"} onClose={(e) => { setShowWatchlistSelector(false); }} />
            {
                showSignupDialog && <SignupDialog
                    isOpen={showSignupDialog}
                    handleClose={handleSignupDialogClose}
                    handleClick={handleSignupClick}
                />
            } */}
            {
                //#region SmallCase
                // <SCSecuritiesClient enabled={openBuySell} onClose={() => { setOpenBuySell(false); setBuySell(BuySellType.NONE); }} companyShort={companyShort} exchange={exchange} buysell={BuySell} />
                //#endregion
            }
            {
                showOwnCompanyDialog && <ListedOwnCompanyDialog
                    isOpen={showOwnCompanyDialog}
                    handleClose={handleOwnCompanyDialogClose}
                    message={'Own Your Company Request Here'}
                    btnText={'Confirm'}
                    // handleConfirm={handleSignupClick}
                    companyCode={companyShort?.co_code}
                    companyName={companyShort?.CompanyName}
                />
            }
        </React.Fragment >);
}

export default CompanyOverview;

