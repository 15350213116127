import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BalanceSheet from '../Financials/BalanceSheet';
import CashFlow from '../Financials/CashFlow';
import PLSheet from '../Financials/PLSheet';
import QuartResults from '../Financials/QuartResults';
import HoldingPatterns from './HoldingPattern';
import ShareHoldersInfo from './ShareHoldersInfo';

function ShareHoldings(props: { co_code: string, load: boolean }) {

    const [tab, setTab] = useState("shareholdingPattern");
    const [searchParams] = useSearchParams();
    const { code, name } = useParams();
    const path = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const secondaryTab = searchParams.get("secondaryTab");
        if (!secondaryTab) {
            navigateTo(searchParams.get('tab')!, "#tabdetail-shareholdingspattern", tab);
        } else {
            setTab(secondaryTab);
        }

    }, []);

    const navigateTo = (tab: string, hash: string | null, secondaryTab = "") => {
        navigate(`/company/${code}/companyname/${name}?${tab ? "tab=" + tab : ""}${secondaryTab ? ('&secondaryTab=' + secondaryTab) : ""}${hash ? hash : path.hash}`)
    }

    return (
        <React.Fragment>
            <TabContext value={tab}>
                <Box sx={{
                    bgcolor: "#F9F9F9", borderRadius: "8px", height: '44px', '& button.Mui-selected': {
                        color: '#FFFFFF',
                        background: '#0F9764',
                        borderRadius: '8px 8px 0px 0px'
                    },
                    '& button.MuiButtonBase-root.MuiTab-root': {
                        paddingY: '8px',
                        minHeight: '44px'
                    }
                }}>
                    <Tabs
                        value={tab}
                        variant="standard"
                        scrollButtons="auto"
                        textColor="inherit"
                        indicatorColor="primary"
                        TabIndicatorProps={{ style: { display: "none" } }}
                        onChange={(e, v) => {
                            setTab(v);
                            navigateTo(searchParams.get('tab')!, "#tabdetail-shareholdingspattern", v);
                        }}
                        aria-label="financial-sub-tabs">
                        <Tab sx={{ textTransform: "capitalize", fontSize: "14px", fontWeight: 700, fontFamily: 'Oxygen' }} label="Shareholding Pattern" value="shareholdingPattern" />
                        <Tab sx={{ textTransform: "capitalize", fontSize: "14px", fontWeight: 700, fontFamily: 'Oxygen' }} label="Top Shareholders" value="topShareholders" />
                    </Tabs>
                </Box>
                <TabPanel sx={{ padding: 0 }} value="shareholdingPattern">
                    {props.co_code && props.co_code.length > 0 && HoldingPatterns(props.co_code, props.load)}
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="topShareholders"  >
                    {props.co_code && props.co_code.length > 0 && ShareHoldersInfo(props.co_code, props.load)}
                </TabPanel>
            </TabContext>
        </ React.Fragment>
    );
}

export default ShareHoldings;