import React, { useEffect, useState } from 'react'
import { Card, Dialog, DialogContent, Divider, IconButton } from '@mui/material';

import {
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { getBoardOfDirectors } from '../../../services/CompanyService';
import { Director } from '../../../model/BoardOfDirectorsResult';
import { StyledDataGrid } from './StyledDataGrid';
import { CopyLinkButton } from '../../../components/Common/CopyLinkButton';


const muiToolbarXGrid = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    paddingX: '2%',
    height: "70px",
    bgcolor: "#F9F9F9"
};

function ManagementDetails(co_code: string, load: boolean) {
    const [boardOfDirectors, setBoardOfDirectors] = useState<any | null>(null);

    function EmptyFooterXGrid() {
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }

    function CustomToolbarXGrid() {
        return (
            <React.Fragment>
                <GridToolbarContainer sx={muiToolbarXGrid}>
                    <div>
                        <XGridPop />
                        <CopyLinkButton locationHash='tabdetail-management'/>
                    </div>
                </GridToolbarContainer>
                <Divider orientation="horizontal" flexItem />
            </React.Fragment>
        );
    }

    function CustomToolbarXGridPopUp() {
        return (
            <React.Fragment>
                <GridToolbarContainer sx={muiToolbarXGrid}>
                    <div>
                        {/* <GridToolbarColumnsButton sx={{ marginLeft: "5px", marginRight: "5px" }} /> */}
                        <GridToolbarFilterButton sx={{ marginLeft: "0px", marginRight: "5px" }} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                    </div>
                </GridToolbarContainer>
                <Divider orientation="horizontal" flexItem />
            </React.Fragment>
        );
    }

    function XGridPop() {
        //Dialog Control
        const [open, setOpen] = useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        return (
            <>
                <IconButton
                    color="primary"
                    aria-label="x-grid-pop"
                    component="span"
                    onClick={handleOpen}
                    sx={{ marginX: "10px" }}
                >
                    <img src="expand-window.png" style={{ height: "24px", width: "24px" }} />
                </IconButton>
                <Dialog
                    PaperProps={{
                        sx: {
                            width: '770px',
                            maxWidth: "90%",
                            '& .super-app-theme--header': { color: '#0f9764' },
                            height: (boardOfDirectors && boardOfDirectors.rows && boardOfDirectors.rows.length > 0) ? `${36 * (boardOfDirectors.rows.length - 1) + 200}px` : '350px'
                        }
                    }}
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <DialogContent dividers
                        style={{
                            width: '720px',
                        }}
                    >
                        <StyledDataGrid
                            rows={boardOfDirectors.rows}
                            columns={boardOfDirectors.columns}
                            density={"compact"}
                            components={{
                                Footer: EmptyFooterXGrid,
                                Toolbar: CustomToolbarXGridPopUp,
                            }}
                            autoHeight
                            disableColumnMenu
                            disableSelectionOnClick
                            columnVisibilityModel={{
                                // Hide columns id, the other columns will remain visible
                                id: false,
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    function PrepareGridData(data: Director[]): any {
        if (data) {
            // Process Columns
            var columnOptions: any[] = [];
            columnOptions.push({ field: 'id', headerName: '', width: '0', color: 'primary', headerClassName: 'super-app-theme--header', });
            columnOptions.push({ field: 'name', headerName: 'Name of Directors/KMP', width: 350, headerClassName: 'super-app-theme--header', });
            columnOptions.push({ field: 'year', headerName: 'Year', width: 80, headerClassName: 'super-app-theme--header', });
            columnOptions.push({ field: 'designation', headerName: 'Designation', width: 270, headerClassName: 'super-app-theme--header', });

            // Process Rows
            var rows: any[] = [];
            var count = 1;
            data.forEach((row) => {
                var responseObject = {};

                responseObject["id"] = count; count = count + 1;
                responseObject["name"] = row.dir_name;
                responseObject["year"] = Number(row.year);
                responseObject["designation"] = row.dir_desg?.toString().trim();

                rows.push(responseObject);
            });

            return { columns: columnOptions, rows: rows };
        }
    }

    useEffect(() => {
        if (co_code && load === true) {
            getBoardOfDirectors(co_code)
                .then((value) => {
                    var gridData = PrepareGridData(value);
                    setBoardOfDirectors(gridData);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [co_code, load]);

    return (
        <React.Fragment>
            <Card
                sx={{
                    border: '2px',
                    borderRadius: '8px',
                    '& .super-app-theme--header': {}
                    // '& .super-app-theme--header': { color: '#0f9764' }
                }}
            >
                {
                    <div style={{
                        height: (boardOfDirectors && boardOfDirectors.rows && boardOfDirectors.rows.length > 0) ? `${36 * (boardOfDirectors.rows.length - 1) + 200}px` : '200px',
                        width: '100%'
                    }}>
                        {boardOfDirectors &&
                            <StyledDataGrid
                                rows={boardOfDirectors.rows}
                                columns={boardOfDirectors.columns}
                                density={"compact"}
                                components={{
                                    Footer: EmptyFooterXGrid,
                                    Toolbar: CustomToolbarXGrid,
                                }}
                                columnVisibilityModel={{
                                    // Hide columns id, the other columns will remain visible
                                    id: false,
                                }}
                                sx={{
                                    '& .MuiDataGrid-columnHeaders': { bgcolor: '#FFFFFF', color: '#686868' },
                                    '& .MuiDataGrid-row': { bgcolor: '#F9F9F9', color: '#0D0D0D' }
                                }}
                            />
                        }

                    </div>
                }
            </Card>
        </React.Fragment>
    );
}

export default ManagementDetails

