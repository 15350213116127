import { Avatar, Box, Divider, Fab, Grid, IconButton, InputBase, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { chat, getAnnualReportBot, getChatHistory } from "../../services/AssistantBotService";
import { useParams } from "react-router-dom";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import SendIcon from '@mui/icons-material/Send';
import { AssistantChatResponse } from "../../model/AssistantChatResponse";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import { ChatTypingBubbles } from "../../components/ChatTypingBubbles/ChatTypingBubbles";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export function Askme() {

    const { code } = useParams();
    const [sourceId, setSourceId] = useState('');
    const [file, setFile] = useState('');

    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [chatHistory, setChatHistory] = useState<AssistantChatResponse[]>([]);
    const [typedMessage, setTypedMessage] = useState("");

    const userData = useSelector((state: ReduxStore) => state.userData.user);
    const profilePic = userData?.profilepic || "./static/icons/default-avatar.svg";

    const companyShort = useSelector((store: ReduxStore) => store.app.companiesData.companies.find(u => u.co_code == code as any));

    const chatContainerRef = useRef<HTMLDivElement>();

    const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    async function submitMessage() {
        if (typedMessage.trim().length > 0) {
            setIsWaitingForAnswer(true);
            setChatHistory(chatHistory.concat({ question: typedMessage } as any));
            setTimeout(() => {
                chatContainerRef.current!.scrollBy({ top: 200, behavior: "smooth" })
            }, 200);
            setTypedMessage("");
            var res = await chat(sourceId, typedMessage);
            setIsWaitingForAnswer(false);
            res.answer.content = res.answer.content.replace(/pages? \d+|\[P\d+\]/ig, '<span class="link" onClick="naviageToPdfPage(`$&`)">$&</span>');
            setChatHistory([...chatHistory, res]);
            setTimeout(() => {
                chatContainerRef.current!.scrollBy({ top: 200, behavior: "smooth" })
            }, 200);
        }
    }

    useEffect(() => {
        getAnnualReportBot(companyShort?.id || "").then(x => {
            setSourceId(x.sourceId)
            setFile(x.fileUrl)
            setPageNumber(1);

            getChatHistory(x.sourceId, 1, 50).then(y => setChatHistory(chatHistory.concat(y)));

        }).catch(x => console.error(x))

        window["naviageToPdfPage"] = (pageNumber) => {
            try {
                setPageNumber(parseInt(pageNumber.match(/\d+/)[0]))
            } catch (e) {

            }

        }
    }, [code])

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, marginBottom: { xs: "50px", md: "0px" } }}>
            <Box sx={{ border: '1px solid #EBEBEB', width: { xs: "100%", md: "50%" } }}>
                <Box sx={{ padding: "5px 10px" }}>
                    <IconButton disabled={pageNumber >= numPages} onClick={(e => setPageNumber(pageNumber + 1))}>
                        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6873 8.24004L7.77025 0.423407C7.65648 0.294715 7.46003 0.282902 7.33133 0.396673C7.32201 0.404756 7.3133 0.414081 7.3046 0.423407L0.388184 8.24004" stroke="black" stroke-width="0.6217" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </IconButton>
                    <TextField size="small"
                        sx={{
                            width: "50px",
                            marginRight: "5px",
                            fontSize: "14px",
                            '& .MuiInputBase-input': { padding: "4px 8px" }
                        }} value={pageNumber}
                        onChange={e => {
                            setPageNumber(parseInt(e.target.value));
                        }} variant="outlined"
                        onKeyPress={(evt: any) => {
                            evt = (evt) ? evt : window.event;
                            var charCode = (evt.which) ? evt.which : evt.keyCode;
                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                return false;
                            }
                            return true;
                        }}
                    />/ <Typography sx={{ fontSize: "14px", lineHeight: "30px", paddingTop: "5px" }}>{numPages}</Typography>
                    <IconButton sx={{ opacity: pageNumber > 1 ? 1 : 0.4 }} disabled={pageNumber <= 1} onClick={(e => setPageNumber(pageNumber - 1))}>
                        <svg style={{ rotate: "180deg" }} width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6873 8.24004L7.77025 0.423407C7.65648 0.294715 7.46003 0.282902 7.33133 0.396673C7.32201 0.404756 7.3133 0.414081 7.3046 0.423407L0.388184 8.24004" stroke="black" stroke-width="0.6217" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{ overflow: "auto", height: { xs: "500px", md: "calc(100vh - 180px)" }, width: "100%" }}>
                    <Document file={file} onLoadError={(e) => { }} onLoadSuccess={onDocumentLoadSuccess}>
                        {(pageNumber > numPages || pageNumber <= 0 || isNaN(pageNumber)) && <h1 style={{ textAlign: "center" }}>No Content Available For This Page.</h1>}
                        {pageNumber <= numPages && pageNumber > 0 && <Page scale={1} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />}
                    </Document>
                </Box>
            </Box>
            <Box sx={{ border: '1px solid #EBEBEB', width: { xs: "100%", md: "50%" } }}>
                <Box>
                    <Box sx={{ height: "41px" }}>
                        <img src="/bot_icon.svg" height="40px" style={{ margin: "0px 10px" }} />
                        <Typography component="div" sx={{ display: "inline-block", verticalAlign: "top", marginTop: "10px" }}>Ask me about {companyShort?.CompanyShortName}</Typography>
                    </Box>
                    <Divider />
                    <Box ref={chatContainerRef} sx={{ height: { xs: "500px", md: "calc(100vh - 240px)" }, overflow: "auto" }}>
                        <Box>
                            <Box display={"flex"} flexDirection="row" sx={{ padding: "20px 100px 20px 10px" }}>
                                <img src="/logo_32_32.png" style={{ height: "32px", width: "32px" }}></img>
                                <Typography style={{ marginLeft: "10px", fontFamily: 'Oxygen', fontSize: "14px" }}>Hi, I am virtual financial assistant,  powered by stockknocks. I can answer your queries related to this company.</Typography>
                            </Box>
                        </Box>
                        {chatHistory.map((c, i) => <Box>
                            {c.question &&
                                <Box key={`question_${i}`}>
                                    <Box display={"flex"} flexDirection="row" sx={{ padding: "20px 10px 20px 100px", justifyContent: "right" }}>

                                        <Typography sx={{ marginTop: "5px", marginRight: "5px" }}>
                                            {c.question}
                                        </Typography>
                                        <Avatar src={profilePic} sx={{ border: "2px solid #0F9764", height: "26px", width: "26px" }} />
                                    </Box>

                                </Box>
                            }
                            {c.answer &&
                                <Box key={`answer_${i}`}>
                                    <Box display={"flex"} flexDirection="row" sx={{ padding: "20px 100px 20px 10px" }}>
                                        <img src="/logo_32_32.png" style={{ height: "32px", width: "32px" }}></img>
                                        <span style={{ marginLeft: "10px", fontFamily: 'Oxygen', fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: `<span>${c.answer.content}</span>` }}></span>
                                    </Box>
                                </Box>
                            }

                        </Box>)}
                        {isWaitingForAnswer && <Box>
                            <Box display={"flex"} flexDirection="row" sx={{ padding: "20px 100px 20px 10px" }}>
                                <img src="/logo_32_32.png" style={{ height: "32px", width: "32px" }}></img>
                                <ChatTypingBubbles />
                            </Box>
                        </Box>}
                    </Box>
                </Box>
                <Box sx={{
                    bgcolor: "#E5E3DA",
                    height: "50px",
                    padding: { xs: "10px", md: "15px 0px 0px 0px" }
                }}>
                    <Grid item xs={12}>
                        <Paper
                            component="form"
                            sx={{
                                p: '2px 4px',
                                width: { md: "calc(100% - 80px)" },
                                marginLeft: { md: 4, xs: 0 },
                                marginTop: { md: 0, xs: 1 },
                                display: 'flex',
                                height: "30px",
                                marginBottom: { xs: 8 },
                                borderRadius: "4px",
                                border: "1px solid #0F9764"
                            }}
                        >
                            <InputBase
                                sx={{ flex: 1, fontSize: "14px", fontWeight: 400 }}
                                placeholder='Enter your question (max 100 characters)'
                                inputProps={{ 'aria-label': 'Type your message', maxLength: 100 }}
                                onChange={e => { setTypedMessage(e.target.value) }}
                                value={typedMessage}
                                onKeyDown={(e) => { if (e.key == "Enter") { e.preventDefault(); submitMessage(); } }}
                                fullWidth
                            />

                            <Fab color="primary" onClick={() => { submitMessage() }} sx={{ height: "25px", width: "25px", margin: "2px 0px 0px 9px", minHeight: "25px", zIndex: 0, marginRight: 0.5 }} aria-label="add"><SendIcon sx={{ fontSize: "15px" }} /></Fab>
                        </Paper>
                    </Grid>
                </Box>
            </Box>
        </Box >
    )
}