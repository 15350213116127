import * as firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBq4f6_Po7vorWZusTzx5QG3VipEHtldPs",
    authDomain: "stockknocks-frontend-web-dev.firebaseapp.com",
    databaseURL: "https://stockknocks-frontend-web-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "stockknocks-frontend-web-dev",
    storageBucket: "stockknocks-frontend-web-dev.appspot.com",
    messagingSenderId: "910404691413",
    appId: "1:910404691413:web:11f171f5190790f344495c",
    measurementId: "G-WQQGEXTEPQ"
  };

  // const firebaseConfig = {
  //   apiKey: "AIzaSyAUhDNyfjhckQNyc9epgFh_DzGUMjHUyEo",
  //   authDomain: "stockknocks-prod.firebaseapp.com",
  //   projectId: "stockknocks-prod",
  //   storageBucket: "stockknocks-prod.appspot.com",
  //   messagingSenderId: "798481395046",
  //   appId: "1:798481395046:web:87b5b0d18d05321a4eaca7",
  //   measurementId: "G-W58NCCTGRX",
  // };
firebase.initializeApp(firebaseConfig);