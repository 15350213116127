export class NumberExtensions {
  static isNumber(str: string | number | undefined | null): boolean {
    if (str) {
      if (typeof str === 'number') {
        return true;
      }
      if (typeof str !== 'string') {
        return false;
      }
      if (str.trim() === '') {
        return false;
      }
      return !Number.isNaN(Number(str));
    }
    else {
      return false;
    }
  };
  static formatInTwoDigit(num: any): number {
    return (num && num >= 10) ? num : `0${num || 0}`;
  }
}