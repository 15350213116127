import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { PathNames } from '../../appConfig/PathNames';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '100%',
  height: '100%',

});

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}  sx={{ marginTop:{xs:"-10px", md:"50px"} }} >
          <div
            style={{
              // marginTop: '150px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize:{md:"58px", xs:"32px"},
                  fontFamily: 'Inter',
                  color: '#455A64',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '70px',
                  marginLeft: {md:"100px", xs:"15px"},
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                  flexDirection: 'column',
                }}
              >
                Page Not Found
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: {md:"32px", xs:"20px"},
                  fontFamily: 'Inter',
                  color: '#455A64',
                  lineHeight: '39px',
                  marginBottom: '20Px',
                  marginLeft: {md:"115px", xs:"20px"},
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                  flexDirection: 'column',
                }}
              >
                Sadly, the page doesn’t exist.
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                sx={{
                  fontSize: {md:"16px", xs:"10px"},
                  fontFamily: 'Oxygen',
                  color: '#455A64',
                  textAlign: 'center',
                  display:"flex",
                  marginLeft:{xs:"0px", md:"100px"},
                  justifyContent: 'center',
                }}
              >
                Our bad, the page you wanted to see doesn’t exist anymore. But
                good news,
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                sx={{
                  fontSize: {md:"16px", xs:"10px"},
                  fontFamily: 'Oxygen',
                  color: '#455A64',
                  textAlign: 'justify',
                  display:"flex",
                  marginLeft:{xs:"0px", md:"60px"},
                  justifyContent: 'center',
                }}
              >
                you can still find other things by searching here.
              </Typography>
            </Box>
            <Box >
              <Link to={"https://www.stockknocks.com/"}>
                <Button  sx={{ marginLeft: {xs:"0px", md:"60px"}, marginTop: '20px', textAlign: 'justify', justifyContent: "center" }} variant="contained">
                  Home Page
                </Button>
              </Link>
              <Link to={PathNames.FEED}>
                <Button sx={{ marginLeft:{xs:"30px", md:"50px"}, marginTop: '20px' }} variant="contained">
                  Go To Community
                </Button>
              </Link>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginBottom:{xs:"20px"} }}>
          <div
            style={{
              textAlign: 'right',
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              flexWrap:"wrap",
            }}
          >
            <Img alt="404 Error Page" src="/static/not-found-page/404 Error Page.png" />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NotFoundPage;
