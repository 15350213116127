import { CompanyTypeNames } from "../../utility/Constants"

function getNavigationLink(obj: any): string {
    let responseLink = '';
    try {
        switch (obj.type) {
            case CompanyTypeNames.Company:
                responseLink = `/company/${obj.co_code}/companyname/${obj.searchId}`;
                break;
            case CompanyTypeNames.UnlistedCompany:
                responseLink = `/unlistedcompany?code=${obj.id || obj._id}`;
                break;
            default:
                responseLink = `/viewuser?userid=${obj.id || obj._id}`;
        }
    }
    catch (e) {
        console.log('getNavigationLink failed');
        console.log(obj);
        console.error(e);
    }

    return responseLink;
}

export { getNavigationLink }