import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent, CardHeader, Tab, Tabs, Typography, useTheme, ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { ApexOptions } from 'apexcharts';
import { Dictionary } from 'lodash';
import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { UnlistedFinanceSummary } from '../../model/UnlistedFinanceSummaryResponseModel';
import useStyle from "./Styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 



function GetProfitAndLostCard(props: { balanceSheetSummaryS: UnlistedFinanceSummary, balanceSheetSummaryC: UnlistedFinanceSummary, loading: boolean }) {
    const TabDisplayName = {
        'revenue_from_operation': 'Net Sales',
        'ebitda': 'EBDITA',
        'operating_profit': 'EBIT',
        'pat': 'PAT',
        'eps': 'EPS'
    }

    const defaultTheme = useTheme();

    const useStyles = makeStyles((theme) => ({
        tabs: {
            //   borderRight: '1px solid #EBEBEB',
            // padding: "0px",
            // alignItems: "flex-start",
            // justifyItems: "flex-start",
            // justifyContent: "flex-start",
            // margin: 0
            //   width: "100%",
            //   backgroundColor: "#EBEBEB"
        },
        flexVertTabs: {
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "column",
            justifyContent: "left",
            alignContent: "left",
            justifyItems: "left"
        },
        flexVertTabsDiv: {
            backgroundColor: "#F9F9F9",
            textAlign: "left",
            fontSize: "30px",
            width: "80%",
            paddingX: "10%",
            marginLeft: "5%",
            marginRight: "5%"
        },
        flexOuter: {
            display: "flex",
            flexDirection: "row",
            width: "35%",
            backgroundColor: "#F9F9F9",
        },
        flexContainerVertical: {
            display: "flex",
            justifyContent: 'space-around',
            alignItems: "center",
            backgroundColor: "#F9F9F9",
            width: "100%",
        }
    }));
    const classes = useStyles();
    const masterClasses = useStyle();

    const VerticalTab = withStyles({
        root: {
            backgroundColor: '#EBEBEB',
            width: '100%',
            border: '1px solid #F9F9F9',
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    })(Tab);

    const [tabValue, setTabValue] = React.useState<string>('revenue_from_operation');

    const [link, setLink] = React.useState(false);

    const [showContent, setShowContent] = React.useState<boolean>((props.balanceSheetSummaryS?.show_balance_sheet === true || props.balanceSheetSummaryC?.show_balance_sheet === true));

    const [balanceSheetSummary, setBalanceSheetSummary] = React.useState<UnlistedFinanceSummary>({
        has_data: false,
        show_balance_sheet: false,
        show_profit_and_loss: false,
        show_ratios: false,
        balance_sheet: null,
        profit_and_loss: null,
        ratios: null,
        type: 0
    });

    const [alignment, setAlignment] = React.useState<'S' | 'C'>('S');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue && !(tabValue === newValue)) {
            setTabValue(newValue);
        }
    };

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'S' | 'C',
    ) => {
        if (!(alignment === newAlignment) && (newAlignment === 'S' || newAlignment === 'C')) {
            setAlignment(newAlignment);
        }
    };

    useEffect(() => {
        if (alignment === 'S') {
            setBalanceSheetSummary(props.balanceSheetSummaryS);
        }
        if (alignment === 'C') {
            setBalanceSheetSummary(props.balanceSheetSummaryC);
        }
    }, [alignment]);

    const getChart = (tab: string) => {
        let categories: string[] = [];
        let series: any[] = [];

        if (balanceSheetSummary && balanceSheetSummary["profit_and_loss"]) {
            let summary: Dictionary<any> = balanceSheetSummary["profit_and_loss"][tab];

            categories = Object.keys(summary);

            for (let cat in categories) {
                series.push(summary[categories[cat]]);
            }
        }

        const chartData: ApexOptions = {
            chart: {
                type: "bar",
                id: tab,
                toolbar: {
                    show: false
                },
                parentHeightOffset: 0,
                redrawOnParentResize: true,
                background: '#F9F9F9'
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                //categories: [1991, 1992, 1993, 1994, 1995],
                categories: categories,
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true
                },
            },
            fill: {
                colors: ['#0F9764', '#0F9764', '#0F9764']
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '60%',
                    colors: {
                        ranges: [{
                            from: -0.01,
                            to: -1.7976931348623157e+308,
                            color: '#FF0000'
                        }, {
                            from: 0,
                            to: 1.7976931348623157e+308,
                            color: '#0F9764'
                        }]
                    }
                }
            },
            colors: ['#F9F9F9'],
            series: [
                {
                    name: "Price (In Lacs)",
                    type: "bar",
                    //data: [440, -505, 414, 571, -227]
                    data: series
                }
            ]
        };

        try {
            return <ReactApexChart options={chartData} series={chartData.series} height='100%' width='100%' style={{ paddingY: '0px' }} />;
        }
        catch (error) {
            console.error("Chart generation failed", error);
        }
    }

    const showDiv = (): boolean => {
        if ((props.balanceSheetSummaryS && props.balanceSheetSummaryS.show_balance_sheet) || (props.balanceSheetSummaryC && props.balanceSheetSummaryC.show_balance_sheet)) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        if (props.loading === false) {
            if (props.balanceSheetSummaryS?.show_balance_sheet === true) {
                setAlignment('S');
                setBalanceSheetSummary(props.balanceSheetSummaryS);
            }
            else if (props.balanceSheetSummaryC?.show_balance_sheet === true) {
                setAlignment('C');
                setBalanceSheetSummary(props.balanceSheetSummaryC);
            }
            setShowContent(showDiv());
        }
    }, [props.loading]);

    try {
        return (
            <React.Fragment>
                {showContent &&
                    <Box className={masterClasses.root} sx={{ marginTop: '24px' }} id='tabdetail-profitandloss'>
                        <Card elevation={0} sx={{ paddingY: '24px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '8px' }}>
                            <CardHeader
                                sx={{ marginX: { sm: "24px", xs: "24px" }, textAlign: 'left', paddingLeft: '0px', paddingTop: '0px', paddingBottom: '32px' }}
                                title={<Typography
                                    sx={{ fontSize: { xs: '16px', md: '24px' } }}
                                    // className={masterClasses.cardHeaderTitle}
                                >Profit And Loss</Typography>}
                                // action={
                                //     <DownloadButton variant="contained" color="primary" sx={{ height: '39px', width: '128px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none' }} />
                                // }
                                action={
                                    <>
                                    <ToggleButtonGroup
                                        value={alignment}
                                        exclusive
                                        size='small'
                                        onChange={handleChange}
                                        sx={{ height: "39px", maxWidth: "250px", marginRight: 2, '& button': { fontFamily: 'Oxygen' } }}
                                    >
                                        {(props.balanceSheetSummaryS?.show_balance_sheet === true) &&
                                            <ToggleButton value="S"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: alignment === 'S' ? '#FFFFFF !important' : '#0F9764 !important',
                                                    backgroundColor: alignment === 'S' ? '#0F9764 !important' : '#FFFFFF !important',
                                                    paddingLeft: "15px",
                                                    paddingRight: "10px",
                                                    textTransform: "none",
                                                    fontSize: {xs:'10px', md:'14px'}
                                                }} >Standalone</ToggleButton>}
                                        {(props.balanceSheetSummaryC?.show_balance_sheet === true) &&
                                            <ToggleButton value="C"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: alignment === 'C' ? '#FFFFFF !important' : '#0F9764 !important',
                                                    backgroundColor: alignment === 'C' ? '#0F9764 !important' : '#FFFFFF !important',
                                                    paddingLeft: "10px",
                                                    paddingRight: "15px",
                                                    textTransform: "none",
                                                    fontSize: {xs:'10px', md:'14px'}
                                                }} >Consolidated</ToggleButton>}
                                        </ToggleButtonGroup>
                                        <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                        <IconButton
                            onClick={() => {
                                window.location.hash = 'tabdetail-profitandloss'
                                navigator.clipboard.writeText(window.location.href)
                                setLink(true)
                            }}
                        >
                            <ContentCopyIcon
                                sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                        </IconButton>
                    </Tooltip>
                                        </>
                                }
                            >
                            </CardHeader>
                            <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }} className={masterClasses.cardBodyText}>
                                <Box
                                    sx={{ flexGrow: 1, bgcolor: '#F9F9F9', display: 'flex', height: 300, paddingY: '0px', paddingLeft: '24px' }}
                                >
                                    <TabContext value={tabValue}>
                                        <div className={classes.flexOuter}>
                                            <div className={(classes.flexVertTabs, classes.flexVertTabsDiv)}>
                                                <Tabs
                                                    orientation="vertical"
                                                    value={tabValue}
                                                    onChange={handleTabChange}
                                                    aria-label="profit_and_loss"
                                                    sx={{
                                                        borderRight: 'none', borderColor: 'divider',
                                                        display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
                                                        backgroundColor: '#EBEBEB'
                                                    }}
                                                    centered={true}
                                                    variant={'fullWidth'}
                                                    classes={{
                                                        flexContainerVertical: classes.flexContainerVertical
                                                    }}
                                                >
                                                    <VerticalTab label={TabDisplayName["revenue_from_operation"]} value="revenue_from_operation" />
                                                    <VerticalTab label={TabDisplayName["ebitda"]} value="ebitda" />
                                                    <VerticalTab label={TabDisplayName["operating_profit"]} value="operating_profit" />
                                                    <VerticalTab label={TabDisplayName["pat"]} value="pat" />
                                                    <VerticalTab label={TabDisplayName["eps"]} value="eps" />
                                                </Tabs>
                                            </div>
                                        </div>
                                    </TabContext>
                                    <TabContext value={tabValue}>
                                        <TabPanel value="revenue_from_operation" sx={{ width: '50%', paddingX: '5%', paddingY: '0px', backgroundColor: '#F9F9F9' }}>
                                            {getChart("revenue_from_operation")}
                                        </TabPanel>
                                        <TabPanel value="ebitda" sx={{ width: '50%', paddingX: '5%', paddingY: '0px', backgroundColor: '#F9F9F9' }}>
                                            {getChart("ebitda")}
                                        </TabPanel>
                                        <TabPanel value="operating_profit" sx={{ width: '50%', paddingX: '5%', paddingY: '0px', backgroundColor: '#F9F9F9' }}>
                                            {getChart("operating_profit")}
                                        </TabPanel>
                                        <TabPanel value="pat" sx={{ width: '50%', paddingX: '5%', paddingY: '0px', backgroundColor: '#F9F9F9' }}>
                                            {getChart("pat")}
                                        </TabPanel>
                                        <TabPanel value="eps" sx={{ width: '50%', paddingX: '5%', paddingY: '0px', backgroundColor: '#F9F9F9' }}>
                                            {getChart("eps")}
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </ React.Fragment>
        );
    }
    catch (error) {
        console.error(error);
        return (<React.Fragment></React.Fragment>);
    }
}

export default GetProfitAndLostCard;