import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PathNames } from './appConfig/PathNames';
import Loading from "./components/Loading/Loading";
import Authorize from './components/Routing/Authorize';
import Loader from "./components/Loading/Loader";
import CompanyOverview from './pages/CompanyOverview/CompanyOverview';
import UnlistedCompanyOverview from './pages/UnlistedCompanyOverview/CompanyOverview';
import UserOverview from './pages/UserView/UserOverview';
import PaymentSuccess from './pages/Premium/PaymentSuccess';
import PaymentFailure from './pages/Premium/PaymentFailure';
import PaymentRefund from './pages/Premium/PaymentRefund';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from './model/ReduxStore';
import { Notifier } from './components/Notifier/Notifier';
// import { getWatchList } from './services/WatchlistService';
import { Actions } from './redux/Actions';
import PageContainer from './components/PageContainer/PageContainer';
import UserPreview from './pages/UserPreview/UserPreview';
import UnlistedPreview from './pages/UnListed/UnlistedPreview';
import Premium from './pages/Premium/Premium';
import ContactUs from './pages/ContactUs/ContactUs';
import Plans from './pages/Premium/Plans';
import Transaction from './pages/Payment/Transaction';
import Dashboard from './pages/Dashboard/Dashboard';
import AuthGuard from './components/AuthGuard/AuthGuard';
import { MarketPlace } from './pages/MarketPlace/MarketPlace';
import ImpressionTracker from './components/ImpressionTracker/ImpressionTracker';
import { Askme } from './pages/CompanyOverview/Askme';
import { ServiceWorkerManager } from './components/ServiceWorkerManager/ServiceWorkerManager';
import { UnlistedScreener } from './pages/UnlistedScreener/UnlistedScreener';


const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const SignIn = React.lazy(() => import('./pages/SignIn/SignIn'));
const SignUp = React.lazy(() => import('./pages/SignUp/SignUp'));
const SignOut = React.lazy(() => import('./pages/SignOut/SignOut'));
const Layout = React.lazy(() => import('./pages/Layout/Layout'));
const CareerPage = React.lazy(() => import('./pages/CareerPage/CareerPage'));
const NewsBlock = React.lazy(() => import('./pages/StockknocksNewsFeed/NewsBlock'));
const ListedHomePage = React.lazy(() => import('./pages/Listed/ListedHomePage'));
const ListedFAQ = React.lazy(() => import('./pages/ListedFAQ/ListedFAQ'));
const FAQ = React.lazy(() => import('./pages/FAQ/FAQ'));
const UnListedFAQ = React.lazy(() => import('./pages/UnlistedFAQ/UnListedFAQ'));
const ComunityFAQ = React.lazy(() => import('./pages/ComunityFAQ/ComunityFAQ'));
const UnListedHomePage = React.lazy(() => import('./pages/UnListed/UnListedHomePage'));
const Account = React.lazy(() => import('./pages/Account/Account'));
const DeploymentHistory = React.lazy(() => import('./pages/DeploymentHistory/DeploymentHistory'));
const ResetPassword = React.lazy(() => import('./pages/PasswordReset/PasswordReset'));
const ABOUT = React.lazy(() => import('./pages/About/About'));
const Feed = React.lazy(() => import('./pages/NewsFeed/NewsFeed'));
const TrendingPage = React.lazy(() => import('./pages/TrendingUsers/TrendingPage'));
const AdminPanel = React.lazy(() => import('./pages/Admin/AdminPanel'));
const Blogs = React.lazy(() => import("./pages/Blogs/Blogs"));
const Channel = React.lazy(() => import("./pages/Channel/ChannelPage"));
const ChannelPreview = React.lazy(() => import("./pages/Channel/ChannelPreviewPage"));
// const WatchList = React.lazy(() => import("./pages/Watchlist/WatchlistOverview"));
const Notification = React.lazy(() => import("./pages/Notification/Notification"));
const NotFound = React.lazy(() => import("./pages/Sessions/NotFoundPage"));
const DevelopersUI = React.lazy(() => import("./pages/Developers/Developers"));
const CreditHistory = React.lazy(() => import("./pages/Credits/Credits"));


const Join = React.lazy(() => import('./pages/JoinPage/Joinpage'));

const loadPageWithoutLayout = (content: JSX.Element) => {
  return <Suspense fallback={<Loading />}>
    {content}
  </Suspense>
}

const loadPageWithLayout = (content: JSX.Element) => {
  return <Suspense fallback={<Loading />}>
    <Layout>
      {content}
    </Layout>
  </Suspense>
}

const App = () => {
  const user = useSelector((state: ReduxStore) => state.auth.user);
  const dispatch = useDispatch();


  // React.useEffect(() => {
  //   if (user != null) {
  //     getWatchList().then(x => dispatch({ type: Actions.SETWATCHLIST, payload: x }));
  //   }
  // }, []);


  return <div>
    {/* <TokenExpire /> */}
    <ServiceWorkerManager />
    <Notifier />
    <AuthGuard>
      <ImpressionTracker>
        <Routes>
          <Route path="/" element={<Navigate to={PathNames.FEED} />} />
          {/* <Route path={PathNames.HOME} element={loadPageWithLayout(<LandingPage />)} /> */}
          <Route path={`${PathNames.MARKETPLACE}/*`} element={loadPageWithLayout(<MarketPlace />)} />
          <Route path={PathNames.NEWSBLOCK} element={loadPageWithLayout(<NewsBlock />)} />
          <Route path={PathNames.LISTED} element={<PageContainer
            title='Best listed share&#39;s market research platform India'
            keyword='stock research platform, Stock research websites, stock market research'
            description='Discover the most comprehensive research on listed shares in India with StockKnocks&#39; best market
        research platform.'
            h1='Stock Market Research Platform'>
            {loadPageWithLayout(<Feed />)}
          </PageContainer>} />
          <Route path={PathNames.UNLISTED} element={<PageContainer
            title='Best Trending Unlisted Company&#39;s Financial Information'
            keyword='Buy sell unlisted shares, Unlisted companies in India, unlisted shares price list'
            description='Invest in the best unlisted companies with StockKnocks&#39; top-rated financial information.'
            h1='Unlisted Shares in India '>
            {loadPageWithLayout(<UnlistedScreener />)}
          </PageContainer>} />
          <Route path={PathNames.SIGNIN} element={loadPageWithoutLayout(<SignIn />)} />
          <Route path={PathNames.SIGNOUT} element={loadPageWithoutLayout(<SignOut />)} />
          {/* <Route path={PathNames.LISTEDFAQ} element={<PageContainer
            title='Best listed shares research Platform India'
            keyword='stock research platform, Stock research websites, stock market research'
            description='Find your next profitable investment opportunity with the top investors community platform in
        India.'
            h1='Stock Market Research Platform'>
            {loadPageWithLayout(<ListedFAQ />)}
          </PageContainer>} /> */}
          <Route path={PathNames.UNLISTEDFAQ} element={<PageContainer
            title='Best trending unlisted shares research India'
            keyword='Buy sell unlisted shares, Unlisted companies in India, unlisted shares price list'
            description='Buy and sell unlisted company shares with confidence, backed by our expert unlisted shares
        research.'
            h1='Unlisted Shares in India'>
            {loadPageWithLayout(<UnListedFAQ />)}
          </PageContainer>} />
          <Route path={PathNames.COMUNITYFAQ} element={<PageContainer
            title='Best investors community research platform India'
            keyword='Stock market community, Stock trading community, Stock trader forum'
            description='Get ahead in the listed and Unlisted shares market with the top research platform in India.'
            h1='Stock Market Community'>
            {loadPageWithLayout(<ComunityFAQ />)}
          </PageContainer>} />
          <Route path={PathNames.CAREERS} element={loadPageWithLayout(<CareerPage />)} />
          <Route path={PathNames.SIGNUP} element={loadPageWithoutLayout(<SignUp />)} />
          <Route path={PathNames.ACCOUNT} element={loadPageWithLayout(<Authorize><Account /></Authorize>)} />
          <Route path={PathNames.DeploymentHistory} element={loadPageWithoutLayout(<DeploymentHistory />)} />
          <Route path={PathNames.CompanyOverview} element={loadPageWithLayout(<CompanyOverview />)} />
          <Route path={PathNames.CompanyOverviewWithCode} element={loadPageWithLayout(<CompanyOverview />)} />
          <Route path={PathNames.AskMeListed} element={loadPageWithLayout(<Askme/>)}/>
          <Route path={PathNames.CompanyOverviewWithName} element={loadPageWithLayout(<UnlistedCompanyOverview />)} />
          <Route path={PathNames.UnlistedCompanyOverview} element={loadPageWithLayout(<UnlistedCompanyOverview />)} />
          <Route path={PathNames.VIEW_USER} element={loadPageWithLayout(<UserOverview />)} />
          <Route path={PathNames.ResetPassword} element={loadPageWithoutLayout(<ResetPassword />)} />
          <Route path={PathNames.ABOUT} element={loadPageWithLayout(<ABOUT />)} />
          <Route path={PathNames.ADMIN_PANEL} element={loadPageWithLayout(<AdminPanel />)} />
          <Route path={PathNames.FEED} element={<PageContainer
            title='Best stock market trading community platforms in India'
            keyword='Stock market community, Stock trading community, Stock trader forum'
            description='Join the StockKnocks community for the best market research on Listed and Unlisted shares in India'
            h1='Stock Market Community'>
            {loadPageWithLayout(<Feed />)}
          </PageContainer>} />
          <Route path={PathNames.BLOGS} element={loadPageWithLayout(<Blogs />)} />
          <Route path={PathNames.FAQ_PAGE} element={loadPageWithLayout(<FAQ />)} />
          <Route path={PathNames.CHANNELS} element={loadPageWithLayout(<Channel />)} />
          <Route path={PathNames.CHANNELPREVIEW} element={loadPageWithLayout(<ChannelPreview />)} />
          <Route path={PathNames.UNLISTED_PREVIEW} element={loadPageWithLayout(<UnlistedPreview />)} />
          <Route path={PathNames.UNLISTED_SCREENER} element={loadPageWithLayout(<UnlistedScreener />)} />
          <Route path={PathNames.USER_PREVIEW} element={loadPageWithLayout(<UserPreview />)} />
          {/* <Route path={PathNames.WATCH_LIST} element={loadPageWithLayout(<WatchList />)} /> */}
          <Route path={PathNames.TRENDING_USERS} element={loadPageWithLayout(<TrendingPage />)} />
          <Route path={PathNames.NOTIFICATION} element={loadPageWithLayout(<Notification />)} />
          <Route path={PathNames.PREMIUM} element={loadPageWithLayout(<Premium />)} />
          <Route path={PathNames.PLANS} element={loadPageWithLayout(<Plans />)} />
          <Route path={PathNames.PAYMENTSUCCESS} element={loadPageWithLayout(<PaymentSuccess />)} />
          <Route path={PathNames.PAYMENTFAILURE} element={loadPageWithLayout(<PaymentFailure />)} />
          <Route path={PathNames.PAYMENTREFUND} element={loadPageWithLayout(<PaymentRefund />)} />
          <Route path={PathNames.CONTACTUS} element={loadPageWithLayout(<ContactUs />)} />
          <Route path={PathNames.TRANSACT} element={loadPageWithLayout(<Transaction />)} />
          <Route path={PathNames.Dashboard} element={loadPageWithLayout(<Dashboard />)} />
          <Route path={PathNames.NOT_FOUND} element={loadPageWithLayout(<NotFound />)} />
          <Route path={PathNames.NOT_FOUND_PAGE} element={loadPageWithLayout(<NotFound />)} />
          <Route path={PathNames.DEVELOPERSUI} element={loadPageWithLayout(<DevelopersUI />)} />
          <Route path={PathNames.CREDIT_HISTORY} element={loadPageWithLayout(<CreditHistory />)} />
          {/* bidhan 18th jan 2023 */}
          <Route path={PathNames.JOIN} element={loadPageWithoutLayout(<Join />)} />

        </Routes>
      </ImpressionTracker>
    </AuthGuard>
    <Loader />
  </div>
}

export default App;