import { AxiosResponse } from "axios";
import { socialNetworkService } from "../appConfig/AxiosConfig";
import { LikeRequest, likeData } from "../model/LikeModel";
import { Utility } from "../utility/Utility"

let client = socialNetworkService;
client.defaults.headers.common = {"Authorization": `bearer ${Utility.getAuthToken()}`, "Content-Type": "application/x-www-form-urlencoded"};

const postLikeAPI = async (likeData: LikeRequest): Promise<Boolean> => {
    let response: AxiosResponse<any> = await client.post<any, AxiosResponse<any>>(`/like`, likeData);
    return response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204;
}
const getLikesData = async (recordId: string, recordType: string, likePage: number, pagesize: number): Promise<likeData> => {
    let response: AxiosResponse<any> = await client.get<any, AxiosResponse<any>>(`/like/${recordType}/${recordId}?page=${likePage}&pagesize=${pagesize}`);
    return response.data;
}

export { postLikeAPI, getLikesData }
