import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import { Utility } from '../../utility/Utility';

export function ServiceWorkerManager() {
    const userData = useSelector((state: ReduxStore) => state?.userData?.user);

    React.useEffect(() => {
        if (userData && (userData?._id?.length > 0 || userData?.id?.length > 0)) {
            navigator.serviceWorker.controller?.postMessage({
                type: 'ACTIVATE',
                data: {
                    userId: userData?._id || userData?.id,
                    token: Utility.getAuthToken()
                }
            });
        }

        return () => {

        }
    }, [userData?._id, userData?.id, navigator?.serviceWorker])

    return <React.Fragment />;
}