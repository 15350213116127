import * as React from 'react';
import { Avatar, Box, Button, Card, Typography, Link } from '@mui/material';
import data from './userData.json';
import PlansFAQ from './PlansFAQ';
import { useNavigate } from 'react-router-dom';
import { PathNames } from '../../appConfig/PathNames';
import { ReduxStore } from '../../model/ReduxStore';
import { useSelector } from 'react-redux';

const UsersPlans = () => {
  const navigate = useNavigate();
  const user = useSelector((state: ReduxStore) => state.auth.user);
  const isAuthenticated = user != null;

  const goToPaymentPage = (planCode: string) => {
    // Check if user is authenticated
    if (!isAuthenticated) {
      navigate(`${PathNames.SIGNIN}`);
      return; // Stop further execution
    }
    // Proceed to payment page if authenticated
    navigate(`${PathNames.PREMIUM}?plan_code=${planCode}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Card Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginTop: { md: '1%', xs: '-20%' },
          justifyContent: 'center',
          width: '90%',
          alignItems: 'center',
        }}
      >
        {data.plans.map((card, index) => (
          <Card
            key={index}
            sx={{
              display: 'flex',
              flexDirection: { md: 'row', xs: 'column' },
              justifyContent: 'space-between',
              width: { md: '70%', xs: '90%' },
              height: 'auto',
              background: '#FFF',
              border: '1px solid #DFECFF',
              borderRadius: '16px',
              marginTop: { md: index === 0 ? 4 : 0, xs: index === 0 ? 2 : 0 },
              margin: { md: 0, xs: 2 },
              alignItems: 'center',
              transition: 'background-color 0.3s, transform 0.3s',
              '&:hover': {
                backgroundColor: '#F0F0F0',
                transform: 'scale(1.03)',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: { md: 'start', xs: 'center' },
                margin: { md: 3, xs: 1 },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Oxygen',
                  fontSize: '24px',
                  fontWeight: 600,
                  lineHeight: '44px',
                }}
              >
                {card.title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Oxygen',
                  fontSize: '16px',
                  fontWeight: 1000,
                  lineHeight: { md: '38px', xs: '28px' },
                  color: '#0F9764',
                }}
              >
                {card.description1}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: { md: 3, xs: 0 },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Oxygen',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '40px',
                }}
              >
                {card.price}
              </Typography>
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Oxygen',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '40px',
                    }}
                  >
                    Exl. GST
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: '8px',
                      lineHeight: '40px',
                      width: { md: '100%', xs: '100%', textAlign: 'center' },
                      marginBottom: { md: 0, xs: '16px' },
                    }}
                    onClick={() => goToPaymentPage(card.plan_code)}
                  >
                    Get Started
                  </Button>
                </>
            </Box>
          </Card>
        ))}
      </Box>

      {/* Refund Policy*/}
      <Box>
        <Link
          sx={{ color: '#0F9764', cursor: 'pointer' }}
          onClick={() => navigate(`${PathNames.PAYMENTREFUND}`)}
        >
          <Typography
            color="#0F9764"
            sx={{
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            Please check our refund policy
          </Typography>
        </Link>
      </Box>
      {/* Flow After Purchase */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: { md: 4, xs: 2 },
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: 'Oxygen',
              fontSize: { md: '46px', xs: '26px' },
              fontWeight: 700,
              lineHeight: { md: '56px', xs: '32px' },
            }}
          >
            Flow After <span style={{ color: '#0F9764' }}>Purchase</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'center',
            margin: { md: 2, xs: 2 },
            alignItems: 'center',
            width: '90%',
          }}
        >
          {data.flow.map((item: any, index: number) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                width: { md: '30%', xs: '90%' },
                height: { md: '220px', xs: 'auto' },
                background: '#FFF',
                border: '1px solid #DFECFF',
                borderRadius: '10px',
                margin: 2,
              }}
            >
              <img
                src={item.imageSrc}
                alt="FlowAfterPurchase"
                style={{ padding: '20px' }}
              />
              <Typography
                sx={{
                  fontFamily: 'Oxygen',
                  fontSize: { md: '16px', xs: '12px' },
                  fontWeight: 600,
                  lineHeight: { md: '20px', xs: '12px' },
                  margin: 1,
                }}
              >
                {item.flowTitle}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Oxygen',
                  color: '#666A6C',
                  fontSize: { md: '14px', xs: '10px' },
                  fontWeight: 400,
                  lineHeight: { md: '22px', xs: '12px' },
                  margin: 2,
                }}
              >
                {item.flowDescription}
              </Typography>
            </Card>
          ))}
        </Box>
      </Box>
      {/* Additional Features */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: { md: 2, xs: 2 },
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: 'Oxygen',
              fontSize: { md: '46px', xs: '26px' },
              fontWeight: 700,
              lineHeight: { md: '56px', xs: '32px' },
            }}
          >
            Additional <span style={{ color: '#0F9764' }}>Features</span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'space-between',
            margin: { md: 2, xs: 2 },
          }}
        >
          <Box
            sx={{
              display: { md: 'flex', xs: 'none' },
              alignItems: 'center',
              justifyContent: 'center',
              width: { md: '50%', xs: '100%' },
            }}
          >
            <img
              src="/static/pricing-page/Graphics.png"
              alt="AdditionalFeatures"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: { md: '50%', xs: '100%' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: { md: 'row', xs: 'column' },
                justifyContent: 'center',
                margin: { md: 2, xs: 2 },
                alignItems: 'center',
                width: '90%',
              }}
            >
              {data.features.map((item: any, index: number) => (
                <Card
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    width: { md: '40%', xs: '90%' },
                    height: 'auto',
                    background: '#FFF',
                    border: '1px solid #DFECFF',
                    borderRadius: '10px',
                    margin: 2,
                  }}
                >
                  <img
                    src={item.imageSrc}
                    alt="FlowAfterPurchase"
                    style={{ padding: '20px' }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Oxygen',
                      fontSize: { md: '16px', xs: '12px' },
                      fontWeight: 600,
                      lineHeight: { md: '20px', xs: '12px' },
                      margin: 1,
                    }}
                  >
                    {item.flowTitle}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Oxygen',
                      color: '#484848',
                      fontSize: { md: '14px', xs: '10px' },
                      fontWeight: 400,
                      lineHeight: { md: '22px', xs: '12px' },
                      margin: 2,
                    }}
                  >
                    {item.flowDescription}
                  </Typography>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* FAQ */}
      <Box sx={{ marginTop: { md: '-3%', xs: '-16%' } }}>
        <PlansFAQ faqs={data.faqs} />
      </Box>
      {/* Testimonials */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: { md: 2, xs: 2 },
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: 'Oxygen',
              fontSize: { md: '46px', xs: '26px' },
              fontWeight: 700,
              lineHeight: { md: '56px', xs: '32px' },
            }}
          >
            What <span style={{ color: '#0F9764' }}>Our Customers </span> Say
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'center',
            margin: { md: 2, xs: 2 },
            alignItems: 'center',
            width: { md: '100%', xs: '90%' },
          }}
        >
          {data.testimonials.map((testimonial, index) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'left',
                width: { md: '25%', xs: '90%' },
                height: { md: '190px', xs: 'auto' },
                background: '#FFF',
                border: '1px solid #DFECFF',
                borderRadius: '10px',
                margin: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Oxygen',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  margin: 2,
                  textAlign: 'left',
                  color: ' #000000',
                }}
              >
                {testimonial.quote}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar
                  sx={{
                    margin: 2,
                    width: 56,
                    height: 56,
                  }}
                  alt={testimonial.name}
                  src={testimonial.avatarSrc}
                />
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', marginY: 3 }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    {testimonial.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '22px',
                    }}
                  >
                    {testimonial.designation}
                  </Typography>
                </Box>
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UsersPlans;
