import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { ListItemButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import { getLikesData } from '../../services/SocialMediaLike';
import InfiniteScroll from "react-infinite-scroll-component";
import { getNavigationLink } from './UserNav';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '1px solid #c8c7c7',

};

export default function LikeMenu({ recordId, recordType, onClose}) {

    const [likeData, setLikeData] = React.useState<any[]>([]);
    const [likePage, setLikePage] = React.useState(0);

    React.useEffect(() => {
        setLikeData([]);
        setLikePage(0);
    }, [recordId]);

    React.useEffect(() => {
        fetchLike();
    }, [])


    const fetchLike = () => {
        getLikesData(recordId, recordType, likePage, 10).then(data => {
            setLikeData([...likeData, ...(data as any as any[])]);
            setLikePage(likePage + 1);
        }).catch((ex) => {
            console.error(ex);
        })
    }


    const handleClose = () => onClose(false);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            onClose={handleClose}
            closeAfterTransition
            hideBackdrop={true}
        >
            <Box sx={style}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px 15px 15px", alignItems: "center" }}>
                    <Typography variant="h6">Likes</Typography>
                    <CancelIcon sx={{ cursor: "pointer" }} onClick={() => handleClose()} />
                </div>
                <Divider />
                <nav aria-label="main mailbox folders">
                    <InfiniteScroll
                        dataLength={likeData.length}
                        next={fetchLike}
                        hasMore={true}
                        loader={<></>}
                        height={300}
                    >
                        <List>
                            {likeData?.map((item, key) => {
                                return (
                                    <ListItem disablePadding>
                                        <ListItemButton >
                                            <Avatar sx={{ marginRight: "10px" }} aria-label="created-by-avatar"
                                                alt={item?.name?.charAt(0)}
                                                src={item?.profilepic}
                                            >
                                            </Avatar>
                                            <Typography
                                                sx={{ fontSize: '15px', fontWeight: '700', textDecorationLine: 'none' }}
                                                component={Link}
                                                to={getNavigationLink(item)}
                                            >
                                                {item?.name}
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </InfiniteScroll>
                </nav>
                <Divider />
            </Box>
        </Modal >
    );
}
