import React from 'react';
import { Box, List, ListItem, ListItemIcon, Paper, Typography } from '@mui/material';
import ReactReadMoreReadLess from '../../../components/ReadMoreReadLess';
import { Company } from '../../../model/CompaniesResult';
import { BoardMeeting } from '../../../model/CorporateActionsResult';
import useStyle from '../Styles';
import StarIcon from '@mui/icons-material/Star';

export interface IEventCardProps {
    companyShort: Company;
    boardMeetings: BoardMeeting[];
}

const ListedEventsCard = (props: IEventCardProps) => {
    const classes = useStyle();

    return <>
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography className={classes.headerTypography}>
                    Events
                </Typography>
            </Box>
            <Paper elevation={0}>
                <List className={classes.cardBody} sx={{
                    // maxHeight: "358px",
                    display: "flex",
                    flexDirection: "column",
                    // overflowY: "scroll",
                    // border: "1px solid #EBEBEB"
                }}>
                    {
                        props.boardMeetings && (props.boardMeetings.length > 0) && props.boardMeetings.slice(0, (props.boardMeetings.length > 5) ? 5 : props.boardMeetings.length)
                            .map((item, index) =>
                                <ListItem sx={{ p: 1, border: '5px' }} key={`${index}_${1 + Math.random() * (100 - 1)}_li`} >
                                    {/* <ListItemIcon>
                                            <StarIcon />
                                        </ListItemIcon> */}
                                    <Typography component="div" key={`${index}_${1 + Math.random() * (100 - 1)}_t`}
                                        sx={{
                                            color: '#686868',
                                            textAlign: 'justify',
                                            fontweight: index < 2 ? 700 : 400,
                                            fontSize: '14px',
                                            lineHeight: '16px',
                                            fontFamily: 'Oxygen',
                                            fontStyle: 'normal',
                                            marginY: '0px',

                                        }}
                                    >
                                        <ReactReadMoreReadLess
                                            charLimit={200}
                                            readIconColor={"#0F9764"}
                                            readMoreText={"Read more ▼"}
                                            readLessText={"Read less ▲"}
                                        >
                                            {item.Description}
                                        </ReactReadMoreReadLess>
                                    </Typography>
                                </ListItem >
                            )
                    }
                </List>
            </Paper>
        </Box>
    </>;
}

export default ListedEventsCard;