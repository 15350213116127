import * as React from "react"
import { SVGProps } from "react"

const UnlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 9V6a5 5 0 0 1 9.9-1M5.8 19h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C17 16.72 17 15.88 17 14.2v-.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C14.72 9 13.88 9 12.2 9H5.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C1 11.28 1 12.12 1 13.8v.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C3.28 19 4.12 19 5.8 19Z"
      stroke="#0F9764"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default React.memo(UnlockIcon)
