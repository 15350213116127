// Copyright 2023 vamsikrishnabakkamunthala
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Person } from "@mui/icons-material";
import { getPreviewUser } from "../../services/UserService";
import { getPreviewUnlistedAdvert } from "../../services/CompanyService";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material"
import React from "react";
import { getMinimalBlogs } from "../../services/SocialMediaService";
import { Utility } from "../../utility/Utility";

interface User {
    "id": string,
    "name": string,
    "profilepic": string,
    "coverpic": string,
    "designation": string
}

interface BlogItems {
    title: string;
    description: string;
    displaypic: string;
    id: string;
}

interface RecommendationItem {
    title: string;
    description: string;
    image: string;
    actionLink: string;
}

const UserPreview = () => {
    const navigate = useNavigate();
    const [user, setUser] = React.useState<User>();
    const [items, setItems] = React.useState<RecommendationItem[]>([]);
    const [blogs, setBlogs] = React.useState<BlogItems[]>([]);
    const location = useLocation();
    const pathParams = location.pathname?.trim()?.split("/").filter(e => e).slice(-1)[0].split("-").slice(-1)[0];

    const getUserDetails = async (userId: string) => {
        const user = await getPreviewUser(userId).catch(() => {
            Utility.notify({
                type: "error",
                message: "User not found redirecting to signin page"
            })
            navigate(`/signin?from=/viewuser?userid=${pathParams}`)
        });
        setUser(user);
    }

    const getBlogs = async () => {
        const blogs = await getMinimalBlogs();
        setBlogs(blogs);
    }

    const getUnlistedAdvert = async () => {
        const unlisted = await getPreviewUnlistedAdvert();
        setItems(unlisted);
    }

    React.useEffect(() => {
        if (Utility.isUserAuthenticated()) {
            navigate(`/viewuser?userid=${pathParams}`)
        } else {
            getUserDetails(pathParams);
            getUnlistedAdvert();
            getBlogs();
        }
    }, [])

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: { xs: "wrap", md: "nowrap" },
            gap: 4
        }}>
            {user !== undefined &&
                <Box
                    sx={{
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: { md: "3.5rem", xs: 0 },
                        height: '100%',
                        background: "#FFFFFF",
                        border: "1px solid #EBEBEB",
                        borderRadius: "8px"
                    }}
                >
                    <Paper
                        elevation={3}
                        style={{
                            position: 'relative',
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            height: '133px',
                            width: '100%',
                            backgroundColor: '#0F9764',
                            backgroundImage: `url(${user?.coverpic})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        {user && user.profilepic && (
                            <Avatar
                                style={{ width: 100, height: 100, marginTop: 80, marginLeft: 20 }}
                                alt="User Picture"
                                src={user.profilepic}
                            />
                        )}
                        {user && !user.profilepic && (
                            <Avatar
                                style={{ width: 150, height: 150, marginTop: 100 }}
                                alt="User Picture"
                            >
                                {user.name ? (
                                    user.name[0].toUpperCase()
                                ) : (
                                    <Person />
                                )}
                            </Avatar>
                        )}
                        <Box sx={{
                            position: 'relative',
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 3,
                            marginTop: 2
                        }}>
                            <Typography sx={{
                                fontWeight: 700,
                                fontSize: 24,
                                color: "#0B3B41",
                                lineHeight: "30px"
                            }}>
                                {user.name}
                            </Typography>
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: "#686868",
                                lineHeight: "30px"
                            }}>
                                {user.designation}
                            </Typography>
                            <Button
                                onClick={() => navigate(`/signin?from=/viewuser?userid=${pathParams}`)}
                                sx={{
                                    background: "#0F9764",
                                    "&:hover": {
                                        backgroundColor: "#0F9764"
                                    },
                                    borderRadius: "24px",
                                    color: "#ffffff",
                                    width: "70%",
                                    height: "34px",
                                    marginTop: 2
                                }}>
                                Sign in to follow
                            </Button>
                        </Box>
                    </Paper>
                    <Box sx={{
                        position: 'relative',
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 3,
                        marginTop: 28
                    }}>
                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            color: "#12626C"
                        }}>
                            Unlisted Companies With Financial Data
                        </Typography>
                        <Box className="page" sx={{
                            position: 'relative',
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            height: { md: "22em" },
                            marginLeft: 1.5,
                            marginTop: 3
                        }}>
                            {
                                items.map((x, i) =>
                                    <Box display="flex" flexDirection="row" key={`${i}_${x.title}`}
                                        sx={{
                                            flexWrap: "nowrap",
                                            marginBottom: "16px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            window.location.href = window.location.href.split("/").slice(0, 3).join("/") + '/' + x.actionLink.replace('undefined/', '')
                                        }}>
                                        <Box><Avatar src={x.image} sx={{ height: "50px", width: "50px", borderRadius: "8px" }} variant="square" ></Avatar></Box>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginLeft: "8px"
                                        }}>
                                            <Typography sx={{ fontSize: "14px", fontFamily: "Oxygen", color: "#0B3B41", fontWeight: "700", display: "block", textTransform: "capitalize" }}>{x.title.length > 40 ? x.title.substring(0, 40) + "..." : x.title}</Typography>
                                            <Typography sx={{ fontSize: "14px", fontFamily: "Oxygen", color: "#686868" }}>{x.description}</Typography>
                                        </Box>
                                    </Box>)
                            }
                        </Box>
                    </Box>
                </Box>
            }
            {blogs.length > 0 && <Box className="page" sx={{
                width: { md: "26%", xs: "100%" },
                background: "#F9F9F9",
                border: "1px solid #EBEBEB",
                borderRadius: "12px",
                padding: '16px 14px 14px 14px',
                marginBottom: '28px'
            }}>
                <Typography color="#0B3B41" sx={{ fontWeight: "700", fontSize: "14px", marginBottom: "8px", display: "block", textTransform: "uppercase" }} component="div">{"Recent Blogs"}</Typography>
                {
                    blogs.map((x, i) =>
                        <Box display="flex" flexDirection="row" key={`${i}_${x.title}`}
                            sx={{
                                marginBottom: "16px", padding: "16px", background: "#FFFFFF", border: "1px solid #E5E5E5", borderRadius: "6px", cursor: "pointer"
                            }}
                        >
                            <Box><Avatar src={x.displaypic} sx={{ height: "50px", width: "50px", borderRadius: "8px" }} variant="square" ></Avatar></Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginLeft: "8px"
                            }}>
                                <Typography sx={{ fontSize: "12px", fontFamily: "Oxygen", color: "#042D1E", fontWeight: "700", display: "block", textTransform: "capitalize" }}>{x.title.length > 40 ? x.title.substring(0, 40) + "..." : x.title}</Typography>
                                <Typography sx={{ fontSize: "11px", fontFamily: "Oxygen", color: "#A4A4A4" }}>{x.description}</Typography>
                            </Box>
                        </Box>)
                }
            </Box>}
        </Box>
    )
}

export default UserPreview