import { IconButton, Tooltip, Theme } from "@mui/material"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";
import { SxProps } from '@mui/system';

export function CopyLinkButton(props: { locationHash: string, style?: SxProps<Theme>; linkToCopy?: string | (() => string) }) {
    const [isCopied, setIsCopied] = useState(false);
    return <><Tooltip title={isCopied ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
        <IconButton
            onClick={() => {
                if (props.linkToCopy) {
                    let link = typeof (props.linkToCopy) == "string" ? props.linkToCopy : props.linkToCopy();
                    navigator.clipboard.writeText(link)
                } else {
                    navigator.clipboard.writeText(window.location.href.replace(new RegExp('/(?=#)(.*?)(?=$)/'), props.locationHash))
                }
                setIsCopied(true)
            }}
            sx={props.style}

        >
            <ContentCopyIcon
                sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
        </IconButton>
    </Tooltip></>
}