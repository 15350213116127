import { GridCellParams } from '@mui/x-data-grid';
import { NumberExtensions } from '../../utility/NumberExtensions';

export function renderCellNumber(params: GridCellParams) {
    var trimmedVal = params.value?.toString().trim();
    if (trimmedVal && trimmedVal.length > 0) {
        if (NumberExtensions.isNumber(trimmedVal)) {
            var nVal = Number(trimmedVal).toFixed(2);
            var nValNum = Number(nVal);
            return <div>{nValNum.toLocaleString("en-IN")}</div>;
        }
        else { 
            return <div>trimmedVal</div>; 
        }
    }
    else {
        return <div>-</div>;
    }
}