import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import ReactReadMoreReadLess from '../../components/ReadMoreReadLess';
import UserPosts from './UserPosts';

function GetUserHome(user_view: any) {
    return (
        <React.Fragment>
            {/* <Box sx={{ padding: '5px 15px' }}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold", paddingY: "5px" }}>
                    Overview
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    <ReactReadMoreReadLess
                        charLimit={300}
                        readIconColor={"#ed6c02"}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                    >
                        {user_view?.about || ''}
                    </ReactReadMoreReadLess>
                </Typography>
            </Box>
            <Divider /> */}
            <Box sx={{ padding: '5px 15px' }}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold", paddingY: "5px", marginBottom: "10px" }}>
                    Posts
                </Typography>
                <UserPosts user={user_view}></UserPosts>
            </Box>
            <Divider />
        </ React.Fragment>
    );
}

export default GetUserHome;