import { ColumnInfo } from "../../model/ColumnInfo";
import { DateExtensions } from "../../utility/DateExtensions";
import { NumberExtensions } from "../../utility/NumberExtensions";
import { isMobile } from "react-device-detect";



function ProcessRawData(dataMaster: any): any {
    var columnOptions = ProcessColumnNames(dataMaster)
    var rows = ProcessRows(dataMaster, columnOptions);
    return { columns: columnOptions, rows: rows };
}

function ProcessColumnNames(dataMaster: any): any[] {
    var columnOptions: any[] = [];
    if (dataMaster) {
        columnOptions.push({ field: 'id', hide: true, sortable: false, filterable: false, headerName: '', width: 0 });
        columnOptions.push({ field: 'name', hide: true, sortable: false, headerName: 'Particulars', width: isMobile ? 180 : 250 });

        var sampleData = dataMaster[0];
        Object.keys(sampleData).forEach(function (key) {
            if (key.startsWith("Y")) {
                columnOptions.push({ field: key, hideable: true, sortable: false, filterable: false, headerName: DateExtensions.getDateStringForHeader(key), width: 150, align: 'right', headerAlign: 'right' });
            }
        });
    }
    return columnOptions;
}

function ProcessRows(dataMaster: any, columns: any[]): any[] {
    var rows: any[] = [];
    var rowCount = 1;
    if (dataMaster) {
        dataMaster.forEach((item) => {
            const responseObject = {};
            responseObject["id"] = rowCount;
            responseObject["name"] = item.COLUMNNAME;

            var hasData = false;

            var cNameCheck = item.COLUMNNAME.trim() as string;
            if (cNameCheck && cNameCheck.endsWith(":")) {
                hasData = true;
            }

            columns.forEach((column) => {

                if (item[column.field]) {
                    var cRowVal = item[column.field] + '';
                    var trimmedVal = cRowVal.trim();
                    if (trimmedVal) {
                        if (trimmedVal && NumberExtensions.isNumber(trimmedVal)) {
                            var nVal = Number(trimmedVal).toFixed(2);
                            var nValNum = Number(nVal);
                            responseObject[column.field] = nValNum;
                            if (nValNum != 0 && hasData === false) {
                                hasData = true;
                            }
                        }
                        else if (trimmedVal.length > 0) {
                            responseObject[column.field] = trimmedVal;
                            if (!hasData) {
                                hasData = true;
                            }
                        }
                        else {
                            responseObject[column.field] = trimmedVal;
                        }
                    }
                }
            });

            if (hasData) {
                FormatCellValueForValidRow(responseObject, columns);
                rows.push(responseObject);
            }
            rowCount = rowCount + 1;
        });
    }
    return rows;
}

function ProcessRawDataWithColumnInfo(dataMaster: any, columnInfo: ColumnInfo): any {
    var columnOptions = ProcessColumnNames(dataMaster);
    var rows;
    if (columnInfo) {
        rows = ProcessRowsWithColumnInfo(dataMaster, columnOptions, columnInfo);
    }
    else {
        rows = ProcessRows(dataMaster, columnOptions);
    }

    return { columns: columnOptions, rows: rows };
}

function ProcessRowsWithColumnInfo(dataMaster: any, columns: any[], columnInfo: ColumnInfo | null): any[] {
    var rows: any[] = [];

    if (dataMaster && columns && columnInfo) {
        columnInfo.forEach((item) => {
            if (item.type.toUpperCase() === "EMPTY") {
                const responseObject = {};
                responseObject["id"] = item.seq;
                responseObject["hierarchy"] = [`EMPTY ${item.seq}`];
                rows.push(responseObject);
            }
            else if (item.type.toUpperCase() === "SECTION") {
                const responseObject = {};
                responseObject["id"] = item.seq;
                responseObject["name"] = item.displayName.trim();
                responseObject["hierarchy"] = item.hierarchy;
                responseObject["type"] = "SECTION";
                rows.push(responseObject);
            }
            else {
                var displayName = item.displayName.trim();
                var rowId = item.rowId.trim();
                if (displayName && rowId) {
                    if (rowId.startsWith("SUM")) {
                        const responseObject = {};
                        responseObject["id"] = item.seq;
                        responseObject["name"] = displayName;
                        responseObject["hierarchy"] = item.hierarchy;
                        responseObject["type"] = item.type;

                        var rowIdString = rowId.substring(4, rowId.length - 1);
                        const rowIdArray = rowIdString.split(",");
                        if (rowIdArray && rowIdArray.length > 0) {
                            rowIdArray.forEach((tempRowId) => {
                                if (tempRowId && tempRowId !== "") {
                                    var isNegetive = false;
                                    var pristineRowId = Number(tempRowId);
                                    var posRowId = Math.abs(pristineRowId);
                                    if (posRowId > pristineRowId) {
                                        isNegetive = true;
                                    }
                                    var filteredRows = dataMaster.filter((o) => { return o.rowno == posRowId; });

                                    if (filteredRows && filteredRows.length > 0) {
                                        var currentRow = filteredRows[0];

                                        columns.forEach((column) => {
                                            if (column.field == "id" || column.field == "name") {

                                            }
                                            else if (!(column.field in responseObject)) {
                                                if (currentRow[column.field]) {
                                                    var cRowVal = currentRow[column.field] + '';
                                                    var trimmedVal = cRowVal.trim();
                                                    if (trimmedVal && NumberExtensions.isNumber(trimmedVal)) {
                                                        var nVal = Number(trimmedVal).toFixed(2);
                                                        if (isNegetive) {
                                                            var calcValue = (0 - Number(nVal)).toFixed(2);
                                                            responseObject[column.field] = Number(calcValue);
                                                        }
                                                        else {
                                                            responseObject[column.field] = Number(nVal);
                                                        }
                                                    }
                                                    else {
                                                        console.log(`Column id ${column.field} is not a number.`)
                                                        responseObject[column.field] = 0;
                                                    }
                                                }
                                            }
                                            else {
                                                if (currentRow[column.field]) {
                                                    var cRowVal = currentRow[column.field] + '';
                                                    var trimmedVal = cRowVal.trim();
                                                    if (trimmedVal && NumberExtensions.isNumber(trimmedVal)) {
                                                        var nVal = Number(trimmedVal).toFixed(2);
                                                        if (isNegetive) {
                                                            var calcValue = (responseObject[column.field] - Number(nVal)).toFixed(2);
                                                            responseObject[column.field] = Number(calcValue);
                                                        }
                                                        else {
                                                            var calcValue = (responseObject[column.field] + Number(nVal)).toFixed(2);
                                                            responseObject[column.field] = Number(calcValue);
                                                        }
                                                    }
                                                    else {
                                                        console.log(`Column id ${column.field} is not a number.`)
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                        FormatCellValueForValidRow(responseObject, columns);
                        rows.push(responseObject);
                    }
                    else {
                        const responseObject = {};
                        responseObject["id"] = item.seq;
                        responseObject["name"] = displayName;
                        responseObject["hierarchy"] = item.hierarchy;
                        responseObject["type"] = item.type;

                        var hasData = false;

                        var filteredRows = dataMaster.filter((o) => { return o.rowno == rowId; });

                        if (filteredRows && filteredRows.length > 0) {
                            var currentRow = filteredRows[0];

                            columns.forEach((column) => {
                                if (column.field == "id" || column.field == "name") {

                                }
                                else if (currentRow[column.field]) {
                                    var cRowVal = currentRow[column.field] + '';
                                    var trimmedVal = cRowVal.trim();
                                    if (trimmedVal) {
                                        if (NumberExtensions.isNumber(trimmedVal)) {
                                            var nVal = Number(trimmedVal).toFixed(2);
                                            var nValNum = Number(nVal);
                                            responseObject[column.field] = nValNum;
                                            if (nValNum != 0 && hasData === false) {
                                                hasData = true;
                                            }
                                        }
                                        else if (trimmedVal.length > 0) {
                                            responseObject[column.field] = trimmedVal;
                                            if (!hasData) {
                                                hasData = true;
                                            }
                                        }
                                        else {
                                            responseObject[column.field] = trimmedVal;
                                        }
                                    }
                                }
                            });
                            if (hasData) {
                                FormatCellValueForValidRow(responseObject, columns);
                                rows.push(responseObject);
                            }
                        }
                    }
                }
            }
        });
    }
    return rows;


}


function FormatCellValueForValidRow(responseObject: any, columns: any[]) {
    if (responseObject && columns && columns.length > 0) {
        columns.forEach((column) => {
            if (column.field.startsWith("Y")) {
                if ((column.field in responseObject)) {
                    var val = responseObject[column.field];

                    if (val) {
                        var cRowVal = val + '';
                        var trimmedVal = cRowVal.trim();
                        if (trimmedVal && trimmedVal.length > 0) {
                            if (NumberExtensions.isNumber(trimmedVal)) {
                                responseObject[column.field] = (Math.round(Number(trimmedVal) * 100) / 100).toLocaleString("en-IN");
                            }
                        }
                        else {
                            responseObject[column.field] = "-";
                        }
                    }
                    else {
                        responseObject[column.field] = "-";
                    }
                }
                else {
                    responseObject[column.field] = "-";
                }
            }
        });
    }
}


export { ProcessRawData, ProcessRawDataWithColumnInfo }