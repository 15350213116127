import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Divider, Grid,IconButton, Paper, Slider, Typography, useMediaQuery, Tooltip, } from '@mui/material';
import useStyle from '../Styles';
import { styled } from '@mui/material/styles';
import { HistoricalData } from '../../../model/HistoricalDataModel';
import { getHistoricalData } from '../../../services/CompanyService';
import { DateExtensions } from '../../../utility/DateExtensions';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Price } from '../../../model/CompanyIndividualSummaryModel';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../../model/ReduxStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 

export interface IEventCardProps {
    co_code: string | number | undefined;
    exchange?: 'NSE' | 'BSE';
    historicalData?: HistoricalData[];
    price: Price | undefined;
    advancedTabCallback: Function;
}

const ListedPriceCard = (props: IEventCardProps) => {
    const classes = useStyle();


    const [historicalData, setHistoricalData] = React.useState<HistoricalData[] | undefined>(undefined);
    const [priceData, setPriceData] = React.useState<Price | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const currentUser = useSelector( ( state: ReduxStore ) => state.userData.user );
    const [link,setLink]= React.useState(false)

    const CustomPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: '#F9F9F9',
        ...theme.typography.body2,
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'left',
    }));

    const CustomSlider = withStyles(theme => ({
        root: {
            "&.MuiSlider-root.Mui-disabled": {
                color: "#0F9764",
                marginTop: 5
            },
            '& .MuiSlider-markLabel': {
                fontSize: "12px",
                fontWeight: "700",
                color: "#686868"
            }
        }
    }))(Slider);

    const ItemKey = styled(Paper)(({ theme }) => ({
        backgroundColor: '#F9F9F9',
        padding: theme.spacing(1),
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'left',
        paddingLeft: '5px'
    }));

    const ItemValue = styled(Paper)(({ theme }) => ({
        backgroundColor: '#F9F9F9',
        padding: theme.spacing(1),
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'right',
        paddingRight: '5px'
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
        backgroundColor: '#F9F9F9',
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'center',
        padding: '0px',
        margin: '0px',
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '16px',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            width: '10px',
        },
        [theme.breakpoints.up('md')]: {
            width: '40px',
        },
        color: "#686868"
    }));

    const CustomDivider = styled(Divider)(({ theme }) => ({
        color: '#EBEBEB',
        flexItem: true,
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '1px'
        },
    }));

    const clickHandler = (event: React.MouseEvent<HTMLElement>, value: number, period: string) => {
        event.preventDefault();
        setLoading(true);
        var d = new Date()
        if (period === 'week')
            d.setDate(d.getDate() - (value * 7));
        if (period === 'month')
            d.setMonth(d.getMonth() - value);
        if (period === 'year') {
            d.setFullYear(d.getFullYear() - value);
            d.setDate(d.getDate() + 2);
        }
        fetchData(d, new Date());
    };

    const fetchData = async (fromDate: Date, toDate: Date) => {
        if (props && props.exchange && props.co_code) {
            getHistoricalData(props.exchange, props.co_code, fromDate, toDate).then(periodicStockData => {
                setHistoricalData(periodicStockData);
                setLoading(false);
            }).catch((ex) => {
                console.error(ex);
            });
        }
    };

    useEffect(() => {
        if (props.price && props.historicalData) {
            setHistoricalData(props.historicalData);
            setPriceData(props.price);
            setLoading(false);
        }
    }, [props.price, props.historicalData])

    const getChart = (historicalData: HistoricalData[] | undefined) => {
        let categories: string[] = [];
        let series: any[] = [];

        if (historicalData && historicalData.length > 0) {
            historicalData.sort(function (a, b) {
            return (new Date(a.date)).getTime() - (new Date(b.date)).getTime();
            });
            
            historicalData.forEach((x) => {
                series.push(x.Close);
                categories.push(DateExtensions.getDateString(new Date(x.date)));
            });
        }

        const apexData: ApexOptions = {
            series: [
                {
                    name: '₹',
                    data: series
                }
            ],
            chart: {
                height: "320px",
                type: "line",
                zoom: {
                    enabled: true
                },
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: false,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: false,
                        delay: 0
                    },
                    dynamicAnimation: {
                        enabled: false,
                        speed: 800
                    }
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "smooth"
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: false
                },
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: false
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#686868',
                        fontSize: "12px"
                    }
                }
            },
            colors: ['#0F9764']
        };

        try {
            return <ReactApexChart options={apexData} series={apexData.series} height='100%' width='100%' style={{ paddingY: '0px', background: "#F9F9F9", color: "#686868" }} />;
        }
        catch (error) {
            console.error("Chart generation failed", error);
        }
    }

    return (props.price && props.historicalData) ? <React.Fragment>
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography className={classes.headerTypography}>
                    Price
                </Typography>
                <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                    <IconButton
                        onClick={() => {
                            window.location.hash = 'tabdetail-score'
                            navigator.clipboard.writeText(window.location.href)
                            setLink(true)

                        }}
                    >
                        <ContentCopyIcon
                            sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>
                {currentUser && <Button variant="contained" color="primary" onClick={() => { props.advancedTabCallback && props.advancedTabCallback(); }}
                    sx={{ height: '35px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none', float: "right" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advance Options&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button> }
            </Box>
            <Paper elevation={0}>
                <Box className={classes.cardBody}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} lg={7} spacing={2} sx={{ padding: '0px', height: '380px', maxHeight: '380px' }}
                            display="flex"
                            direction='column'
                            alignItems={'center'}
                            justifyContent={'center'}>
                            <CustomPaper sx={{ height: "340px", width: "100%" }}>
                                {loading ?
                                    <div style={{
                                        height: '280px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: loading ? "flex" : "none"
                                    }}>
                                        <CircularProgress color="primary" />
                                    </div>
                                    : getChart(historicalData)}

                            </CustomPaper>
                            <Grid container item xs={12} spacing={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: 'center', maxHeight: "40px" }}>
                                <StyledButton onClick={(e) => { clickHandler(e, 1, "week"); }} disabled={loading}>{'1 W'}</StyledButton>
                                <StyledButton onClick={(e) => { clickHandler(e, 1, "month"); }} disabled={loading}>{'1 M'}</StyledButton>
                                <StyledButton onClick={(e) => { clickHandler(e, 6, "month"); }} disabled={loading}>{'6 M'}</StyledButton>
                                <StyledButton onClick={(e) => { clickHandler(e, 1, "year"); }} disabled={loading}>{'1 YR'}</StyledButton>
                                <StyledButton onClick={(e) => { clickHandler(e, 2, "year"); }} disabled={loading}>{'2 YR'}</StyledButton>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px', height: '380px', maxHeight: '380px', marginLeft: { xs: "0px", md: "50px" }, marginTop: { xs: "30px", md: "0px" }, marginBottom: { xs: "10px", md: "0px" } }}>
                            <CustomPaper sx={{ width: '90%' }}>
                                <Grid container
                                    spacing={1}
                                    sx={{ display: priceData ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px' }}
                                >
                                    <Grid container item xs={12} spacing={0}>
                                        <Grid item xs={6} md={6} style={{ textAlign: "center", color: "#686868" }}>
                                            Prev. Close
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ textAlign: "center", color: "#0D0D0", fontWeight: 700 }}>
                                            {priceData && priceData.prev_close?.toFixed(2)}
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={0}>
                                        <Grid item xs={6} md={6} style={{ textAlign: "center", color: "#686868" }}>
                                            Open
                                        </Grid>
                                        <Grid item xs={6} md={6} style={{ textAlign: "center", color: "#0D0D0", fontWeight: 700 }}>
                                            {priceData && priceData.open?.toFixed(2)}
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={0} style={{ textAlign: "center", color: "#686868", padding: '0px', margin: "20px 0px 10px 0px" }}>Day Range</Grid>
                                    <CustomSlider id='day' key={0} sx={{
                                        width: '100%',
                                        "& .MuiSlider-markLabel[data-index='1']": {
                                            top: { md: -8, xs: -1 }
                                        },
                                        color: '#0F9764',
                                        marginBottom: "40px"
                                    }}
                                        size='small'
                                        disabled
                                        aria-label="Always visible"
                                        step={0.01}
                                        defaultValue={priceData ? priceData.price : 0}
                                        min={priceData ? priceData.low_day : 0}
                                        max={priceData ? priceData.high_day : 0}
                                        marks={[
                                            { value: priceData ? priceData.low_day : 0, label: priceData && priceData.low_day },
                                            { value: priceData ? priceData.price : 0, label: priceData && priceData.price },
                                            { value: priceData ? priceData.high_day : 0, label: priceData && priceData.high_day }]} />
                                    <Grid container item xs={12} spacing={0} style={{ textAlign: "center", color: "#686868", padding: '0px', margin: "20px 0px 10px 0px" }}>52 Weeks Range</Grid>
                                    <CustomSlider id='52wk' key={1} sx={{
                                        width: '100%',
                                        color: '#0F9764',
                                        "& .MuiSlider-markLabel[data-index='1']": {
                                            top: { md: -8, xs: -1 }
                                        }
                                    }}
                                        size='small'
                                        disabled
                                        aria-label="Always visible"
                                        step={0.01}
                                        defaultValue={priceData ? priceData.price : 0}
                                        min={priceData ? priceData.low_52_wk : 0}
                                        max={priceData ? priceData.high_52_wk : 0}
                                        marks={[
                                            { value: priceData ? priceData.low_52_wk : 0, label: priceData && priceData.low_52_wk },
                                            { value: priceData ? priceData.price : 0, label: priceData && priceData.price },
                                            { value: priceData ? priceData.high_52_wk : 0, label: priceData && priceData.high_52_wk }]} />
                                    <Grid container item xs={12} spacing={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                                        <Grid container spacing={0} item xs={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "#686868" }}>
                                            <Grid item xs={12}>Bid</Grid>
                                            <Grid item xs={12} color={'#0F9764'}>{priceData && priceData.bbuy_price}</Grid>
                                            <Grid item xs={12} color={'#0F9764'}>{priceData && priceData.bbuy_qty}</Grid>
                                            <Grid item xs={12}>Bid Qty</Grid>
                                        </Grid>
                                        <Grid container item xs={2} style={{ color: "#686868" }}>
                                            OR
                                        </Grid>
                                        <Grid container spacing={0} item xs={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: "#686868" }}>
                                            <Grid item xs={12}>Ask</Grid>
                                            <Grid item xs={12} color={'#0F9764'}>{priceData && priceData.bsell_price}</Grid>
                                            <Grid item xs={12} color={'#0F9764'}>{priceData && priceData.bsell_qty}</Grid>
                                            <Grid item xs={12}>Ask Qty</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomPaper>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    </React.Fragment> : <React.Fragment />;
}

export default ListedPriceCard;