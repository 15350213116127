import { Box } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CSSProperties } from "react";
import { addEntry } from '../../services/UserImpressionService';

export function Creatives(props: { creatives: { image: string, actionLink: string, id?: string }[] }) {
    return <Box sx={{ marginBottom: "28px" }}> <Carousel autoPlay={true} infiniteLoop={true} showStatus={false} showThumbs={false}>
        {props.creatives.map((x, i) =>
            <div onClick={e => {
                x.id && addEntry('Creative', x.id, 'Click');
                window.open(`${x.actionLink}`);
            }} key={i}>
                <img src={x.image} />
            </div>
        )}
    </Carousel></Box>
    // return <Box sx={{ marginBottom: "28px", cursor: "pointer" }} onClick={e => window.location.href = props.actionLink}>
    //     <img src={props.image} />
    // </Box>
}