import { companyService as client } from "../appConfig/AxiosConfig";
import { refreshRewardInfo } from "./UserService";

export async function createDataRequest(companyId: string, type: 'unlock' | 'update') {
    try {
        let resp = await (await client.post(`/servicerequest/data/create?type=${type}`,
            { request_object_id: companyId, request_object_type: 'UnlistedCompany' })).data;
        refreshRewardInfo();
        return resp;
    }
    catch (err) {
        console.log(err);
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Unlock/Update Request Failed");
    }
}

export async function getRequests(companyId: string, type: 'unlock' | 'update', page: number, pageSize: number) {
    return await (await client.post(`/servicerequest/data/create?type=${type}&page=${page}$pagesize=${pageSize}`,
        { request_object_id: companyId, request_object_type: 'UnlistedCompany' })).data;
}

// Obsolete, use getRequests
// export async function getUnlockRequest() {
//     return await (await client.get("/servicerequest/unlock")).data;
// }

export async function getUnlockRequestById(requestId: string) {
    return await (await client.get(`/servicerequest/unlock/${requestId}`)).data;
}

export async function updateUnlockRequestStatus(status: string, requestId: string) {
    return await (await client.put(`/servicerequest/unlock/${requestId}`, { status: status }))
}