import React from 'react'
import CompanyVideo from './CompanyVideo';

function IFrameVideo (companyShort: any) {
  return (
      <>
          <CompanyVideo  companyShort={companyShort}></CompanyVideo>
      </>
  )
}

export default IFrameVideo;