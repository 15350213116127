import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  DialogContent,
  TextField,
  Box,
  Typography,
  Grid,
  IconButton,
  Icon,
  Divider,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { Utility } from '../../utility/Utility';
import { createListedOwnCompany } from '../../services/AdminPanelService';
import FileUploadDialog from './FileUploadDialog';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListedOwnCompanyDialog({
  isOpen,
  message,
  btnText,
  handleClose,
  companyCode,
  companyName,
}) {
  const [email, setEmail] = React.useState('');
  const [showFileUploadDialog, setShowFileUploadDialog] = React.useState(false);
  const [gst, setGst] = React.useState<any | null>('');
  const [incorporation, setIncorporation] = React.useState<any | null>('');
  const [itr, setItr] = React.useState<any | null>('');
  const [gstFile, setGstFile] = React.useState<File | null>(null);
  const [incorporationFile, setIncorporationFile] = React.useState<File | null>(
    null
  );
  const [itrFile, setItrFile] = React.useState<File | null>(null);
  const [error, setError] = React.useState<any | null>('');
  const [isDisabled, setIsDisabled] = React.useState(true);
  const MAX_FILE_SIZE = 8388608; // 8MB in Bytes (in binary)

  const handleConfirm = async () => {
    if (!email) return alert('Enter email');
    // if (!gstFile) return alert('Select a File');
    if (!incorporationFile)
      return alert('Select Incorporation Certificate File');
    if (!itrFile) return alert('Select ITR Acknowledgement File');

    const files: File[] = [];

    if (gstFile) {
      files.push(gstFile);
    }
    if (incorporationFile) {
      files.push(incorporationFile);
    }
    if (itrFile) {
      files.push(itrFile);
    }

    await createListedOwnCompany(companyName, email, files, companyCode)
      .then(() => {
        setShowFileUploadDialog(true);
      })
      .catch((err) => {
        Utility.notify({
          message: `${err.message}`,
          type: 'error',
        });
      });
  };

  const handleFileUploadDialogClose = () => {
    setShowFileUploadDialog(false);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  React.useEffect(() => {
    setIsDisabled(!isValidEmail(email));
  }, [email]);

  return (
    <>
      {!showFileUploadDialog && (
        <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-handle-confirm"
          PaperProps={{
            sx: {
              width: {
                xs: '370px',
                sm: '370px',
                md: '499px',
                lg: '820px',
                xl: '820px',
              },
              maxWidth: '627px !important',
            },
          }}
        >
          <DialogTitle>
            {
              <>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item>
                    <Box className="control-row">
                      <Typography
                        color="#0F9764"
                        sx={{
                          display: 'inline',
                          justifyContent: 'left',
                          // marginTop: '10px',
                          fontSize: { xs: '16px', md: '16px' },
                          fontWeight: '700',
                        }}
                      >
                        {companyName}
                      </Typography>

                      <Typography
                        sx={{
                          display: 'inline',
                          flexDirection: 'center',
                          justifyContent: 'center',
                          color: '#696969',
                          fontSize: { xs: '10px', md: '13px' },
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;(Own your company request here)
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      color="primary"
                      disableRipple={true}
                    >
                      <CloseSharpIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <hr style={{ color: '#EBEBEB', margin: '0px' }} />
              </>
            }
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontSize: '12px', margin: '0px', color: '#696969' }}
                >
                  Email*
                </Typography>
                <TextField
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '250px',
                  }}
                  helperText="Preferable use the email id provided at the Ministry of Corporate Affairs"
                  size="small"
                  type={'email'}
                  error={error}
                  rows={4}
                  inputProps={{ maxlength: '50' }}
                  value={email}
                  // onChange={(ev) => setEmail(ev.currentTarget.value)}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                    },
                  }}
                />
                {error && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontSize: '12px', margin: '0px', color: '#696969' }}
                >
                  GST Certificate
                </Typography>
                <TextField
                  value={gst}
                  size="small"
                  rows={4}
                  helperText="Pdf format only (Max 8mb)"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '250px',
                  }}
                  InputProps={{
                    fullWidth: true,
                    endAdornment: (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <IconButton component="label">
                          <Icon>
                            <img
                              src="static/icons/upload-01.svg"
                              alt="upload-01"
                            />
                          </Icon>
                          <input
                            type="file"
                            accept=".pdf"
                            multiple={false}
                            hidden
                            onChange={(ev) => {
                              if (
                                ev.currentTarget.files &&
                                ev.currentTarget.files.length > 0 &&
                                ev.currentTarget.files[0]
                              ) {
                                if (
                                  ev.currentTarget.files[0].size > MAX_FILE_SIZE
                                ) {
                                  Utility.notify({
                                    message:
                                      'GST File size is greater than 8mb',
                                    type: 'error',
                                  });
                                  return;
                                }
                                if (
                                  !ev.currentTarget.files[0].type.includes(
                                    'pdf'
                                  )
                                ) {
                                  Utility.notify({
                                    message: 'Only PDF files are supported',
                                    type: 'error',
                                  });
                                  return;
                                }

                                setGstFile(ev.currentTarget.files[0]);
                                let fileValue = '';
                                for (
                                  let i = 0;
                                  i < ev.currentTarget.files.length;
                                  i++
                                ) {
                                  fileValue =
                                    fileValue.length > 0
                                      ? fileValue +
                                        ' , ' +
                                        ev.currentTarget.files[
                                          i
                                        ].name?.toString()
                                      : fileValue +
                                        ev.currentTarget.files[
                                          i
                                        ].name?.toString();
                                }
                                setGst(fileValue);
                              }
                            }}
                          />
                        </IconButton>
                      </>
                    ),
                    style: {
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontSize: '12px', margin: '0px', color: '#696969' }}
                >
                  Certificate of Incorporation*
                </Typography>
                <TextField
                  value={incorporation}
                  size="small"
                  rows={4}
                  helperText="Pdf format only (Max 8mb)"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '250px',
                  }}
                  InputProps={{
                    fullWidth: true,
                    endAdornment: (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <IconButton component="label">
                          <Icon>
                            <img
                              src="static/icons/upload-01.svg"
                              alt="upload-01"
                            />
                          </Icon>
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={(ev) => {
                              if (
                                ev.currentTarget.files &&
                                ev.currentTarget.files.length > 0 &&
                                ev.currentTarget.files[0]
                              ) {
                                if (
                                  ev.currentTarget.files[0].size > MAX_FILE_SIZE
                                ) {
                                  Utility.notify({
                                    message:
                                      'GST File size is greater than 8mb',
                                    type: 'error',
                                  });
                                  return;
                                }
                                if (
                                  !ev.currentTarget.files[0].type.includes(
                                    'pdf'
                                  )
                                ) {
                                  Utility.notify({
                                    message: 'Only PDF files are supported',
                                    type: 'error',
                                  });
                                  return;
                                }

                                setIncorporationFile(ev.currentTarget.files[0]);
                                let fileValue = '';
                                for (
                                  let i = 0;
                                  i < ev.currentTarget.files.length;
                                  i++
                                ) {
                                  fileValue =
                                    fileValue.length > 0
                                      ? fileValue +
                                        ' , ' +
                                        ev.currentTarget.files[
                                          i
                                        ].name?.toString()
                                      : fileValue +
                                        ev.currentTarget.files[
                                          i
                                        ].name?.toString();
                                }
                                setIncorporation(fileValue);
                              }
                            }}
                          />
                        </IconButton>
                      </>
                    ),
                    style: {
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontSize: '12px', margin: '0px', color: '#696969' }}
                >
                  Latest ITR Acknowledgement*
                </Typography>
                <TextField
                  value={itr}
                  size="small"
                  rows={4}
                  helperText="Pdf format only (Max 8mb)"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '250px',
                  }}
                  InputProps={{
                    fullWidth: true,
                    endAdornment: (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <IconButton component="label">
                          <Icon>
                            <img
                              src="static/icons/upload-01.svg"
                              alt="upload-01"
                            />
                          </Icon>
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={(ev) => {
                              if (
                                ev.currentTarget.files &&
                                ev.currentTarget.files.length > 0 &&
                                ev.currentTarget.files[0]
                              ) {
                                if (
                                  ev.currentTarget.files[0].size > MAX_FILE_SIZE
                                ) {
                                  Utility.notify({
                                    message:
                                      'GST File size is greater than 8mb',
                                    type: 'error',
                                  });
                                  return;
                                }
                                if (
                                  !ev.currentTarget.files[0].type.includes(
                                    'pdf'
                                  )
                                ) {
                                  Utility.notify({
                                    message: 'Only PDF files are supported',
                                    type: 'error',
                                  });
                                  return;
                                }

                                setItrFile(ev.currentTarget.files[0]);
                                let fileValue = '';
                                for (
                                  let i = 0;
                                  i < ev.currentTarget.files.length;
                                  i++
                                ) {
                                  fileValue =
                                    fileValue.length > 0
                                      ? fileValue +
                                        ' , ' +
                                        ev.currentTarget.files[
                                          i
                                        ].name?.toString()
                                      : fileValue +
                                        ev.currentTarget.files[
                                          i
                                        ].name?.toString();
                                }
                                setItr(fileValue);
                              }
                            }}
                          />
                        </IconButton>
                      </>
                    ),
                    style: {
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{ fontSize: '12px', margin: '0px', color: '#E27769' }}
                >
                  *All the documents should be signed by director
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginTop: '0px' }}>
            <Button
              sx={{
                marginBottom: '16px',
                marginRight: { md: '25px', xs: '10px' },
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={isDisabled}
              sx={{
                marginBottom: '16px',
                marginRight: { md: '25px', xs: '10px' },
              }}
              onClick={handleConfirm}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showFileUploadDialog && (
        <FileUploadDialog
          isOpen={showFileUploadDialog}
          onClose={handleFileUploadDialogClose}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
