import { userImpressionService } from "../appConfig/AxiosConfig";
import { isMobile, browserName } from 'react-device-detect';

const addEntry = async (refType: 'User' | 'Company' | 'UnlistedCompany' | 'Creative' | 'Product', refId: string, actionType: 'View' | 'Click', refSubType?: string) => {

    let entry: any = {};
    // entry.refType = StringExtensions.humanize(refType);
    entry.refType = refType;
    entry.refId = refId;
    entry.actionType = actionType;
    entry.appType = 'Web';
    if (refSubType && refSubType.length > 0) {
        entry.refSubType = refSubType;
    }
    if (browserName && browserName.length > 0) {
        entry.osType = browserName
    }

    if (isMobile === true) {
        entry.mobileDevice = true;
    }

    // entry.appType = 'Browser';
    // entry.deviceType = isMobile === true ? 'Mobile' : 'Web';

    return await (await userImpressionService.post(`/userimpression/track`, entry)).data;
}

const addEntryBulk = async (ref: { refType: 'User' | 'Company' | 'UnlistedCompany' | 'Creative', refId: string }[], actionType: 'View' | 'Click', refSubType?: string) => {
    if (ref && Array.isArray(ref) && ref.length > 0) {
        let entryList: any[] = ref.map(x => {
            let entry: any = {
                refId: x.refId,
                refType: x.refType,
                actionType: actionType,
                appType: 'Web'
            }
            if (refSubType && refSubType.length > 0) {
                entry.refSubType = refSubType;
            }
            if (browserName && browserName.length > 0) {
                entry.osType = browserName
            }

            if (isMobile === true) {
                entry.mobileDevice = true;
            }
            return entry;
        });

        if (entryList && entryList.length > 0) {
            await userImpressionService.post(`/userimpression/track/bulk`, entryList);
        }
    }
    return;
}

const updateElapsedTime = async (id: string, ellapsedTimeInSeconds: number) => {
    if (ellapsedTimeInSeconds && ellapsedTimeInSeconds > 0) {
        let entry: any = {};
        entry.ellapsedTime = ellapsedTimeInSeconds;
        await userImpressionService.post(`/userimpression/track/${id}`, entry);
    }
    else {

    }
    return;
}

export { addEntry, addEntryBulk, updateElapsedTime }