import axios from 'axios';
import { paymentService as client } from '../appConfig/AxiosConfig';
import { OrderDetails } from '../model/OrderDetails';
import { PricingPlan } from '../model/PricingPlan';
import { Utility } from '../utility/Utility';

export async function storeTransaction(orderDetails : OrderDetails) {
    const response = await client.post('/transact', orderDetails);
    return response;
}

export async function createTransaction(orderId : String) {
    const headers = {"authorization": `Bearer ${Utility.getAuthToken()}`};
    const response = await axios.get(`/transact/${orderId}`, {headers});
    return response;
}

export async function startTransaction(orderId : String) {
    const headers = {"content-type": 'application/json'};
    const response = await axios.post(`/transact/${orderId}`, {"authorization": `${Utility.getAuthToken()}`}, {headers});
    return response;
}

export async function getPlanDetails(plan_code: string) {
    const response = await client.get<PricingPlan>(`/pricingplans/${plan_code}`);
    return response.data;
}

export async function getAllPlanDetails() {
    const response = await client.get<PricingPlan[]>(`/pricingplans`);
    return response.data;
}
