import { Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import React, { useEffect } from 'react';
import { DateExtensions } from "../../utility/DateExtensions";
import useStyle from "./Styles";
import DinDataDialog from './DinDataDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 


function GetDirectorsCard(props: { directors: any, type: number, loading: boolean, updated_on?: string }) {
    const ColumnsDisplayName = {
        'din': 'DIN',
        'name': 'Name',
        'designation': 'Designation',
        'appointment_date': 'Date of Appt.',
        'age': 'Age'
    }

    const masterClasses = useStyle();

    const [rows, setRows] = React.useState<any>([]);

    const [columns, setColumns] = React.useState<any>([]);

    const [showContent, setShowContent] = React.useState<boolean>(false);

    const [updatedOn, setUpdatedOn] = React.useState<string>('');

    const [showDinDataDialog, setShowDinDataDialog] = React.useState<boolean>(false);
    const [selRowDIN, setSelRowDIN] = React.useState<string>('');
    const [selRowName, setSelRowName] = React.useState<string>('');
    const [link, setLink] = React.useState(false);

    const isSM = useMediaQuery("(min-width:600px)");

    const handleClickDinDetailsOpen = (din: string, name: string) => {
        setSelRowDIN(din);
        setSelRowName(name);
        setShowDinDataDialog(true);
    }

    const handleDinDataDialogClose = () => {
        setShowDinDataDialog(false);
    } 

    // React.useEffect(() => {
    //     if (showDinDataDialog === true && selRowDIN?.length > 0 && selRowName?.length > 0) {}

    // }, [selRowDIN, selRowName, showDinDataDialog])

    useEffect(() => {
        if (props.updated_on && props.updated_on.length > 0) {
            let dateString = DateExtensions.getDateStringUppercaseMonthFromString(props.updated_on, ' ', '');
            if (dateString?.trim()?.length > 0) {
                setUpdatedOn(`Updated On: ${dateString}`);
            }
        }
    }, [props.updated_on]);

    const showDiv = (): boolean => {
        if ((!props.loading) && (props.type === 9) && props.directors && Array.isArray(props.directors) && props.directors.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        if (showDiv()) {
            setShowContent(showDiv());

            // Process Columns
            var columnOptions: GridColDef[] = [];
            columnOptions.push({
                field: 'din',
                headerName: ColumnsDisplayName['din'],
                width: 100,
                renderCell: (params: any) => {
                    let dinNumber = params.row.din;
                    return (
                        <IconButton
                            onClick={() => { handleClickDinDetailsOpen(dinNumber, params.row.name); }}
                            sx={{ textAlign: 'left', maxWidth: { xs: '120px', sm: '120px' } }} color='primary'>
                            {dinNumber}
                        </IconButton>
                    )
                }
            });
            columnOptions.push({ field: 'name', headerName: ColumnsDisplayName['name'], flex: 1, minWidth: 250 });
            columnOptions.push({ field: 'designation', headerName: ColumnsDisplayName['designation'], width: 150 });
            columnOptions.push({ field: 'appointment_date', headerName: ColumnsDisplayName['appointment_date'], width: 120 });
            columnOptions.push({ field: 'age', headerName: ColumnsDisplayName['age'], width: 50 });

            setColumns(columnOptions);

            // Process Rows
            var rows: any[] = [];
            try {
                props.directors.forEach((row) => {
                    if (row) {
                        var responseObject = {};

                        responseObject["din"] = row.din;
                        responseObject["name"] = row.name;
                        responseObject["designation"] = row.designation;
                        responseObject["appointment_date"] = DateExtensions.getDateStringUppercaseMonthFromString(row.appointment_date, " ");
                        responseObject["age"] = row.age;
                        rows.push(responseObject);
                    }

                    if (rows?.length > 0) {
                        setRows(rows);
                    }
                    else {
                        setRows([]);
                    }
                });
            }
            catch (error) {
                console.error(error);
                setRows([]);
            }
        }
    }, [props.directors, props.loading]);

    try {
        return (
            <React.Fragment>
                {showContent &&
                    <Box className={masterClasses.root} sx={{ marginTop: '24px' }} id='tabdetail-directors'>
                        <Card elevation={0} sx={{ paddingY: '24px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '8px' }}>
                            <CardHeader
                                classes={{ action: masterClasses.action, title: masterClasses.cardHeaderTitle, subheader: masterClasses.cardSubHeaderTitle }}
                                sx={{ marginX: { sm: "24px", xs: "24px" }, textAlign: 'left', paddingLeft: '0px', paddingRight: '8px', paddingTop: '0px', paddingBottom: '32px' }}
                                title={<Typography
                                    sx={{ fontSize: { xs: '16px', md: '24px' } }}
                                >Directors</Typography>}
                                subheader={updatedOn}
                            
                                
                            action={ <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                            <IconButton
                                onClick={() => {
                                    window.location.hash = 'tabdetail-directors'
                                    navigator.clipboard.writeText(window.location.href)
                                    setLink(true)
                                }}
                            >
                                <ContentCopyIcon
                                    sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                            </IconButton>
                        </Tooltip>}
                            
                            // action={
                            //     <DownloadButton variant="contained" color="primary" sx={{ height: '39px', width: '128px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none' }} />
                            // }
                            >
                                
                            </CardHeader>
                            <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }} className={masterClasses.cardBodyText}>
                                <Box
                                    sx={{
                                        flexGrow: 1, bgcolor: '#F9F9F9', display: 'flex', paddingY: '0px', paddingX: { xs: '5px', sm: '5px', md: '24px' },
                                        '& .MuiDataGrid-pinnedColumnHeaders': {
                                            backgroundColor: '#F9F9F9 !important'
                                        },
                                        '& .MuiDataGrid-pinnedColumns': {
                                            backgroundColor: '#F9F9F9 !important'
                                        }
                                    }}
                                >
                                    <DataGridPro
                                        initialState={isSM === true ? {} : { pinnedColumns: { left: ['din'] } }}
                                        rows={rows}
                                        getRowId={(row) => row.din}
                                        columns={columns}
                                        density={"compact"}
                                        autoHeight={true}
                                        pageSize={5}
                                        pagination={true}
                                        rowsPerPageOptions={[5]}
                                        columnVisibilityModel={{
                                            // Hide columns id, the other columns will remain visible
                                            id: false,
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }
                {showDinDataDialog &&
                    <DinDataDialog
                    isOpen={showDinDataDialog}
                    onClose={handleDinDataDialogClose}
                    din={selRowDIN}
                    name={selRowName}
                    />
                }
            </ React.Fragment>
        );
    }
    catch (error) {
        console.error(error);
        return (<React.Fragment></React.Fragment>);
    }
}

export default GetDirectorsCard;