import { Box, Divider, IconButton, InputBase, Paper, Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useRef, useState } from "react";
import { ISellerProduct } from "./Models/SellerProduct";
import { getAllProducts } from "../../services/MarketPlaceService";
import { Utility } from "../../utility/Utility";

export function SellerProductList() {
    const params = useParams();
    const navigate = useNavigate();
    const { sellerCode, categoryid } = params;

    const [products, setProducts] = useState<ISellerProduct[]>([]);

    const [filterText, setFilterText] = useState<string>('');
    const filterInputRef = useRef<HTMLInputElement>();


    useEffect(() => {
        Utility.setLoading(true);
        getAllProducts(sellerCode!, 0, categoryid).then(products => {
            setProducts(products.data);
            Utility.setLoading(false);
        })
        setFilterText("");
    }, [sellerCode, categoryid]);

    const onProductSearch = () => {
        filterInputRef.current && setFilterText(filterInputRef.current.value);
    }


    return <Box sx={{ marginTop: "10px" }}>
        <Box sx={{ paddingLeft: { xs: "10px", md: "0px" } }}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#000", lineHeight: "24px" }}>Whats new?</Typography>
        </Box>
        <Box sx={{ display: "flex", paddingLeft: { xs: "10px", md: "0px" }, flexDirection: { md: "row", xs: "column" }, gap: { md: 0, xs: "10px" } }}>

            {categoryid ?
                <Typography sx={{ color: "#003459", fontWeight: 600, fontSize: { md: "24px", xs: "20px" }, lineHeight: "36px" }}>Below products matched to the selected category</Typography>
                : <Typography sx={{ color: "#003459", fontWeight: 600, fontSize: { md: "24px", xs: "20px" }, lineHeight: "36px" }}>Take a look at some of our products</Typography>
            }
            <Paper
                component="form"
                sx={{
                    p: '2px 4px', display: 'flex',
                    alignItems: 'center', width: 280, borderRadius: "40px",
                    boxShadow: "none", border: "1px solid #EBEBEB", marginLeft: { md: "auto", xs: "0px" }
                }}
                onSubmit={(e) => { e.preventDefault(); onProductSearch(); }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Products"
                    inputProps={{ 'aria-label': 'search products' }}
                    inputRef={filterInputRef}
                />

                <IconButton type="button" sx={{ p: '10px' }} onClick={() => onProductSearch()} >
                    <SearchIcon />
                </IconButton>
            </Paper>
        </Box>
        <Box sx={{ display: "block", marginTop: "36px", bgcolor: "#F9F9F9" }}>
            {
                products.filter(x => x.name.toLowerCase().indexOf((filterText || x.name).toLowerCase()) > -1)
                    .map(product => (
                        <Box onClick={e => navigate(`../product/${product.id}`)} sx={{
                            width: { md: "calc(25% - 32px)", xs: "calc(50% - 32px)" }, margin: "8px", padding: "8px", borderRadius: "12px", float: "left", cursor: "pointer",
                            background: "#FDFDFD", boxShadow: "0px 4px 28px -2px rgba(0, 0, 0, 0.08)"
                        }} >
                            {(product.media && product.media.length > 0) ? <img src={product.media![0].link} style={{ width: "100%", height: "264px", borderRadius: "12px" }} /> : <Box sx={{ height: "264px", background: "grey", borderRadius: "8px" }}><Typography sx={{
                                marginTop: "auto", color: "#FFFFFF", textAlign: "center",
                                paddingTop: "50%",
                                display: "block",
                                fontSize: "18px"
                            }}>No Image</Typography></Box>}

                            <Box sx={{ padding: "8px 8px 10px 8px", bgcolor: "#FFFFFF" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>{product.name}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    {product.details && product.details.slice(0, 2).map(attr => <Box>
                                        <Typography sx={{ fontSize: "12px", color: "#667479", textTransform: "capitalize", width: "50%", marginY: "4px", display: "block", float: "left" }}>{attr.name}</Typography>
                                        <Typography sx={{ fontSize: "12px", color: "#667479", textTransform: "capitalize", width: "50%", marginY: "4px", display: "block", float: "left" }}>{attr.value}</Typography>
                                    </Box>)}
                                </Box>
                                {/* <Typography sx={{ fontSize: "14px", fontWeight: 500, color: "#00171F" }}>₹ {product.price}</Typography> */}
                            </Box>
                        </Box>
                    ))
            }
            {
                products.length == 0 && <Box sx={{ textAlign: "center" }}><Typography sx={{ fontSize: "26px", color: "red", fontWeight: "600" }}>No Products Found.</Typography></Box>
            }
        </Box>
    </Box>
}