import * as React from 'react';
import { Box, CardMedia, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CSSProperties, createStyles, makeStyles } from '@mui/styles';
import { Carousel } from 'react-responsive-carousel';
import { MediaListItems } from './ImageGridInterfaces';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import handleImageError from './DefaultHandler';



const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    color: "#fff"
};

const arrowIconButtonStyles: CSSProperties = {
    color: "#fff",
    borderRadius: "50%",
    border: "2px solid #0f9764",
    padding: "5px",
    fontSize: "30px",
    backgroundColor: "#0f9764"
};


const styles = makeStyles((theme: any) => ({
    rootDialogContent: {
        maxWidth: "100%",
        [theme.breakpoints.down('md')]: {
            paddingX: "12px",
            // width: "calc(100% - 24px)"
        },
        [theme.breakpoints.up('md')]: {
            paddingX: "24px",
            // width: "calc(100% - 48px)"
        }
    },
    rootDialogFullWidth: {
        maxWidth: "100%",
        [theme.breakpoints.down('md')]: {
            paddingX: "4px",
            margin: "8px",
            width: "100%",
        },
        [theme.breakpoints.up('md')]: {
            paddingX: "24px",
            width: "70%",
        },
        backgroundColor: "#ebebeb",
        boxShadow: 'none',
    },
}));

export default function FullScreenView(props: MediaListItems) {
    const classes = styles();

    return ((props?.open === true && props.mediaList?.length > 0) ?
        <React.Fragment>
            <Dialog id="media-fullscreen-view"
                open={props.open}
                onClose={props.onClose}
                fullWidth={true}
                sx={{ maxWidth: "100%", width: '100%' }}
                classes={{
                    paperFullWidth: classes.rootDialogFullWidth
                }}
                PaperProps={{
                    // style: {
                    //     backgroundColor: "#ebebeb",
                    //     boxShadow: 'none',
                    //     maxWidth: "100%",
                    //     width: "60%",
                    // },
                }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: "flex-end", height: "20px" }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                        size="small"
                        sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                        <CloseRoundedIcon style={{ ...arrowIconButtonStyles }}
                            sx={{ color: '#0f9764 !important', backgroundColor: '#ebebeb !important', border: 'none !important' }}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.rootDialogContent}>
                    <Box sx={{ display: "flex", alignItems: "center" }} >
                        {
                            <Carousel autoPlay={false} infiniteLoop={true} className={"presentation-mode"}
                                selectedItem={props.currentIndex || 0}
                                // statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
                                statusFormatter={(current, total) => ""}
                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                    hasPrev && (
                                        <IconButton onClick={onClickHandler} style={{ ...arrowStyles, left: 15 }}>
                                            <ArrowBackIosNewOutlinedIcon style={{ ...arrowIconButtonStyles }} />
                                        </IconButton>
                                    )
                                }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <IconButton onClick={onClickHandler} style={{ ...arrowStyles, right: 15 }}>
                                            <ArrowForwardIosOutlinedIcon style={{ ...arrowIconButtonStyles }} />
                                        </IconButton>
                                    )
                                }
                                showStatus={true}
                                showThumbs={false}

                            >
                                {props.mediaList!.map((x, index) =>
                                    <Box>
                                        {
                                            <CardMedia
                                                key={"story-feed-fullScreen-" + index}
                                                component={x.type === "video" ? "video" : "img"}
                                                controls={x.type === "video" ? true : false}
                                                // src={x.link}
                                                sx={{
                                                    margin: "auto",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    objectFit: "contain",
                                                    maxWidth: "80%",
                                                    // marginTop: "10px"
                                                }}
                                                autoPlay={false}
                                                image={x.link}
                                                onError={(e) => { handleImageError(e) }}
                                            />
                                        }
                                    </Box>
                                )}
                            </Carousel>
                        }
                    </Box>
                </DialogContent>
            </Dialog >
        </React.Fragment>
        :
        <React.Fragment />
    );
}
