import { Box, FormControl, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface IProps {
    value: string[];
    onChange: (value: any) => void;
    label: string;
}

const ruppeesIcon = <>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="url(#paint0_linear_8_2277)" />
        <defs>
            <linearGradient id="paint0_linear_8_2277" x1="8" y1="-2.26667" x2="8" y2="17.68" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0F9764" />
                <stop offset="1" stop-color="#4ACCA0" />
            </linearGradient>
        </defs>
    </svg>
    <span style={{ color: "#fff", position: "absolute", marginLeft: "5px" }}>₹</span>
</>

export function AmountMinMaxControl(props: IProps) {

    const [min, setMin] = useState('');
    const [max, setMax] = useState('');

    useEffect(() => {
        if (props.value && props.value.length > 0) {
            const [min, max] = props.value;
            setMin(min);
            setMax(max);
        } else {
            setMin('');
            setMax('');
        }
    }, [props.value]);

    useEffect(() => {
        let m1 = parseInt(min) as any;
        let m2 = parseInt(max) as any;
        if (isNaN(m1)) {
            m1 = void (0);
        }
        if (isNaN(m2)) {
            m2 = void (0);
        }
        props.onChange([m1, m2]);
    }, [min, max]);

    return <Box sx={{
        borderRadius: "8px",
        padding: "5px 28px"
    }}>
        <Typography sx={{ fontSize: "14px", marginBottom: "5px", color: "#393939", fontWeight: 700 }} component={"div"}>{props.label}</Typography>

        <form style={{ display: "flex", gap: "20px" }} noValidate autoComplete="off">
            <FormControl>
                <OutlinedInput sx={{ fontSize: "12px", background: "#fff", height: "45px" }} onChange={(e) => {
                    setMin(e.target.value);
                }} value={min} inputProps={{ style: { paddingLeft: "5px" } }} startAdornment={<InputAdornment position="end">{ruppeesIcon}</InputAdornment>} placeholder="Min" />
            </FormControl>
            <FormControl>
                <OutlinedInput sx={{ fontSize: "12px", background: "#fff", height: "45px" }} onChange={(e) => {
                    setMax(e.target.value);
                }} value={max} inputProps={{ style: { paddingLeft: "5px" } }} startAdornment={<InputAdornment position="end">{ruppeesIcon}</InputAdornment>} placeholder="Max" />
            </FormControl>
        </form>
    </Box>
}