import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, IconButton,Tooltip,FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { Company } from '../../../model/CompaniesResult';
import { getScoreData, postScoreData } from "../../../services/UserService";
import useStyle from '../Styles';
import { Score } from '../../../model/CompanyIndividualSummaryModel';
import { styled } from '@mui/material/styles';
import { ListedIndividualPageSettings } from '../../../model/PageSettingsModel';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../../model/ReduxStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 


export interface IEventCardProps {
    companyShort: Company;
    score: Score | undefined;
    pageSettings?: ListedIndividualPageSettings;
}

const scores = [
    {
        "value": "equity",
        "label": "Equity (Crores)",
        "checked": true,
    },
    {
        "value": "face_value",
        "label": "Face Value (₹)",
        "checked": true,
    },
    {
        "value": "reserves",
        "label": "Reserves (Crores)",
        "checked": true,
    },
    {
        "value": "dividend",
        "label": "Dividend (%)",
        "checked": true,
    },
    {
        "value": "sales_turnover",
        "label": "Sales Turnover (Crores)",
        "checked": true,
    },
    {
        "value": "net_profit",
        "label": "Net Profit (Crores)",
        "checked": true,
    },
    {
        "value": "full_year_cps",
        "label": "Full Year CPS (₹)",
        "checked": true,
    },
    {
        "value": "eps",
        "label": "Earning Per Share (₹)",
        "checked": true,
    },
    {
        "value": "lq_opm",
        "label": "Latest Quarter OPM",
        "checked": true,
    },
    {
        "value": "lq_nsv",
        "label": "Latest Quarter Net Sales Variance",
        "checked": true,
    },
    {
        "value": "t12mnth_yrc",
        "label": "Trailing 12 Months YRC",
        "checked": false,
    },
    {
        "value": "t12mnth_sto",
        "label": "Trailing 12 Months STO",
        "checked": false,
    },
    {
        "value": "t12mnth_op",
        "label": "Trailing 12 Months OP",
        "checked": false,
    },
    {
        "value": "t12mnth_opm",
        "label": "Trailing 12 Months OPM",
        "checked": false,
    },
    {
        "value": "t12mnth_gp",
        "label": "Trailing 12 Months GP",
        "checked": false,
    },
    {
        "value": "t12mnth_gpm",
        "label": "Trailing 12 Months GPM",
        "checked": false,
    },
    {
        "value": "t12mnth_np",
        "label": "Trailing 12 Months NP",
        "checked": false,
    },
    {
        "value": "t12mnth_npv",
        "label": "Trailing 12 Months NP Varience",
        "checked": false,
    },
    {
        "value": "fy_nsv",
        "label": "Full Year Net Sales Variance",
        "checked": false,
    },
    {
        "value": "pv_to_bv",
        "label": "Price Value To Book Value",
        "checked": false,
    },
    {
        "value": "ttm_dy",
        "label": "TTM Dividend Yield",
        "checked": false,
    },
    {
        "value": "ttm_roe",
        "label": "TTM ROE",
        "checked": false,
    },
    {
        "value": "ttm_ind_pe",
        "label": "TTM Industry PE",
        "checked": false,
    },
    {
        "value": "out_standing_shares",
        "label": "Out Standing Shares (units)",
        "checked": false,
    }
]

const scoreArray = [
    "equity",
    "face_value",
    "reserves",
    "dividend",
    "sales_turnover",
    "net_profit",
    "full_year_cps",
    "eps",
    "lq_opm",
    "lq_nsv",
    "t12mnth_yrc",
    "t12mnth_sto",
    "t12mnth_op",
    "t12mnth_opm",
    "t12mnth_gp",
    "t12mnth_gpm",
    "t12mnth_np",
    "t12mnth_npv",
    "fy_nsv",
    "pv_to_bv",
    "ttm_dy",
    "ttm_roe",
    "ttm_ind_pe",
    "out_standing_shares",
].slice(0, 10);

const ListedScoreCard = (props: IEventCardProps) => {
    const classes = useStyle();
    const [scoreObject, setScoreObject] = React.useState(scores);
    const [scoreResponse, setScoreResponse] = React.useState(scoreArray);
    const [open, setOpen] = React.useState(false);
    const isSM = useMediaQuery("(min-width:600px)");
    const currentUser = useSelector( ( state: ReduxStore ) => state.userData.user );
    const [link,setLink]= React.useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    let settings: string[] = (props && props.pageSettings && props.pageSettings.selectedScoreCardFields?.length > 0)
        ? props.pageSettings.selectedScoreCardFields
        : scoreResponse;

    function isFieldEnabled(key: string): boolean {
        return settings.includes(key);
    }

    const handleDataPoints = (event: any) => {
        setOpen(true);
    }

    const initialFetchScoreData = async () => {
        let scoreResponse = await getScoreData().then(res => res.data.listed_individual_score_card);
        if (scoreResponse !== undefined || scoreResponse !== null) {
            setScoreResponse(scoreResponse);
            setScoreObject(scoreObject.map(item => {
                return { ...item, checked: scoreResponse.includes(item.value) ? true : false }
            }));
        };
    }

    React.useEffect(() => {
        if (props.score) {
            initialFetchScoreData();
        }
    }, [])

    const ItemKey = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#EDEDED' : '#EDEDED',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'left',
        paddingLeft: '20px',
        color: "#686868"
    }));
    const ItemValue = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#EDEDED' : '#EDEDED',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        border: 'none',
        borderRadius: '0px',
        boxShadow: 'none',
        textAlign: 'right',
        paddingRight: '20px',
        color: "#0D0D0D",
        fontWeight: 700
    }));

    const handleSelectScore = (data: any) => {
        let updateScoreChecked = scoreObject.map(item => {
            if (item.value === data.value) {
                return { ...item, checked: !data.checked }
            }
            return item
        })
        setScoreObject(updateScoreChecked);
    }

    const handleSubmitScore = async () => {
        const postData = {
            listed_individual_score_card: scoreObject.filter(item => item.checked).map(item => { return item.value })
        }
        const scoreResponse = await postScoreData(postData).then(res => res.data.listed_individual_score_card);
        setScoreResponse(scoreResponse);
        setOpen(false);
    }

    if (props.score) {
        return <>
            <Box className={classes.root}>
                <Box className={classes.header}>
                    <Typography className={classes.headerTypography}>
                        Score
                    </Typography>
                    <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                        <IconButton
                            onClick={() => {
                                window.location.hash = 'tabdetail-score'
                                navigator.clipboard.writeText(window.location.href)
                                setLink(true)
                            }}
                        >
                            <ContentCopyIcon
                                sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                        </IconButton>
                    </Tooltip>
                    
                    {currentUser && <Button sx={{
                        width: "221px",
                        height: "35px",
                        border: "1px solid #0F9764",
                        borderRadius: "24px",
                        fontSize: "14px",
                        fontFamily: "Oxygen",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "19px",
                        color: "#0F9764",
                        float: "right"
                    } } onClick={ handleDataPoints } >Add More Data Points 
                    </Button> }
                </Box>
                <Paper elevation={0}>
                    <Dialog onClose={handleClose} open={open} >
                        <Box sx={{
                            marginTop: { md: 2, xs: 2 },
                            marginBottom: { md: 2, xs: 2 },
                            marginLeft: { md: 6, xs: 10 }
                        }}>
                            <FormLabel
                                sx={{
                                    color: scoreObject.filter(item => item.checked).length !== 10 ? "red" : "black",
                                }}
                                component="legend">{`Selected ${scoreObject.filter(item => item.checked).length} of 10 items`}</FormLabel>
                        </Box>
                        <Box className={classes.cardBody}>
                            <Grid container spacing={isSM ? 1 : 1}>
                                {scoreObject.map((item, index) => {
                                    return <Grid container item xs={12} md={6} spacing={0} key={`${index}_${1 + Math.random() * (100 - 1)}_grid`}>
                                        <FormGroup sx={{ marginLeft: { md: 3, xs: 6 } }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={item.checked} onChange={() => handleSelectScore(item)} name={item.value} />
                                                }
                                                label={item.label}
                                            />
                                        </FormGroup>
                                    </Grid>
                                })}
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <DialogActions>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Button
                                        color="error"
                                        variant="outlined"
                                        onClick={handleClose}>Cancel</Button>
                                    <Button
                                        color="success"
                                        variant="contained"
                                        disabled={scoreObject.filter(item => item.checked).length !== 10}
                                        onClick={handleSubmitScore}>Submit</Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </Dialog>

                    <Box className={classes.cardBody}>
                        <Grid className='white-rectangle' container spacing={isSM ? 3 : 2}>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("equity") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Equity (Crores)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.equity?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("face_value") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Face Value (₹)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.face_value?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("reserves") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Reserves (Crores)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.reserves?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("dividend") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Dividend (%)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.dividend?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("sales_turnover") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Sales Turnover (Crores)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.sales_turnover?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("net_profit") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Net Profit (Crores)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.net_profit?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("full_year_cps") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Full Year CPS (₹)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.full_year_cps?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("eps") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Earning Per Share (₹)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.eps?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_yrc") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Trailing 12 Months YRC</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.t12mnth_yrc?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_sto") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Trailing 12 Months STO</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.t12mnth_sto?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_op") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Trailing 12 Months OP</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.t12mnth_op?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_opm") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Trailing 12 Months OPM</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.t12mnth_opm?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_gp") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Trailing 12 Months GP</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.t12mnth_gp?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_gpm") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Trailing 12 Months GPM</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.t12mnth_gpm?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_np") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={9}>
                                    <ItemKey>Trailing 12 Months NP</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <ItemValue>{props.score.t12mnth_np?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("t12mnth_npv") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={9}>
                                    <ItemKey>Trailing 12 Months NP Varience</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <ItemValue>{parseFloat(props.score.t12mnth_npv)?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("lq_opm") === true ? 'flex' : 'none'}>
                                <Grid item xs={9} md={8}>
                                    <ItemKey>Latest Quarter OPM</ItemKey>
                                </Grid>
                                <Grid item xs={3} md={4}>
                                    <ItemValue>{props.score.lq_opm?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("fy_nsv") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={9}>
                                    <ItemKey>Full Year Net Sales Variance</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <ItemValue>{props.score.fy_nsv?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("lq_nsv") === true ? 'flex' : 'none'}>
                                <Grid item xs={9} md={9}>
                                    <ItemKey>Latest Quarter Net Sales Variance</ItemKey>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <ItemValue>{props.score.lq_nsv?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("pv_to_bv") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={9}>
                                    <ItemKey>Price Value To Book Value</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <ItemValue>{props.score.pv_to_bv?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("ttm_dy") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>TTM Dividend Yield</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.ttm_dy?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("ttm_roe") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>TTM ROE</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.ttm_roe?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("ttm_ind_pe") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>TTM Industry PE</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.ttm_ind_pe?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={0} display={isFieldEnabled("out_standing_shares") === true ? 'flex' : 'none'}>
                                <Grid item xs={8} md={6}>
                                    <ItemKey>Out Standing Shares (units)</ItemKey>
                                </Grid>
                                <Grid item xs={4} md={6}>
                                    <ItemValue>{props.score.out_standing_shares?.toFixed(2)}</ItemValue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </>;
    }
    else {
        return (<React.Fragment />);
    }
}

export default ListedScoreCard;