import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme: any) => ({
    root: {
        padding: '0px 0px',
        border: '1ps solid #EBEBEB'
    },
    header: {
        marginTop: '30px',
        marginBottom: '10px'
    },
    headerTypography: {
        fontWeight: '700', fontSize: '40px', fontStyle: 'normal',
        lineHeight: "20px",
        [theme.breakpoints.down('md')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '45px',
            paddingRight: '45px',
        },
        color: '#12626C'
    },
    card: {
        marginTop: '24px',
        paddingBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        backgroundColor: '#F9F9F9',
        border: '1px solid #EBEBEB', borderRadius: '8px',
    },
    cardHeader: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginRight: '20px',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '45px',
            marginRight: '45px',
        }
    },
    cardHeaderTitle: {
        height: '30px',
        lineHeight: '30px',
        fontFamily: 'Oxygen',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        color: '#12626C',
    },
    cardSubHeaderTitle: {
        display: 'block',
        fontWeight: '400', fontSize: '10px', fontStyle: 'normal',
        lineHeight: "13px",
        color: '#686868'
    },
    action: {
        margin: 0
    },
    toggleBtnGroup: {
        height: "30px", maxWidth: "250px", marginRight: 2, '& button': { fontFamily: 'Oxygen' }
    },
    cardBody: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginRight: '20px',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '45px',
            marginRight: '45px',
        },
        backgroundColor: '#F9F9F9'
    },
    cardBodyText: {
        lineHeight: '18px',
        fontFamily: 'Oxygen',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#686868',
    }
}));

export default useStyle;