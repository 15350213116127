import { Avatar, Box } from "@mui/material";
import { makeStyles } from '@mui/styles';
import RichTextField from "../Post/RichTextField";

export function CommentEditor(props: IProps) {

    let content = props.content;
    try {
        JSON.parse(content);
    } catch (e) {
        content = `{\"blocks\":[{\"key\":\"47bap\",\"text\":\"${content}\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}`;
    }

    return < Box sx={{ width: "100%" }
    } display={"flex"} flexDirection={"row"} columnGap={"10px"} >
        <Avatar
            alt="User"
            src={props.userImg}
            sx={{ width: "40px", height: "40px" }}
        />
        <Box sx={{
            width: "calc(100% - 50px)",
            "& .public-DraftEditor-content": {
                minHeight: "30px"
            }
        }}>
            <RichTextField
                defaultValue={content}
                placeholder={"Add a comment"}
                onBlur={(e) => {
                    props.onBlur(e);
                }}
            />
        </Box>
    </Box >
}

interface IProps {
    content: string,
    userImg: string,
    onBlur: (val: string) => void
}