import React from 'react';
import { useLocation } from 'react-router-dom';
import { PathExtensions } from '../../utility/ImpressionPathExtensions';
import { addEntry, updateElapsedTime } from '../../services/UserImpressionService';

const ImpressionTracker = ({ children }) => {
    const location = useLocation();
    const [pageRefId, setPageRefId] = React.useState<string>('');
    const [pageRefTrackId, setPageRefTrackId] = React.useState<string>('');
    const [pageRefStartTime, setPageRefStartTime] = React.useState<number>(Date.now());

    const TrackablePages = [
        'company',
        'unlistedcompany',
        'viewuser',
        'seller'
    ];

    function hasPageChanged(prevPageRefId: string, currPageRefId: string): boolean {
        return !(prevPageRefId === currPageRefId);
    }

    async function saveAndResetTracker(pageRefTrackId: string, pageRefStartTime: number) {
        try {
            if (pageRefTrackId?.length > 0 && pageRefStartTime > 0) {
                await updateElapsedTime(pageRefTrackId, Math.floor((Date.now() - pageRefStartTime) / 1000));
                setPageRefId(() => '');
                setPageRefTrackId(() => '');
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    function needTracking(currPageType: string, currPageId: string, location: any): boolean {

        return ((currPageType?.length > 0 && currPageId?.length > 0) && TrackablePages.includes(currPageType)
            && ((currPageType === 'company' && location.pathname?.toLowerCase().includes('companyname'))
                || (currPageType === 'unlistedcompany')
                || currPageType === 'viewuser'
                || currPageType === 'seller'));
    }

    function getTagsIfAny(pageType: string, location: any): string {
        if (pageType === 'seller') {
            return pageType;
        }
        else if (location.hash?.length > 0) {
            return location.hash?.replace('#', '')
        }
        else {
            return '';
        }
    }

    React.useEffect(() => {
        async function track() {
            let pageType = PathExtensions.getBasePathName(location);
            let pageId = PathExtensions.getRecordId(location) || '';
            let currPageRefId = `${pageType}/${pageId}`;

            if (hasPageChanged(pageRefId, currPageRefId)) {
                if (needTracking(pageType, pageId, location)) {
                    setPageRefId(() => currPageRefId);
                    if (pageRefTrackId?.length > 0) {
                        // update prev entry
                        updateElapsedTime(pageRefTrackId, Math.floor((Date.now() - pageRefStartTime) / 1000));
                    }
                    // create new entry
                    addEntry(PathExtensions.getRefType(pageType), pageId, 'View', getTagsIfAny(pageType, location))
                        .then((data) => {
                            // update pageRefId, pageRefTrackId
                            setPageRefTrackId(() => data);
                            setPageRefStartTime(() => Date.now());
                        });
                }
                else if (pageRefTrackId?.length > 0) {
                    saveAndResetTracker(pageRefTrackId, pageRefStartTime);
                }
            }
        }

        try {
            track();
        }
        catch (e) {
            console.error(e);
        }

    }, [location]);

    return <>{children}</>;
}

export default ImpressionTracker;