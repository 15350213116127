import * as React from 'react';
import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { getUnlistedCompanyVideoForPage } from '../../services/CompanyService';

const CompanyVideo = (companyShort) => {
  const refId = companyShort.companyShort.CIN;

  const [userData, setUserData] = React.useState<any[]>([]);
  const [Page, setPage] = React.useState(1);
  const [description, setDescription] = React.useState<any>('');
  const [url, setUrl] = React.useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedVideoIndex, setSelectedVideoIndex] =
    React.useState<number>(-1);

  React.useEffect(() => {
    getUnlistedCompanyVideoData();
  }, [refId]);

  function getUnlistedCompanyVideoData() {
    setLoading(true);
    getUnlistedCompanyVideoForPage(refId)
      .then((data) => {
        let records: any[] = [];
        if (data?.length > 0) {
          for (let i = 0; i < data.length; i++) {
            records.push(data);
          }
        }
        setUserData(data.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }

  const handleVideoPlayClick = (x) => {
    setLoading(true);
    setDescription(x.description);
    setUrl(x.link_url);
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: { md: '300px', xs: '150px' },
          }}
        >
          <CircularProgress color="primary" size={60} />
        </Box>
      ) : userData && userData.length > 0 ? (
        <React.Fragment>
          <Card
            variant="outlined"
            sx={{
              paddingY: '16px',
              paddingX: '16px',
              border: '1px solid #EBEBEB',
              background: '#F9F9F9',
              borderRadius: '12px',
              minHeight: { md: '100%', xs: '100%' },
              height: { md: '100%', xs: '100%' },
              width: '95%',
              minWidth: '85%',
              maxWidth: '95%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Grid container spacing={2}>
              <>
                <Grid item xs={8}>
                  <Card
                    sx={{
                      borderRadius: '15px',
                      height: { md: '300px', xs: '150px' },
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: '100%',
                        height: '100%',
                      }}
                      component="iframe"
                      src={
                        userData && userData.length > 0 && url && url.length > 0
                          ? url
                          : userData[0].link_url
                      }
                    />
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      //   width: { md: '150px' },
                      width: 'auto',
                      borderRadius: '15px',
                      height: { md: '300px', xs: '150px' },
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        height: { md: '100%', xs: '100%' },
                        overflow: 'auto',
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color: '#525050',
                            fontWeight: '700',
                            fontFamily: 'Oxygen',
                            fontStyle: 'bold',
                            fontSize: { md: '16px', xs: '12px' },
                            lineHeight: '14px',
                            margin: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          Description
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: '#747474',
                            fontWeight: '400',
                            fontFamily: 'Oxygen',
                            fontStyle: 'bold',
                            fontSize: { md: '12px', xs: '10px' },
                            lineHeight: '16px',
                            margin: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            height: { md: '100%', xs: '100%' },
                          }}
                        >
                          {userData &&
                          userData.length > 0 &&
                          description &&
                          description.length > 0
                            ? description
                            : userData[0].description}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </>
              <div style={{ marginTop: '20px' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={4}
                  direction="row"
                  justifyContent="center"
                >
                  {userData &&
                    userData.length > 1 &&
                    userData.map((elem, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={4}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Card
                            sx={{
                              borderRadius: '15px',
                              height: { md: '120px', xs: '100px' },
                              border:
                                selectedVideoIndex === index
                                  ? '2px solid #FF5733'
                                  : 'none',
                            }}
                            onClick={() => {
                              handleVideoPlayClick(elem);
                              setSelectedVideoIndex(index);
                            }}
                          >
                            <CardMedia
                              sx={{
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none',
                              }}
                              component="iframe"
                              src={elem?.link_url}
                            />
                          </Card>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </Grid>
          </Card>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Card
            variant="outlined"
            sx={{
              //   width: { md: '150px' },
              width: 'auto',
              borderRadius: '15px',
              height: { md: '300px', xs: '150px' },
              overflow: 'hidden',
              border: '1px solid #EBEBEB',
              background: '#F9F9F9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: '#525050',
                fontWeight: '700',
                fontFamily: 'Oxygen',
                fontStyle: 'bold',
                fontSize: { md: '24px', xs: '12px' },
                lineHeight: '14px',
                margin: '10px',
                textAlign: 'center',
              }}
            >
              Videos unavailable for this company
            </Typography>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CompanyVideo;
// image="https://www.youtube.com/embed/fz6DTj8v9IA"
