import { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { userService } from "../appConfig/AxiosConfig";
import { User } from "../model/User";
import { userListData } from "../model/UserListData";
import { store } from "../redux/store";
import { User as AuthUser } from "firebase/auth";
import { refreshRewardInfo } from "./UserService";
import { Utility } from "../utility/Utility";

const getCurrentUser = async (): Promise<userListData> => {
    let response: AxiosResponse<userListData> = await userService.get<any, AxiosResponse<userListData>>("/user/current");
    return response.data;
}

const getFullCurrentUser = async (): Promise<User> => {
    let response: AxiosResponse<User> = await userService.get<any, AxiosResponse<User>>("/user/current");
    return response.data;
}

const getCurrentLoggedInUser = async (user: AuthUser): Promise<User> => {
    let response: AxiosResponse<User> = await userService.get<any, AxiosResponse<User>>("/user/current");
    refreshRewardInfo()
    return response.data;
}

const getUserView = async (userid: string | null): Promise<User> => {
    let response: AxiosResponse<User> = await userService.get<any, AxiosResponse<User>>(`/users/${userid}`);
    return response.data;
}

const createCookiesForUser = async (idToken) => {
    const headers = {Authorization:"Bearer "+idToken}
    let response: any = await userService.get<any, any>(`/authenticate`, {headers: headers});
    console.log(response);
    return response.data;
}

export { getCurrentUser, getFullCurrentUser, getUserView, getCurrentLoggedInUser, createCookiesForUser }