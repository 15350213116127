// Copyright 2023 vamsikrishnabakkamunthala
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = React.useState(9);

  React.useEffect(() => {
    // timer to update the countdown every second
    const timerId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // timer to redirect after 5 seconds
    const redirectTimerId = setTimeout(() => {
      // Redirect to a specific route
      navigate(-1);
    }, 9000);

    // Clear the timers if the component unmounts or if the user navigates away
    return () => {
      clearInterval(timerId);
      clearTimeout(redirectTimerId);
    };
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 5,
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '33.31px',
            fontWeight: 700,
          }}
          color="#FB645C"
        >
          Payment Failed
        </Typography>
        <Typography
          sx={{
            fontSize: '25.38px',
            fontWeight: 400,
          }}
          color="#688686"
        >
          Please{' '}
          <Link color="#FB645C" onClick={() => navigate(-1)}>
            Retry
          </Link>{' '}
          the payment
        </Typography>
      </Box>
      <Box>
        <img src="failure.svg" />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 400,
          }}
          color="#0F9764"
        >
          Redirecting in {countdown} seconds...
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
