import { Box, Card, CardContent, CardHeader, useMediaQuery, ToggleButtonGroup, ToggleButton, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect } from 'react';
import { DateExtensions } from "../../utility/DateExtensions";
import useStyle from "./Styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function GetChargesCard(props: { charges: any, type: number, loading: boolean, updated_on?: string }) {
    const ColumnsDisplayName = {
        'charge_id': 'Charge Id',
        'holder_name': 'Charge Holder',
        'amount': 'Amount',
        'creation_date': 'Creation Date',
        'satisfaction_date': 'Satisfaction Date',
        'modification_date': 'Modification Date'
    }

    const masterClasses = useStyle();

    const [rows, setRows] = React.useState<any>([]);

    const [columns, setColumns] = React.useState<any>([]);

    const [showContent, setShowContent] = React.useState<boolean>(false);

    const [updatedOn, setUpdatedOn] = React.useState<string>('');

    const [alignment, setAlignment] = React.useState<'open' | 'close'>('open');

    const isSM = useMediaQuery("(min-width:600px)");

    const [link, setLink] = React.useState(false);

    useEffect(() => {
        if (props.updated_on && props.updated_on.length > 0) {
            let dateString = DateExtensions.getDateStringUppercaseMonthFromString(props.updated_on, ' ', '');
            if (dateString?.trim()?.length > 0) {
                setUpdatedOn(`Updated On: ${dateString}`);
            }
        }
    }, [props.updated_on]);

    const showDiv = (): boolean => {
        if ((!props.loading) && (props.type === 9) && props.charges
            && (
                (props.charges?.open_charges && Array.isArray(props.charges.open_charges) && props.charges.open_charges.length > 0) ||
                (props.charges?.closed_charges && Array.isArray(props.charges.closed_charges) && props.charges.closed_charges.length > 0)
            )) {
            return true;
        }
        else {
            return false;
        }
    }

    const processCharges = (newAlignment: 'open' | 'close') => {
        if (newAlignment === 'open') {
            // Process Columns
            var columnOptions: any[] = [];
            columnOptions.push({
                field: 'charge_id',
                headerName: ColumnsDisplayName['charge_id'],
                width: 100
            });
            columnOptions.push({ field: 'holder_name', headerName: ColumnsDisplayName['holder_name'], flex: 1, minWidth: 250 });
            columnOptions.push({ field: 'amount', headerName: ColumnsDisplayName['amount'], width: 150 });
            columnOptions.push({ field: 'creation_date', headerName: ColumnsDisplayName['creation_date'], width: 120 });
            columnOptions.push({ field: 'modification_date', headerName: ColumnsDisplayName['modification_date'], width: 120 });

            setColumns(columnOptions);

            // Process Rows
            var rows: any[] = [];
            try {
                props.charges?.open_charges?.forEach((row) => {
                    if (row) {
                        var responseObject = {};

                        responseObject["charge_id"] = row.id;
                        responseObject["holder_name"] = row.holder_name;
                        responseObject["amount"] = row.amount;
                        responseObject["creation_date"] = DateExtensions.getDateStringUppercaseMonthFromString(row.creation_date, " ");
                        responseObject["modification_date"] = DateExtensions.getDateStringUppercaseMonthFromString(row.modification_date, " ");
                        rows.push(responseObject);
                    }

                    if (rows?.length > 0) {
                        setRows(rows);
                    }
                    else {
                        setRows([]);
                    }
                });
            }
            catch (error) {
                console.error(error);
                setRows([]);
            }
        }
        else if (newAlignment === 'close') {
            // Process Columns
            var columnOptions: any[] = [];
            columnOptions.push({
                field: 'charge_id',
                headerName: ColumnsDisplayName['charge_id'],
                width: 100
            });
            columnOptions.push({ field: 'holder_name', headerName: ColumnsDisplayName['holder_name'], flex: 1, minWidth: 250 });
            columnOptions.push({ field: 'amount', headerName: ColumnsDisplayName['amount'], width: 150 });
            columnOptions.push({ field: 'creation_date', headerName: ColumnsDisplayName['creation_date'], width: 120 });
            columnOptions.push({ field: 'satisfaction_date', headerName: ColumnsDisplayName['satisfaction_date'], width: 120 });

            setColumns(columnOptions);

            // Process Rows
            var rows: any[] = [];
            try {
                props.charges?.closed_charges?.forEach((row) => {
                    if (row) {
                        var responseObject = {};

                        responseObject["charge_id"] = row.id;
                        responseObject["holder_name"] = row.holder_name;
                        responseObject["amount"] = row.amount;
                        responseObject["creation_date"] = DateExtensions.getDateStringUppercaseMonthFromString(row.creation_date, " ");
                        responseObject["satisfaction_date"] = DateExtensions.getDateStringUppercaseMonthFromString(row.satisfaction_date, " ");
                        rows.push(responseObject);
                    }

                    if (rows?.length > 0) {
                        setRows(rows);
                    }
                    else {
                        setRows([]);
                    }
                });
            }
            catch (error) {
                console.error(error);
                setRows([]);
            }
        }
        else {
            // Process Columns
            var columnOptions: any[] = [];
            columnOptions.push({
                field: 'charge_id',
                headerName: ColumnsDisplayName['charge_id'],
                width: 100
            });
            columnOptions.push({ field: 'holder_name', headerName: ColumnsDisplayName['holder_name'], flex: 1, minWidth: 250 });
            columnOptions.push({ field: 'amount', headerName: ColumnsDisplayName['amount'], width: 150 });
            columnOptions.push({ field: 'creation_date', headerName: ColumnsDisplayName['creation_date'], width: 120 });
            columnOptions.push({ field: 'satisfaction_date', headerName: ColumnsDisplayName['satisfaction_date'], width: 50 });

            setColumns(columnOptions);

            setRows([]);
        }
    }

    useEffect(() => {
        if (props.loading === false && showDiv()) {
            processCharges(alignment);
            setShowContent(showDiv());
        }
    }, [props.charges, props.loading, alignment]);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'open' | 'close',
    ) => {
        if (!(alignment === newAlignment) && (newAlignment === 'open' || newAlignment === 'close')) {
            setAlignment(newAlignment);
            processCharges(newAlignment);
        }
    };

    try {
        return (
            <React.Fragment>
                {showContent &&
                    <Box className={masterClasses.root} sx={{ marginTop: '24px' }} id='tabdetail-charges'>
                        <Card elevation={0} sx={{ paddingY: '24px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '8px' }}>
                            <CardHeader
                                classes={{ action: masterClasses.action, title: masterClasses.cardHeaderTitle, subheader: masterClasses.cardSubHeaderTitle }}
                                sx={{ marginX: { sm: "24px", xs: "24px" }, textAlign: 'left', paddingLeft: '0px', paddingRight: '8px', paddingTop: '0px', paddingBottom: '32px' }}
                                title={<Typography
                                    sx={{ fontSize: { xs: '16px', md: '24px' } }}
                                    // className={masterClasses.cardHeaderTitle}
                                >Charges</Typography>}
                                subheader={updatedOn}
                                // action={
                                //     <DownloadButton variant="contained" color="primary" sx={{ height: '39px', width: '128px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none' }} />
                                // }
                                action={
                                    <>
                                    <ToggleButtonGroup
                                        value={alignment}
                                        exclusive
                                        size='small'
                                        onChange={handleChange}
                                        className={masterClasses.toggleBtnGroup}
                                    // sx={{ height: "39px", maxWidth: "250px", marginRight: 2, '& button': { fontFamily: 'Oxygen' } }}
                                    >
                                        {(props.charges?.open_charges && Array.isArray(props.charges.open_charges) && props.charges.open_charges.length > 0) &&
                                            <ToggleButton value="open"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: alignment === 'open' ? '#FFFFFF !important' : '#0F9764 !important',
                                                    backgroundColor: alignment === 'open' ? '#0F9764 !important' : '#FFFFFF !important',
                                                    paddingLeft: "15px",
                                                    paddingRight: "10px",
                                                    textTransform: "none",
                                                    fontSize: {xs:'10px', md:'14px'}
                                                }} >Open</ToggleButton>}
                                        {(props.charges?.closed_charges && Array.isArray(props.charges.closed_charges) && props.charges.closed_charges.length > 0) &&
                                            <ToggleButton value="close"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: alignment === 'close' ? '#FFFFFF !important' : '#0F9764 !important',
                                                    backgroundColor: alignment === 'close' ? '#0F9764 !important' : '#FFFFFF !important',
                                                    paddingLeft: "10px",
                                                    paddingRight: "15px",
                                                    textTransform: "none",
                                                    fontSize: {xs:'10px', md:'14px'}
                                                }} >Close</ToggleButton>}
                                    </ToggleButtonGroup>
                                    <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                                    <IconButton
                                        onClick={() => {
                                            window.location.hash = 'tabdetail-charges'
                                            navigator.clipboard.writeText(window.location.href)
                                            setLink(true)
                                        }}
                                    >
                                        <ContentCopyIcon
                                            sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                                    </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            >
                            </CardHeader>
                            <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }} className={masterClasses.cardBodyText}>
                                <Box
                                    sx={{
                                        flexGrow: 1, bgcolor: '#F9F9F9', display: 'flex', paddingY: '0px', paddingX: { xs: '5px', sm: '5px', md: '24px' },
                                        '& .MuiDataGrid-pinnedColumnHeaders': {
                                            backgroundColor: '#F9F9F9 !important'
                                        },
                                        '& .MuiDataGrid-pinnedColumns': {
                                            backgroundColor: '#F9F9F9 !important'
                                        }
                                    }}
                                >
                                    <DataGridPro
                                        initialState={isSM === true ? {} : { pinnedColumns: { left: ['charge_id'] } }}
                                        rows={rows}
                                        getRowId={(row) => `${row.id}_${row.holder_name}_${row.amount}_${row.creation_date}`}
                                        columns={columns}
                                        density={"compact"}
                                        autoHeight={true}
                                        pageSize={5}
                                        pagination={true}
                                        rowsPerPageOptions={[5]}
                                        columnVisibilityModel={{
                                            // Hide columns id, the other columns will remain visible
                                            id: false,
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </ React.Fragment>
        );
    }
    catch (error) {
        console.error(error);
        return (<React.Fragment></React.Fragment>);
    }
}

export default GetChargesCard;