import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import "./PostViewer.css";
import { Typography, Button } from "@mui/material";
import React from "react";
import { StringExtensions } from "../../utility/StringExtensions";

export function PostContentViewer(props: {
  rawContent: string;
  style?: React.CSSProperties;
  isMediaPresent?: boolean;
  renderContentAsHtml?: boolean;
}) {

  const style: React.CSSProperties = {
    fontFamily: "Oxygen",
    fontSize: "16px",
    fontWeight: "575",
    ...props.style,
  };

  if (props.renderContentAsHtml) {
    if (props.rawContent.length > 500) {
      return (<div className='news-block'><div className="short-news" dangerouslySetInnerHTML={{ __html: props.rawContent.substring(0, 400) + `... <a href='javascript:void(0)' style="color:#0F9764;" onclick="{this.closest('.news-block').querySelector('.short-news').style.display='none';this.closest('.news-block').querySelector('.full-news').style.display='block';}">Read more ▼</a>` }} />
        <div className="full-news" style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: props.rawContent + ` <a href='javascript:void(0)' style="color:#0F9764;" onclick="{this.closest('.news-block').querySelector('.full-news').style.display='none';this.closest('.news-block').querySelector('.short-news').style.display='block';}">Read less ▲</a>` }} /></div>)
    } else {
      return <div dangerouslySetInnerHTML={{ __html: props.rawContent }} />;
    }
  }


  try {
    let rawContentState = JSON.parse(props.rawContent);

    const hashtagConfig = {
      trigger: "#",
      separator: "",
    };
    let markup = draftToHtml(
      rawContentState,
      hashtagConfig,
      void 0,
      (entity, originalText) => {
        if (entity.type == "mention") {
          var mention = entity.data.mention;
          let link = "";
          if (mention.type == "User") {
            link = `/viewuser?userid=${mention.id}`;
          }
          else if (mention.type === "Listed") {
            // link = `/company/${mention.id}/companyname/${mention.name.replaceAll(" ", "-")}`;
            link = `/company/${mention?.name?.length > 0 ? StringExtensions.getSearchIdFromCompanyName(mention.name.replaceAll(" ", "-")) : 'companyname'}/${mention.id}`;
          }
          else if (mention.type === "UnlistedCompany" || mention.type === "Unlisted") {
            link = `/unlistedcompany?code=${mention.id}`;
          }
          else {
            link = '/notfound';
          }
          return `<a class='post-viewer-mention' href='${link}' >${originalText}</a>`;
        }
      }
    );

    const linkify = (inputText) => {
      var replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, (...match) => (match[match.length-2] > 1 && match[match.length-1][match[match.length-2] - 2] == "=") ? match[0] : `<a href="${match[0]}" target="_blank">${match[0]}</a>`);

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      // //Change email addresses to mailto:: links.
      // replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

      return replacedText;
    }

    markup = linkify(markup);

    return (
      <div style={style}>
        {(props.isMediaPresent || markup.length < 500) ? (<div
          style={style}
          dangerouslySetInnerHTML={{ __html: markup }}
        ></div>) : (
          (<div className='news-block'><div className="short-news" dangerouslySetInnerHTML={{ __html: markup.substring(0, 400).trim() + `<a href='javascript:void(0)' style="color:#0F9764;" onclick="{this.closest('.news-block').querySelector('.short-news').style.display='none';this.closest('.news-block').querySelector('.full-news').style.display='block';}">Read more ▼</a>` }} />
            <div className="full-news" style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: markup + ` <a href='javascript:void(0)' style="color:#0F9764;" onclick="{this.closest('.news-block').querySelector('.full-news').style.display='none';this.closest('.news-block').querySelector('.short-news').style.display='block';}">Read less ▲</a>` }} /></div>)
        )
        }
      </div>
    );
  } catch (e) {
    return <Typography sx={style}>{props.rawContent}</Typography>;
  }
}

