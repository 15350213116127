enum Actions {
    "SETAUTH" = "SET_AUTH",
    "CLEARAUTH" = "CLEAR_AUTH",
    "SETUSERDATA" = "SET_USER_DATA",
    "SETREWARDDATA" = "SET_REWARD_DATA",
    "CLEARUSERDATA" = "CLEAR_USER_DATA",
    "SETDEVICE" = "SET_DEVICE",
    "SETRTL" = "SET_RTL",
    "SETDARKMODE" = "SET_DARK_MODE",
    "SETPAGECONFIG" = "SET_PAGE_CONFIG",
    "SETLOADING" = "SET_LOADING",
    "RESETAPPSTATEWITHVERSION" = "RESET_APP_STATE_VERSION",
    "LOADORUPDATECOMPANIES" = "LOAD_UPDATE_COMPANIES",
    "SETNOTIFICATION" = "SET_NOTIFICATION",
    "UNSETNOTIFICATION" = "UNSET_NOTIFICATION",
    "SETWATCHLIST" = "SET_WATCHLIST",
    "DELETEWATCHLIST" = "DELETE_WATCHLIST",
    "ADDITEMTOWATCHLIST" = "ADD_ITEM_TO_WATCHLIST",
    "REMOVEITEMFROMWATCHLIST" = "REMOVE_ITEM_FROM_WATCHLIST",
    "SETSCDATA" = "SET_SC_DATA"
}

export { Actions };