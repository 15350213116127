import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

interface FAQItemProps {
  question: string;
  answer: string;
  panelId: string;
  expanded: string | false;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  panelId,
  expanded,
  handleChange,
}) => {
  return (
    <Accordion
      sx={{
        marginY: '16px',
        padding: '8px',
        background: '#F5F5F5',
        borderRadius: '16px !important',
        boxShadow: 'none',
        "&.MuiAccordion-root:before": {
          backgroundColor: "white"
        }
      }}
      expanded={expanded === panelId}
      onChange={handleChange(panelId)}
    >
      <AccordionSummary
        aria-controls={`${panelId}bh-content`}
        id={`${panelId}bh-header`}>
        {expanded === panelId ? (
          <CloseIcon
            fontSize="small"
            sx={{ alignSelf: 'center', marginRight: '16px' }}
          />
        ) : (
          <AddIcon
            fontSize="small"
            sx={{ alignSelf: 'center', marginRight: '16px' }}
          />
        )}
        <Typography
          sx={{
            width: {md:'100%', xs:'90%'},
            flexShrink: 0,
            fontSize: { md: '22px', xs: '16px' },
            fontWeight: '500',
            fontFamily: 'Oxygen',
          }}
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ marginLeft: '36px' }}>
          <Typography
            sx={{
              fontSize: { md: '16px', xs: '12px' },
              fontWeight: '400',
              fontFamily: 'Oxygen',
            }}
          >
            {answer}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
interface PlansFAQProps {
  faqs: { question: string; answer: string; panelId: string; }[];
}

const PlansFAQ: React.FC<PlansFAQProps> = ({ faqs }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '40px',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              color: '#000',
              fontSize: { md: '48px', xs: '32px' },
              fontWeight: '700',
              lineHeight: { md: '64px', xs: '48px' },
            }}
          >
            Frequently Asked{' '}
            <span style={{ color: '#0F9764' }}> Questions </span>
          </Typography>
        </Box>
        <Box sx={{ marginTop: '-30px', width: { md: '70%', xs:'100%'}}}>
          {faqs.map((faq) => (
            <FAQItem
              key={faq.panelId}
              {...faq}
              expanded={expanded}
              handleChange={handleChange}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PlansFAQ;
