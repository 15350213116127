import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CompanyUnlockLoadingPopup = ({ onClose, isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-handle-confirm"
      PaperProps={{
        sx: {
          width: {
            xs: '370px',
            sm: '370px',
            md: '499px',
            lg: '820px',
            xl: '820px',
          },
          maxWidth: '627px !important',
          backgroundImage: 'url("/static/companyUnlock/frame_bg_img.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'auto',
          }}
        >
          <Box>
            <img
              src="/static/companyUnlock/loading.gif"
              alt="investor"
              style={{
                width: '120px',
                height: '120px',
                padding: '-10px',
                margin: '-10px',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '10px',
              marginX: '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '16px', xs: '12px' },
                fontFamily: 'Oxygen',
                fontWeight: 400,
                color: '#000',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                animation: 'moveText 3s linear infinite',
                textAlign: 'center',
              }}
            >
              Please wait, fetching your data ...
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '10px',
              marginX: '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '16px', xs: '12px' },
                fontFamily: 'Oxygen',
                fontWeight: 400,
                color: '#000',
                textAlign: 'center',
              }}
            >
              Hang tight! We’re in the process of fetching the data you
              requested. This might take a little bit of time, but we assure you
              it’s worth the wait.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '10px',
              marginX: '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '16px', xs: '12px' },
                fontFamily: 'Oxygen',
                fontWeight: 400,
                color: '#000',
                textAlign: 'center',
              }}
            >
              Your request is being handled with the utmost care, and we’re
              making sure everything is just right.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '10px',
              marginX: '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '16px', xs: '12px' },
                fontFamily: 'Oxygen',
                fontWeight: 400,
                color: '#000',
                textAlign: 'center',
              }}
            >
              Appreciate your patience!
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyUnlockLoadingPopup;
