import initialAppConfig from "../appConfig/initialAppConfig";
import { AppState, PageState } from "../model/AppState";
import { AuthState } from "../model/AuthState";
import { UserState } from '../model/UserState';
import { DeviceState } from "../model/DeviceState";
import { IReduxAction } from "../model/ReduxAction"
import { Actions } from "./Actions";

function authReducer(state: AuthState = {
    user: null
}, action: IReduxAction): AuthState {
    switch (action.type) {
        case Actions.SETAUTH:
            return { ...state, user: action.payload };
        case Actions.CLEARAUTH:
            return { ...state, user: null };
        default:
            return { ...state };
    }
}

function userDataReducer(state: UserState = {
    lastUpdated: null,
    user: null,
    reward: null,
    watchLists: [],
    scData: null
}, action: IReduxAction): UserState {
    switch (action.type) {
        case Actions.SETUSERDATA:
            return { ...state, user: action.payload };
        case Actions.SETREWARDDATA:
            return { ...state, reward: action.payload };
        case Actions.CLEARUSERDATA:
            return { ...state, user: null, reward: null, watchLists: [], scData: null };
        case Actions.SETWATCHLIST:
            return { ...state, watchLists: action.payload };
        case Actions.DELETEWATCHLIST: {
            let watchlist = [...state.watchLists];
            watchlist.splice(watchlist.findIndex(x => x.id == action.payload.id), 1);
            return { ...state, watchLists: watchlist };
        }
        case Actions.ADDITEMTOWATCHLIST: {
            const watchlists = [...state.watchLists];
            const watchlist = watchlists.find(x => x.id == action.payload.watchlistId);
            const stocks = watchlist!.list;
            stocks.push(action.payload.company);
            watchlist!.list = [...stocks];
            watchlists.splice(watchlists.indexOf(watchlist!), 1, { ...watchlist! });
            return { ...state, watchLists: watchlists };
        }
        case Actions.REMOVEITEMFROMWATCHLIST: {
            const watchlists = [...state.watchLists];
            const watchlist = watchlists.find(x => x.id == action.payload.watchlistId);
            const stocks = watchlist!.list;
            stocks?.splice(stocks.indexOf(action.payload.company), 1);
            watchlist!.list = [...stocks];
            watchlists.splice(watchlists.indexOf(watchlist!), 1, { ...watchlist! });
            return { ...state, watchLists: watchlists };
        }
        case Actions.SETSCDATA:
            return { ...state, scData: action.payload };
        default:
            return { ...state };
    }
}

function deviceReducer(
    state: DeviceState = { deviceType: window.innerWidth > 900 ? "web" : "mobile" },
    action: IReduxAction): DeviceState {
    switch (action.type) {
        case Actions.SETDEVICE:
            return { ...state, deviceType: action.payload };
        default:
            return { ...state };
    }
}

function appReducer(
    state: AppState = initialAppConfig,
    action: IReduxAction): AppState {
    switch (action.type) {
        case Actions.SETRTL:
            return { ...state, rtlEnabled: action.payload };
        case Actions.SETDARKMODE:
            return { ...state, darkModeEnabled: action.payload };
        case Actions.SETPAGECONFIG:
            const page: PageState = action.payload;
            const currentPersistedPage = state.pages.find(u => u.name == page.name);
            if (currentPersistedPage) {
                currentPersistedPage.enableLeftMenuMinimize = page.enableLeftMenuMinimize;
                currentPersistedPage.isMinimized = page.isMinimized;
                currentPersistedPage.showAdvert = page.showAdvert;
                currentPersistedPage.showLeftMenu = page.showLeftMenu;
                currentPersistedPage.showSearchBar = page.showSearchBar;
            }
            return { ...state, pages: [...state.pages] };
        case Actions.SETLOADING:
            return { ...state, loading: action.payload };
        case Actions.RESETAPPSTATEWITHVERSION:
            return { ...initialAppConfig, appVersion: action.payload };
        case Actions.LOADORUPDATECOMPANIES:
            return { ...state, companiesData: action.payload };
        case Actions.SETNOTIFICATION:
            return { ...state, notifications: [...state.notifications, action.payload] };
        case Actions.UNSETNOTIFICATION:
            const notifications = state.notifications.filter(u => u.identifier !== action.payload);
            return { ...state, notifications: [...notifications] };
        default:
            return { ...state }
    }
}

export { authReducer, deviceReducer, appReducer, userDataReducer };