import { MediaInfo } from "../../model/MediaInfoModel"
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Avatar, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, ImageList, ImageListItem, ImageListItemBar, TextField, useMediaQuery } from '@mui/material';
import { CSSProperties } from "@emotion/serialize";
import DeleteIcon from '@mui/icons-material/Delete';

export declare type IPostMedia = MediaInfo & { file?: File, fileName?: string };

interface IMediaUploaderProps {
    files: IPostMedia[],
    onClose: (file?: IPostMedia[]) => void,
    isOpen: boolean,
    mediaType: 'image' | 'video',
    title: string,
    placeholder: string
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const ButtonStyle = {
    background: '#FBFAFA',
    color: '#0F9764',
    border: '1px solid',
    float: 'right'
}

const mediaFileNameStyle: React.CSSProperties = {
    fontFamily: 'Oxygen',
    fontSize: '12px',
    width: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center'
}


export function MediaSelector(props: IMediaUploaderProps) {

    const [mediaList, setMediaList] = React.useState<IPostMedia[]>(props.files);

    React.useEffect(() => setMediaList(props.files), [props.isOpen]);

    const getFileName = (mediafile: IPostMedia) => {
        if (mediafile.fileName)
            return mediafile.fileName
        else {
            const matched = mediafile.link.match(/[^_]*$/);
            return matched ? matched[0] : "";
        }
    }

    const onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            var selectedMedias: IPostMedia[] = [];
            for (var count = 0; count < e.currentTarget.files.length; count++) {
                var media = {} as IPostMedia;
                media.file = e.currentTarget.files[count];
                media.type = props.mediaType == "image" ? "image" : 'video';
                media.link = window.URL.createObjectURL(media.file);
                media.fileName = media.file.name;
                selectedMedias.push(media);
            }
            setMediaList([...mediaList, ...selectedMedias]);
        }
        e.currentTarget.value = '';
    }

    const renderMedia = (medias: IPostMedia[]) => {
        return <ImageList sx={{ width: '100%', height: '200px' }} cols={3} rowHeight={150} gap={5}>
            {medias.map((item, i) => (
                <ImageListItem key={i} sx={{ height: '100px', width: '80' }}>
                    <img
                        src={`${props.mediaType == 'image' ? item.link : 'https://cdn1.iconfinder.com/data/icons/ampola-final-by-ampeross/256/video_file.png'}`}
                        loading="lazy"
                        style={{ objectFit: 'cover', height: '100px' }}

                    />
                    <span style={mediaFileNameStyle}>{getFileName(item)}</span>
                    <ImageListItemBar
                        sx={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        }}
                        position="top"
                        actionIcon={
                            <IconButton
                                sx={{ color: 'white' }}
                                onClick={() => {
                                    mediaList.splice(mediaList.indexOf(item), 1);
                                    setMediaList([...mediaList]);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                        actionPosition="right"
                    />
                </ImageListItem>

            ))}
        </ImageList>
    }

    return <div>
        <BootstrapDialog
            onClose={() => {
                props.onClose();
            }}
            aria-labelledby={props.title}
            open={props.isOpen}
        >
            <DialogTitle sx={{ m: 0, p: 2, color: '#686868' }}>
                {`Select your ${props.mediaType}s`}
            </DialogTitle>
            <DialogContent dividers>
                <Grid sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: "14px",
                    justifyItems: 'center',
                    alignItems: 'center',
                    width: "100%",
                    height: "200px"
                }}>
                    {mediaList.length == 0 ? <Typography
                        sx={{ font: "Oxygen", width: "100%", textAlign: 'center', color: '#0F9764', fontSize: '15px', fontWeight: '700', textDecorationLine: 'none' }}
                        component="div">
                        {`Select your ${props.mediaType}s to share`}
                    </Typography> :
                        renderMedia(mediaList)
                    }
                </Grid>
                <Grid sx={{ marginTop: '28px', width: { xs: 'auto', sm: '550px' } }}>

                    <Button variant="contained" component="label">
                        Upload
                        <input hidden accept={`${props.mediaType}/*`} multiple type="file" onChange={(e) => onSelectFiles(e)} />
                    </Button>
                    <Button variant="contained" component="label" sx={ButtonStyle} onClick={() => { props.onClose(mediaList); }}>
                        Done
                    </Button>
                    <Button onClick={() => { props.onClose(); }} variant="contained" component="label" sx={{ ...ButtonStyle, marginRight: '23px', marginLeft: '20px' }}>
                        Cancel
                    </Button>
                </Grid>
            </DialogContent>
        </BootstrapDialog >
    </div >
}