import { Roles } from "./Constants"

export class RoleUtility {
    static isAdmin(roleString: string | null | undefined): boolean {
        return (roleString?.toLowerCase().split(',')?.some(x => x === Roles.Admin) === true)
    };
    static isPremium(roleString: string | null | undefined) {
        return (roleString?.toLowerCase().split(',')?.some(x => (x === Roles.Premium_User_1 || x === Roles.Company_User)) === true)
    };
    static isPremiumUser(roleString: string | null | undefined) {
        return (roleString?.toLowerCase().split(',')?.some(x => x === Roles.Premium_User_1) === true)
    };
    static isPremiumCompanyUser(roleString: string | null | undefined) {
        return (roleString?.toLowerCase().split(',')?.some(x => x === Roles.Company_User) === true)
    };
    static isPremiumCompanyUserOrPendingCompanyUser(roleString: string | null | undefined) {
        return ((this.isPremiumCompanyUser(roleString) || this.isPremiumCompanyUserPaymentPending(roleString)) === true)
    };
    static isPremiumCompanyUserPaymentPending(roleString: string | null | undefined) {
        return (roleString?.toLowerCase().split(',')?.some(x => x === Roles.Company_User_Request) === true)
    };
}