import { Box, Link, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ContactUs = () => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            marginLeft: { md: 7, xs: 1 },
            marginRight: { md: 6, xs: 4 }
        }}>
            <Box>
                <Typography fontWeight={700} fontSize={"24px"} color="#12626C">Contact us</Typography>
            </Box>
            <Box sx={{
                width: "96%",
                padding: { md: "2%", xs: "5%" },
                height: { md: "262px", xs: "auto" },
                background: "#F9F9F9",
                border: "1px solid #EBEBEB",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                gap: 4
            }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <PlaceIcon fontSize="medium" sx={{
                        color: "#0F9764",
                    }} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        justifyContent: "center",
                        width: { md: "20%", xs: "50%" }
                    }}>
                        <Link sx={{
                            textDecoration: "none",
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#686868"
                        }} href="https://goo.gl/maps/5KaRo8AYbA26feee9">https://goo.gl/maps/5KaRo8AYbA26feee9</Link>
                        <Typography sx={{
                            marginLeft: 3,
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#686868"
                        }}>Tower, 6th floor 601, Nanda,
                            90, Phears Ln, Kolkata,
                            West Bengal 700012</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <PhoneIcon fontSize="medium" sx={{
                        color: "#0F9764",
                    }} />
                    <Box >
                        <Typography sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#686868"
                        }}>09007100258</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <MailOutlineIcon fontSize="medium" sx={{
                        color: "#0F9764",
                    }} />
                    <Box >
                        <Link sx={{
                            textDecoration: "none",
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#686868"
                        }} href="mailto:support@stockknocks.com">support@stockknocks.com</Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ContactUs
