import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ISellerBanner } from "./Models/SellerBanner";
import { getSellerBanner } from "../../services/MarketPlaceService";
import { Carousel } from "react-responsive-carousel";

export function SellerBanner() {
    const params = useParams();
    const { sellerCode } = params;

    const [bannerData, setBannerData] = useState<ISellerBanner>();

    useEffect(() => {
        getSellerBanner(sellerCode!).then(x => {
            // x && x.media!.push({ type: "video/mp4", link: "https://storage.googleapis.com/stockknocks-prod.appspot.com/posts-files/c7d08d05-e39d-4600-89c0-f81d3194ed7c_Untitledvideo-MadewithClipchamp.mp4" } as any);
            setBannerData(x)
        });
    }, [sellerCode]);

    return <><Box sx={{ height: { md: "373px", xs: "200px" } }}>
        {
            bannerData && bannerData.media && bannerData.media.length > 0 && <Carousel autoPlay={false} infiniteLoop={true} showStatus={true} showThumbs={false} >
                {bannerData!.media!.map(x => <Box sx={{ height: { md: "373px", xs: "200px" } }}>{!!x.type.match("video") ? (<video height="100%" src={x.link} controls></video>) : <img style={{ borderRadius: "10px", height: "100%" }} src={x.link} />}</Box>)}
            </Carousel>
        }
    </Box>
        <Typography sx={{ fontWeight: 700, color: "#667479", marginTop: "15px" }} component="h3">{bannerData?.description}</Typography>
    </>
    // return <Box sx={{ marginBottom: "30px", height: { md: "373px", xs: "200px" }, backgroundImage: `url('${bannerData.image}')`, backgroundSize: "cover", borderRadius: "10px" }}>
    //     <Box sx={{
    //         padding: { md: "63px 83px 0px 0px", xs: "10px 10px 0px 0px" },
    //         "& span": {
    //             textTransform: "capitalize",
    //             textAlign: "right",
    //             display: "block"
    //         }
    //     }}>
    //         <Typography sx={{
    //             color: (bannerData.heading1Color || "#FFFFFF"),
    //             fontWeight: 800,
    //             fontSize: { md: "50px", xs: "35px" }
    //         }}>{bannerData.heading1}</Typography>
    //         <Typography sx={{
    //             color: (bannerData.heading2Color || "#FFFFFF"),
    //             fontWeight: 700,
    //             fontSize: { md: "35px", xs: "25px" }
    //         }}>{bannerData.heading2}</Typography>
    //         <Typography sx={{
    //             color: (bannerData.heading3Color || "#CCD1D2"),
    //             fontWeight: 400,
    //             fontSize: "12px",
    //             float: "right",
    //             width: { md: "385px", xs: "100%" }
    //         }}
    //         >{bannerData.heading3}</Typography>
    //     </Box>
    // </Box>
}