import * as React from 'react';
import { Card, CardMedia } from '@mui/material';

const Website = (props: { websiteURL: string | null }) => {
  return (
    <>
      {(props.websiteURL && props.websiteURL?.length > 0) ? (
        <CardMedia
          component="iframe"
          src={props.websiteURL}
          sx={{
            width: '100%',
            height: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      ) : (
        <Card sx={{ width: 680, height: 500, textAlign: 'center' }}></Card>
      )}
    </>
  );
};

export default Website;
