// import { getFunctions, httpsCallable } from "firebase/functions";
// import functions from "./functionconfig";
import axios, { AxiosResponse } from "axios";
import {
    getAuth,
    User,
    updateProfile,
    updateCurrentUser,
    onAuthStateChanged
} from "firebase/auth";
import { UserImpl } from '@firebase/auth/internal';
import { Actions } from "../redux/Actions";
import { store } from "../redux/store";
import { cloudFunctionService, userImpressionService, userService } from "../appConfig/AxiosConfig";
import { Utility } from "../utility/Utility";
import { createCookiesForUser } from "./loginUserServices";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

function getCookie(name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

const createFirstTimeUserInMongo = async (user: User) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    const token = await user.getIdToken();
    const response = await userService.post('/user', {}, {headers:{"Authorization":`Bearer ${token}`}});
    refreshRewardInfo();
    return response;
}

const refreshRewardInfo = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    const rewardResponse = await userService.get('/user/reward')
    store.dispatch({ type: Actions.SETREWARDDATA, payload: rewardResponse.data.reward });
}

const getCreditHistory = async (page: number, pagesize: number): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await userService.get<any,AxiosResponse<any>>(`/user/credits/history?page=${page}&pagesize=${pagesize}&count=true`);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Credit details read failed.");
    }
}

const searchUsers = (term: String) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    return userService.get(`/search/users?term=${term}`)
}

const searchUsersByEmailOrPhone = (term: String) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    return userService.get(`/search/users/${term}`)
}

const getScoreData = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    return await userService.get('/pagesettings');
}
const postScoreData = async (data: any) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    return await userService.post('/pagesettings', data);
}

const fetchuser = async (docId: string) => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let timeout = setTimeout(() => {
        source.cancel();
    }, 5000);

    cloudFunctionService.get('/getuser', {
        params: {
            docId: docId
        },
        cancelToken: source.token
    })
        .then(function (response) {
            console.log(response.data);
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {
            clearTimeout(timeout);
            // always executed
        });
}

function getSignedInUser(onSuccess: (user: User | null) => void, onFailure: () => void) {
    //return getAuth().currentUser;

    const auth = getAuth();
    if (auth.currentUser == null) {
        const state = store.getState();
        if (state.auth.user != null) {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                onSuccess && onSuccess(user);
            });
            const user = state.auth.user;
            const currentUser = UserImpl._fromJSON(auth as any, user as any);
            unsubscribe();
            updateCurrentUser(auth, currentUser);
        }
        else {
            onFailure && onFailure();
        }
    }
    else {
        onSuccess && onSuccess(auth.currentUser);
    }
}

function updateCurrentUserProfile(user: User) {
    updateCurrentUser(
        getAuth(),
        user).then(() => {
            // Update successful
            // ...
        }).catch((error: any) => {
            console.log(error);
            // An error occurred
            // ...
        });
}

const getPreviewUser = async (userId: string) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    const user = await userService.get(`/users/minimal/${userId}`)
    return user.data
}

const logoutUser = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    await userService.get(`/logout`)
    return
}

function updateUserProfile(displayName: string | null, photoURL: string | null) {
    const update = {
        displayName: displayName,
        photoURL: photoURL,
    };
    console.log("New profile Data: ", update);
    const auth = getAuth();
    if (auth.currentUser == null) {
        const state = store.getState();
        if (state.auth.user != null) {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                store.dispatch({ type: Actions.SETAUTH, payload: user });
                unsubscribe();
                updateProfile(auth.currentUser as any, update);
            });
            const user = state.auth.user;
            const currentUser = UserImpl._fromJSON(auth as any, user as any);
            updateCurrentUser(auth, currentUser);
        }
        else {
            updateProfile(auth.currentUser as any, update);
        }
    }
    else {
        updateProfile(auth.currentUser as any, update);
    }
}

const getUserWebsiteUrl = async (userid: any): Promise<any> => {
    let response: AxiosResponse<any> = await userImpressionService.get<any, AxiosResponse<any>>(`/webpageconfig/website?userid=${userid}`);
    return response.data;
}

const postUnlockFreeTrial = async (code: any) => {
    try {
        let data = {
            "code": code,
        }
        let response: AxiosResponse<any> = await userService.post<any, AxiosResponse<any>>(`/user/credits/trial`, data);
        await refreshRewardInfo();
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Unlock Free Trial failed.");
    }
}

export {
    getPreviewUser, createFirstTimeUserInMongo, searchUsers,
    searchUsersByEmailOrPhone, fetchuser, getSignedInUser,
    updateCurrentUserProfile, updateUserProfile, getAuth,
    onAuthStateChanged, refreshRewardInfo,getCreditHistory, getScoreData, postScoreData, getUserWebsiteUrl, logoutUser, postUnlockFreeTrial
}