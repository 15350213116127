import React, { useEffect, useState } from 'react';
import Story from "./Story";
import { Grid, Card, Typography, InputAdornment, OutlinedInput, CardActions, IconButton, Avatar, Button, CircularProgress } from '@mui/material';
import { getTimeLine, deleteFeed, getFeedById } from '../../services/SocialMediaService';
import { getCommentsAPI, createCommentAPI, deleteCommentAPI, updateCommentAPI } from '../../services/SocialMediaComment';
import { postLikeAPI } from '../../services/SocialMediaLike';
import { FeedResult, PostData } from '../../model/FeedDataModel';
import { LikeRequest } from '../../model/LikeModel';
import { CommentRequest, CommentUpdateRequest } from '../../model/CommentDataModel';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import { useSearchParams } from 'react-router-dom';
import PostEditor from '../../components/Post/PostEditor';
import { MobileAdvert } from '../Layout/MobileAdvert';

const newsFeedCreateStoryCardAction = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px',
    height: '40%',
    marginTop: "14px",

};
const newsFeedCreateStoryIconButtons = {
    color: '#686868',
    fontFamily: 'Oxygen',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '0px',
    display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' },
};

interface INewFeedProps {
    userId?: string, // in case of userid present then show only user posts otherwise newsfeed will be listed
    feedApi?: (userid: string, page: number, pagesize: number) => Promise<FeedResult> // feed api wether its a new feed or user post
}

function NewsFeed(props: INewFeedProps) {

    const user = useSelector((state: ReduxStore) => state.auth.user)
    const pagesize = 10;
    const [page, setPage] = useState(0);
    const [commentPage, setCommentPage] = useState(0);
    const [isMoreData, setIsMoreData] = useState(true);
    const [likePage, seLikePage] = useState(0);
    const [postData, setPostData] = useState<PostData[]>([]);
    const [selectedFeedComment, setSelectedFeedComment] = useState<PostData | null>(null);
    const [editImageURL, setEditImageURL] = useState<ArrayBuffer | string>();
    const [selectedFeedLikeId, setSelectedFeedLikeId] = useState('');
    const [commentData, setCommentData] = useState([] as any);
    const [showDescription, setShowDescription] = useState(false);
    const [likeData, setLikeData] = useState([] as any);
    const userId = user?.uid || '';
    const [searchParams, setSearchParams] = useSearchParams();
    const code: string | null = searchParams.get('feedkey');


    const [anchorEl, setAnchorEl] = React.useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isCodeUsed, setIsCodeUsed] = useState(false);

    const currentUser = useSelector((state: ReduxStore) => state.userData.user);
    const currentUserId = currentUser && currentUser?._id
    const [loading, setLoading] = React.useState<boolean>(false);

    const [postEditorVisibility, setPostEditorVisibility] = useState<'visible' | 'hidden'>('hidden');
    const [postMode, setPostMode] = useState<'Post' | 'Article'>('Post');

    const [selectedPost, setSelectedPost] = React.useState<PostData | null>(null);

    const [parentPost, setParentPost] = React.useState<PostData | null>(null);


    useEffect(() => {
        if (!isFetching || !isMoreData) {
            return;
        }
        if (postData.length > 0) {
            setPage(page + 1);
        }
    }, [isFetching]);

    // Fetch Post Data
    React.useEffect(() => {
        fetchData()
    }, [page])

    React.useEffect(() => {
        refreshFeeds();
    }, [props.userId])

    function fetchSinglepost() {
        if (code && !isCodeUsed) {
            setLoading(true)
            getFeedById(code).then(data => {
                postData.unshift(data[0])
                setPostData(postData);
                setLoading(false);
                setIsCodeUsed(true);
            })
        }
    }
    function fetchData() {
        setLoading(true)
        const feedApi = props.feedApi || getTimeLine;
        feedApi((props.userId || userId), page, pagesize).then(data => {
            if (!data || data.length === 0) {
                setIsMoreData(false)
            }
            const tempPostData = postData
            const tempData = tempPostData.concat(data);
            setPostData(tempData);
            fetchSinglepost()
            setIsFetching(false);
            setLoading(false)
        }).catch((ex) => {
            console.error(ex);
        })
    }

    //on Delete or Edit
    function onActionPerform(actionType, postId, index) {
        if (actionType === "Delete") {
            deleteFeed(postId).then(data => {
                const tempPostData = postData
                tempPostData.splice(index, 1);
                setPostData([...tempPostData]);
            }).catch((ex) => {
                console.error(ex);
            })
        } else {
            setSelectedPost(postData[index]);
            setPostEditorVisibility('visible');
            postData[index].parent && postData[index].parent?.id && setParentPost(postData[index].parent!);
        }
    }

    // Get Comment Data
    useEffect(() => {
        if (selectedFeedComment) {
            getCommentsAPI(selectedFeedComment.type, selectedFeedComment.id, commentPage, 10).then(data => {
                const tempPostData = commentData
                const tempData = tempPostData.concat(data);
                setCommentData([...tempData]);
            }).catch((ex) => {
                console.error(ex);
            })
        }
    }, [commentPage])

    useEffect(() => {
        if (selectedFeedComment) {
            getCommentsAPI(selectedFeedComment.type, selectedFeedComment.id, commentPage, 10).then(data => {
                setCommentData(data);
            }).catch((ex) => {
                console.error(ex);
            })
        }
    }, [selectedFeedComment])

    // Add Comment
    function addComment(comment, postId, postType, recordIndex) {
        const commentDataObj: CommentRequest = { record_id: postId, content: comment, record_type: postType }
        createCommentAPI(commentDataObj).then(data => {
            const tempCommentData = commentData
            if (data) {
                tempCommentData.unshift(data)
                setCommentData([...tempCommentData]);
            }
            // increase comment count by 1
            const tempPostData = postData
            tempPostData[recordIndex].comments_count += 1
            setPostData([...tempPostData])
        }).catch((ex) => {
            console.error(ex);
        })
    }

    // Delete Comment
    function onDeleteComment(commentId, index, recordIndex) {
        deleteCommentAPI(commentId).then(data => {
            const tempCommentData = commentData
            tempCommentData.splice(index, 1);
            setCommentData([...tempCommentData]);
            // decrease comment count by 1
            const tempPostData = postData
            tempPostData[recordIndex].comments_count -= 1
            setPostData([...tempPostData])
        }).catch((ex) => {
            console.error(ex);
        })
    }

    // Edit Comment
    function onEditComment(editCommentId, editedComment, editIndex) {
        const obj: CommentUpdateRequest = { content: editedComment }
        updateCommentAPI(editCommentId, obj).then(data => {
            const tempPostData = commentData
            tempPostData[editIndex].content = editedComment
            setCommentData([...tempPostData]);

        }).catch((ex) => {
            console.error(ex);
        })

    }

    // Post Like
    function postLike(recordId, isLike, postType, recordIndex, callback) {
        const likeObj: LikeRequest = {
            record_id: recordId,
            record_type: postType,
            like: isLike ? 0 : 1
        }
        postLikeAPI(likeObj).then(data => {
            const tempPostData = postData
            if (isLike) {
                tempPostData[recordIndex].likes_count -= 1
                tempPostData[recordIndex].like_type = ""
            } else {
                tempPostData[recordIndex].like_type = "like"
                tempPostData[recordIndex].likes_count += 1
            }
            setPostData([...tempPostData])
        }).catch((ex) => {
            console.error(ex);
        }).finally(() => { callback && callback(); })
    }

    // on Scroll
    React.useLayoutEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop > (document.documentElement.offsetHeight - 900)) {
                setIsFetching(true);
            }
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    const refreshFeeds = () => {
        const feedApi = props.feedApi || getTimeLine;
        feedApi(props.userId || userId, 0, pagesize).then(data => {
            setPostData(data);
        }).catch((ex) => {
            console.error(ex);
        });
    }

    return (
        <div>
            <PostEditor postMode={postMode} post={selectedPost} parentPost={parentPost} isOpen={postEditorVisibility == 'visible'} onClose={(newPost) => {
                setPostEditorVisibility('hidden');
                if (newPost) {
                    //refreshFeeds();
                    let updatedPostIndex = postData.findIndex(x => x.id == newPost.id);
                    if (updatedPostIndex != -1) {
                        postData.splice(updatedPostIndex, 1, newPost);
                        setPostData([...postData]);
                    } else {
                        setPostData([newPost, ...postData]);
                    }
                }
                setSelectedPost(null);
                setParentPost(null);
            }} />
            <div style={{ position: "relative" }}>
                <form style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "1.6rem" }}>
                    {(!props.userId || props.userId == currentUserId) && <Card
                        variant="outlined"
                        sx={{
                            paddingY: "17px",
                            paddingX: "16px",
                            height: "auto",
                            width: { xs: "88%", sm: "88%", md: "calc(100% - 40px)" },
                            minWidth: { xs: "88%", sm: "88%", md: "70%" },
                            background: "#F9F9F9",
                            borderRadius: "6px",
                            maxWidth: { md: "671px" },

                        }}
                        onClick={(e) => { setPostMode('Post'); setPostEditorVisibility('visible'); }}
                    >
                        <Grid sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: "14px",
                            width: "100%"
                        }}>
                            <Grid sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: "14px",
                                justifyItems: 'center',
                                alignItems: 'center',
                                width: "100%"
                            }}>
                                <Avatar
                                    alt="User"
                                    src={currentUser?.profilepic}
                                    sx={{ width: 50, height: 50 }}
                                />
                                <OutlinedInput
                                    id="start-post"
                                    sx={{
                                        background: '#FFFFFF',
                                        boxSizing: 'border-box',
                                        borderRadius: '6px;',
                                        height: '37px',
                                        width: "100%",

                                    }}
                                    endAdornment={<InputAdornment position={'start'}>
                                    </InputAdornment>}
                                    aria-describedby="start-your-post"
                                    inputProps={{
                                        'aria-label': 'start-post',
                                        style: {
                                            fontFamily: 'Oxygen',
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            lineHeight: "18px",
                                            color: '#686868'
                                        }
                                    }}
                                    name="title"

                                    placeholder={showDescription ? "Title" : "Start your post"}

                                />
                            </Grid>
                            {showDescription ?
                                <OutlinedInput
                                    id="filled-multiline-static"
                                    label="Description"
                                    multiline
                                    name="description"
                                    sx={{
                                        background: '#FFFFFF',
                                        boxSizing: 'border-box',
                                        borderRadius: '25px',
                                        width: "100%",
                                        marginBottom: "10px",
                                    }}
                                    endAdornment={<InputAdornment position={'start'}>
                                    </InputAdornment>}
                                    aria-describedby="start-your-post"
                                    inputProps={{
                                        'aria-label': 'start-post',
                                    }}
                                    placeholder="Description"
                                    disabled={true}
                                />

                                : null}
                            {/* <button style={{ border: "none"}} type='submit'>
                                <Send sx={{ width: '40px', height: '40px', color: '#0F9764' }} />
                            </button> */}
                        </Grid>
                        <CardActions sx={newsFeedCreateStoryCardAction}>
                            <IconButton aria-label="photo" sx={{ marginLeft: "0px" }}>
                                <img src='news-photo.svg' alt='Stocknocks' />
                                <Typography sx={newsFeedCreateStoryIconButtons}>&nbsp;&nbsp;&nbsp;Photo</Typography>
                            </IconButton>
                            <IconButton aria-label="video" sx={{ marginLeft: "0px" }}>
                                <img src='news-video.svg' alt='Stocknocks' />
                                <Typography sx={newsFeedCreateStoryIconButtons}>&nbsp;&nbsp;&nbsp;Video</Typography>
                            </IconButton>
                            <IconButton aria-label="event" sx={{ marginLeft: "0px" }}>
                                <img src='news-event.svg' alt='Stocknocks' />
                                <Typography sx={newsFeedCreateStoryIconButtons}>&nbsp;&nbsp;&nbsp;Event</Typography>

                            </IconButton>
                            <IconButton onClick={(e) => { setPostMode('Article'); setPostEditorVisibility('visible'); e.stopPropagation(); }} aria-label="write-article" sx={{ marginLeft: "0px" }}>
                                <img src='news-article.svg' alt='Stocknocks' />
                                <Typography sx={newsFeedCreateStoryIconButtons}>&nbsp;&nbsp;&nbsp;Write article</Typography>
                            </IconButton>
                        </CardActions>
                    </Card>
                    }
                    {loading && <CircularProgress color="primary" size="1.4em" sx={{ p: '10px' }} />}
                    {
                        currentUserId && postData.map((item, index) => {
                            return <>
                                <Story
                                    key={'story-' + index}
                                    feedData={item || {}}
                                    onActionPerform={onActionPerform}
                                    index={index}
                                    userId={userId}
                                    currentUserId={currentUserId}
                                    editImageURL={editImageURL || ''}
                                    setEditImageURL={setEditImageURL}
                                    //Comment
                                    selectedFeedComment={selectedFeedComment}
                                    setSelectedFeedComment={setSelectedFeedComment}
                                    commentData={commentData}
                                    addComment={addComment}
                                    deleteComment={onDeleteComment}
                                    editComment={onEditComment}
                                    setCommentPage={setCommentPage}
                                    commentPage={commentPage}
                                    // Like
                                    postLike={postLike}
                                    selectedFeedLikeId={selectedFeedLikeId}
                                    setSelectedFeedLikeId={setSelectedFeedLikeId}
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                    onPostShare={(sharedPost) => {
                                        setParentPost(sharedPost);
                                        setPostEditorVisibility('visible');
                                    }}
                                    likeData={likeData} />
                                {(index + 1) % 3 == 0 && <MobileAdvert />}
                            </>
                        })
                    }
                </form>
            </div>
            {/* <div style={{ paddingTop: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <button onClick={() => setPage(page + 1)}>Load More</button>
            </div> */}
        </div >
    );
}

export default NewsFeed;


