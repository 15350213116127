import { assistantBotService } from "../appConfig/AxiosConfig";
import { AssistantChatResponse } from "../model/AssistantChatResponse";

export async function getAnnualReportBot(companyId: string): Promise<{ fileUrl: string; sourceId: string; }> {
    return await (await assistantBotService.get("/annualreport/doc/" + companyId)).data;
}

export async function chat(sourceId: string, question: string): Promise<AssistantChatResponse> {
    return await (await assistantBotService.post('/annualreport/chat', { sourceId, question })).data
}

export async function getChatHistory(sourceId: string, page = 0, pageSize = 10): Promise<AssistantChatResponse> {
    return await (await assistantBotService.get(`/annualreport/chatHistory/${sourceId}?page=${page}&pagesize=${pageSize}`)).data
}