import axios, { AxiosResponse } from "axios";
import { advertisementService } from "../appConfig/AxiosConfig";
import { creativeData } from "../model/Creative";

const createCreative = async (name: string, tags: string[], link: string, creative: File) => {

    var form = new FormData();
    form.append("name", name);
    form.append("tags", tags.join(","));
    form.append("link", link);
    form.append("file", creative);
    return await (await advertisementService.post(`/advertisement/creatives`, form)).data;
}

const updateCreative = async (name: string, link: string, tags: string[], creativeId:string) => {

    var form = new FormData();
    form.append("name", name);
    form.append("link", link);
    form.append("tags", tags.join(','));
    return await (await advertisementService.put(`/advertisement/creatives/${creativeId}`, form)).data;
}

const getCreatives = async (page: number) => {
    let response = await advertisementService.get<creativeData[]>(`/advertisement/creatives?pagesize=10&page=${page}`);
    return response.data;
}

const getCreative = async (id: number) => {
    let response = await advertisementService.get(`/advertisement/creatives/${id}`);
    return response.data;
}

const deleteCreative = async (id: number) => {
    let response = await advertisementService.delete(`/advertisement/creatives/${id}`);
    return response.data;
}

let cachedAdvertisement=[];

const getAdvertisementByUser = async () => {
    if (cachedAdvertisement.length == 0) {
        let response = await (await advertisementService.get(`/advertisement`));
        cachedAdvertisement = response.data;
    }
    return cachedAdvertisement;
}

const postPricingForm = async (firstName: string, lastName: string, phone: string, email: string, message: string) => {
    try {
        let data = {
            "firstName": firstName,
            "lastName": lastName,
            "businessName": "NA",
            "phone": phone,
            "email": email,
            "type": "pricing-get-in-touch",
            "comments": message
        }
        let response: AxiosResponse<any> = await advertisementService.post<any, AxiosResponse<any>>(`/leads/create`, data);
        return response.data;
    }
    catch (error: any) {
        console.log(error);
        throw new Error(error?.message || error?.response?.data?.message);
    }
}


const getAllLeads = async (page: number, pagesize: number): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await advertisementService.get<any,AxiosResponse<any>>(`/leads/all?page=${page}&pagesize=${pagesize}&count=true`);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Leads data read failed.");
    }
}



export { getCreatives, getCreative, deleteCreative, createCreative, updateCreative, getAdvertisementByUser , postPricingForm, getAllLeads };