import { CardMedia, ImageList, ImageListItem, Card, Typography, Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { GridImagesProps, MediaProps } from "./ImageGridInterfaces";
import FullScreenView from "./FullScreenView";
import FullscreenTwoToneIcon from '@mui/icons-material/FullscreenTwoTone';

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    borderStyle: "none",
    boxShadow: "none"
  },
  font: {
    position: "absolute",
    padding: "none",
    top: "20%",
    width: "100%",
    textAlign: "center",
    color: "white",
    backgroundColor: "none",
    display: "flex",
    justifyContent: "space-around",
    fontSize: "23px",
    fontStyle: "italic",
    fontWeight: "bold",
    fontFamily: "Oxygen",
    paddingTop: "20px"
  },
  mediaCardIconButton: {
    position: "absolute",
    padding: "none",
    top: "10%",
    right: "5%",
  }
}));

function getItemSource(
  source: string | undefined
): { component: "video" | "img" } {
  if (!source) {
    return {
      component: "img"
    };
  }
  if (
    [
      "MP4",
      "MOV",
      "WMV",
      "AVI",
      "AVCHD",
      "FLV",
      "F4V",
      "SWF",
      "MKV",
      "WEBM",
      "HTML5",
    ].includes(source.split(".")[source.split(".").length - 1].toUpperCase())
  ) {
    return {
      component: "video"
    };
  } else {
    return {
      component: "img"
    };
  }
};

function GridImages(props: GridImagesProps) {
  const [currIndex, setCurrIndex] = React.useState<number>(0);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [mediaList, setMediaList] = React.useState<MediaProps[]>([]);

  let classes = useStyles();

  useEffect(() => {
    if (props.mediaList?.length > 0) {
      let allItems: MediaProps[] = [];
      props.mediaList.forEach(item => {
        allItems.push({ link: item.link, type: getItemSource(item.link).component });
      });
      setMediaList(allItems);
    }

    return () => {
      setMediaList([]);
      setCurrIndex(0);
    }
  }, [props]);

  function openFullScreenView(ind = 0) {
    if (ind >= 0 && ind !== currIndex) {
      setCurrIndex(ind);
    }
    setViewOpen(true);
  }
  function closeFullScreenView() {
    setViewOpen(false);
  }

  function render() {
    switch (itemCount) {
      case 0:
        return <React.Fragment />;
      case 1:
        return <React.Fragment>
          <ImageListItem key={"story-feed-" + props.title + '_' + 1}>
            {props.mediaList!.map((x, index) => (
              <React.Fragment>
                <CardMedia
                  key={"story-feed-" + props.title + index}
                  component={getItemSource(x.link).component}
                  controls
                  src={x.link}
                  sx={{ width: "100%", marginTop: "10px" }}
                  alt={`${props.title}_` + (index)}
                  image={x.link}
                  onClick={getItemSource(x.link).component === "video" ? () => { } : () => { openFullScreenView(0) }}
                />
                {getItemSource(x.link).component === "video" &&
                  <Typography className={classes.mediaCardIconButton}>
                    <IconButton sx={{ color: "#fff" }} onClick={() => { openFullScreenView(0) }}>
                      <FullscreenTwoToneIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </Typography>
                }
              </React.Fragment>
            ))}
          </ImageListItem>
        </React.Fragment>;
      case 2:
        return <ImageList sx={{ width: '100%' }} gap={1} cols={2} variant="quilted">
          {props.mediaList.map((item, index) => (
            <ImageListItem key={"story-feed-" + props.title + '_' + index}>
              <CardMedia
                key={"story-feed-" + props.title + index}
                component={getItemSource(item.link).component}
                controls
                src={`${item.link}`}
                sx={{
                  width: "99%",
                  objectFit: "cover",
                  border: 1, borderColor: '#ebebeb', borderRadius: '8px'
                }}
                alt={`${props.title}_` + (index)}
                image={item.link}
                onClick={getItemSource(item.link).component === "video" ? () => { } : () => { openFullScreenView(index) }}
              />
              {getItemSource(item.link).component === "video" &&
                <Typography className={classes.mediaCardIconButton}>
                  <IconButton sx={{ color: "#fff" }} onClick={() => { openFullScreenView(index) }}>
                    <FullscreenTwoToneIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Typography>
              }
            </ImageListItem>
          ))}
        </ImageList>;
      default:
        let compList: any[] = [];
        compList.push(props.mediaList.slice(0, 1).map((item, index) => (
          <ImageListItem key={"story-feed-" + props.title + '_' + index}>
            <CardMedia
              key={"story-feed-" + props.title + index}
              component={getItemSource(item.link).component}
              controls
              src={`${item.link}`}
              sx={{
                width: "99%",
                height: "164px",
                objectFit: "cover",
                border: 1, borderColor: '#ebebeb', borderRadius: '8px'
              }}
              alt={`${props.title}_` + (index)}
              image={item.link}
              onClick={getItemSource(item.link).component === "video" ? () => { } : () => { openFullScreenView(0) }}
            />
            {getItemSource(item.link).component === "video" &&
              <Typography className={classes.mediaCardIconButton}>
                <IconButton sx={{ color: "#fff" }} onClick={() => { openFullScreenView(0) }}>
                  <FullscreenTwoToneIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Typography>
            }
          </ImageListItem>
        )));
        compList.push(
          <ImageListItem key={"story-feed-" + props.title + '_' + 1}>
            <Card className={classes.root}>
              <CardMedia
                key={"story-feed-" + 1}
                component={getItemSource(props.mediaList[1].link).component}
                src={`${props.mediaList[1].link}`}
                sx={{
                  width: "99%",
                  height: "164px",
                  objectFit: "cover",
                  border: 1, borderColor: '#ebebeb', borderRadius: '8px',
                  filter: "brightness(50%)"
                }}
                alt={`${props.title}_` + (1)}
              />
              <Typography>
                <Button
                  variant="text"
                  sx={{
                    display: "flex", alignItems: "center", border: "none", boxShadow: "none",
                    "&:hover": { backgroundColor: "transparent" }
                  }}
                  className={classes.font}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  onClick={() => { openFullScreenView(1) }}
                >
                  {`${itemCount - 2} + items`}
                </Button>
              </Typography>
            </Card>
          </ImageListItem>);
        return <React.Fragment>
          <ImageList sx={{ width: '100%' }} gap={1} cols={2} variant="quilted">
            {compList}
          </ImageList>
        </React.Fragment>;
    }
  }

  let itemCount = props.mediaList?.length || 0;

  return <React.Fragment>
    {render()}
    <FullScreenView mediaList={mediaList} open={viewOpen} onClose={closeFullScreenView} currentIndex={currIndex} />
  </React.Fragment>;
}

export default GridImages;