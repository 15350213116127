import axios, { AxiosResponse } from "axios";
import { userListData, userListDataObject } from "../model/UserListData";
import {CinUserListData,CinIdUserData} from "../model/CinUserListData";
import { assistantBotService, communicationService, companyDataService, companyService, userImpressionService, userService } from "../appConfig/AxiosConfig";
import { OrderDetails } from "../model/OrderDetails";
import { storeTransaction } from "./PaymentService";
import { Utility } from "../utility/Utility";
import { CommunicationServiceURL } from "../appConfig/Host";

const getUserData = async (): Promise<userListData> => {
    let response: AxiosResponse<userListData> = await userService.get<any, AxiosResponse<userListData>>("/users");
    return response.data;
}

async function getUserListForAdmin(page: number, pagesize: number, getCount: boolean): Promise<userListDataObject> {
    let response: AxiosResponse<userListDataObject> = await userService
        .get<any, AxiosResponse<userListDataObject>>(`/users?page=${page}&pagesize=${pagesize}&count=${getCount}`);
    return response.data;
}

const getuserDetail = async (email: string): Promise<userListData> => {
    let response: AxiosResponse<userListData> = await userService.get<any, AxiosResponse<userListData>>(`/users/${email}`);
    return response.data;
}

const getCurrentUser = async (): Promise<userListData> => {
    let response: AxiosResponse<userListData> = await userService.get<any, AxiosResponse<userListData>>("/user/current");
    return response.data;
}

const getCINuserDetail = async (CIN: string): Promise<CinUserListData> => {
    let response: AxiosResponse<CinUserListData> = await companyService.get<any, AxiosResponse<CinUserListData>>(`/unlistedcompanies/financials/cin/${CIN}`);
    return response.data;
}

const getCINuserDetailByID = async (ID: string): Promise<CinIdUserData> => {
    let response: AxiosResponse<CinIdUserData> = await companyService.get<any, AxiosResponse<CinIdUserData>>(`/unlistedcompanies/financials/id/${ID}`);
    return response.data;
}

const postCINuserDetails = async (cin: any, formData: any) => {
    let response: AxiosResponse<CinUserListData> = await companyService.post<any, AxiosResponse<CinUserListData>>(`/unlistedcompanies/financials/create/${cin}`,formData);
    return response.data;
}

const postXMLFile = async (cin: any, file: any) => {
    let response: AxiosResponse<CinUserListData> = await companyService.post<any, AxiosResponse<CinUserListData>>(`/unlistedcompanies/financials/upload?cin=${cin}`,file);
    return response.data;
}

const postUpdateCINuserDetails = async (ID: any, formData: any) => {
    let response: AxiosResponse<CinUserListData> = await companyService.post<any, AxiosResponse<CinUserListData>>(`/unlistedcompanies/financials/update/${ID}`,formData);
    return response.data;
}

const getInstaFinanceStatus = async ( cin:any): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/unlisted/instasummary/status?cin=${cin}`);
    return response.data;
}

const getInstaFinanceProcess = async ( cin:string ) => {
    let response: AxiosResponse<any> = await companyDataService.post<any, AxiosResponse<any>>(`/unlisted/instasummary/process`,[cin]);
    return response.data;
}

const getInstaFinanceRefresh = async (cin: string, name: string) => {
    let data = { "cin" : cin, "name" : name}
    let response: AxiosResponse<any> = await companyDataService.post<any, AxiosResponse<any>>(`unlisted/instasummary/refresh`,[data]);
    return response.data;
}

const getSupportStatus = async (page: number, pagesize: number, getCount: boolean ): Promise<any> => {
    let response: AxiosResponse<any> = await userImpressionService.get<any,
        AxiosResponse<any>>(`/support/admin/get?page=${page}&pagesize=${pagesize}&count=${getCount}`);
    return response.data;
}

const getCompanyOwnStatus = async (page: number, pagesize: number, inputValue:string ): Promise<any> => {
    let response: AxiosResponse<any> = await userImpressionService.get<any,
        AxiosResponse<any>>(`/owncompany/getallrequest?page=${page}&pagesize=${pagesize}&status=${inputValue}&count=true`);
    return response.data;
}

const getUnlistedCompanyVideo = async (selectedCompany,page: number, pagesize: number, ): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any,
        AxiosResponse<any>>(
            // `/companyvideos/list?refid=${refid}&reftype=UnlistedCompany&page=${page}&pagesize=${pagesize}&count=true`
            // `/companyvideos/list?refid=U67120TN1976PLC007167&reftype=UnlistedCompany&page=0&pagesize=50&count=true`
            `/companyvideos/list?refid=${selectedCompany.cin}&reftype=UnlistedCompany&page=${page}&pagesize=${pagesize}&count=true`
        );
    return response.data;
}

const getListedCompanyVideo = async (selectedComp,page: number, pagesize: number, ): Promise<any> => {
    let response: AxiosResponse<any> = await companyService.get<any,
        AxiosResponse<any>>(
            // `/companyvideos/list?refid=${refid}&reftype=UnlistedCompany&page=${page}&pagesize=${pagesize}&count=true`
            // `/companyvideos/list?refid=U67120TN1976PLC007167&reftype=UnlistedCompany&page=0&pagesize=50&count=true`
            `/companyvideos/list?refid=${selectedComp.id}&reftype=Company&page=${page}&pagesize=${pagesize}&count=true`
        );
    return response.data;
}

const postUnlistedCompanyVideo = async (cin: string, description, url) => {
    let data = {
        "ref_id": cin,
        "ref_type": "UnlistedCompany",
        "description":description,
        "link_url":url,
    }
    let response: AxiosResponse<any> = await companyService.post<any, AxiosResponse<any>>(`companyvideos/create`,data);
    return response.data;
}

const postListedCompanyVideo = async (code: string, description, url) => {
    let data = {
        "ref_id": code,
        "ref_type": "Company",
        "description":description,
        "link_url":url,
    }
    let response: AxiosResponse<any> = await companyService.post<any, AxiosResponse<any>>(`companyvideos/create`,data);
    return response.data;
}

const deleteUnlistedCompanyVideo = async (params): Promise<[]> => {
    let response: AxiosResponse<[]> = await companyService.delete<any,
        AxiosResponse<[]>>(`companyvideos/delete?id=${params}`
        );
    return response.data;
}

const postListedCompanyFileUpload = async (companyId: string, year:number, url:string, description:string) => {
    let data = {
        "companyId": companyId,
        "year": year,
        "fileUrl": url,
        "description": description,
    }
    let response: AxiosResponse<any> = await assistantBotService.post<any, AxiosResponse<any>>(`/annualreport`,data);
    return response.data;
}

const getListedCompanyFinancialData = async (page: number, pagesize: number, ): Promise<any> => {
    let response: AxiosResponse<any> = await assistantBotService.get<any,
        AxiosResponse<any>>(
            `/annualreport/docs?page=${page}&pagesize=${pagesize}`
        );
    return response.data;
}


const createUnlistedOwnCompany = async (companyName, email: string, files: File[], companyCin:any) => {
    const orderDetails = await storeOrderDetails(companyName, email, "UnlistedCompany", companyCin);

    var form = new FormData();
    form.append("name", companyName);
    form.append("email", email);
    // form.append("file", file);
    for (let i = 0; i < files.length; i++) {
        form.append(files[i].name, files[i]);
      }
    form.append("companyType", "UnlistedCompany");
    form.append("companyId", companyCin);
    form.append("orderId", orderDetails.data._id);
    form.append("type", "Own-Company");
    // return await (await userImpressionService.post(`/owncompany/create`, form)).data;
    try {
        let res : AxiosResponse<any> = await userImpressionService.post(`/owncompany/create`, form);
        return res.data;
      } catch (error: any) {
        console.log(error );
        throw new Error(error?.response?.data?.message);
      }
}

const putCompanyOwnRequest = async (params, status, message:string): Promise<[]> => {
    var tempData = {status:status, message:message}
    let response: AxiosResponse<[]> = await userImpressionService.put<any,
        AxiosResponse<[]>>(`/owncompany/updatestatus?id=${params.id}`, tempData);
    return response.data;
}

const storeOrderDetails = async (name: string, email: string, type: string, co_code: string) => {
    const orderDetails: OrderDetails = {
        created_for_type: type,
        co_code: co_code
    }
    orderDetails.buyerFirstName = name;
    orderDetails.buyerLastName = "";
    orderDetails.buyerEmail = email;
    orderDetails.buyerAddress = "NA";
    orderDetails.buyerCity = "NA";
    orderDetails.buyerState = "NA";
    orderDetails.buyerCountry = "IN";
    orderDetails.buyerPincode = "NA";

    orderDetails.plan_id = '64b58ed385e9dd92a1efe3a3';
    // const totalAmount = parseInt(planDetails?.plan_price || "0");
    const totalAmount = 29500;
    orderDetails.amount = totalAmount + "";

    orderDetails.currency = "INR";
    const transactionResponse = await storeTransaction(orderDetails);
    console.log('order response',transactionResponse);
    return transactionResponse;
}

const sendPaymentLink = async (orderId: string): Promise<[]> => {
    const headers = {'authorization': `Bearer ${Utility.getAuthToken()}`}
    let response: AxiosResponse<[]> = await axios.get(`/transact/paymentlink/${orderId}`, {headers: headers});
    return response.data;
}

const createListedOwnCompany = async (companyName:any,email: string, files: File[], companyCode:any) => {
    const orderDetails = await storeOrderDetails(companyName, email, "Company", companyCode);
    var form = new FormData();
    form.append("name", companyName);
    form.append("email", email);
    // form.append("files", files);
    for (let i = 0; i < files.length; i++) {
      form.append(files[i].name, files[i]);
    }
    form.append("companyType", "Company");
    form.append("companyId", companyCode);
    form.append("orderId", orderDetails.data._id);
    form.append("type", "Own-Company");
    // return await (await userImpressionService.post(`/owncompany/create`, form)).data;
    try {
        let res : AxiosResponse<any> = await userImpressionService.post(`/owncompany/create`, form);
        return res.data;
      } catch (error: any) {
        console.log(error );
        throw new Error(error?.response?.data?.message);
      }
}

const postAdminNotification = async (title, description, url) => {
    try {
        let data = {
            "title": title,
            "message": description,
            "url": url,
        }
        let response: AxiosResponse<any> = await communicationService.post<any, AxiosResponse<any>>(`/notifications/send/pushbulk`, data);
        return response.data;
    }
    catch (error: any) {
        console.log(error);
        throw new Error(error?.message || error?.response?.data?.message);
    }
}

const postListedFinavo = async (cin: string, companyName: string, isin: string, nse: string, bse: string) => {
    try {
        let data = {
            "CIN": cin,
            "companyName": companyName,
            "isin": isin,
            "nse": nse,
            "bse": bse,
            
        }
        let response: AxiosResponse<any> = await companyDataService.post<any, AxiosResponse<any>>(`/unlisted/admin/refresh/finavo/profile`, data);
        return response.data;
    }
    catch (error: any) {
        console.log(error);
        throw new Error(error?.message || error?.response?.data?.message);
    }
}

const postAddCredits = async (userId: string, amount: string, comments: string) => {
    try {
        let data = {
            "userId": userId,
            "userType" : "User",
            "amount": amount,
            "comments": comments,
        }
        let response: AxiosResponse<any> = await userService.post<any, AxiosResponse<any>>(`/user/credits/admin`, data);
        return response.data;
    }
    catch (error: any) {
        console.log(error);
        throw new Error(error?.message || error?.response?.data?.message);
    }
}




export {
    getUserData, getUserListForAdmin, getuserDetail, getCINuserDetailByID,
    getCurrentUser, getCINuserDetail, postCINuserDetails,
    postUpdateCINuserDetails, postXMLFile, getInstaFinanceStatus,
    getInstaFinanceProcess, getInstaFinanceRefresh, getSupportStatus, createUnlistedOwnCompany, createListedOwnCompany,
    getCompanyOwnStatus, putCompanyOwnRequest, sendPaymentLink, getUnlistedCompanyVideo, deleteUnlistedCompanyVideo, 
    postUnlistedCompanyVideo, postListedCompanyFileUpload, getListedCompanyFinancialData,
    postListedCompanyVideo, getListedCompanyVideo, postAdminNotification, postListedFinavo, postAddCredits
}

