import { TextareaAutosize } from "@mui/core";
import { Box, TextField, Typography, styled } from "@mui/material";
import { values } from "lodash";
import { useEffect, useState } from "react";

interface IProps {
    value: string[];
    onChange: (value: any) => void;
    label: string;
    hint?: string;
}

export default function MultiselectPinControl(props: IProps) {
    const [pin, setPin] = useState('');

    useEffect(() => {
        if (!props.value)
            setPin('');
    }, [props.value]);

    useEffect(() => {
        props.onChange(pin);
    }, [pin])

    return (
        <Box sx={{
            borderRadius: "8px",
            padding: "5px 28px"
        }}>
            <Typography sx={{ fontSize: "14px", marginBottom: "5px", color: "#393939", fontWeight: 700 }} component={"div"}>{props.label}</Typography>
            <TextField multiline={true} minRows={3}
                placeholder={(props.hint && props.hint.length > 0) ? props.hint : "6 digit pincode separated by comma(,)"}
                value={pin}
                onChange={(e) => { setPin(e.target.value); }}
                sx={{
                    width: "100%",
                    "& textarea": { fontSize: "12px" },
                    "& input::placeholder": {
                        fontSize: "12px",
                        textAlign: "right", paddingRight: "5px", marginTop: "5px"
                    },
                    background: "#ffffff"
                }} />

            {/* <Typography component={"div"} sx={{ fontSize: "9px", textAlign: "right", paddingRight: "5px", marginTop: "5px" }}>
                "6 digit pincode separated by comma(,)"
            </Typography> */}
        </Box>
    );
}