import React, { useEffect } from "react";
import { NavigateFunction, useNavigate, useLocation, Location} from "react-router-dom";
import { Utility } from "../../utility/Utility";

const Authorize = ({children}: {children: JSX.Element}) => {
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation();
    useEffect(() => {
        if (!Utility.isUserAuthenticated()) {
            navigate(`/signin?from=${location.pathname}${location.search}`);
        }        
    })
    return children;
}

export default Authorize;