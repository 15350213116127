import { Box, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom";
import { ISellerCategory } from "./Models/SellerCategory";
import { useEffect, useState } from "react";
import { getSellerCategory } from "../../services/MarketPlaceService";
import { PathNames } from "../../appConfig/PathNames";
import { Utility } from "../../utility/Utility";

export function SellerCategory() {
    const params = useParams();
    const { sellerCode } = params;
    const navigate = useNavigate();
    const [categories, setCategories] = useState<ISellerCategory[]>([]);

    useEffect(() => {
        Utility.setLoading(true);
        getSellerCategory(sellerCode!).then(x => { setCategories(x); Utility.setLoading(false); });
    }, [sellerCode]);

    return <Box sx={{ marginTop: "10px" }}>
        <Box sx={{ paddingLeft: { xs: "10px", md: "0px" } }}>
            <Typography sx={{ color: "#003459", fontWeight: 600, fontSize: { md: "24px", xs: "20px" }, lineHeight: "36px" }}>Take a look at some of our categories</Typography>
        </Box>
        <Box sx={{ display: "block", marginTop: "36px", bgcolor: "#F9F9F9" }}>
            {
                categories
                    .map(category => (
                        <Box onClick={e => navigate(`${PathNames.MARKETPLACE}/seller/${sellerCode}/category/${category.id}/products`)} sx={{
                            width: { md: "calc(25% - 32px)", xs: "calc(50% - 32px)" }, margin: "8px", padding: "8px", borderRadius: "12px", float: "left", cursor: "pointer",
                            background: "#FDFDFD", boxShadow: "0px 4px 28px -2px rgba(0, 0, 0, 0.08)"
                        }} >
                            {category.image && <img src={category.image.link} style={{ width: "100%", height: "264px", borderRadius: "12px" }} />}
                            {!category.image && <Box sx={{ height: "264px", background: "grey", borderRadius: "8px" }}><Typography sx={{
                                marginTop: "auto", color: "#FFFFFF", textAlign: "center",
                                paddingTop: "50%",
                                display: "block",
                                fontSize: "18px"
                            }}>No Image</Typography></Box>}
                            <Box sx={{ padding: "8px 8px 10px 8px", bgcolor: "#FFFFFF" }}>
                                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>{category.name}</Typography>
                            </Box>
                        </Box>
                    ))
            }
        </Box>
        {
            categories.length == 0 && <Box sx={{ textAlign: "center" }}><Typography sx={{ fontSize: "26px", color: "red", fontWeight: "600" }}>No Categories Listed By Seller.</Typography></Box>
        }
    </Box>
}