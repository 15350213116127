import React from 'react';
import { render, hydrate } from 'react-dom';
import { IntlProvider, FormattedMessage, FormattedNumber } from 'react-intl'
import App from './App';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import "./services/FirebaseConfig";
import { createTheme, responsiveFontSizes, Theme, ThemeProvider } from '@mui/material/styles';
import "./index.css";
import { InitializeMUI } from './appConfig/mui-x-grid';
import { BrowserRouter } from 'react-router-dom';

let theme: Theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '24px',
                    textTransform: 'none',
                    fontSize: '15px'
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: "1360px !important",
                    margin: '0 auto'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0px',
                    textTransform: 'none',
                    fontSize: '15px'
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h2',
                    h2: 'h2',
                    h3: 'h2',
                    h4: 'h2',
                    h5: 'h2',
                    h6: 'h2',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0f9764',
        },
        secondary: {
            main: '#ed6c02',
        },
        info: {
            main: '#e5e5e5'
        }
    },
    typography: {
        fontFamily: "Oxygen"
    }
});

theme = responsiveFontSizes(theme);

InitializeMUI();

const rootElement = document.getElementById("root");

if (rootElement && rootElement.hasChildNodes()) {
    hydrate(<IntlProvider locale="en" defaultLocale="en">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </IntlProvider>, rootElement);
} else {
    render(<IntlProvider locale="en" defaultLocale="en">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </IntlProvider>, rootElement);
}

// render(
//     <IntlProvider locale="en" defaultLocale="en">
//         <Provider store={store}>
//             <PersistGate loading={null} persistor={persistor}>
//                 <ThemeProvider theme={theme}>
//                     <App />
//                 </ThemeProvider>
//             </PersistGate>
//         </Provider>
//     </IntlProvider>,
//     document.getElementById('root'))
