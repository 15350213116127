import axios, { AxiosRequestConfig } from "axios";

const isLogoAvailable = async (path: string): Promise<boolean> => {
    try {
        const config: AxiosRequestConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "HEAD"
        };
        let response = await axios(path, config);

        if (response.status === 404) {
            return false;
        } else {
            return true;
        }
    }
    catch {
        return false;
    }
}

export { isLogoAvailable }