import * as React from 'react';
import './index.css';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	IChartingLibraryWidget,
	ResolutionString,
} from '../../components/ChartingLibrary/charting_library';
import { CompanyServiceURL } from '../../appConfig/Host';

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];
	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
}

export interface ChartContainerState {
}

function getLanguageFromURL(): LanguageCode | null {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}

export class TVChartContainer extends React.PureComponent<Partial<ChartContainerProps>, ChartContainerState> {
	public static defaultProps: Omit<ChartContainerProps, 'container'> = {
		symbol: 'NSE:ACC',
		interval: 'D' as ResolutionString,
		datafeedUrl: `${CompanyServiceURL}/chart`,
		libraryPath: '/charting_library/',
		clientId: 'stockknocks.com',
		userId: '1',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	private tvWidget: IChartingLibraryWidget | null = null;
	private ref: React.RefObject<HTMLDivElement> = React.createRef();

	public componentDidMount(): void {
		if (!this.ref.current) {
			return;
		}

		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: this.props.symbol as string,
			datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
			interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
			container: this.ref.current,
			library_path: this.props.libraryPath as string,

			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['side_toolbar_in_fullscreen_mode', 'header_in_fullscreen_mode'],
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
			timezone: 'exchange',// "Asia/Kolkata"
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;
	}

	public componentWillUnmount(): void {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	public render(): JSX.Element {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
