import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PopupProps {
  onClose: () => void;
  isOpen: boolean;
}

const CompanyUnlockSuccessPopup: React.FC<PopupProps> = ({
  onClose,
  isOpen,
}) => {
  const [countdown, setCountdown] = React.useState(5);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    timerRef.current = setTimeout(() => {
      onClose();
      window.location.reload();
    }, 5000);

    return () => {
      clearInterval(intervalRef.current!);
      clearTimeout(timerRef.current!);
    };
  }, [onClose]);

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-handle-confirm"
      PaperProps={{
        sx: {
          width: {
            xs: '370px',
            sm: '370px',
            md: '499px',
            lg: '820px',
            xl: '820px',
          },
          maxWidth: '627px !important',
          backgroundImage: 'url("/static/companyUnlock/frame_bg_img.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'auto',
          }}
        >
          <Box>
            <img
              src="/static/companyUnlock/success.gif"
              alt="investor"
              style={{
                width: '200px',
                height: '200px',
                padding: '-10px',
                margin: '-10px',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '16px',
              marginX: '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '30px', xs: '30px' },
                fontFamily: 'Oxygen',
                fontWeight: 700,
                color: '#009262',
                textAlign: 'center',
              }}
            >
              Data loaded successfully
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginY: '16px',
              marginX: '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '16px', xs: '12px' },
                fontFamily: 'Oxygen',
                fontWeight: 400,
                color: '#FF0000',
                textAlign: 'center',
              }}
            >
              Closing in {countdown} seconds...
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyUnlockSuccessPopup;
