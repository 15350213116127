import {
    ReactElement,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import createLinkifyPlugin from '@draft-js-plugins/linkify';

import editorStyles from './RichTextField.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/hashtag/lib/plugin.css'
import { searchUsers } from '../../services/UserService';
import { searchCompanies } from '../../services/CompanyService';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';

interface IRichTextFieldProps {
    onBlur?: (value: string) => void,
    defaultValue?: string,
    placeholder?: string
}

export default function RichTextField(props: IRichTextFieldProps): ReactElement {
    const ref = useRef<Editor>(null);
    const [editorState, setEditorState] = useState(() =>
        props.defaultValue ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.defaultValue))) : EditorState.createEmpty()
    );

    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState<MentionData[]>([]);

    const companies = useSelector((state: ReduxStore) => state.app.companiesData.companies);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({ supportWhitespace: true });
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow

        const hastagPlugin = createHashtagPlugin();

        const linkifyPlugin = createLinkifyPlugin({
            component(props) {
              // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
              return <a {...props}/>;
            },
            customExtractLinks: (text) =>
              linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text),
          });

        const plugins = [mentionPlugin, hastagPlugin, linkifyPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = useCallback((_open: boolean) => {
        setOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ value }: { value: string }) => {
        if (value && value.trim().length >= 3) {

            Promise.allSettled([
                searchUsers(value.trim()),
                searchCompanies(value, 'Listed'),
                searchCompanies(value.toLocaleLowerCase(), 'Unlisted')])
                .then(([users, listedcompanies, unlistedCompanies]) => {
                    var data: any[] = [];
                    (users as any).value.data.forEach(x => {
                        data.push({ name: x.name, "id": x._id, "avatar": "https://image.shutterstock.com/image-vector/thin-line-user-icon-on-260nw-519039097.jpg", type: 'User' });
                    });

                    (listedcompanies as any).value.splice(0, 5).forEach(x => {
                        x && data.push({
                            id: x.id,
                            name: x.name,
                            type: 'Listed',
                            // searchId: x.searchId 
                        });
                    });

                    (unlistedCompanies as any).value.splice(0, 5).forEach(x => {
                        x && data.push({
                            id: x.id,
                            name: x.name,
                            type: 'Unlisted',
                            //  searchId: x.searchId 
                        });
                    });
                    setSuggestions(data);
                });
        }
    }, []);

    return (
        <>
            <div
                className={editorStyles.editor}
                onClick={() => {
                    ref.current!.focus();
                }}
                onBlur={() => {
                    props.onBlur && props.onBlur!(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
                }}
            >
                <Editor
                    editorKey={'editor'}
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={plugins}
                    ref={ref}
                    placeholder={props.placeholder}
                />
                <MentionSuggestions
                    open={open}
                    onOpenChange={onOpenChange}
                    suggestions={suggestions}
                    onSearchChange={onSearchChange}
                    onAddMention={() => {
                    }}                    
                />
            </div>
        </>
    );
}

