import {
  getAuth,
  User,
  updateProfile,
  signInWithEmailAndPassword,
  UserCredential,
  signOut,
  updateCurrentUser,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  sendEmailVerification,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithCredential,
  signInWithPhoneNumber
} from "firebase/auth";
import { SigninModel } from "../model/SigninModel";
import "./FirebaseConfig";
import { createFirstTimeUserInMongo } from './UserService'
import { store } from "../redux/store";
import { Actions } from "../redux/Actions";
import { Notification } from "../model/Notification";
import { Utility } from '../utility/Utility';
import { HostName } from "../appConfig/Host";
import { Buffer } from "buffer";
import { logoutUser } from "../services/UserService"


function gtag_report_conversion(url) {
  var callback = function () {
    if (url) {
      window.location = url;
    }
  };
  if(window && window.gtag){
    window.gtag('event', 'conversion', {
      'send_to': 'AW-10985331218/NqtcCIeL8o4YEJK0m_Yo',
      'event_callback': callback
    });
  }
  
  return false;
}


export const signUpUser = (
  { displayName, userEmail },
  onSuccess?: () => void,
  onFailure?: (error: any) => void) => {
  const auth = getAuth();
  if (!displayName || displayName === "")
    displayName = "existing-user";
  const token = Buffer.from(displayName + ':' + userEmail, "utf8").toString("base64")
  const callbackUrl = encodeURI(`${HostName}/signin?source=emailLink&token=${token}`);
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: callbackUrl,
    // This must be true.
    handleCodeInApp: true,
  };
  sendSignInLinkToEmail(auth, userEmail, actionCodeSettings)
    .then(async () => {
      localStorage.setItem("emailForSignin", userEmail)
      onSuccess && onSuccess();
    }, (error) => {
      console.log("error==", error.code)
      switch (error.code) {
        case 'auth/email-already-in-use':
          onFailure && onFailure("Email is already used");
          break;
      }
    });
};

export const verifySigninLink = async ({ displayName, userEmail },
  onSuccess?: (signedInUser: UserCredential) => void,
  onFailure?: (error: any) => void) => {
  // Confirm the link is a sign-in with email link.
  const auth = getAuth();
  if (isSignInWithEmailLink(auth, window.location.href)) {
    console.log("it is indeed signin with email link", window.location.href)
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = localStorage.getItem('emailForSignIn');

    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = userEmail;
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email || "", window.location.href)
      .then(async (result) => {
        // Clear email from storage.
        localStorage.removeItem('emailForSignIn');
        if (displayName !== "existing-user") {
          await updateProfile(result.user, { displayName });
          let signedInUser = result.user;
          await signedInUser.getIdToken(true);
          createFirstTimeUserInMongo(signedInUser)
          gtag_report_conversion(null);
        }

        onSuccess && onSuccess(result);
      }, function (error) {
        console.log("it is indeed signin with email error", error)
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log("it is indeed signin with email error", error)
      });
  }

}

export const signInUser = (
  signinModel: SigninModel,
  onSuccess?: (signedInUser: UserCredential) => void,
  onFailure?: (error: any) => void
) => {
  const auth = getAuth();
  signInWithEmailAndPassword(
    auth,
    signinModel.username,
    signinModel.password)
    .then(async (signedInUser) => {
      if(!signedInUser.user.displayName){
        await updateProfile(signedInUser.user, {displayName:"abc"});
      }
      await signedInUser.user.getIdToken(true);
      createFirstTimeUserInMongo(signedInUser.user).then((response)=>{
        onSuccess && onSuccess(signedInUser);
      }).catch(err=>{
        console.log("user not created-->",err)
      })
      
    }, function (error) {
      onFailure && onFailure(error);
    })
};

export const signOutUser = (currentUser: User | null, onSuccess: () => void, onFailure: () => void) => {
  if (currentUser == null) {
    store.dispatch({ type: Actions.CLEARUSERDATA, payload: null })
    store.dispatch({ type: Actions.CLEARAUTH, payload: null });
    onSuccess && onSuccess();
    return;
  }
  const auth = getAuth();
  // if (auth.currentUser != null) {
  if (auth != null) {
    signOut(auth)
      .then(async () => {
        store.dispatch({ type: Actions.CLEARUSERDATA, payload: null })
        store.dispatch({ type: Actions.CLEARAUTH, payload: null });
        // await logoutUser();
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.log(error);
        store.dispatch({ type: Actions.CLEARUSERDATA, payload: null })
        store.dispatch({ type: Actions.CLEARAUTH, payload: null });
        onFailure && onFailure();
      })
  }
  else {
    store.dispatch({ type: Actions.CLEARUSERDATA, payload: null })
    store.dispatch({ type: Actions.CLEARAUTH, payload: null });
    onSuccess && onSuccess();
    logoutUser();
  }
};

export const sendVerificationEmail = async () => {
  const auth = getAuth();
  if (!auth.currentUser)
    return;
  try {
    await sendEmailVerification(auth.currentUser)
    const notification: Notification = { message: "Email verification link send to registered email.", type: "success" }
    Utility.notify(notification)
  } catch (err) {
    const notification: Notification = { message: "Error while fetching user data. Email verification link not sent.", type: "error" }
    Utility.notify(notification)
  }
};

export const getSignedInUser = () => {
  const user = getAuth().currentUser;
  updateCurrentUser(getAuth(), user).then(() => {
    // Update successful
    // ...
  }).catch((error: any) => {
    console.log(error);
    // An error occurred
    // ...
  });
  // [END auth_update_user_profile]
}

export const sendPasswordReset = async (email) => {
  const auth = getAuth();
  try {
    await sendPasswordResetEmail(auth, email);
    const notification: Notification = { message: "Password reset email send.", type: "success" }
    Utility.notify(notification)
  } catch (err) {
    const notification: Notification = { message: "Error while fetching user data. Password reset email not sent.", type: "error" }
    Utility.notify(notification)
  }
};

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async (
  onSuccess?: (signedInUser: User) => void,
  onFailure?: (error: any) => void) => {
  const auth = getAuth();
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    createFirstTimeUserInMongo(user);
    gtag_report_conversion(null);
    onSuccess && onSuccess(user);
  } catch (err) {
    console.error(err);
  }
};

export const sendOtp = async (
  phoneNumber: string,
  onSuccess?: () => void,
  onFailure?: (error: any) => void) => {
  const auth = getAuth();
  console.log(phoneNumber);
  try {
    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        window.confirmationResult = confirmationResult;
        onSuccess && onSuccess();
        // ...
      }).catch((error) => {
        console.log(error);
        onFailure && onFailure(error);
      });
  } catch (err) {
    console.error(err);
    onFailure && onFailure(err);
  }
};

export const verifyOtp = async (
  otp: string,
  displayName: string,
  onSuccess?: (signedInUser: User) => void,
  onFailure?: (error: any) => void) => {
  try {
    window.confirmationResult.confirm(otp).then(async (result) => {
      // User signed in successfully.
      let signedInUser = result.user;
      if (displayName)
        await updateProfile(signedInUser, { displayName });

      await signedInUser.getIdToken(true);
      if(displayName){
        createFirstTimeUserInMongo(signedInUser);
        gtag_report_conversion(null);
      }
        
      onSuccess && onSuccess(signedInUser);
      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
    });
  } catch (err) {
    console.error(err);
    onFailure && onFailure(err);
  }
};

export const signInWithGoogleOneTap = async (
  response: any,
  onSuccess?: (signedInUser: User) => void,
  onFailure?: (error: any) => void) => {
  const auth = getAuth();
  try {
    const credential = GoogleAuthProvider.credential(response.credential)
    const res = await signInWithCredential(auth, credential)
    const user = res.user;
    createFirstTimeUserInMongo(user);
    onSuccess && onSuccess(user);
  } catch (err) {
    onFailure && onFailure(err);
  }
};

const FaceBookProvider = new FacebookAuthProvider();

export const signInWithFacebook = async (
  onSuccess?: (signedInUser: User) => void,
  onFailure?: (error: any) => void) => {
  const auth = getAuth();
  try {
    const res = await signInWithPopup(auth, FaceBookProvider);
    const user = res.user;
    onSuccess && onSuccess(user);
    console.log(user);

  } catch (err) {
    console.error(err);
  }
};

export const refreshToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  try {
    let token = await user?.getIdToken(true);
    store.dispatch({ type: Actions.SETAUTH, payload: user });
    return token;
  } catch (e) {
    console.log('error updating token');
    store.dispatch({ type: Actions.SETAUTH, payload: null });
    window.location.href = `/signin?from=${window.location.pathname}`;
  }
}

export const emailVerification = async (email: string) => {
  const auth = getAuth();
  let response: any;
  await fetchSignInMethodsForEmail(auth, email).then(res => {
    response = res;
  }).catch(err => {
    response = err;
  });
  return response;
}


