import { Person } from '@mui/icons-material';
import { getUnListedMinimal } from '../../services/CompanyService';
import { getPreviewUnlistedAdvert } from '../../services/CompanyService';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Avatar, Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Utility } from '../../utility/Utility';
import NotFoundPage from '../Sessions/NotFoundPage';
import { UnlistedCompanyBasicDetails } from '../../model/UnlistedCompanyBasicDetails';
import { StockKnocksGrid } from './Grid';

interface IFAQ { q: string, a: string }

export interface UnlistedCompanyPreviewData extends UnlistedCompanyBasicDetails {
  // trade_enabled: boolean;
  // has_financials: boolean;
  // _id: string;
  ID: string;
  // CIN: string;
  // verified: number;
  // type: string;
  // registrationNumber: number;
  // companyName: string;
  // proprieterName: string;
  // companyStatus: string;
  // companyClass: string;
  // companyCategory: string;
  // companySubCategory: string;
  // dateOfRegistration: string;
  // registeredState: string;
  // authorizedCapital: string;
  // paidUpCapital: string;
  // industrialClass: string;
  // principalBusinessActivity: string;
  // registeredOfficeAddress: string;
  // registrarOfCompanies: string;
  // latestYearAnnualReturn: string;
  // latestYearFinancialStatement: string;
  // listedUnlisted: string;
  // partners: string;
  // designatedPartners: string;
  // totalObligationOfContribution: string;
  // email: string;
  // descriptionOfMainActivity: string;
  // LLPDate1: any;
  // LLPDate2: any;
  // FCountryOfIncorporation: string;
  // FTypeOfOffice: string;
  // Website: string;
  // phone: any;
  // facebook: any;
  // linkedin: any;
  // cover_photo: any;
  // profile_photo: any;
  lastUpdatedDateTime: string;
  // hasOwner: boolean;
  directors: any[];
}

interface BlogItems {
  title: string;
  description: string;
  displaypic: string;
  id: string;
  name: string;
}

interface RecommendationItem {
  title: string;
  description: string;
  image: string;
  actionLink: string;
}

const UnlistedPreview = () => {
  const navigate = useNavigate();
  const [companyData, setCompanyData] =
    React.useState<UnlistedCompanyPreviewData>();
  const [items, setItems] = React.useState<RecommendationItem[]>([]);
  const { companyName } = useParams();
  const [isNotFoundVisible, showNotFound] = useState(false);

  const navigateToLogin = useCallback(() => {
    if (companyData)
      navigate(
        `/signin?from=/unlistedcompany?code=${companyData._id}`
      )
  }, [companyData]);

  const getCompanyDetails = async () => {
    showNotFound(false);
    const data = await getUnListedMinimal(companyName!).catch(() => {
      Utility.notify({
        type: 'error',
        message: 'Company not found',
      });
      //setTimeout(() => navigate('/'), 2000);
      showNotFound(true);
    });
    setCompanyData(data);
  };

  const getUnlistedAdvert = async () => {
    const unlisted = await getPreviewUnlistedAdvert();
    setItems(unlisted);
  };

  React.useEffect(() => {
    getCompanyDetails();
    getUnlistedAdvert();
    setCompanyData(void 0);
  }, [companyName]);

  useEffect(() => {
    if (Utility.isUserAuthenticated() && companyData) {
      navigate(`/unlistedcompany?code=${companyData?._id}`);
    }
  }, [companyData]);


  const FAQs = React.useMemo<IFAQ[]>(() => ([
    { q: `What is the Incorporation date of ${companyData?.companyName} ?`, a: `${companyData?.companyName} was incorporated on ${companyData?.dateOfRegistration}` },
    { q: `What is the CIN number of ${companyData?.companyName} ?`, a: `The CIN number of ${companyData?.companyName} is ${companyData?.CIN}` },
    { q: ` What is the authorized capital of ${companyData?.companyName} ?`, a: `The authorized capital of ${companyData?.companyName} is ${companyData?.authorizedCapital}` },
    { q: `What is the registered address of ${companyData?.companyName} ?`, a: `The registered address of ${companyData?.companyName} is at ${companyData?.registeredOfficeAddress}.` }
  ]), [companyData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        gap: 4,
      }}
    >
      {
        isNotFoundVisible && <NotFoundPage />
      }
      {companyData !== undefined && (
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: { md: '3.5rem', xs: 0 },
            height: '100%',
            background: '#FFFFFF',
            border: '1px solid #EBEBEB',
            borderRadius: '8px',
          }}
        >
          <Box>
            <Paper
              elevation={3}
              style={{
                position: 'relative',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '133px',
                width: '100%',
                backgroundColor: '#0F9764',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ><Box sx={{ marginX: { xs: "0px", md: "60px" } }}>
                {companyData && companyData.profile_photo && (
                  <Avatar
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: 80,
                      marginLeft: 20,
                    }}
                    alt="User Picture"
                    src={companyData.profile_photo}
                  />
                )}
                {companyData && !companyData.profile_photo && (
                  <Avatar
                    style={{
                      width: 150,
                      height: 150,
                      marginTop: 100,
                      marginLeft: '20px',
                    }}
                    alt="User Picture"
                  >
                    {companyData.companyName ? (
                      companyData.companyName[0].toUpperCase()
                    ) : (
                      <Person />
                    )}
                  </Avatar>
                )}
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: { md: 4, xs: "20px" },
                    marginTop: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 18,
                      color: '#0B3B41',
                      lineHeight: '30px',
                    }}
                    component={"h1"}
                  >
                    {companyData.companyName}
                  </Typography>
                  <Button
                    onClick={() =>
                      navigate(
                        `/signin?from=/unlistedcompany?code=${companyData._id}`
                      )
                    }
                    sx={{
                      background: '#0F9764',
                      '&:hover': {
                        backgroundColor: '#0F9764',
                      },
                      borderRadius: '24px',
                      color: '#ffffff',
                      width: '142px',
                      height: '34px',
                      marginTop: 2,
                    }}
                  >
                    Sign in to follow
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                marginTop: "250px",
                marginX: { md: "100px", xs: "20px" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 18,
                  color: '#0B3B41',
                }}
              >
                Description
              </Typography>
              <Box sx={{
                marginTop: "20px",
                "& > span": {
                  display: "block",
                  fontSize: "15px",
                  color: "#858585",
                  lineHeight: "30px",
                  marginBottom: "5px"
                }
              }}>
                <Typography>
                  {companyData.companyName}, established on {companyData.dateOfRegistration?.trim()?.substring(0, 10)}, exemplifies a {companyData.companyClass} held entity with a focus on the {companyData.principalBusinessActivity}, underpinned by its classification as a {companyData.companySubCategory}, '{companyData.companyCategory}'. Operating under the jurisdiction {companyData.registeredState == 'INVALID_1' ? '' : `of the ${companyData.registeredState} Registrar of Companies`}, it showcases a robust financial foundation with an authorized share capital of INR {companyData.authorizedCapital}, alongside a fully subscribed and paid-up capital of INR {companyData.paidUpCapital}.
                </Typography>
                <Typography>
                  The operational headquarters of {companyData.companyName} is strategically located {companyData.registeredCity == 'INVALID_1' ? '' : `in ${companyData.registeredCity} ,`} at {companyData.registeredOfficeAddress}, marking its presence in a key commercial hub. This locale serves as the nerve center for its strategic decisions and operations.
                </Typography>
                <Typography>
                  As per the latest compliance filings with the Ministry of Corporate Affairs (MCA), the company has diligently updated its financials and annual returns up to March 31, 2022. These records affirm its ongoing commitment to transparency and regulatory adherence.
                </Typography>
                {/* <Typography>
                  The [number of directors] pivotal figures serving as the director of the company are [Director’s Name], each bringing unique insights and direction to the firm's strategic roadmap. Their collective vision drives the company forward, ensuring it remains aligned with its goals and industry standards.
                </Typography> */}
              </Box>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "50px",
              marginX: "100px",
              gap: "50px",
              "&>div": {
                borderRadius: "10px",
                border: "1px solid #008053",
                background: "#E6FFF7",
                height: "200px",
                width: "200px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                "& img": {
                  height: "60px",
                  width: "60px",
                  marginTop: "30px",
                  marginLeft: "calc(50% - 30px)"
                },
                "& .title": {
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: 700
                },
                "& .description": {
                  fontSize: "12px",
                  color: "#858585",
                  textAlign: "center",
                  fontWeight: 700
                }
              }
            }}>
              <Box>
                <img src='/CIN-Icon.gif' />
                <Typography className='title'>CIN</Typography>
                <Typography className='description'>{companyData?.CIN}</Typography>
              </Box>
              <Box>
                <img src='/Category-Icon.gif' />
                <Typography className='title'>CATEGORY</Typography>
                <Typography className='description'>{companyData?.companyCategory}</Typography>
              </Box>
              <Box>
                <img src='/Subcategory-Icon.gif' />
                <Typography className='title'>SUB CATEGORY</Typography>
                <Typography className='description'>{companyData?.companySubCategory}</Typography>
              </Box>
              <Box>
                <img src='/Address-Icon.gif' />
                <Typography className='title'>ADDRESS</Typography>
                <Typography title={companyData?.registeredOfficeAddress} sx={{ paddingX: "5px", overflow: "hidden" }} className='description'>{companyData?.registeredOfficeAddress}</Typography>
              </Box>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "40px",
              marginTop: "75px",
              marginX: { md: "100px", xs: "20px" },
              marginBottom: "30px",
              "&>div": {
                borderRadius: "5px",
                border: "1px solid #F2F2F2",
                background: "#F2F2F2",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                minHeight: "150px",
                padding: "30px",
                "& .title": {
                  fontWeight: 700,
                  fontSize: "18px",
                  marginBottom: "5px"
                }
              }

            }}>
              <Box sx={{
                width: { xs: "calc(100% - 80px)", md: "40%" },
                gap: "10px",
                letterSpacing: 0.8,
                "& button": {
                  borderRadius: "5px",
                  fontWeight: "700",
                  height: "30px",
                  width: "100px",
                  fontSize: "14px",
                  marginLeft: "auto"
                },
                "& .details": {
                  display: "flex",
                  gap: "10px",
                  '& > span': {
                    fontSize: "14px",
                    color: "#858585",
                    fontWeight: 700
                  }
                },
                "& img": {
                  height: "28px"
                }
              }} display="flex" flexDirection="column">
                <Typography className="title">Unlock for Details :</Typography>
                <Box className="details" display="flex" flexDirection="row">
                  <img src='/activities-icon.png' />
                  <Typography>Primary Activity :</Typography>
                  <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                </Box>
                <Box className="details" display="flex" flexDirection="row">
                  <img src='/certificate-icon.png' />
                  <Typography>NIC :</Typography>
                  <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                </Box>
                <Box className="details" display="flex" flexDirection="row">
                  <img src='/email-icon.png' />
                  <Typography>Email Id :</Typography>
                  <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                </Box>
              </Box>
              <Box sx={{
                width: { xs: "calc(100% - 80px)", md: "60%" },
                gap: "10px",
                letterSpacing: 0.8,
                "& button": {
                  borderRadius: "5px",
                  border: "1px solid #009262",
                  background: "#F5F5F5",
                  fontWeight: "400",
                  height: "30px",
                  width: "100px",
                  fontSize: "13px",
                  marginLeft: "auto",
                  color: "#000000 !important"
                },
                "& .details": {
                  display: "flex",
                  gap: "10px",
                  '& > span': {
                    fontSize: "14px",
                    color: "#858585",
                    fontWeight: 700,
                    width: { xs: "160px", md: "auto" }
                  }
                },
                "& img": {
                  height: "28px"
                }
              }} display="flex" flexDirection="column">
                <Typography className="title">Company other details :</Typography>
                <Box className="details" display="flex" flexDirection="row">
                  <img src='/age-icon.png' />
                  <Typography>Company Age -</Typography>
                  <Button disabled>{companyData.dateOfRegistration ? (new Date().getFullYear() - new Date(companyData.dateOfRegistration).getFullYear()) : 'NA'} Years</Button>
                </Box>
                <Box className="details" display="flex" flexDirection="row">
                  <img src='/date-icon.png' />
                  <Typography>Date of Balance Sheet -</Typography>
                  <Button disabled>{companyData.latestYearFinancialStatement}</Button>
                </Box>
                <Box className="details" display="flex" flexDirection="row">
                  <img src='/list-icon.png' />
                  <Typography>Company Whether Listed or Not -</Typography>
                  <Button disabled>{companyData.listedUnlisted}</Button>
                </Box>
                <Box className="details" display="flex" flexDirection="row">
                  <img src="/status-icon.png" />
                  <Typography>Efiling Status of Company -</Typography>
                  <Button disabled>{companyData.companyStatus}</Button>
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginY: "50px", marginX: { md: "100px", xs: "20px" }, display: "flex", gap: "20px", flexDirection: "column", textAlign: "center", letterSpacing: "0.5px" }}>
              <StockKnocksGrid
                title='Directors Details:'
                columns={[{ name: 'DIN', title: 'Din', minWidth: "100px" },
                { name: 'NAME', title: 'Director Name' },
                { name: 'DESIGNATION', title: 'Designation' },
                { name: 'DATE_JOIN', title: 'Date of Appointment', formatter: (d) => new Date(d).toLocaleDateString('hi') }]}
                data={companyData.directors}
              />
            </Box>
            <Box sx={{
              marginX: { md: "200px", xs: "30px" }, marginTop: "50px", background: "#F2F2F2", padding: { md: "48px", xs: "20px" }, borderRadius: "6px"
              , "& button": {
                borderRadius: "5px",
                fontWeight: "700",
                height: "30px",
                width: "100px",
                fontSize: "14px",
                marginLeft: "auto",
                marginRight: { md: "50px", xs: "0px" }
              },
              gap: "20px",
              flexDirection: "column",
              display: "flex"
            }}>
              <Typography sx={{ color: "#000000", fontWeight: 700, fontSize: "18px", textAlign: "center" }}>Financial Data</Typography>
              <Box sx={{ flexDirection: { xs: "column", md: "row" } }} display="flex">
                <Box flex={1} display="flex" flexDirection="column" gap="25px" sx={{ padding: "20px", "& span": { fontWeight: 700 } }}>
                  <Box display="flex">
                    <Typography>Networth :</Typography>
                    <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                  </Box>
                  <Box display="flex">
                    <Typography>Total Expense :</Typography>
                    <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                  </Box>
                </Box>
                <Divider orientation="vertical" sx={{ background: "rgba(133, 133, 133, 0.50)", width: "1px", height: "130px", display: { xs: "none", md: "inherit" } }} />
                <Box flex={1} display="flex" flexDirection="column" gap="25px" sx={{ padding: "20px", "& span": { fontWeight: 700 } }}>
                  <Box display="flex">
                    <Typography>Total Income :</Typography>
                    <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                  </Box>
                  <Box display="flex">
                    <Typography>PBT :</Typography>
                    <Button variant="contained" onClick={navigateToLogin}>Unlock</Button>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{
              backgroundSize: "cover",
              backgroundImage: "url(/unlisted-rectangle-icon.png)",
              backgroundRepeat: "no-repeat",
              gap: "20px",
              marginTop: "50px",
              padding: "40px",
              marginX: { md: "100px", xs: "20px" },
              flexDirection: "column",
              display: "flex",
              textAlign: "center",
              letterSpacing: "0.6px",
              "& .heading": {
                fontWeight: 700,
                color: "#000",
                fontSize: "20px"
              },
              "& .sub-text": {
                color: "#575353"
              },
              borderRadius: "30px"
            }}>
              <Typography className="heading">
                Stock Knocks connects Unlisted companies with Investors
              </Typography>
              <Typography className='sub-text'>
                At Stock knocks we connect Unlisted companies with Investors. We are a one-stop portal for Unlisted companies looking for Equity financing. We are different from others as we focus on building trust, credibility, and networking.
              </Typography>
              <Button sx={{ background: "#009262", color: "#FFFFFF", borderRadius: "30px", fontWeight: 700, letterSpacing: "1px", marginLeft: "calc(50% - 70px)", width: "130px" }} onClick={navigateToLogin}>LOGIN</Button>
            </Box>

            <Box sx={{ marginY: "50px", marginX: { md: "100px", xs: "20px" }, display: "flex", gap: "20px", flexDirection: "column", textAlign: "center", letterSpacing: "0.5px" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>FAQ</Typography>
              <Box>
                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left", border: "1px solid #CCC", borderRadius: "10px" }}>
                  {FAQs.map((x, i) => <AccordianFAQ faq={x} isExpanded={i == 0} />)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const AccordianFAQ = (props: { faq: IFAQ, isExpanded }) => {
  const [isExpanded, setExpanded] = useState(props.isExpanded || false);
  return (<Box display="flex" flexDirection={"row"} sx={{ padding: "20px 30px", borderBottom: "1px solid #CCC" }}>
    <Box display="flex" flexDirection="column" gap={"10px"} sx={{ width: "calc(100% - 30px)" }}>
      <Typography sx={{ color: "#009262", fontWeight: 700 }}>{props.faq.q} </Typography>
      {isExpanded && <Typography sx={{ color: "#858585", fontSize: "12px" }}>{props.faq.a}</Typography>}
    </Box>
    <Box sx={{ placeContent: "center", "& img": { cursor: "pointer" } }}>
      <img src={`/${isExpanded ? 'minus' : 'plus'}-square-icon.svg`} onClick={() => {
        setExpanded(!isExpanded);
      }} />
    </Box>
  </Box>);
}

export default UnlistedPreview;
