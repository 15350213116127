import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { HostName } from '../../appConfig/Host';
import { Box, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles"
import { Utility } from '../../utility/Utility';


const useStyles: any = makeStyles((theme) => ({
    menu: {
        "& .MuiMenu-list": {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: "320px",
            height: "26px",
        }
    },
    menuItem: {
        margin: "theme.spacing(0, 2)",
    },
}));


const ITEM_HEIGHT = 48;
const muiIconButtonText = {
    fontWeight: { sm: '400', xs: '400' },
    fontFamily: 'Oxygen',
    fontSize: { sm: '13px', xs: '13px' },
    margin: '0px',
    display: { xs: 'none', sm: 'block', md: 'block', lg: 'block', xl: 'block' }
};
export default function SendMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const storyId = props.storyId;
    const redirectUrl = encodeURI(`${HostName}/community?feedkey=${storyId}`);
    const sendTitle = "Via Stock Knocks \n"
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <img src='/news-send.svg' alt='Send' />
                <Typography sx={muiIconButtonText}>&nbsp;&nbsp;&nbsp;Send</Typography>
            </IconButton>
            <Menu
                id="long-menu"
                className={classes.menu}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 10.5,
                        paddingBottom: '15px',
                        opacity: '100%',
                        borderRadius: "20px",
                        background: "#F9F9F9",
                    },
                }}
            >
                <MenuItem sx={{ marginBottom: "-20px" }}>
                    <WhatsappShareButton
                        url={redirectUrl}
                        title={sendTitle}
                    >
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </MenuItem>
                <MenuItem sx={{ marginBottom: "-20px" }}>
                    <FacebookShareButton
                        url={redirectUrl}
                        quote={sendTitle}
                        hashtag="#stockknocks"
                    >
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                </MenuItem>
                <MenuItem sx={{ marginBottom: "-20px" }}>
                    <LinkedinShareButton
                        url={redirectUrl as string}
                        title={sendTitle}
                        source={"www.stockknocks.com"}
                    >
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                </MenuItem>
                <MenuItem sx={{ marginBottom: "-20px" }}>
                    <TwitterShareButton
                        url={redirectUrl as string}
                        title={sendTitle}
                    >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                </MenuItem>
                <MenuItem sx={{
                    marginBottom: "-20px",
                    "&:hover": {
                        border: "0px",
                    }
                }}>
                    <IconButton
                        sx={{
                            background: "rgba(15, 151, 100, 1)",
                            borderRadius: "20px",
                            marginTop: { xs: -1 },
                            "&:hover": {
                                background: "rgba(15, 151, 100, 1)"
                            }
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(redirectUrl);
                            Utility.notify({ message: "Link copied to your clipboard", type: "success" });
                        }}>
                        <ContentCopyIcon sx={{ color: "#FFF", fontSize: "16px" }} />
                    </IconButton>
                </MenuItem>
            </Menu>
        </Box>
    );
}
