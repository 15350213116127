import React, { useEffect, useState } from 'react'
import { Button, Card, CardContent, CardHeader, CircularProgress, Dialog, IconButton, Paper, Slide, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { CompanyShareholdersInfo } from '../../../../model/CompanyShareholdersResult';
import { TransitionProps } from '@mui/material/transitions';
import { getCompanyShareHoldingDetailsByShareHoldersName } from '../../../../services/CompanyService';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { DateExtensions } from '../../../../utility/DateExtensions';


function InvestorHoldingsDialog(name: string, open: any, onClose: any) {
    const navigate = useNavigate();
    const [data, setData] = useState<CompanyShareholdersInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const useStyles = makeStyles((theme: any) => ({
        root: {
            width: "100%",
            marginTop: theme.spacing.unit * 3,
            overflowX: "auto"
        },
        table: {
            minWidth: 500,
            maxHeight: '60%',
            height: '100px',
            overflowY: 'auto' 
        },
        sticky: {
            position: "sticky",
            left: 0,
            background: "white",
            boxShadow: "5px 2px 5px grey",
            borderRight: "none",
            width: "300px",
            backgroundColor: "#F9F9F9"
        },
        cardHeaderRoot: {
            backgroundColor: "#fff",
            paddingLeft: "30px",
            paddingRight: "10px",
            paddingTop: "0px",
            paddingBottom: "0px",
            margin: "5px",
            height: "80px",
            verticalAlign: "middle"
        },
        cardHeaderAction: {
            margin: "0px",
            paddingRight: "10px",
            verticalAlign: "middle",
            height: "100%",
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center"
        },
        cardHeaderTitleRoot: {
            textAlign: 'left',
            verticalAlign: "middle",
            height: "100%"
        },
        cardHeaderSubTitleRoot: {
            textAlign: 'left'
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        root: {
            textAlign: "right",
            width: "100px"
        }
    }))(TableCell);

    useEffect(() => {
        async function fetchData() {
            try {
                if (open) {
                    setLoading(true);
                    const response = await getCompanyShareHoldingDetailsByShareHoldersName(name);
                    setData(response);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [name, open]);

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                scroll='body'
            >
                <Card elevation={0} sx={{ paddingY: '0px', marginX: '0px', padding: '5px', backgroundColor: '#fff', border: 'none' }}>
                    <CardHeader
                        sx={{
                            marginX: { xl: "50px", lg: "50px", md: "20px", sm: "20px", xs: "20px" },
                            textAlign: 'center', paddingLeft: '64px',
                            justifyContent: 'space-between'
                        }}
                        title={name}
                        subheader="Share Holdings"
                        action={
                            <IconButton aria-label="close" onClick={onClose} color='primary' >
                                <CloseSharpIcon />
                            </IconButton>
                        }
                        classes={{
                            root: classes.cardHeaderRoot,
                            action: classes.cardHeaderAction
                        }}
                        titleTypographyProps={{
                            classes: {
                                root: classes.cardHeaderTitleRoot
                            }
                        }}
                        subheaderTypographyProps={{
                            classes: {
                                root: classes.cardHeaderSubTitleRoot
                            }
                        }}
                    >
                    </CardHeader>
                    <CardContent sx={{ paddingY: '0px', paddingX: '5px', paddingBottom: '0px !important' }}>
                        <Paper className={classes.root}>
                            <div style={{
                                height: '300px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: loading ? "flex" : "none"
                            }}>
                                <CircularProgress color="primary" />
                            </div>
                            <div style={{ display: loading ? "none" : "flex" }}>
                                <Table
                                    className={classes.table}
                                    size="small"
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "#EBEBEB" }}>
                                            <TableCell className={classes.sticky} sx={{ color: '#0f9764' }}>
                                                Company Name
                                            </TableCell>
                                            <StyledTableCell sx={{ color: '#0f9764' }}>NO. OF SHARES</StyledTableCell>
                                            <StyledTableCell sx={{ color: '#0f9764' }}>STAKE (%)</StyledTableCell>
                                            <StyledTableCell sx={{ color: '#0f9764' }}>Date</StyledTableCell>
                                            <StyledTableCell sx={{ color: '#0f9764' }}>Type</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <TableRow key={row.co_code}>
                                                <TableCell
                                                    className={classes.sticky}
                                                    component="th"
                                                    scope="row"
                                                    sx={{ backgroundColor: "#F9F9F9", color: '#0f9764' }}
                                                >
                                                    <Button onClick={() => { onClose(); navigate(`/company/${row.co_code}/companyname/${row.co_name.replaceAll(" ","-")}`); }}>{row.co_name}</Button>

                                                </TableCell>
                                                <StyledTableCell>{row.NOOFshares}</StyledTableCell>
                                                <StyledTableCell>{row.perstake}</StyledTableCell>
                                                <StyledTableCell>{DateExtensions.getDateStringUppercaseMonth(new Date(row.date), " ")}</StyledTableCell>
                                                <StyledTableCell>{row.Type}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </CardContent>
                </Card>
                </Dialog>
        </React.Fragment>
    );
}

export default InvestorHoldingsDialog

