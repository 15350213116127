import React, { useEffect } from "react"
import { Avatar, Box, Button, Grid, InputAdornment, OutlinedInput, Typography } from "@mui/material"
import Paper from '@mui/material/Paper';
import CardMenu from "./CardMenu";
import ReactReadMoreReadLess from "../ReadMoreReadLess";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import { Link } from "react-router-dom";
import { CommentEditor } from "./CommentEditor";
import { CommentViewer } from "./CommentViewer";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseJSON from "date-fns/parseJSON";
import { getNavigationLink } from "./UserNav";

export default function Comments(props) {
    const { commentPage, commentData, postId, postType, addComment, editComment, deleteComment, setCommentPage, recordIndex, currentUserId } = props
    const [comment, setComment] = React.useState('')
    const [editCommentId, setEditCommentId] = React.useState('')
    const [editedComment, setEditedComment] = React.useState('')
    const [editCommentIndex, setEditCommentIndex] = React.useState('')
    const currentUser = useSelector((state: ReduxStore) => state.userData.user);

    // on Add Comment Button Click
    function onCommentPost() {
        addComment(comment, postId, postType, recordIndex)
        setComment('')
    }

    // On Edit Delete Menu Click
    function onActionPerform(actionType, commentId, index) {
        if (actionType === "Delete") {
            deleteComment(commentId, index, recordIndex)
        } else {
            setEditCommentId(commentId);
            setEditCommentIndex(index);
            setEditedComment(commentData[index].content);
        }
    }

    // Om Update Comment
    function onUpdate() {
        editComment(editCommentId, editedComment, editCommentIndex)
        setEditCommentId('')
        setEditedComment('')
        setEditCommentIndex('')
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "13px", marginTop:"10px" }}>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: "14px",
                    width: "100%",
                    justifyItems: 'center',
                    alignItems: 'center',
                }}>

                {
                    commentData?.map((item, key) => {
                        return (
                            <div key={key} style={{ height: 'auto', width: "100%", display: 'flex', flexDirection: "row", gap: "10px" }}>
                                {editCommentId === item?.id ?
                                    <Box style={{ width: "100%", flexDirection: "column", gap: "10px" }}>
                                        <CommentEditor
                                            onBlur={(txt) => setEditedComment(txt)}
                                            userImg={item.created_by.profilepic || ""}
                                            content={item?.content || ""}
                                        />
                                        <Box style={{ marginTop: "10px", display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button sx={{ px: "25px" }} onClick={() => onUpdate()} variant="contained">Update  </Button>
                                        </Box>
                                    </Box>

                                    :
                                    <Box display={"flex"} flexDirection={"row"} columnGap={"5px"} sx={{ width: "100%" }}>
                                        <Avatar
                                            alt="User"
                                            src={item.created_by.profilepic}
                                            sx={{ width: "40px", height: "40px" }}
                                        />
                                        <Paper key={key} elevation={1} sx={{
                                            justifyContent: "space-between", padding: "15px", paddingTop: "0px", display: 'flex', flexDirection: "column", width: "100%",
                                            borderBottom: key == commentData.length - 1 ? "none" : "1px solid #E7E8EC",
                                            boxShadow: "none"
                                        }} >
                                            <Box sx={{ display: 'flex', justifyContent: "space-between", flexDirection: "row", '& button': { padding: "1px" } }}>
                                                <Typography color="primary"
                                                    sx={{ fontSize: '15px', fontWeight: '700', textDecorationLine: 'none' }}
                                                    component={Link}
                                                    // to={"/viewuser?userid=" + item?.created_by?.id}
                                                    to={getNavigationLink(item?.created_by)}
                                                >
                                                    {item?.created_by?.name}
                                                </Typography>
                                                {item?.created_by?.id === currentUserId ? <CardMenu index={key} onActionPerform={onActionPerform} postId={item?.id} /> : null}
                                            </Box>
                                            <CommentViewer rawContent={item?.content} />
                                            <Typography sx={{ color: "#939393", fontSize: "13px", marginTop: "3px" }}>{formatDistanceToNow(parseJSON(item?.updatedAt))} ago</Typography>
                                        </Paper>
                                    </Box>
                                }
                            </div >
                        )
                    })
                }
                {
                    commentData?.length >= 10 ?
                        <div style={{ paddingTop: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <button onClick={() => setCommentPage(commentPage + 1)}>Load More</button>
                        </div> : null
                }
            </Grid >
            <Box>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: "14px",
                        justifyItems: 'center',
                        alignItems: 'center',
                        height: "60%",
                    }}>
                    <CommentEditor key={comment ? "txtNonEmpty" : "txtEmpty"}
                        onBlur={(txt) => setComment(txt)}
                        userImg={currentUser?.profilepic || ""}
                        content={comment}
                    />
                </Grid>
                <Box sx={{ cursor: "pointer", display: "flex", justifyContent: "end", marginTop: "10px" }}>
                    <Button sx={{ px: "25px" }} onClick={() => onCommentPost()} variant="contained">Post</Button>
                </Box>
            </Box>
        </div >
    )
}