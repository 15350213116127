import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { getCompanyPeerData } from '../../../services/CompanyService';
import { CompanyPeerDataResponse } from '../../../model/CompanyPeerDataModel';
import { makeStyles, withStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { CopyLinkButton } from '../../../components/Common/CopyLinkButton';


function PeerComparison(co_code: string | number, displayType: string, active: boolean) {

    const [alignment, setAlignment] = React.useState<'S' | 'C'>('S');
    const [data, setData] = useState<CompanyPeerDataResponse>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const useStyles = makeStyles((theme: any) => ({
        root: {
            width: "100%",
            marginTop: theme.spacing.unit * 3,
            overflowX: "auto"
        },
        table: {
            minWidth: 500
        },
        sticky: {
            position: "sticky",
            left: 0,
            background: "white",
            boxShadow: "5px 2px 5px grey",
            borderRight: "none",
            width: "300px",
            backgroundColor: "#F9F9F9"
        },
        cardHeaderRoot: {
            backgroundColor: "#F9F9F9",
            paddingLeft: "30px",
            paddingRight: "10px",
            paddingTop: "0px",
            paddingBottom: "0px",
            margin: "5px",
            height: "80px",
            verticalAlign: "middle"
        },
        cardHeaderAction: {
            margin: "0px",
            paddingRight: "10px",
            verticalAlign: "middle",
            height: "100%",
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center"
        },
        cardHeaderTitleRoot: {
            textAlign: 'left',
            verticalAlign: "middle",
            height: "100%"
        },
        cardHeaderSubTitleRoot: {
            textAlign: 'center'
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        root: {
            textAlign: "left",
            width: "100px"
        }
    }))(TableCell);

    const handleTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'S' | 'C',
    ) => {
        if (!(alignment === newAlignment) && (newAlignment === 'S' || newAlignment === 'C')) {
            setAlignment(newAlignment);
            setLoading(true);
        }
    };

    const toggleButton = () => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                justifyItems: "center",
                alignItems: "center",
                margin: 0,
                lineHeight: "30px",
                paddingTop: "20px",
                paddingBottom: "25px"
            }
            }>
                <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    size='small'
                    onChange={handleTypeChange}
                    sx={{ height: "39px", maxWidth: "250px", marginRight: 2, '& button': { fontFamily: 'Oxygen' } }}
                >
                    <ToggleButton value="S"
                        sx={{
                            borderRadius: "20px",
                            color: alignment === 'S' ? '#FFFFFF !important' : '#0F9764 !important',
                            backgroundColor: alignment === 'S' ? '#0F9764 !important' : '#FFFFFF !important',
                            paddingLeft: "15px",
                            paddingRight: "10px",
                            textTransform: "none"
                        }} >Standalone</ToggleButton>
                    <ToggleButton value="C"
                        sx={{
                            borderRadius: "20px",
                            color: alignment === 'C' ? '#FFFFFF !important' : '#0F9764 !important',
                            backgroundColor: alignment === 'C' ? '#0F9764 !important' : '#FFFFFF !important',
                            paddingLeft: "10px",
                            paddingRight: "15px",
                            textTransform: "none"
                        }} >Consolidated</ToggleButton>
                </ToggleButtonGroup>
                <CopyLinkButton locationHash='tabdetail-peercomparison'/>
            </div>)
    };

    useEffect(() => {
        async function fetchData() {
            try {
                if (active) {
                    const response = await getCompanyPeerData(co_code, alignment, 10);
                    setData(response);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [active, alignment, co_code]);

    return (
        <React.Fragment>
            <Box>
                <Card elevation={0} sx={{ paddingY: '0px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '12px' }}>
                    <CardHeader
                        sx={{

                            textAlign: 'center', paddingLeft: '64px',
                            justifyContent: 'space-between'
                        }}
                        action={toggleButton()}
                        classes={{
                            root: classes.cardHeaderRoot,
                            action: classes.cardHeaderAction
                        }}
                        titleTypographyProps={{
                            classes: {
                                root: classes.cardHeaderTitleRoot
                            }
                        }}
                        subheaderTypographyProps={{
                            classes: {
                                root: classes.cardHeaderSubTitleRoot
                            }
                        }}
                    >
                    </CardHeader>
                    <CardContent sx={{ padding: '0px!important' }}>
                        <Paper className={classes.root}>
                            <div style={{
                                height: '300px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: loading ? "flex" : "none"
                            }}>
                                <CircularProgress color="primary" />
                            </div>
                            <div style={{ display: loading ? "none" : "flex" }}>
                                <Table
                                    className={classes.table}
                                    size="small"
                                    aria-label="simple table"
                                    sx={{
                                        '& thead th': {
                                            background: '#ffffff',
                                            color: '#686868',
                                            fontWeight: 700
                                        },
                                        '& tbody tr': {
                                            background: '#F9F9F9'
                                        },
                                        '& tbody th': {
                                            color: '#686868'
                                        },
                                        '& tbody td': {
                                            color: '#0D0D0D',
                                            fontWeight: 700
                                        }
                                    }}
                                >
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "#EBEBEB" }}>
                                            <TableCell className={classes.sticky}>
                                                Company Name
                                            </TableCell>
                                            <StyledTableCell>Market Capital</StyledTableCell>
                                            <StyledTableCell>Sales</StyledTableCell>
                                            <StyledTableCell>Net Profit</StyledTableCell>
                                            <StyledTableCell>PAT</StyledTableCell>
                                            <StyledTableCell>ROCE</StyledTableCell>
                                            <StyledTableCell>DE Ratio</StyledTableCell>
                                            <StyledTableCell>PAT Change(%)</StyledTableCell>
                                            <StyledTableCell>ROE</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <TableRow key={row.ISIN}>
                                                <TableCell
                                                    className={classes.sticky}
                                                    component="th"
                                                    scope="row"
                                                    sx={{ backgroundColor: "#F9F9F9" }}
                                                >
                                                    <Button sx={{
                                                        color: '#0F9764',
                                                        "&.Mui-disabled": {
                                                            color: '#0F9764',
                                                            paddingLeft: '2px', paddingRight: '2px',
                                                            minWidth: '0px',
                                                        },
                                                        "&:hover": {
                                                            backgroundColor: "transparent"
                                                        },
                                                    }}
                                                        disableRipple={true}
                                                        disableFocusRipple={true}
                                                        onClick={() => { navigate(`/company/${row.co_code}/companyname/${row.CompanyName.replaceAll(" ","-")}`); }}>
                                                        {row.CompanyName}
                                                    </Button>

                                                </TableCell>
                                                <StyledTableCell>{row.MarketCap}</StyledTableCell>
                                                <StyledTableCell>{row.Sales}</StyledTableCell>
                                                <StyledTableCell>{row.NetProfit}</StyledTableCell>
                                                <StyledTableCell>{row.ratio_PAT}</StyledTableCell>
                                                <StyledTableCell>{row.ratio_ROCE}</StyledTableCell>
                                                <StyledTableCell>{row.ratio_DE_Ratio}</StyledTableCell>
                                                <StyledTableCell>{row.ratio_PAT_PerChange}</StyledTableCell>
                                                <StyledTableCell>{row.ratio_ROE}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    );
}

export default PeerComparison

