declare var appConfig;

const HostName: string = appConfig.hostName;
const DefaultNewsImageFileStorePath: string = appConfig.newsImageStore;
const SociaMediaServiceURL: string = appConfig.socialMediaService;
const UserServiceURL: string = appConfig.userService;
const CompanyServiceURL: string = appConfig.companyService;
const CommunicationServiceURL: string = appConfig.communicationService;
const AdvertisementServiceURL: string = appConfig.advertisementService;
const DashboardServiceURL: string = appConfig.dashboardService;
const DashboardSiteURL: string = appConfig.dashboardSiteURL;
const PaymentServiceURL: string = appConfig.paymentService;
const CloudFunctionService: string = appConfig.cloudFunctionService;
const WebbuilderServiceUrl = appConfig.webbuilderService;
const Google_Client_id: string = appConfig.googleClientId;
const AssistantBotServiceUrl: string = appConfig.assistantBotService;
const companyDataServiceUrl: string = appConfig.companyDataService;


export {
    HostName,
    DefaultNewsImageFileStorePath,
    SociaMediaServiceURL,
    UserServiceURL,
    CompanyServiceURL,
    CloudFunctionService,
    CommunicationServiceURL,
    AdvertisementServiceURL,
    PaymentServiceURL,
    DashboardServiceURL,
    DashboardSiteURL,
    Google_Client_id,
    WebbuilderServiceUrl,
    AssistantBotServiceUrl,
    companyDataServiceUrl
};