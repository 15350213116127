import { Box, Button, Card, CardMedia, Typography } from '@mui/material';
import React from 'react';

const PlansTestimonials = () => {
  const reqNowClick = () => {
    window.open('https://calendly.com/vishnu-stockknocks/stock-knocks-demo');
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
          marginTop: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: '#484848',
                fontSize: { md: '48px', xs: '32px' },
                fontWeight: '600',
                lineHeight: '28px',
              }}
            >
              What Our{' '}
              <span
                style={{
                  color: '#0F9764',
                }}
              >
                {' '}
                Clients{' '}
              </span>
              Say
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '-20px',
            }}
          >
            <Typography
              sx={{
                color: '#484848',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.16px',
                textAlign: 'center',
                margin: '10px',
              }}
            >
              "<span style={{ color: '#0F9764' }}>Stock Knocks</span> helped us
              connect with investors in a more engaging way, leading to
              increased <span style={{ color: '#0F9764' }}>interest</span> and{' '}
              <span style={{ color: '#0F9764' }}>investments</span> in our
              company."
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '-20px',
              width: { md: '60%', xs: '100%' },
              height: { md: '412px', xs: '169px' },
            }}
          >
            <Card
              sx={{
                borderRadius: '15px',
                width: '100%',
                height: '100%',
              }}
            >
              <CardMedia
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                component="iframe"
                src="https://www.youtube.com/embed/JUbsYO0rSzU?si=xd4gK7KCWFA5Kjy1"
              />
            </Card>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { md: 'row', xs: 'column' },
              marginTop: '-10px',
              backgroundColor: '#0F9764',
              borderRadius: '8px',
              width: { md: '80%', xs: '100%' },
              height: 'auto',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingY: '10px',
                paddingX: '20px',
              }}
            >
              <Typography
                sx={{
                  color: '#FFF',
                  fontSize: '20px',
                  fontWeight: '600',
                  lineHeight: '28px',
                }}
              >
                Request a Demo
              </Typography>
              <Typography
                sx={{
                  color: '#FFF',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                }}
              >
                Get immediate assistance and see what Stock Knocks can do for
                you.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                paddingY: '10px',
                paddingX: '20px',
              }}
            >
              <Button
                onClick={reqNowClick}
                variant="contained"
                style={{
                  borderRadius: '8px',
                  color: '#0F9764',
                  background: '#FFF',
                }}
              >
                Request Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PlansTestimonials;
