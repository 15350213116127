import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { makeStyles } from '@mui/styles';
import { getCinStatus, getDinStatus } from '../../services/CompanyService';
import { GridColDef } from '@mui/x-data-grid';
import { DateExtensions } from '../../utility/DateExtensions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DinDataDialog = (props: {
  din: string;
  name: string;
  isOpen: any;
  onClose: any;
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState([] as any);

  const navigate = useNavigate();

  const handleCinData = async (cin: string) => {
    if (cin?.trim().length > 0) {
      let response = await getCinStatus(cin);
      if (
        response &&
        response.CIN?.trim()?.toUpperCase() === cin?.trim()?.toUpperCase()
      ) {
        navigate(`/unlistedcompany?code=${response?._id}`);
      }
    }
  };

  // Data Column
  const column: GridColDef[] = [
    {
      field: 'company_name',
      headerName: 'Company Name',
      minWidth: 199,
      flex: 1,
      renderCell: (params: any) => {
        let coName = params.row.company_name;
        return (
          <IconButton
            onClick={() => {
              handleCinData(params.row.cin);
            }}
            color="primary"
          >
            {coName}
          </IconButton>
        );
      },
    },
    { field: 'designation', headerName: 'Designation', minWidth: 200 },
    {
      field: 'appointment_date',
      headerName: 'Appointment Date',
      width: 150,
    },
    { field: 'age', headerName: 'Age', width: 80 },
  ];

  //  Custom Style
  const useStyles = makeStyles({
    dataGrid: {
      width: 'auto',
    },
  });
  const classes = useStyles();

  React.useEffect(() => {
    async function fetchData() {
      try {
        if (props.isOpen && props.din?.length > 0 && props.name?.length > 0) {
          setLoading(true);
          let response = await getDinStatus(props.din);
          if (response && Array.isArray(response) && response.length > 0) {
            setRows(
              response.map((x) => ({
                company_name: x?.company_name,
                designation: x?.designation,
                appointment_date: DateExtensions.getDateStringUppercaseMonth(
                  new Date(x?.appointment_date),
                  ' '
                ),
                age: x?.age,
                cin: x?.company_cin,
              }))
            );
          } else {
            setRows([]);
          }
          setLoading(false);
        } else {
          setRows([]);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchData();
  }, [props.din, props.name, props.isOpen]);

  return (
    <React.Fragment>
      <Dialog
        id="din-dialog-root"
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-describedby="din-dialog-handle"
        PaperProps={{
          sx: {
            width: {
              xs: '370px',
              sm: '370px',
              md: '570px',
              lg: '820px',
              xl: '820px',
            },
            maxWidth: '900px !important',
          },
        }}
      >
        <DialogTitle>
          {
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {props.name}
                </Typography>
                <Typography color="primary" variant="subtitle1" gutterBottom>
                  {props.din}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close"
                  onClick={props.onClose}
                  color="primary"
                  disableRipple={true}
                >
                  <CloseSharpIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        </DialogTitle>
        <DialogContent
          sx={{
            height: 'auto',
            width: {
              xs: '350px',
              sm: '350px',
              md: '550px',
              lg: '800px',
              xl: '800px',
            },
            paddingX: '10px',
          }}
        >
          <DataGridPro
            autoHeight={true}
            columns={column}
            rows={rows}
            pageSize={10}
            className={classes.dataGrid}
            getRowId={(row: any) => `${row.dinNumber}_${row.appointment_date}`}
            loading={loading}
            paginationMode="server"
            disableColumnSelector={true}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DinDataDialog;
