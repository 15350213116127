import { Box, Link } from "@mui/material";
import { SellerBanner } from "./SellerBanner";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { SellerAbout } from "./SellerAbout";
import { SellerContactus } from "./SellerContactUs";
import { SellerCategory } from "./SellerCategory";
import { SellerProductList } from "./SellerProductList";
import { SellerProduct } from "./SellerProduct";

export function SellerHome() {
    const navigate = useNavigate();
    const params = useParams();
    const { sellerCode } = params;
    const location = useLocation();

    const isPathMatch = (path: "home" | "category") => {
        if (path == "category") {
            return location.pathname.indexOf("/category") >= 0;
        } else if (path == "home") {
            return !!location.pathname.match(/marketplace\/seller\/[a-z0-9]+$/)
        }
        return false;
    }


    return <Box sx={{ padding: { md: "10px 100px", xs: "0px" } }}>
        {location.pathname.indexOf('/product/') == -1 && <SellerBanner />}
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, paddingLeft: { xs: "10px", md: "0px" } }}>
            {/* <Box><Typography sx={{ fontSize: { md: "46px", xs: "30px" }, color: "#103559", fontWeight: 900 }}>{sellerDetails?.name}</Typography></Box> */}
            <Box sx={{
                color: "#003459",
                marginTop: "15px",
                "& a": {
                    color: "#003459",
                    fontSize: "16px",
                    fontWeight: 700,
                    marginLeft: { md: "0px", xs: "0px" },
                    marginRight: { md: "40px", xs: "10px" },
                    lineHeight: "30px",
                    fontFamily: "Oxygen",
                    textDecoration: "none",
                    cursor: "pointer",
                    padding: "0px 10px",
                    letterSpacing: "1px"
                },
                marginLeft: "calc(50% - 100px)"
            }}>
                <Link sx={{ borderBottom: isPathMatch("home") ? "5px solid #0F9764" : "none" }} onClick={() => navigate("")} >Home</Link>
                <Link sx={{ borderBottom: isPathMatch("category") ? "5px solid #0F9764" : "none" }} onClick={() => navigate("category")} >Category</Link>
                {/* <Link onClick={() => navigate("about")} >About</Link>
                <Link onClick={() => navigate("contact")} >Contact</Link> */}
            </Box>
        </Box>
        <Box>
            <Routes>
                <Route path="/" element={<SellerProductList />} />
                <Route path="/category/:categoryid/products" element={<SellerProductList />} />
                <Route path="/about" element={<SellerAbout />} />
                <Route path="/category" element={<SellerCategory />} />
                <Route path="/contact" element={<SellerContactus />} />
                <Route path="/product/:productId" element={<SellerProduct />} />
            </Routes>
        </Box>
    </Box>
}