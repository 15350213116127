import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import ReactReadMoreReadLess from '../../components/ReadMoreReadLess';

function GetUserAbout(user_view: any) {
    return (
        <React.Fragment>
            <Box sx={{ padding: '24px', margin: "24px 0px", background: "#F9F9F9", border: "1px solid #EBEBEB", borderRadius: "8px" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontSize: "16px", color: "#0D0D0D" }}>
                    About me
                </Typography>
                <Typography variant="body2" sx={{ color: '#686868', fontSize: "14px" }}>
                    <ReactReadMoreReadLess
                        charLimit={300}
                        readIconColor={"#ed6c02"}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                    >
                        {user_view?.about || 'Not disclosed'}
                    </ReactReadMoreReadLess>
                </Typography>
            </Box>
            <Box sx={{ padding: '24px', margin: "24px 0px", background: "#F9F9F9", border: "1px solid #EBEBEB", borderRadius: "8px" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontSize: "16px", color: "#0D0D0D" }}>
                    Professional
                </Typography>
                {getContent(user_view)}
            </Box>

            {/* <Box sx={{ padding: '5px 15px' }}>
                            <Button
                                variant="outlined"
                                href={companyShort?.Website || ''}
                                target="_blank" rel="noopener noreferrer">
                                VISIT WEBSITE
                                <OpenInNewIcon sx={{ paddingX: '2px' }} fontSize="small" />
                            </Button>
                        </Box> */}
        </ React.Fragment>
    );
}

const getContent = (user_view: any) => {
    // todo data should be loaded dynamically based on sub tab: one_day, one_week e.t.c
    return <>
        <table style={{ color: '#686868', fontSize: "14px" }}>
            <tr>
                <td width="150px"><Typography sx={{ fontSize: "14px" }}>Designation</Typography></td>
                <td><Typography sx={{ fontSize: "14px" }}>{user_view?.designation}</Typography></td>
            </tr>
            <tr>
                <td><Typography sx={{ fontSize: "14px" }}>Working at</Typography></td>
                <td><Typography sx={{ fontSize: "14px" }}>{user_view?.company}</Typography></td>
            </tr>
            <tr>
                <td><Typography sx={{ fontSize: "14px" }}>Skills</Typography></td>
                <td><Typography sx={{ fontSize: "14px" }}> {user_view?.skills}</Typography></td>
            </tr>
            <tr>
                <td><Typography sx={{ fontSize: "14px" }}> Professional history</Typography></td>
                <td><Typography sx={{ fontSize: "14px" }}>{user_view?.work}</Typography></td>
            </tr>
        </table>
    </>;
}

export default GetUserAbout;