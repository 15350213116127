import { NumberExtensions } from "./NumberExtensions";

const monthNames = ["jan", "feb", "mar", "apr", "may", "jun",
  "jul", "aug", "sep", "oct", "nov", "dec",
];

const monthNamesWithCase = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
];

export class DateExtensions {
  // 27-oct-2023
  static getDateString(date: Date): string {
    var day = ("0" + date.getDate()).slice(-2);
    var month: number = Number(("0" + (date.getMonth() + 1)).slice(-2));
    var year = date.getFullYear();

    const dateString = day + "-" + monthNames[month - 1] + "-" + year;
    return dateString;
  };

  // 2022-11-31
  static getShortDateString(date: Date): string {
    var day = ("0" + date.getDate()).slice(-2);
    var month =("0" + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }

  // 27 Oct
  static getDateOnlyString(date: Date): string {
    var day = ("0" + date.getDate()).slice(-2);
    var month: number = Number(("0" + (date.getMonth() + 1)).slice(-2));

    const dateString = day + " " + monthNamesWithCase[month - 1];
    return dateString;
  };

  // 2022-03-31T00:00:00 => 31 Mar 2022
  static getDateStringUppercaseMonth(date: Date, seperator: string): string {
    var valSeperator = " ";
    if (seperator && seperator.length > 0) {
      valSeperator = seperator;
    }

    var day = ("0" + date.getDate()).slice(-2);
    var month: number = Number(("0" + (date.getMonth() + 1)).slice(-2));
    var year = date.getFullYear();

    const dateString = day + valSeperator + monthNamesWithCase[month - 1] + valSeperator + year;
    return dateString;
  };
  // 2022-03-31T00:00:00 => 31 Mar 2022
  static getDateStringUppercaseMonthFromString(dateStringInput: string, seperator: string, defaultString?: string): string {
    try {
      let date = new Date(dateStringInput);
      var valSeperator = " ";
      if (seperator && seperator.length > 0) {
        valSeperator = seperator;
      }

      var day = ("0" + date.getDate()).slice(-2);
      var month: number = Number(("0" + (date.getMonth() + 1)).slice(-2));
      var year = date.getFullYear();

      const dateString = day + valSeperator + monthNamesWithCase[month - 1] + valSeperator + year;
      return dateString;
    }
    catch (error) {
      console.error(error);
      if (defaultString && defaultString.length > 0) {
        return defaultString;
      }
      else {
        return '---';
      }
    }
  };

  static addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  static getDateStringForHeader(date: string): string {
    var month: number = Number(date.slice(-2));
    var year = date.substring(1, 5);

    const dateString = monthNamesWithCase[month - 1] + " " + year;
    return dateString;
  };

  // 202203 => Mar 2022
  static getDateStringForHeaderFormatB(date: string): string {
    var month: number = Number(date.slice(-2));
    var year = date.substring(0, 4);

    const dateString = monthNamesWithCase[month - 1] + " " + year;
    return dateString;
  };

  // 27\10\2023 11:59 pm
  static formatDateTimeForChannelChat(date: Date) {
    const stringifyDate = DateExtensions.stringifyDate;
    const stringifyTime = DateExtensions.stringifyTime;
    const today = new Date();

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return stringifyDate(date).replace(stringifyDate(today), "Today").replace(stringifyDate(yesterday), "Yesterday") + " " + stringifyTime(date);
  }
  
  static formatDateTimeForChannelList(date){
    const stringifyDate = DateExtensions.stringifyDate;
    const stringifyTime = DateExtensions.stringifyTime;
    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (today.toDateString() === date.toDateString()) {
           return stringifyDate(date).replace(stringifyDate(today), "")+ " " + stringifyTime(date);
    } else {
          return stringifyDate(date).replace(stringifyDate(yesterday),"Yesterday");
    }
  }


  // 27\10\2023
  static stringifyDate(date: Date) {
    const dateFormat = new Date(date);
    const day = `00${dateFormat.getDate()}`.slice(-2);
    const month = `00${dateFormat.getMonth() + 1}`.slice(-2);
    const year = `${dateFormat.getFullYear()}`;
    return `${day}\\${month}\\${year}`
  }

  // 27\10\2023
  static stringifyDateYYYYMMDD(date: Date, seperator: string) {
    var valSeperator = " ";
    if (seperator && seperator.length > 0) {
      valSeperator = seperator;
    }
    const dateFormat = new Date(date);
    const day = `00${dateFormat.getDate()}`.slice(-2);
    const month = `00${dateFormat.getMonth() + 1}`.slice(-2);
    const year = `${dateFormat.getFullYear()}`;
    return `${year}${valSeperator}${month}${valSeperator}${day}`
  }

  // 11:59 pm
  static stringifyTime(date: Date) {
     const dateFormat = new Date(date);
    let hours = dateFormat.getHours();
    let minutes = dateFormat.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    var strTime = hours + ':' + `0${minutes}`.slice(-2) + ' ' + ampm;
    return strTime;
  }

  // Handle epoch time 
  static getEpochDateStringUppercaseMonth(dateString: string, seperator: string) {
    try {
      if (!NumberExtensions.isNumber(dateString)) {
        
        return DateExtensions.getDateStringUppercaseMonth(new Date(dateString), seperator);
      }
      else {
        let epoch = Number(dateString);
        let utc_days = Math.floor(epoch - 25569);
        let utc_value = utc_days * 86400;
        let date_info = new Date(utc_value * 1000);

        let fractional_day = epoch - Math.floor(epoch) + 0.0000001;

        let total_seconds = Math.floor(86400 * fractional_day);

        let seconds = total_seconds % 60;

        total_seconds -= seconds;

        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;

        let dor = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
        return DateExtensions.getDateStringUppercaseMonth(dor, seperator);
      }
    }
    catch (err) {
      console.error(err);
      return "---";
    }
  }
}