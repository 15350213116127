import { Box, Button, ButtonProps, darken, lighten, styled, Tooltip, Typography } from "@mui/material";
import { gridFilteredDescendantCountLookupSelector, GridRenderCellParams, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { isNavigationKey } from "@mui/x-data-grid/internals";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    // border: 0,
    // color:
    //     theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    // fontFamily: [
    //     '-apple-system',
    //     'BlinkMacSystemFont',
    //     '"Segoe UI"',
    //     'Roboto',
    //     '"Helvetica Neue"',
    //     'Arial',
    //     'sans-serif',
    //     '"Apple Color Emoji"',
    //     '"Segoe UI Emoji"',
    //     '"Segoe UI Symbol"',
    // ].join(','),
    // WebkitFontSmoothing: 'auto',
    // letterSpacing: 'normal',
    // '& .MuiDataGrid-columnsContainer': {
    //     backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    // },
    // '& .MuiDataGrid-iconSeparator': {
    //     display: 'none',
    // },
    // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,

    // },
    // '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    //     borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
    // '& .MuiDataGrid-cell': {
    //     color:
    //         theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    // },
    // '& .MuiPaginationItem-root': {
    //     borderRadius: 0,
    // },
    // '& .highlight-row-theme': {
    //     bgcolor: (theme) =>
    //         getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    //     '&:hover': {
    //         bgcolor: (theme) =>
    //             getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    //     },
    //     fontWeight: '600',
    // },
    // '& .MuiDataGrid-columnHeaderTitle': {
    //     bgcolor: (theme) => getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    //     fontWeight: '700',
    //   },
    '& .highlight-row-theme': {
        // bgcolor: (theme) =>
        //     getBackgroundColor(theme.palette.info.main, theme.palette.mode),
        // '&:hover': {
        //     bgcolor: (theme) =>
        //         getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
        // },
        fontWeight: '600',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '700',
        fontFamily:'Oxygen'
    },
    '& .MuiDataGrid-columnHeaders': {
        // bgcolor: (theme) => getDarkBackgroundColor(theme.palette.info.main, theme.palette.mode),
        backgroundColor: '#f4f4f4',
        fontFamily:'Oxygen'
    },
}));

const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleKeyDown: ButtonProps['onKeyDown'] = (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
        if (isNavigationKey(event.key) && !event.shiftKey) {
            apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
        }
    };

    const handleClick: ButtonProps['onClick'] = (event) => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <Box sx={{
            ml: rowNode.depth * 2,
            minWidth: '0px',
            width: '100%'
        }}>
            <div style={{ width: '100%' }}>
                {filteredDescendantCount > 0 ? (
                    apiRef.current.getRow(id)?.type?.toUpperCase() === "SECTION" ?
                        (<Tooltip title={apiRef.current.getRow(id).name || " "}><Button
                            sx={{
                                width: '100%', ":focus": 'outline: none', "::selection": 'outline: none',
                                minWidth: '0px', paddingX: '0px',
                                fontWeight: '600', color: '#1B1C28',
                                letterSpacing: '0.14px',
                                lineHeight: '20px',
                                fontSize: '14px',
                                textTransform: 'none',
                                justifyContent: 'flex-start'
                            }}
                            fullWidth={true}
                            onClick={handleClick}
                            onKeyDown={handleKeyDown}
                            tabIndex={-1}
                            size="small"
                        >
                            {apiRef.current.getRow(id).name}
                            &nbsp;
                            {rowNode.childrenExpanded ?
                                <ExpandLessIcon fontSize="small" sx={{ color: '#0F9764' }} />
                                :
                                <ExpandMoreIcon fontSize="small" sx={{ color: '#0F9764' }} />}
                        </Button></Tooltip>)
                        :
                        (<Tooltip title={apiRef.current.getRow(id).name || " "}><Button
                            sx={{
                                width: '100%', ":focus": 'outline: none', "::selection": 'outline: none',
                                minWidth: '0px', paddingX: '0px',
                                fontWeight: 'bold', color: '#1B1C28',
                                letterSpacing: '0.14px',
                                fontFamily:'Oxygen',
                                lineHeight: '20px',
                                fontSize: '14px',
                                textTransform: 'none',
                                justifyContent: 'flex-start'
                            }}
                            fullWidth={true}
                            onClick={handleClick}
                            onKeyDown={handleKeyDown}
                            tabIndex={-1}
                            size="small"
                        >
                            {apiRef.current.getRow(id).name}
                            &nbsp;
                            {rowNode.childrenExpanded ?
                                <RemoveIcon fontSize="small" sx={{ color: '#0F9764' }} />
                                :
                                <AddIcon fontSize="small" sx={{ color: '#0F9764' }} />}

                        </Button></Tooltip>)
                )
                    :
                    (
                        apiRef.current.getRow(id)?.type?.toUpperCase() == "BOLD" ?
                            (<Tooltip title={apiRef.current.getRow(id).name || " "}><Typography sx={{
                                textAlign: 'left',
                                fontWeight: '600',
                                fontFamily:'Oxygen',
                                color: '#1B1C28',
                                letterSpacing: '0.14px',
                                lineHeight: '20px',
                                fontSize: '14px',
                                textTransform: 'none',
                            }}>{apiRef.current.getRow(id).name}</Typography></Tooltip>)
                            :
                            (<Tooltip title={apiRef.current.getRow(id).name || " "}><Typography sx={{
                                textAlign: 'left',
                                fontWeight: '500',
                                fontFamily:'Oxygen',
                                color: '#1B1C28',
                                letterSpacing: '0.14px',
                                lineHeight: '20px',
                                fontSize: '14px',
                                textTransform: 'none',
                            }}>{apiRef.current.getRow(id).name}</Typography></Tooltip>)
                    )}
            </div>
        </Box>
    );
};

const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Particulars',
    pinnable: false,
    width: 250,
    disableColumnMenu: false,
    sortable: false,
    filterable: true,
    hideable: false,
    resizable: true,
    // groupable: false,
    // maxWidth: 10,
    // minWidth: 0,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params as any} />,
};

const groupingColDefMobile: DataGridProProps['groupingColDef'] = {
    headerName: 'Particulars',
    pinnable: false,
    width: 180,
    disableColumnMenu: false,
    sortable: false,
    filterable: true,
    hideable: false,
    resizable: true,
    // groupable: false,
    // maxWidth: 10,
    // minWidth: 0,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params as any} />,
};

function expandFirstGroupOnLoad(row: any): boolean {
    return (row.id == 1) ? true : false;
};

export { StyledDataGrid, getBackgroundColor, getHoverBackgroundColor, CustomGridTreeDataGroupingCell, groupingColDef, groupingColDefMobile, expandFirstGroupOnLoad };
