import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { authReducer, deviceReducer, appReducer, userDataReducer } from './reducer';
import storage from 'redux-persist/lib/storage';
import { getAuth, updateCurrentUser } from 'firebase/auth';
import { Actions } from './Actions';
import { AppState } from '../model/AppState';
import { DeviceState } from '../model/DeviceState';
import { AuthState } from '../model/AuthState';
import { User } from '../model/User';
import { UserImpl } from '@firebase/auth/internal';
import deploymentHistoryList from "../deploymentHistoryList.json";
import { loadCompaniesToAppState } from '../services/CompanyService';
import { UserState } from '../model/UserState';
import { getCurrentLoggedInUser } from '../services/loginUserServices';

const latestVersion = deploymentHistoryList.find(u => u.latest)?.version;

const persistedAuthConfig: PersistConfig<AuthState, any, any, any> = {
    key: 'auth',
    storage
}

const persistedUserDataConfig: PersistConfig<UserState, any, any, any> = {
    key: 'userData',
    storage
}

const persistedDeviceConfig: PersistConfig<DeviceState, any, any, any> = {
    key: 'device',
    storage
}

const persistedAppConfig: PersistConfig<AppState, any, any, any> = {
    key: 'app',
    storage,
    blacklist: ['loading', 'notifications', 'notifierConfig']
}

const rootReducer = combineReducers({
    auth:  persistReducer(persistedAuthConfig, authReducer),
    userData:  persistReducer(persistedUserDataConfig, userDataReducer),
    device: persistReducer(persistedDeviceConfig, deviceReducer),
    app: persistReducer(persistedAppConfig, appReducer)
});

// const updateListedStoreMiddleware = ({ dispatch, getState  }) => next => {
//     const updateMessages = async () => {
//         console.log("checking for new companies");
//         console.log(getState()?.app?.companiesData);
//         let currentDate = new Date();
//         let lastUpdated: Date = new Date(getState()?.app?.companiesData?.lastUpdated || currentDate);
//         var hours = Math.abs(lastUpdated.valueOf() - currentDate.valueOf()) / 36e5;
//         console.log(`stored date: ${lastUpdated}`);
//         console.log(`current date: ${currentDate}`);
//         console.log(`seconds: ${Math.abs(lastUpdated.valueOf() - currentDate.valueOf()) / 1000}`);
//         console.log(`hours: ${hours}`);
//         if (hours === 0 || hours > 12) {
//             console.log("fetching new companies");
//             await loadCompaniesToAppState();
//         }
//         setTimeout(updateMessages, 1 * 60 * 1000);
//     };

//     updateMessages();

//     return action => next(action);
// };

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const persistor = persistStore(store as any, null, async () => {
    // after reloading existed state.
    const state = store.getState();
    if (state.auth.user != null) {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            store.dispatch({type: Actions.SETAUTH, payload: user});
            const systemUser = await getCurrentLoggedInUser(user as any);
            store.dispatch({type: Actions.SETUSERDATA, payload: systemUser});
            unsubscribe();
        });
        const user = state.auth.user;
        const currentUser = UserImpl._fromJSON(auth as any, user as any);
        updateCurrentUser(auth, currentUser);
    }
    if (state.app.appVersion !== latestVersion) {
        store.dispatch({type: Actions.RESETAPPSTATEWITHVERSION, payload: latestVersion});
    }
    {
        let currentDate = new Date();
        const { lastUpdated, companies } = state.app.companiesData || { lastUpdated: currentDate, companies: [] };
        let storedDate = new Date(lastUpdated);
        var hours = Math.abs(storedDate.valueOf() - currentDate.valueOf()) / 36e5;
        // console.log(`stored date: ${storedDate}`);
        // console.log(`current date: ${currentDate}`);
        // console.log(`seconds: ${Math.abs(storedDate.valueOf() - currentDate.valueOf()) / 1000}`);
        // console.log(`hours: ${hours}`);
        if (companies?.length === 0 || hours > 12) {
            // console.log("refreshing listed companies");
            await loadCompaniesToAppState();
        }
    }
});

window.onresize = (event: UIEvent) => {
    const state = store.getState();
    if (window.innerWidth <= 900 && state.device.deviceType === "web") {
        store.dispatch({type: Actions.SETDEVICE, payload: "mobile"})
    }
    if (window.innerWidth > 900 && state.device.deviceType === "mobile") {
        store.dispatch({type: Actions.SETDEVICE, payload: "web"})
    }
}

export { store, persistor }
