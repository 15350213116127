import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Tab, Tabs, Typography, useMediaQuery,Tooltip,IconButton } from '@mui/material';
import { Company } from '../../../model/CompaniesResult';
import useStyle from '../Styles';
import { ShareHoldingPatternInfo } from '../../../model/ShareHoldingPatternsResult';
import { TabContext, TabPanel } from '@mui/lab';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { DateExtensions } from '../../../utility/DateExtensions';
import { withStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../../model/ReduxStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export interface IEventCardProps {
    co_code: string | number | undefined;
    companyShort: Company | undefined;
    share_holding_pattern: ShareHoldingPatternInfo[] | undefined;
    advancedTabCallback: Function;
}

const colors = ["#CFEAE0", "#9FD5C1", "#6FC0A2", "#3EAB83", "#0F9764", "#095d3e", "#021710"];

const ListedShareHoldingCard = (props: IEventCardProps) => {
    const classes = useStyle();
    const isSM = useMediaQuery("(min-width:600px)");
    const [data, setData] = useState<ShareHoldingPatternInfo[] | undefined>(undefined);
    const [tabValue, setTabValue] = React.useState<number | string>('');
    const currentUser = useSelector( ( state: ReduxStore ) => state.userData.user );
    const [link,setLink]= React.useState(false)

    const CustomTabPanel = withStyles((theme: any) => ({
        root: {
            width: '100%',
            backgroundColor: '#F9F9F9',
            [theme.breakpoints.down('md')]: {
                height: '300px',
                padding: '0px',
            },
            [theme.breakpoints.up('md')]: {
                height: '280px',
                padding: '20px',
            },
        }
    }))(TabPanel);

    const CustomTabs = withStyles((theme: any) => ({
        root: {
            borderRight: 'none', borderColor: 'divider',
            display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
            backgroundColor: '#EBEBEB',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: '48px',
            },
            [theme.breakpoints.up('md')]: {
                width: '140px',
                maxHeight: '192px'
            },
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    }))(Tabs);

    const VerticalTab = withStyles((theme: any) => ({
        root: {
            backgroundColor: '#EBEBEB',
            border: '1px solid #F9F9F9',
            [theme.breakpoints.down('md')]: {
                maxWidth: '25%',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '140px',
                height: "40px"
            },
            textTransform: "capitalize"
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    }))(Tab);

    useEffect(() => {
        if (props && props.share_holding_pattern && props.share_holding_pattern.length > 0) {
            let tempData = props.share_holding_pattern;
            tempData = tempData.sort(function (a, b) {
                return (b.YRC - a.YRC);
            });
            if (tempData.length > 4) {
                setData(tempData.slice(0, 4));
                setTabValue(tempData[0].YRC);
            }
            else {
                setData(tempData);
                setTabValue(tempData[0].YRC);
            }
        }

        return () => {
            setData(undefined);
            setTabValue('');
        }
    }, [props.share_holding_pattern]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue && !(tabValue == newValue)) {
            setTabValue(newValue);
        }
    };

    const getContent = (info: ShareHoldingPatternInfo[] | undefined, YRC: number | string | undefined) => {
        if (info && info.length > 0) {
            try {
                let rowData;
                let rowDataList = info.filter(x => x.YRC == YRC);
                if (rowDataList && rowDataList.length > 0) {
                    rowData = rowDataList[0];
                }
                else {
                    return <React.Fragment />;
                }
                let seriesData: number[] = [];
                let labels: string[] = [];

                // Add Indian
                seriesData.push(Number((rowData.PPMSUBTOT).toFixed(2)));
                labels.push('Promoters/Indian');

                // Add Foreign
                seriesData.push(Number((rowData.PPFSUBTOT).toFixed(2)));
                labels.push('Promoters/Foreign');

                // Add DIIS
                seriesData.push(Number((rowData.PPISUBTOT).toFixed(2)));
                labels.push('Non-Promoters/DIIS');

                // Add Public
                seriesData.push(Number((rowData.PPSUBTOT).toFixed(2)));
                labels.push('Non-Promoters/Public');

                // Add Depository Receipts
                seriesData.push(Number(((rowData.PCUSTOTH + rowData.PCUST) | 0).toFixed(2)));
                labels.push('Depository Receipts');

                // Add ADR
                seriesData.push(Number((rowData.PADR | 0).toFixed(2)));
                labels.push('ADR');

                // Add GDR
                seriesData.push(Number((rowData.PGDR | 0).toFixed(2)));
                labels.push('GDR');

                const chartData: ApexOptions = {
                    series: seriesData,
                    chart: {
                        type: 'donut',
                        height: '380px',
                        animations: {
                            enabled: false,
                            easing: 'easeinout',
                            speed: 800,
                            animateGradually: {
                                enabled: false,
                                delay: 0
                            },
                            dynamicAnimation: {
                                enabled: false,
                                speed: 800
                            }
                        },
                    },
                    labels: labels,
                    colors: colors,
                    plotOptions: {
                        pie: {
                            expandOnClick: false
                        }
                    },
                    legend: { show: false },
                    tooltip: { enabled: true },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val: number, opts: any) {
                            return `${Math.floor(val)}%`;
                        },
                    },
                };

                return <ReactApexChart options={chartData} series={chartData.series} type='donut' height='100%' width='100%' />;
            }
            catch (error) {
                console.error('error while getcontent', error);
                return (<React.Fragment />);
            }
        }
        else {
            return (<React.Fragment />);
        }
    }

    return ((data && data.length > 0) ?
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Typography className={classes.headerTypography}>
                    Share Holdings
                </Typography>
                <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                    <IconButton
                        onClick={() => {
                            window.location.hash = 'tabdetail-shareholding'
                            navigator.clipboard.writeText(window.location.href)
                            setLink(true)

                        }}
                    >
                        <ContentCopyIcon
                            sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                    </IconButton>
                </Tooltip>
                { currentUser && <Button variant="contained" color="primary" onClick={() => { props.advancedTabCallback && props.advancedTabCallback(); }}
                    sx={{ height: '35px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none', float: "right" }}>
                    Advanced
                </Button> }
            </Box>

            <Paper elevation={0}>
                <Box className={classes.cardBody}>
                    <Grid container spacing={2}>
                        <Grid item container order={isSM ? 0 : 1} xs={12} md={3}>
                            <CustomTabs
                                orientation={isSM ? 'vertical' : 'horizontal'}
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="share-holdings-card"
                                centered={true}
                                variant={'fullWidth'}
                            >
                                {
                                    data && data.length > 0 &&
                                    data.map((item, index) =>
                                        <VerticalTab
                                            label={DateExtensions.getDateStringForHeaderFormatB(item.YRC.toString())} value={item.YRC}
                                            key={index} />
                                    )
                                }
                            </CustomTabs>
                        </Grid>
                        <Grid item container order={isSM ? 1 : 0} xs={12} md={9}>
                            <TabContext value={tabValue.toString()}>
                                <CustomTabPanel value={tabValue.toString()}>
                                    {getContent(data, tabValue)}
                                </CustomTabPanel>
                            </TabContext>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
        :
        <React.Fragment />
    );
}

export default ListedShareHoldingCard;