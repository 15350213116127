import axios from 'axios';
import { Utility } from "../utility/Utility";
import { CloudFunctionService, SociaMediaServiceURL, UserServiceURL, CompanyServiceURL, CommunicationServiceURL, AdvertisementServiceURL, DashboardServiceURL, PaymentServiceURL, WebbuilderServiceUrl, AssistantBotServiceUrl, companyDataServiceUrl } from './Host';
import { getAxioInstance } from './AxioHelper';

const userService = axios.create({
  baseURL: UserServiceURL,
  withCredentials: true
});

userService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

// userService.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     console.log('Token has expired.');
//     originalRequest._retry = true;
//     const access_token = await Utility.getRefreshToken();
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     console.log('Token renewed. Retrying.');
//     return userService(originalRequest);
//   }
//   return Promise.reject(error);
// });

const companyService = axios.create({
  baseURL: CompanyServiceURL,
  withCredentials: true
});

companyService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

// companyService.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     console.log('Token has expired.');
//     originalRequest._retry = true;
//     const access_token = await Utility.getRefreshToken();
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     console.log('Token renewed. Retrying.');
//     return companyService(originalRequest);
//   }
//   return Promise.reject(error);
// });

const socialNetworkService = axios.create({
  baseURL: SociaMediaServiceURL,
  withCredentials: true
});

socialNetworkService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

// socialNetworkService.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     console.log('Token has expired.');
//     originalRequest._retry = true;
//     const access_token = await Utility.getRefreshToken();
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     console.log('Token renewed. Retrying.');
//     return socialNetworkService(originalRequest);
//   }
//   return Promise.reject(error);
// });

const communicationService = axios.create({
  baseURL: CommunicationServiceURL
});

communicationService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

// communicationService.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     console.log('Token has expired.');
//     originalRequest._retry = true;
//     const access_token = await Utility.getRefreshToken();
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     console.log('Token renewed. Retrying.');
//     return communicationService(originalRequest);
//   }
//   return Promise.reject(error);
// });

const advertisementService = axios.create({
  baseURL: AdvertisementServiceURL
});

advertisementService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

// advertisementService.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     console.log('Token has expired.');
//     originalRequest._retry = true;
//     const access_token = await Utility.getRefreshToken();
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//     console.log('Token renewed. Retrying.');
//     return communicationService(originalRequest);
//   }
//   return Promise.reject(error);
// });


const userImpressionService = axios.create({
  baseURL: DashboardServiceURL
});

userImpressionService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

const paymentService = axios.create({
  baseURL: PaymentServiceURL
});

paymentService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common as any).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

const cloudFunctionService = axios.create({
  baseURL: CloudFunctionService
});

const webbuilderService = getAxioInstance(WebbuilderServiceUrl);

const marketplaceService = getAxioInstance(DashboardServiceURL);

const assistantBotService = getAxioInstance(AssistantBotServiceUrl);

const companyDataService = getAxioInstance(companyDataServiceUrl);

export { userService, socialNetworkService, cloudFunctionService, companyService, communicationService, advertisementService, userImpressionService, paymentService, webbuilderService, marketplaceService, assistantBotService, companyDataService };