import React from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link,
  Card,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { PathNames } from '../../appConfig/PathNames';
import PlansForm from './PlansForm';
import PlansTestimonials from './PlansTestimonials';
import PlansFAQ from './PlansFAQ';
import data from './companyData.json';

const CompaniesPlans = () => {
  const navigate = useNavigate();

  const goToPaymentPage = (planCode: string) => {
    navigate(`${PathNames.PREMIUM}?plan_code=${planCode}`);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            gap: 5,
            marginTop: { md: '-5%', xs: '-14%' },
            justifyContent: 'center',
            width: '90%',
            marginLeft: { md: 8, xs: 2 },
            marginRight: { md: 6, xs: 2 },
          }}
        >
          {/* Render each plan card */}
          {data.plans.map((plan, index) => (
            <Card
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'auto',
                background: plan.backgroundColor,
                border: '1px solid #DFECFF',
                borderRadius: '16px',
                transition: 'background-color 0.3s, transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.03)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  paddingTop: '20px',
                }}
              >
                <Typography
                  sx={{
                    color: plan.color,
                    fontSize: '24px',
                    fontWeight: '800',
                    lineHeight: '32px',
                  }}
                >
                  {plan.title1}
                </Typography>
                <Typography
                  sx={{
                    color: plan.color,
                    fontSize: '24px',
                    fontWeight: '800',
                    lineHeight: '32px',
                  }}
                >
                  {plan.title2}
                </Typography>
              </Box>
              <Box
                sx={{
                  color: plan.color === '#FFF' ? '#FFF' : '#171923',
                  fontSize: '18px',
                  fontWeight: '500',
                  lineHeight: '28px',
                  marginLeft: '20%',
                  marginTop: '20px',
                  fontFamily: 'Oxygen',
                }}
              >
                billed just once
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '60px',
                      fontWeight: 700,
                      color: plan.color,
                    }}
                  >
                    {plan.price}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    color: plan.color,
                    fontSize: '20px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    fontFamily: 'Oxygen',
                    letterSpacing: '-0.24px',
                  }}
                >
                  +GST
                </Box>
                <hr
                  style={{
                    width: '100%',
                    margin: '10px 0',
                    borderTop: '1px solid #ccc',
                  }}
                />
                <List>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon sx={{ minWidth: '27px' }}>
                        <DoneIcon
                          fontSize="small"
                          sx={{
                            color: plan.color === '#FFF' ? '#FFF' : '#007E60',
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          color={plan.color === '#FFF' ? '#FFF' : '#2D3748'}
                          fontWeight={700}
                          fontSize={14}
                        >
                          {feature}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 'auto',
                  bottom: '0px',
                  paddingY: '20px',
                }}
              >
                <Button
                  onClick={() => goToPaymentPage(plan.planCode)}
                  variant="contained"
                  sx={{
                    width: '250px',
                    color: plan.backgroundColor,
                    background:
                      plan.backgroundColor === '#FFF' ? '#0F9764' : '#FFF',
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Card>
          ))}
        </Box>
        <Box>
          <Link
            sx={{ color: '#0F9764', cursor: 'pointer' }}
            onClick={() => navigate(`${PathNames.PAYMENTREFUND}`)}
          >
            <Typography
              color="#0F9764"
              sx={{
                fontSize: 14,
                fontWeight: 700,
                marginLeft: { md: '70px', xs: '24px' },
              }}
            >
              Please check our refund policy
            </Typography>
          </Link>
        </Box>
      </Box>
      <PlansForm />
      <PlansTestimonials />
      <PlansFAQ faqs={data.faqs} />
    </>
  );
};

export default CompaniesPlans;
