import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CardHeader, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { getCompanyKeyRatios } from '../../../services/CompanyService';
import { makeStyles, withStyles } from '@mui/styles';
import { CopyLinkButton } from '../../../components/Common/CopyLinkButton';


function Ratios(co_code: string | number, displayType: string, active: boolean) {

    const [alignment, setAlignment] = React.useState<'S' | 'C'>('S');
    const [data, setData] = useState<any>({ headers: [], rows: [] });
    const [loading, setLoading] = useState<boolean>(true);

    const useStyles = makeStyles((theme: any) => ({
        root: {
            width: "100%",
            marginTop: theme.spacing.unit * 3,
            overflowX: "auto"
        },
        table: {
            minWidth: 500
        },
        sticky: {
            position: "sticky",
            left: 0,
            background: "white",
            boxShadow: "5px 2px 5px grey",
            borderRight: "none",
            width: "300px",
            backgroundColor: "#F9F9F9"
        },
        cardHeaderRoot: {
            backgroundColor: "#F9F9F9",
            paddingLeft: "30px",
            paddingRight: "10px",
            paddingTop: "0px",
            paddingBottom: "0px",
            height: "80px",
            verticalAlign: "middle"
        },
        cardHeaderAction: {
            margin: "0px",
            paddingRight: "10px",
            verticalAlign: "middle",
            height: "100%",
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center"
        },
        cardHeaderTitleRoot: {
            textAlign: 'left',
            verticalAlign: "middle",
            height: "100%"
        },
        cardHeaderSubTitleRoot: {
            textAlign: 'center'
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        root: {
            textAlign: "right",
            width: "100px"
        }
    }))(TableCell);

    const StickyTableCell = withStyles((theme) => ({
        root: {
            position: "sticky",
            left: 0,
            background: "white",
            boxShadow: "5px 2px 5px grey",
            borderRight: "none",
            width: "300px",
            backgroundColor: "#F9F9F9"
        }
    }))(TableCell);

    const handleTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: 'S' | 'C',
    ) => {
        if (!(alignment === newAlignment) && (newAlignment === 'S' || newAlignment === 'C')) {
            setAlignment(newAlignment);
            setLoading(true);
        }
    };

    const toggleButton = () => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                justifyItems: "center",
                alignItems: "center",
                margin: 0,
                lineHeight: "30px",
                paddingTop: "20px",
                paddingBottom: "25px"
            }
            }>
                <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    size='small'
                    onChange={handleTypeChange}
                    sx={{ height: "39px", maxWidth: "250px", marginRight: 2, '& button': { fontFamily: 'Oxygen' } }}
                >
                    <ToggleButton value="S"
                        sx={{
                            borderRadius: "20px",
                            color: alignment === 'S' ? '#FFFFFF !important' : '#0F9764 !important',
                            backgroundColor: alignment === 'S' ? '#0F9764 !important' : '#FFFFFF !important',
                            paddingLeft: "15px",
                            paddingRight: "10px",
                            textTransform: "none"
                        }} >Standalone</ToggleButton>
                    <ToggleButton value="C"
                        sx={{
                            borderRadius: "20px",
                            color: alignment === 'C' ? '#FFFFFF !important' : '#0F9764 !important',
                            backgroundColor: alignment === 'C' ? '#0F9764 !important' : '#FFFFFF !important',
                            paddingLeft: "10px",
                            paddingRight: "15px",
                            textTransform: "none"
                        }} >Consolidated</ToggleButton>
                </ToggleButtonGroup>
                <CopyLinkButton locationHash='tabdetail-ratios' />
            </div>)
    };

    const processData = (input: any[]): any => {
        if (input && input.length > 0) {
            let headers: any[] = [];
            headers.push(<TableCell sx={{ bgcolor: '#FFFFFF' }} className={classes.sticky}>Years</TableCell>);

            let rows: any[] = [];

            let columnKeys: any[] = [];
            let yearEnd = input
                .filter((o) => o.COLUMNNAME === "Year End")[0];

            // Generate keys from Year End or RID = 1
            Object.keys(yearEnd).forEach(function (key) {
                let yeVal: string = yearEnd[key].toString();
                if (key.startsWith("Y")) {
                    columnKeys.push({ "key": key, "value": yeVal.substring(0, 4) });
                }
            });

            // Generate cells for header
            for (let k of columnKeys) {
                headers.push(<StyledTableCell>{k.value}</StyledTableCell>);
            }

            // Generate rows for each item
            for (let r of input) {
                if (r.RID === 1) {
                    // Skip
                }
                else {
                    let cells: any[] = [];

                    // Generate cell for row name
                    cells.push(<StickyTableCell
                        className={classes.sticky}
                        component="th"
                        scope="row"
                        sx={{ backgroundColor: "#F9F9F9" }}
                    >
                        {r.COLUMNNAME.toString().trim()}
                    </StickyTableCell>);

                    // Generate cells for rows
                    for (let k of columnKeys) {
                        cells.push(<StyledTableCell>{r[k.key]}</StyledTableCell>);
                    }

                    rows.push(cells);
                }
            }

            return { headers: headers, rows: rows };
        }
        else {
            return { headers: [], rows: [] };
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                if (active) {
                    const response = await getCompanyKeyRatios(co_code, alignment);
                    let processedData = processData(response);
                    setData(processedData);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [active, alignment, co_code]);

    return (
        <React.Fragment>
            <Box>
                <Card elevation={0} sx={{ paddingY: '0px', marginX: '0px', backgroundColor: "#F5F5F5", border: '1px solid #EBEBEB', borderRadius: '0px 8px 8px 8px' }}>
                    <CardHeader
                        sx={{
                            textAlign: 'center', paddingLeft: '64px',
                            justifyContent: 'space-between'
                        }}
                        title=""
                        action={toggleButton()}
                        classes={{
                            root: classes.cardHeaderRoot,
                            action: classes.cardHeaderAction
                        }}
                        titleTypographyProps={{
                            classes: {
                                root: classes.cardHeaderTitleRoot
                            }
                        }}
                        subheaderTypographyProps={{
                            classes: {
                                root: classes.cardHeaderSubTitleRoot
                            }
                        }}
                    >
                    </CardHeader>
                    <CardContent sx={{ padding: '0px!important' }}>
                        <Paper className={classes.root}>
                            <div style={{
                                height: '300px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: loading ? "flex" : "none"
                            }}>
                                <CircularProgress color="primary" />
                            </div>
                            <div style={{ display: loading ? "none" : "flex" }}>
                                <Table
                                    className={classes.table}
                                    size="small"
                                    aria-label="simple table"
                                    sx={{
                                        '& td,th': {
                                            height: '30px'
                                        },
                                        '& td': {
                                            color: '#0D0D0D',
                                            fontWeight: 700
                                        },
                                        '& th': {
                                            color: '#686868',
                                            fontWeight: 700
                                        },
                                        '& tr th:nth-child(1)': {
                                            fontWeight: 400
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "#FFFFFF" }}>
                                            {data.headers}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.rows.map((row) => (
                                            <TableRow sx={{ backgroundColor: "#F9F9F9" }} key={data.rows.indexOf(row)}>
                                                {row}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    );
}

export default Ratios

