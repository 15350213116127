import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
class ReactReadMoreReadLess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        };
    }
    render() {
        const { props } = this;
        const { children, ellipsis, readMoreText, readLessText, readMoreClassName, readLessClassName, readMoreStyle, readLessStyle, readIconColor, charLimit,onReadMoreClicked } = props;
        const { showMore } = this.state;
        const shortText = children.substr(0, charLimit).replace(/[\s\n]+$/, '').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, "") + (charLimit >= children.length ? '' : ellipsis);
        const that = this;
        const ReadMore = () => (((charLimit >= children.length) || !readMoreText) ? null : <Button
            varient={"text"}
            color="primary"
            // color={readIconColor}
            onClick={() => { that.setState({ showMore: true });onReadMoreClicked() }}
        >{readMoreText}</Button>);
        const ReadLess = () => (((charLimit >= children.length) || !readLessText) ? null : <Button
            varient={"text"}
            color="primary"
            // color={readIconColor}
            onClick={() => { that.setState({ showMore: false }); }}
        >{readLessText}</Button>);
        return (
            <React.Fragment>{showMore ? children : shortText} {showMore ? <ReadLess /> : <ReadMore />}</React.Fragment>
        );
    }
}

ReactReadMoreReadLess.propTypes = {
    charLimit: PropTypes.number,
    ellipsis: PropTypes.string,
    readMoreText: PropTypes.string,
    readLessText: PropTypes.string,
    readMoreClassName: PropTypes.string,
    readLessClassName: PropTypes.string,
    readMoreStyle: PropTypes.object,
    readLessStyle: PropTypes.object,
    children: PropTypes.string.isRequired,
    readIconColor: PropTypes.string
};
ReactReadMoreReadLess.defaultProps = {

    charLimit: 150,
    ellipsis: '…',
    readMoreText: 'Read more',
    readLessText: 'Read less',
    readMoreClassName: 'react-read-more-read-less react-read-more-read-less-more',
    readLessClassName: 'react-read-more-read-less react-read-more-read-less-less',
    readMoreStyle: { fontFamily: "Oxygen", whiteSpace: "nowrap", cursor: "pointer" },
    readLessStyle: { fontFamily: "Oxygen", whiteSpace: "nowrap", cursor: "pointer" }
};
export default ReactReadMoreReadLess;