import React, { useEffect, useState } from 'react'
import { Button, Card, Dialog, DialogContent, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import {
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { getCompanyShareholdersInfo } from '../../../../services/CompanyService';
import { CompanyShareholdersInfo } from '../../../../model/CompanyShareholdersResult';
import { DateExtensions } from '../../../../utility/DateExtensions';
import { renderCellProgress } from '../../../../components/XGrid/RenderCellProgress';
import { renderCellNumber } from '../../../../components/XGrid/RenderCellNumber';
import { StyledDataGrid } from '../StyledDataGrid';
import InvestorHoldingsDialog from './InvestorHoldingsDialog';
import { CopyLinkButton } from '../../../../components/Common/CopyLinkButton';

const muiToolbarXGrid = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    paddingX: '2%',
    height: "80px",
    bgcolor: "#F9F9F9"
};

function ShareHoldersInfo(co_code: string, load: boolean) {
    const [shareHoldersInfo, setShareHoldersInfo] = useState<any | null>(null);

    const [openShareHoldingDetails, setOpenShareHoldingDetails] = useState<boolean>(false);
    const [investorName, setInvestorName] = useState<string>('');

    const handleClickShareHoldingDetailsOpen = (name: string) => {
        setInvestorName(name);
        setOpenShareHoldingDetails(true);
    };
    const handleClickShareHoldingDetailsClose = () => {
        setOpenShareHoldingDetails(false);
        setInvestorName('');
    };

    const shareHolderText = "Top Shareholders";

    function EmptyFooterXGrid() {
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }

    function CustomToolbarXGrid() {
        return (
            <React.Fragment>
                <GridToolbarContainer sx={muiToolbarXGrid}>
                    <XGridPopShareHoldersInfo />
                    <CopyLinkButton locationHash='tabdetail-shareholdingspattern' />
                </GridToolbarContainer>
                <Divider orientation="horizontal" flexItem />
            </React.Fragment>
        );
    }

    function CustomToolbarXGridPopUpShareHoldersInfo() {
        return (
            <React.Fragment>
                <GridToolbarContainer sx={muiToolbarXGrid}>
                    <Typography sx={{
                        fontStyle: "normal",
                        paddingX: "30px",
                        height: "80px",
                        fontWeight: 700,
                        fontFamily: 'Oxygen',
                        fontSize: '18px',
                        lineHeight: '23px',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minWidth: "10%",
                        paddingRight: "5%",
                    }}>
                        {shareHolderText}
                    </Typography>
                    <div>
                        <GridToolbarFilterButton sx={{ marginLeft: "0px", marginRight: "5px" }} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                    </div>
                </GridToolbarContainer>
                <Divider orientation="horizontal" flexItem />
            </React.Fragment>
        );
    }

    function XGridPopShareHoldersInfo() {
        //Dialog Control
        const [open, setOpen] = useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        return (
            <>
                <IconButton
                    color="primary"
                    aria-label="x-grid-pop"
                    component="span"
                    onClick={handleOpen}
                    sx={{ marginX: "10px" }}
                >
                    <img src="expand-window.png" style={{ height: "24px", width: "24px" }} />
                </IconButton>
                <Dialog
                    PaperProps={{
                        sx: {
                            width: (shareHoldersInfo && shareHoldersInfo.columns && shareHoldersInfo.columns.length > 0) ? `${100 * shareHoldersInfo.columns.length + 300}px` : '300px',
                            maxWidth: "90%",
                            height: "100%"
                        }
                    }}
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <DialogContent dividers
                        style={{
                            width: (shareHoldersInfo && shareHoldersInfo.columns && shareHoldersInfo.columns.length > 0) ? `${100 * shareHoldersInfo.columns.length + 250}px` : '300px',
                        }}
                    >
                        <StyledDataGrid
                            rows={shareHoldersInfo.rows}
                            columns={shareHoldersInfo.columns}
                            density={"compact"}
                            components={{
                                Footer: EmptyFooterXGrid,
                                Toolbar: CustomToolbarXGridPopUpShareHoldersInfo,
                            }}
                            autoHeight={true}
                            // disableColumnMenu
                            disableSelectionOnClick
                            columnVisibilityModel={{
                                // Hide columns id, the other columns will remain visible
                                id: false,
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    useEffect(() => {
        async function fetchData() {
            if (co_code && load === true) {
                try {
                    const responseShareHolders = await getCompanyShareholdersInfo(co_code);

                    if (responseShareHolders) {
                        ProcessShareHoldersInfo(responseShareHolders);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchData();
    }, [co_code, load]);

    return (
        <React.Fragment>
            <Card
                sx={{ border: '2px' }}
            >
                {
                    <div style={{
                        width: '100%'
                    }}>
                        {shareHoldersInfo &&
                            <StyledDataGrid
                                autoHeight={true}
                                rows={shareHoldersInfo.rows}
                                columns={shareHoldersInfo.columns}
                                density={"compact"}
                                components={{
                                    Footer: EmptyFooterXGrid,
                                    Toolbar: CustomToolbarXGrid,
                                }}
                                columnVisibilityModel={{
                                    // Hide columns id, the other columns will remain visible
                                    id: false,
                                }}
                                disableColumnSelector
                            />
                        }

                    </div>
                }
            </Card>
            {InvestorHoldingsDialog(investorName, openShareHoldingDetails, handleClickShareHoldingDetailsClose)}
        </React.Fragment>
    )

    function ProcessShareHoldersInfo(responseShareHolders: CompanyShareholdersInfo[]) {
        if (responseShareHolders) {
            // Process Columns
            var columnOptions: any[] = [];
            columnOptions.push({ field: 'id', headerName: '', width: 0 });
            columnOptions.push({
                field: 'name',
                headerName: 'Name',
                width: 250,
                renderCell: (params) => {
                    let curName = params.row.name;
                    return (
                        <Tooltip title={curName} style={{justifyContent:"flex-start"}}>
                            <Button sx={{ textAlign: 'left', maxWidth: { xs: '250px', sm: '250px' } }} onClick={() => handleClickShareHoldingDetailsOpen(curName)}>{curName}</Button>
                        </Tooltip>
                    );
                },
            });
            columnOptions.push({ field: 'date', headerName: 'Date', width: 100 });
            columnOptions.push({ field: 'noofshares', headerName: 'NO. OF SHARES', width: 150, type: "number", renderCell: renderCellNumber });
            columnOptions.push({ field: 'stake', headerName: 'STAKE (%)', width: 100, type: "number", renderCell: renderCellProgress });
            columnOptions.push({ field: 'type', headerName: 'Type', width: 200 });

            // Process Rows
            var rows: any[] = [];
            var count = 1;
            responseShareHolders.forEach((row) => {
                var responseObject = {};

                responseObject["id"] = count;
                count = count + 1;
                responseObject["name"] = row.Name;
                responseObject["date"] = DateExtensions.getDateStringUppercaseMonth(new Date(row.date), " ");
                responseObject["noofshares"] = row.NOOFshares;
                responseObject["stake"] = row.perstake;
                responseObject["type"] = row.Type.trim();

                if (responseObject["noofshares"] > 0) {
                    rows.push(responseObject);
                }
            });

            setShareHoldersInfo({ columns: columnOptions, rows: rows });
        }
    }
}

export default ShareHoldersInfo

