import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, Divider, IconButton, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';

import {
    GridToolbarContainer,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { getShareHoldingPattern } from '../../../../services/CompanyService';
import { ShareHoldingPatternInfo } from '../../../../model/ShareHoldingPatternsResult';
import { DateExtensions } from '../../../../utility/DateExtensions';
import { StyledDataGrid } from '../StyledDataGrid';
import { CopyLinkButton } from '../../../../components/Common/CopyLinkButton';
import { isMobile } from 'react-device-detect';

const muiToolbarXGrid = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    paddingX: '2%',
    height: "80px",
    bgcolor: "#F9F9F9"
};

const Headers: string[] = [
    "PROMOTERS",
    "NON-PROMOTERS",
    "TOTAL PROMOTER AND NON PROMOTER",
    "TOTAL SHAREHOLDING"
];

function HoldingPatterns(co_code: string, load: boolean) {
    const [data, setData] = useState<ShareHoldingPatternInfo[]>([]);
    const [patternsColumnInfo, setPatternsColumnInfo] = useState<any[]>([]);
    const [patternsRowInfo, setPatternsRowInfo] = useState<any[]>([]);
    const [dataType, setDataType] = useState<string>("TS");     //TS, PSH, PPS

    const patternText = "Shareholding Patterns";

    const theme = useTheme();

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (!(dataType === newAlignment) && (newAlignment === 'TS' || newAlignment === 'PSH' || newAlignment === "PPS")) {
            setDataType(newAlignment);
        }
    };

    function EmptyFooterXGrid() {
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }

    function CustomToolbarXGrid() {
        return (
            <React.Fragment>
                <GridToolbarContainer sx={muiToolbarXGrid}>
                    <div>
                        <ToggleButtonGroup
                            value={dataType}
                            exclusive
                            size='small'
                            onChange={handleChange}
                            sx={{ height: { xs: "45px", md: "39px" }, maxWidth: "360px", marginRight: 2, '& button': { fontFamily: 'Oxygen' } }}
                        >
                            <ToggleButton value="TS"
                                sx={{
                                    borderRadius: "20px",
                                    color: dataType === 'TS' ? '#FFFFFF !important' : '#0F9764 !important',
                                    backgroundColor: dataType === 'TS' ? '#0F9764 !important' : '#FFFFFF !important',
                                    paddingLeft: "15px",
                                    paddingRight: "10px",
                                    textTransform: "none"
                                }} >Total Shares</ToggleButton>
                            <Divider orientation="vertical" />
                            <ToggleButton value="PSH"
                                sx={{
                                    color: dataType === 'PSH' ? '#FFFFFF !important' : '#0F9764 !important',
                                    backgroundColor: dataType === 'PSH' ? '#0F9764 !important' : '#FFFFFF !important',
                                    paddingLeft: "15px",
                                    paddingRight: "10px",
                                    textTransform: "none"
                                }} >Share Holding (%)</ToggleButton>
                            <Divider orientation="vertical" />
                            <ToggleButton value="PPS"
                                sx={{
                                    borderRadius: "20px",
                                    color: dataType === 'PPS' ? '#FFFFFF !important' : '#0F9764 !important',
                                    backgroundColor: dataType === 'PPS' ? '#0F9764 !important' : '#FFFFFF !important',
                                    paddingLeft: "15px",
                                    paddingRight: "10px",
                                    textTransform: "none"
                                }} >Pledge Shares (%)</ToggleButton>
                        </ToggleButtonGroup>
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                            <XGridPopShareHoldingPatterns />
                            <CopyLinkButton locationHash='tabdetail-shareholdingspattern' />
                        </Box>
                    </div>

                </GridToolbarContainer>
            </React.Fragment>
        );
    }

    function CustomToolbarXGridPop() {
        return (
            <React.Fragment>
                <GridToolbarContainer sx={muiToolbarXGrid}>
                    <Typography sx={{
                        fontStyle: "normal",
                        paddingX: "30px",
                        height: "80px",
                        fontWeight: 700,
                        fontFamily: 'Oxygen',
                        fontSize: '18px',
                        lineHeight: '23px',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minWidth: "10%",
                        paddingRight: "5%",
                    }}>
                        {""}
                    </Typography>
                    <div>
                        <GridToolbarFilterButton sx={{ marginLeft: "0px", marginRight: "5px" }} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                    </div>
                </GridToolbarContainer>
            </React.Fragment>
        );
    }

    function XGridPopShareHoldingPatterns() {
        //Dialog Control
        const [open, setOpen] = useState(false);
        const handleOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };

        return (
            <>
                <IconButton
                    color="primary"
                    aria-label="x-grid-pop"
                    component="span"
                    onClick={handleOpen}
                    sx={{ marginX: "10px" }}
                >
                    <img src="expand-window.png" style={{ height: "24px", width: "24px" }} />
                </IconButton>
                <Dialog
                    PaperProps={{
                        sx: {
                            width: (patternsColumnInfo && patternsColumnInfo.length > 0) ? `${100 * patternsColumnInfo.length + 310}px` : '350px',
                            maxWidth: "90%",
                            top: { md: "18px", xs: "45px" },
                            height: "80%"
                        }
                    }}
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <DialogContent dividers
                        sx={{
                            width: (patternsColumnInfo && patternsColumnInfo.length > 0) ? `${100 * patternsColumnInfo.length + 260}px` : '350px',
                        }}
                    >
                        <StyledDataGrid
                            rows={patternsRowInfo}
                            columns={patternsColumnInfo}
                            density={"compact"}
                            components={{
                                Footer: EmptyFooterXGrid,
                                Toolbar: CustomToolbarXGridPop,
                            }}
                            autoHeight
                            disableSelectionOnClick
                            columnVisibilityModel={{
                                // Hide columns id, the other columns will remain visible
                                id: false,
                            }}
                            getRowClassName={(params) => {
                                if (Headers.includes(params.row.name)) {
                                    return 'highlight-row-theme'
                                }
                                else {
                                    return 'MuiDataGrid-row'
                                }
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    useEffect(() => {
        async function fetchData() {
            if (co_code && load === true) {
                try {
                    let responsePatterns = await getShareHoldingPattern(co_code);
                    if (responsePatterns) {
                        var tempColumnInfo = ProcessShareHoldingPatterns_Columns(responsePatterns);
                        setData(responsePatterns);
                        ProcessShareHoldingPatterns_TOTAL_SHARES(responsePatterns, tempColumnInfo);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchData();
    }, [co_code, load]);

    useEffect(() => {
        async function fetchData() {
            if (load === true) {
                try {
                    if (dataType === "TS") {
                        ProcessShareHoldingPatterns_TOTAL_SHARES(data, patternsColumnInfo);
                    }
                    else if (dataType === "PSH") {
                        ProcessShareHoldingPatterns_PERCENTAGE_SHARE_HOLDING(data, patternsColumnInfo);
                    }
                    else if (dataType === "PPS") {
                        ProcessShareHoldingPatterns_PERCENTAGE_PLEDGE_SHARES(data, patternsColumnInfo);
                    }
                    else {
                        ProcessShareHoldingPatterns_TOTAL_SHARES(data, patternsColumnInfo);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchData();
    }, [dataType, data, patternsColumnInfo]);

    return (
        <React.Fragment>
            <Box
                sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: '12px',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#FFFFFF !important',
                        color: '#686868',
                        minHeight: "44px !important",
                        maxHeight: "44px !important"
                    },
                    '& .MuiDataGrid-row[data-rowindex="0"]': {
                        marginTop: '0px !important'
                    },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        backgroundColor: "#F5F5F5",
                        color: '#1B1C28'
                    },
                    '& .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1)': {
                        color: '#686868'
                    }
                }}
            >
                {
                    <div style={{
                        width: '100%'
                    }}>
                        {patternsColumnInfo &&
                            <StyledDataGrid
                                initialState={{ pinnedColumns: { left: ['name'] } }}
                                autoHeight={true}
                                theme={theme}
                                rows={patternsRowInfo}
                                columns={patternsColumnInfo}
                                density={"compact"}
                                components={{
                                    Footer: EmptyFooterXGrid,
                                    Toolbar: CustomToolbarXGrid,
                                }}
                                columnVisibilityModel={{
                                    // Hide columns id, the other columns will remain visible
                                    id: false,
                                }}
                                disableColumnSelector
                                disableColumnFilter={true}
                                getRowClassName={(params) => {
                                    if (Headers.includes(params.row.name)) {
                                        return 'highlight-row-theme'
                                    }
                                    else {
                                        return 'MuiDataGrid-row'
                                    }
                                }
                                }
                            />
                        }

                    </div>
                }
            </Box>
        </React.Fragment>
    )

    // Process Columns
    function ProcessShareHoldingPatterns_Columns(responsePatterns: ShareHoldingPatternInfo[]): any[] {
        var columnOptions: any[] = [];
        if (responsePatterns) {
            columnOptions.push({ field: 'id', headerName: '', width: 0 });
            columnOptions.push({ field: 'name', headerName: 'Particulars', width: isMobile ? 180 : 250 });
            responsePatterns.forEach((item) => {
                if (item) {
                    var YRCVal = item.YRC;
                    columnOptions.push({
                        field: YRCVal,
                        headerName: DateExtensions.getDateStringForHeaderFormatB(YRCVal.toString()),
                        width: 150,
                        align: 'right',
                        headerAlign: 'right'
                    });
                }
            });

            setPatternsColumnInfo(columnOptions);
        }
        else {
            setPatternsColumnInfo(columnOptions);
        }
        return columnOptions;
    }

    function ProcessShareHoldingPatterns_TOTAL_SHARES(responsePatterns: ShareHoldingPatternInfo[], columnInfo: any[]) {
        // Process Rows
        var rows: any[] = [];
        var responseObject = {};

        if (responsePatterns.length == 0 || columnInfo.length == 0) {
            setPatternsRowInfo([]);
            return;
        }

        // PROMOTERS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 1;
            }
            else if (column.field == "name") {
                responseObject["name"] = "PROMOTERS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.TotalPromoter_Shares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Indian
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 2;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Indian";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NPMSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Foreign
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 3;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Foreign";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NPFSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // NON-PROMOTERS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 4;
            }
            else if (column.field == "name") {
                responseObject["name"] = "NON-PROMOTERS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.TotalNonPromoter_Shares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Govt. and clearing bodies
        // columnInfo.forEach((column) => {
        //     if (column.field == "id") {
        //         responseObject["id"] = 5;
        //     }
        //     else if (column.field == "name") {
        //         responseObject["name"] = "Govt. and clearing bodies";
        //     }
        //     else {
        //         var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
        //         if (filteredRows && filteredRows.length > 0) {
        //             var currentRow = filteredRows[0];
        //             if (currentRow) {
        //                 var nVal = (currentRow.NPIGOVT + currentRow.NPICLMEM + currentRow.NPICOB).toFixed(2);
        //                 var nValNum = Number(nVal).toLocaleString("en-IN");
        //                 responseObject[column.field] = nValNum;
        //             }
        //         }
        //     }
        // });
        // rows.push(responseObject); responseObject = {};

        // Foreign Investors
        // columnInfo.forEach((column) => {
        //     if (column.field == "id") {
        //         responseObject["id"] = 6;
        //     }
        //     else if (column.field == "name") {
        //         responseObject["name"] = "Foreign Investors";
        //     }
        //     else {
        //         var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
        //         if (filteredRows && filteredRows.length > 0) {
        //             var currentRow = filteredRows[0];
        //             if (currentRow) {
        //                 var nVal = (currentRow.NPIFDI + currentRow.NPIBK + currentRow.NPIFBK + currentRow.NPIFCOB +
        //                     currentRow.NPIFCOL + currentRow.NPIFII + currentRow.NPIFVEN + currentRow.NPIFOTH +
        //                     currentRow.NPINRI + currentRow.NPFCOB + currentRow.NPFDI + currentRow.NPNRI + currentRow.NPFOTH).toFixed(2);
        //                 var nValNum = Number(nVal).toLocaleString("en-IN");
        //                 responseObject[column.field] = nValNum;
        //             }
        //         }
        //     }
        // });
        // rows.push(responseObject); responseObject = {};

        // DIIS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 7;
            }
            else if (column.field == "name") {
                responseObject["name"] = "DIIS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NPISUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Public
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 8;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Public";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NPSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // TOTAL PROMOTER AND NON PROMOTER
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 9;
            }
            else if (column.field == "name") {
                responseObject["name"] = "TOTAL PROMOTER AND NON PROMOTER";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.Total_Promoter_NonPromoter_Shares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Depository Receipts
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 10;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Depository Receipts";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NCUSTOTH + currentRow.NCUST).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // ADR
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 11;
            }
            else if (column.field == "name") {
                responseObject["name"] = "ADR";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NADR).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // GDR
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 12;
            }
            else if (column.field == "name") {
                responseObject["name"] = "GDR";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NGDR).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // TOTAL SHAREHOLDING
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 13;
            }
            else if (column.field == "name") {
                responseObject["name"] = "TOTAL SHAREHOLDING";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.NGRTOTAL).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        setPatternsRowInfo(rows);
    }

    function ProcessShareHoldingPatterns_PERCENTAGE_SHARE_HOLDING(responsePatterns: ShareHoldingPatternInfo[], columnInfo: any[]) {
        // Process Rows
        var rows: any[] = [];
        var responseObject = {};
        // PROMOTERS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 1;
            }
            else if (column.field == "name") {
                responseObject["name"] = "PROMOTERS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.TotalPromoter_PerShares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Indian
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 2;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Indian";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PPMSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Foreign
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 3;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Foreign";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PPFSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // NON-PROMOTERS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 4;
            }
            else if (column.field == "name") {
                responseObject["name"] = "NON-PROMOTERS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.TotalNonPromoter_PerShares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Govt. and clearing bodies
        // columnInfo.forEach((column) => {
        //     if (column.field == "id") {
        //         responseObject["id"] = 5;
        //     }
        //     else if (column.field == "name") {
        //         responseObject["name"] = "Govt. and clearing bodies";
        //     }
        //     else {
        //         var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
        //         if (filteredRows && filteredRows.length > 0) {
        //             var currentRow = filteredRows[0];
        //             if (currentRow) {
        //                 var nVal = (currentRow.PPIGOVT + currentRow.PPICLMEM + currentRow.PPICOB).toFixed(2);
        //                 var nValNum = Number(nVal).toLocaleString("en-IN");
        //                 responseObject[column.field] = nValNum;
        //             }
        //         }
        //     }
        // });
        // rows.push(responseObject); responseObject = {};

        // Foreign Investors
        // columnInfo.forEach((column) => {
        //     if (column.field == "id") {
        //         responseObject["id"] = 6;
        //     }
        //     else if (column.field == "name") {
        //         responseObject["name"] = "Foreign Investors";
        //     }
        //     else {
        //         var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
        //         if (filteredRows && filteredRows.length > 0) {
        //             var currentRow = filteredRows[0];
        //             if (currentRow) {
        //                 var nVal = (currentRow.PPIFDI + currentRow.PPIBK + currentRow.PPIFBK +
        //                     currentRow.PPIFCOB + currentRow.PPIFCOL + currentRow.PPIFII +
        //                     currentRow.PPIFVEN + currentRow.PPIFOTH + currentRow.PPINRI +
        //                     currentRow.PPFCOB + currentRow.PPFDI + currentRow.PPNRI + currentRow.PPFOTH).toFixed(2);
        //                 var nValNum = Number(nVal).toLocaleString("en-IN");
        //                 responseObject[column.field] = nValNum;
        //             }
        //         }
        //     }
        // });
        // rows.push(responseObject); responseObject = {};

        // DIIS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 7;
            }
            else if (column.field == "name") {
                responseObject["name"] = "DIIS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PPISUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Public
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 8;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Public";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PPSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // TOTAL PROMOTER AND NON PROMOTER
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 9;
            }
            else if (column.field == "name") {
                responseObject["name"] = "TOTAL PROMOTER AND NON PROMOTER";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.Total_Promoter_NonPromoter_PerShares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Depository Receipts
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 10;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Depository Receipts";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PCUSTOTH + currentRow.PCUST).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // ADR
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 11;
            }
            else if (column.field == "name") {
                responseObject["name"] = "ADR";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PADR).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // GDR
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 12;
            }
            else if (column.field == "name") {
                responseObject["name"] = "GDR";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PGDR).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // TOTAL SHAREHOLDING
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 13;
            }
            else if (column.field == "name") {
                responseObject["name"] = "TOTAL SHAREHOLDING";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PGRTOTAL).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        setPatternsRowInfo(rows);
    }

    function ProcessShareHoldingPatterns_PERCENTAGE_PLEDGE_SHARES(responsePatterns: ShareHoldingPatternInfo[], columnInfo: any[]) {
        // Process Rows
        var rows: any[] = [];
        var responseObject = {};
        // PROMOTERS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 1;
            }
            else if (column.field == "name") {
                responseObject["name"] = "PROMOTERS";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.TotalPromoter_PerPledgeShares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Indian
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 2;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Indian";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.GPMSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Foreign
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 3;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Foreign";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.GPMFSUBTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // NON-PROMOTERS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 4;
            }
            else if (column.field == "name") {
                responseObject["name"] = "NON-PROMOTERS";
            }
            else {
                responseObject[column.field] = "0";
            }
        });
        rows.push(responseObject); responseObject = {};

        // Govt. and clearing bodies
        // columnInfo.forEach((column) => {
        //     if (column.field == "id") {
        //         responseObject["id"] = 5;
        //     }
        //     else if (column.field == "name") {
        //         responseObject["name"] = "Govt. and clearing bodies";
        //     }
        //     else {
        //         responseObject[column.field] = "0";
        //     }
        // });
        // rows.push(responseObject); responseObject = {};

        // Foreign Investors
        // columnInfo.forEach((column) => {
        //     if (column.field == "id") {
        //         responseObject["id"] = 6;
        //     }
        //     else if (column.field == "name") {
        //         responseObject["name"] = "Foreign Investors";
        //     }
        //     else {
        //         responseObject[column.field] = "0";
        //     }
        // });
        // rows.push(responseObject); responseObject = {};

        // DIIS
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 7;
            }
            else if (column.field == "name") {
                responseObject["name"] = "DIIS";
            }
            else {
                responseObject[column.field] = "0";
            }
        });
        rows.push(responseObject); responseObject = {};

        // Public
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 8;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Public";
            }
            else {
                responseObject[column.field] = "0";
            }
        });
        rows.push(responseObject); responseObject = {};

        // TOTAL PROMOTER AND NON PROMOTER
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 9;
            }
            else if (column.field == "name") {
                responseObject["name"] = "TOTAL PROMOTER AND NON PROMOTER";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.Total_Promoter_NonPromoter_PerPledgeShares).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        // Depository Receipts
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 10;
            }
            else if (column.field == "name") {
                responseObject["name"] = "Depository Receipts";
            }
            else {
                responseObject[column.field] = "0";
            }
        });
        rows.push(responseObject); responseObject = {};

        // ADR
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 11;
            }
            else if (column.field == "name") {
                responseObject["name"] = "ADR";
            }
            else {
                responseObject[column.field] = "0";
            }
        });
        rows.push(responseObject); responseObject = {};

        // GDR
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 12;
            }
            else if (column.field == "name") {
                responseObject["name"] = "GDR";
            }
            else {
                responseObject[column.field] = "0";
            }
        });
        rows.push(responseObject); responseObject = {};

        // TOTAL SHAREHOLDING
        columnInfo.forEach((column) => {
            if (column.field == "id") {
                responseObject["id"] = 13;
            }
            else if (column.field == "name") {
                responseObject["name"] = "TOTAL SHAREHOLDING";
            }
            else {
                var filteredRows = responsePatterns.filter((o) => { return o.YRC == column.field; });
                if (filteredRows && filteredRows.length > 0) {
                    var currentRow = filteredRows[0];
                    if (currentRow) {
                        var nVal = (currentRow.PLPCGTOT).toFixed(2);
                        var nValNum = Number(nVal).toLocaleString("en-IN");
                        responseObject[column.field] = nValNum;
                    }
                }
            }
        });
        rows.push(responseObject); responseObject = {};

        setPatternsRowInfo(rows);
    }
}

export default HoldingPatterns
