import * as React from 'react';
import {
  Dialog,
  Button,
  Icon,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  //   Slide,
  Typography,
  Box,
  Link,
} from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { useNavigate } from "react-router-dom";
import { PathNames } from '../../appConfig/PathNames';


const FileUploadDialog = ({ isOpen, onClose, handleClose }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        keepMounted
        onClose={onClose}
        aria-describedby="total-views-handle"
        PaperProps={{
          sx: {
            width: {
              xs: '370px',
              sm: '370px',
              md: '499px',
              lg: '820px',
              xl: '820px',
            },
            maxWidth: '900px !important',
          },
        }}
      >
        <DialogTitle>
          {
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close"
                  onClick={onClose && handleClose}
                  color="primary"
                  disableRipple={true}
                >
                  <CloseSharpIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        </DialogTitle>
        <DialogContent
          sx={{
            height: 'auto',
            width: {
              xs: '300px',
              sm: '300px',
              md: '499px',
              lg: '800px',
              xl: '800px',
            },
            paddingX: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ height: '96px', width: '96px' }}
              src="/static/gif/happy_path.gif"
              alt="happy_path"
            />
          </Box>
          <Box>
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'center',
                justifyContent: 'center',
                color: '#696969',
                fontSize:{xs:'14px', md:'20px'},
                fontWeight: '700',
                lineHeight: '36px',
              }}
            >
              Your files have been successfully uploaded.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'center',
                justifyContent: 'center',
                color: '#696969',
                fontSize: {xs:'10px', md:'14px'},
                fontWeight: '700',
                lineHeight: '36px',
              }}
            >
              We will get back to you in the next 24 to 48 hrs.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              noWrap
              sx={{
                display: 'inline',
                flexDirection: 'center',
                justifyContent: 'center',
                color: '#696969',
                fontSize: {xs:'10px', md:'14px'},
                fontWeight: '700',
                lineHeight: '36px',
              }}
            >
              Till then keep exploring
            </Typography>
            <Typography color="inherit" noWrap>
              &nbsp;&nbsp;
            </Typography>
            <Link
            onClick={() => navigate(PathNames.FEED)}>
            <Typography
              noWrap
              sx={{
                display: 'inline',
                flexDirection: 'center',
                justifyContent: 'center',
                color: '#0F9764',
                fontSize: {xs:'10px', md:'14px'},
                fontWeight: '700',
                lineHeight: '36px',
                textDecoration: 'underline',
              }}
            >
              {'  '}
              Stock Knocks
              </Typography>
              </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FileUploadDialog;
