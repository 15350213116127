import { AxiosResponse } from "axios";
import { socialNetworkService, userService } from "../appConfig/AxiosConfig";
import { FeedResult, PostData, PostDataResult } from "../model/FeedDataModel";
import { refreshRewardInfo } from "./UserService";

const getTimeLine = async (userid: string, page: number, pagesize: number): Promise<FeedResult> => {
    let response: AxiosResponse<FeedResult> = await socialNetworkService.get<any, AxiosResponse<FeedResult>>(`/timelines?page=${page}&pagesize=${pagesize}`);
    return response.data;
}

const getFeedById = async (feedId: string): Promise<FeedResult> => {
    let response: AxiosResponse<FeedResult> = await socialNetworkService.get<any, AxiosResponse<FeedResult>>(`/timelines/${feedId}`);
    return response.data;
}

const getMyWall = async (page: number, pagesize: number): Promise<FeedResult> => {
    let response: AxiosResponse<FeedResult> = await socialNetworkService.get<any, AxiosResponse<FeedResult>>(`/wall?page=${page}&pagesize=${pagesize}`);
    return response.data;
}

const getCompanyWall = async (companyid: string, page: number, pagesize: number): Promise<FeedResult> => {
    try {
        throw new Error('getCompanyWall not implemented');
        //let response: AxiosResponse<FeedResult> = await socialNetworkService.get<any, AxiosResponse<FeedResult>>(`/timelines?page=${page}&pagesize=${pagesize}`);
        //let response: AxiosResponse<FeedResult> = await socialNetworkService.get<any, AxiosResponse<FeedResult>>(`/wall/${companyid}?page=${page}&pagesize=${pagesize}`);
        //return response.data;
    }
    catch (error) {
        return [];
    }
}

const getUserWall = async (userid: string, page: number, pagesize: number): Promise<FeedResult> => {
    let response: AxiosResponse<FeedResult> = await socialNetworkService.get<any, AxiosResponse<FeedResult>>(`/wall/${userid}?page=${page}&pagesize=${pagesize}`);
    return response.data;
}

const getMinimalBlogs = async () => {
    let response = await socialNetworkService.get('/v2/blogs/published/minimal');
    return response.data;
}

// const getFeedData = async (userid: string, page: number, pagesize: number): Promise<newsFeedData> => {
//     let response: AxiosResponse<newsFeedData> = await axios.get<any, AxiosResponse<newsFeedData>>(`/posts?page=${page}&pagesize=${pagesize}`);
//     return response.data;
// }

const postFeed = async (feedData: FormData): Promise<PostData> => {
    let response: AxiosResponse<PostDataResult> = await socialNetworkService.post<any, AxiosResponse<PostDataResult>>(`/posts`, feedData);
    refreshRewardInfo()
    return response.data.data;
}

const deleteFeed = async (postId: string): Promise<Boolean> => {
    let response: AxiosResponse<any> = await socialNetworkService.delete<any, AxiosResponse<any>>(`/posts/${postId}`);
    return response.data?.status?.toLowerCase() === "success" || response.data?.status?.toLowerCase() === "ok";
}

const updateFeed = async (postId: string, feedData: FormData): Promise<Boolean> => {
    let response: AxiosResponse<any> = await socialNetworkService.patch<any, AxiosResponse<any>>(`/posts/${postId}`, feedData);
    return response.data?.status?.toLowerCase() === "success" || response.data?.status?.toLowerCase() === "ok";
}

const sharePost = async (postId: string, postType: string, shareNotes: string, mentions: string[], hastags: string[]) => {
    let response = await socialNetworkService.post(`/posts/share`, { parent_id: postId, parent_type: postType, description: shareNotes, hashtags: hastags, mentions: mentions });
    return response.data.data;
}

const updateSharePost = async (postId: string, shareNotes: string, mentions: string[], hastags: string[]) => {
    let response = await socialNetworkService.patch(`/posts/share/${postId}`, { description: shareNotes, hashtags: hastags, mentions: mentions });
    return response.data.data;
}

export { getMinimalBlogs,getTimeLine, getMyWall, getUserWall, getCompanyWall, postFeed, deleteFeed, updateFeed, getFeedById, sharePost, updateSharePost }
