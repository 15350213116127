import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Utility } from '../../utility/Utility';
import { postPricingForm } from '../../services/AdvertisementService';

const PlansForm = () => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState<any | null>('');
  const [isDisabled, setIsDisabled] = React.useState(true);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  React.useEffect(() => {
    setIsDisabled(!isValidEmail(email));
  }, [email]);

  const onSubmit = async () => {
    if (!firstName) {
      return Utility.notify({ message: 'Enter First Name', type: 'error' });
    }
    if (!lastName) {
      return Utility.notify({ message: 'Enter Last Name', type: 'error' });
    }
    if (!phone) {
      return Utility.notify({ message: 'Enter Phone number', type: 'error' });
    }
    if (!email) {
      return Utility.notify({ message: 'Enter Email', type: 'error' });
    }
    if (!message) {
      return Utility.notify({ message: 'Enter Message', type: 'error' });
    }

    try {
      const response = await postPricingForm(
        firstName,
        lastName,
        phone,
        email,
        message
      );
      setFirstName('');
      setLastName('');
      setPhone('');
      setEmail('');
      setMessage('');

      if (response && response.message) {
        Utility.notify({ message: response.message, type: 'success' });
      } else {
        Utility.notify({
          message:
            "Thank you for reaching out! Your inquiry has been successfully submitted. We'll get back to you shortly. If you have any further questions, feel free to contact us. Have a great day!",
          type: 'success',
        });
      }
    } catch (error: any) {
      console.error(error);
      Utility.notify({ message: error.message, type: 'error' });
    }
  };

  
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          marginLeft: { md: 8, xs: 5 },
          marginRight: { md: 6, xs: 2 },
          flexDirection: 'column',
          gap: 2,
          marginTop: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: '#000',
                fontFamily: 'Oxygen',
                fontSize: { ms: '46px', xs: '32px' },
                fontWeight: '700',
              }}
            >
              Get in{' '}
              <span
                style={{
                  color: '#0F9764',
                }}
              >
                Touch
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '-50px',
            }}
          >
            <Typography
              sx={{
                color: '#484848',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.16px',
                textAlign: 'center',
                margin: '10px',
              }}
            >
              If you require a tailored solution or wish to explore additional
              options, please leave your details below. A Stock Knocks
              representative will contact you shortly to discuss personalized
              packages that suit your specific requirements.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: { md: '70%', xs: '100%' },
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '90%',
                  }}
                  size="small"
                  label="First Name *"
                  rows={4}
                  inputProps={{ maxLength: '20' }}
                  value={firstName}
                  onChange={(ev) =>
                    setFirstName(
                      ev.currentTarget.value.replace(/[^A-Za-z]/gi, '')
                    )
                  }
                  InputProps={{
                    style: {
                      borderRadius: '4px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{`${firstName?.length}/20`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '90%',
                  }}
                  size="small"
                  label="Last Name *"
                  rows={4}
                  inputProps={{ maxLength: '20' }}
                  value={lastName}
                  onChange={(ev) =>
                    setLastName(
                      ev.currentTarget.value.replace(/[^A-Za-z]/gi, '')
                    )
                  }
                  InputProps={{
                    style: {
                      borderRadius: '4px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{`${lastName?.length}/20`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '90%',
                  }}
                  size="small"
                  label="Phone number *"
                  rows={4}
                  inputProps={{ maxLength: '10' }}
                  value={phone}
                  onChange={(ev) =>
                    setPhone(ev.currentTarget.value.replace(/[^0-9]/g, ''))
                  }
                  InputProps={{
                    style: {
                      borderRadius: '4px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{`${phone?.length}/10`}</Typography>
                      </InputAdornment>
                    ),
                    inputMode: 'numeric',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: '90%',
                  }}
                  size="small"
                  type={'email'}
                  label="Email *"
                  rows={4}
                  inputProps={{ maxLength: '30' }}
                  value={email}
                  //   onChange={(ev) => setEmail(ev.currentTarget.value)}
                  onChange={handleChange}
                  InputProps={{
                    style: {
                      borderRadius: '4px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{`${email?.length}/30`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    {error}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0px',
                    width: { md: '95%', xs: '90%' },
                  }}
                  size="small"
                  label="Message *"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: '500' }}
                  value={message}
                  onChange={(ev) => setMessage(ev.currentTarget.value)}
                  InputProps={{
                    style: {
                      borderRadius: '4px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>{`${message?.length}/500`}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: { md: '60%', xs: '100%' },
            }}
          >
            <Button
              onClick={onSubmit}
              disabled={isDisabled}
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '0px',
                width: { md: '50%', xs: '60%' },
                color: '#FFF',
                borderRadius: '8px',
                marginLeft: { xs: '-10px' },
              }}
            >
              SEND
            </Button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PlansForm;
