import React from "react";
import { useLocation } from "react-router-dom";
import { PathNames } from "../../appConfig/PathNames";

export interface IPageContainer {
    title: string;
    description: string;
    keyword: string;
    h1: string;
    children: any;
}
const PageContainer = (props: IPageContainer) => {
    const path = useLocation();
    React.useEffect(() => {
        document.title = props.title;
        let metaKeyword = document.getElementsByTagName('meta').namedItem('keywords');
        if (!metaKeyword) {
            metaKeyword = document.createElement('meta');
            metaKeyword.setAttribute('name', 'keywords')
            document.head.appendChild(metaKeyword)
        }
        metaKeyword.setAttribute('content', props.keyword);
        let metaDescription = document.getElementsByTagName('meta').namedItem('description');
        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description')
            document.head.append(metaDescription);
        }
        metaDescription.setAttribute('content', props.description);
        return () => {
            document.title = 'StockKnocks';
            metaKeyword?.setAttribute('content', '');
            metaDescription?.setAttribute('content', 'StockKnocks');
        }
    })
    return <div style={{ backgroundColor: path.pathname === PathNames.FEED ? "#F9F9F9" : "" }}>
        <h1 style={{ display: 'none' }}>{props.h1}</h1>
        {props.children}
    </div>
}

export default PageContainer;