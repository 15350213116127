import * as React from 'react';
import clsx from 'clsx';
import { GridCellParams } from '@mui/x-data-grid';
import { useTheme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';

interface ProgressBarProps {
    value: number;
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
    const { value } = props;

    const defaultTheme = useTheme();

    const useStyles = makeStyles(
        (theme) =>
            createStyles({
                root: {
                    border: `1px solid ${theme.palette.divider}`,
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                    height: 26,
                    borderRadius: 2,
                },
                value: {
                    position: 'absolute',
                    lineHeight: '24px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                },
                bar: {
                    height: '100%',
                    '&.low': {
                        backgroundColor: '#0f9764',
                    },
                    '&.medium': {
                        backgroundColor: '#0f9764',
                    },
                    '&.high': {
                        backgroundColor: '#0f9764',
                    },
                },
            }),
        { defaultTheme },
    );

    const valueInPercent = value;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.value}>{`${valueInPercent.toLocaleString()} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: valueInPercent < 30,
                    medium: valueInPercent >= 30 && valueInPercent <= 70,
                    high: valueInPercent > 70,
                })}
                style={{ maxWidth: `${valueInPercent}%` }}
            />
        </div>
    );
});

export function renderCellProgress(params: GridCellParams) {
    return <ProgressBar value={Number(params.value)!} />;
}