import React from 'react';
import { getUserWall } from '../../services/SocialMediaService';
import NewsFeed from './NewsFeed';

const PostSectionComponent = (props: { co_id: string; co_userid: string }) => {
  return (
    <NewsFeed
      userId={props.co_userid}
      companyId={props.co_id}
      feedApi={getUserWall}
    />
  );
};

export default PostSectionComponent;
