import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Link } from 'react-router-dom';
import UnlistedScreenerContactForm from './UnlistedScreenerContactForm';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UnlistedScreenerGoToPlan = ({ isOpen, handleClose, credits }) => {
  const [showContactForm, setShowContactForm] = React.useState<boolean>(false);

  const handleContactFormClose = () => setShowContactForm(false); //handleContactFormClose
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-handle-confirm"
        PaperProps={{
          sx: {
            width: {
              xs: '370px',
              sm: '370px',
              md: '499px',
              lg: '820px',
              xl: '820px',
            },
            maxWidth: '627px !important',
          },
        }}
      >
        <DialogTitle>
          <>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
                <Box className="control-row">
                  <Typography
                    sx={{
                      display: 'inline',
                      justifyContent: 'left',
                      fontSize: { xs: '16px', md: '16px' },
                      fontWeight: '700',
                      color: '#717889',
                    }}
                  >
                    ⚠️ Insufficient Credits Alert
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  color="primary"
                  disableRipple={true}
                >
                  <CloseSharpIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'center',
              //   textAlign: 'left',
            }}
          >
            <Typography sx={{ paddingY: '12px', color: ' #717889' }}>
              Hello there! 👋
            </Typography>
            <Typography sx={{ paddingTop: '12px', color: ' #717889' }}>
              It looks like you currently have{' '}
              <span style={{ color: '#0F9764' }}>
                {credits} {credits === 1 ? 'credit' : 'credits'}{' '}
              </span>
              in your account, which is not enough to unlock a company.
            </Typography>
            <Typography sx={{ paddingBottom: '12px', color: ' #717889' }}>
              No worries, though! You can easily top up your credits and
              continue exploring amazing companies waiting for you.
            </Typography>
            <Link
              onClick={scrollToTop}
              style={{
                color: '#0F9764',
                display: 'flex',
                alignItems: 'center',
              }}
              to={`/premium-plans`}
            >
              <img
                src="/static/pricing-page/rupee.png"
                alt="rupee"
                style={{ width: '24px', height: '24px' }}
              />
              <Typography
                color="primary"
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  paddingY: '12px',
                  fontFamily: 'Oxygen',
                  marginLeft: '8px',
                }}
                component={'div'}
              >
                Add More Credits
              </Typography>
            </Link>

            <Typography sx={{ paddingTop: '12px', color: ' #717889' }}>
              Need help or have questions? Our support team is here for you!
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginTop: '0px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ margin: '12px', borderRadius: '8px' }}
            >
              Later
            </Button>
            <Button
              variant="contained"
              sx={{ margin: '12px', borderRadius: '8px' }}
              onClick={() => setShowContactForm(true)}
            >
              Contact
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {showContactForm && (
        <UnlistedScreenerContactForm
          isOpen={showContactForm}
          onClose={handleContactFormClose}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default UnlistedScreenerGoToPlan;
