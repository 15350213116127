import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathNames } from '../../appConfig/PathNames';
import { Notification } from '../../model/Notification';
import { OrderDetails } from '../../model/OrderDetails';
import { PricingPlan } from '../../model/PricingPlan';
import { ReduxStore } from '../../model/ReduxStore';
import {
  getPlanDetails,
  startTransaction,
  storeTransaction,
} from '../../services/PaymentService';
import { Utility } from '../../utility/Utility';
import { makePayment } from './PaymentDisplay';

const indianStates = [
  { label: 'Andhra Pradesh' },
  { label: 'Arunachal Pradesh' },
  { label: 'Assam' },
  { label: 'Bihar' },
  { label: 'Chhattisgarh' },
  { label: 'Goa' },
  { label: 'Gujarat' },
  { label: 'Haryana' },
  { label: 'Himachal Pradesh' },
  { label: 'Jharkhand' },
  { label: 'Karnataka' },
  { label: 'Kerala' },
  { label: 'Madhya Pradesh' },
  { label: 'Maharashtra' },
  { label: 'Manipur' },
  { label: 'Meghalaya' },
  { label: 'Mizoram' },
  { label: 'Nagaland' },
  { label: 'Odisha' },
  { label: 'Punjab' },
  { label: 'Rajasthan' },
  { label: 'Sikkim' },
  { label: 'Tamil Nadu' },
  { label: 'Telangana' },
  { label: 'Tripura' },
  { label: 'Uttar Pradesh' },
  { label: 'Uttarakhand' },
  { label: 'West Bengal' },
  { label: 'Andaman and Nicobar Islands' },
  { label: 'Chandigarh' },
  { label: 'Dadra and Nagar Haveli and Daman and Diu' },
  { label: 'Delhi' },
  { label: 'Lakshadweep' },
  { label: 'Puducherry' },
];

const Premium = () => {
  const userData = useSelector((state: ReduxStore) => state.userData.user);
  let location = useLocation();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState(userData?.name.split(' ')[0]);
  const [lastName, setLastName] = useState(userData?.name.split(' ')[1]);
  const [email, setEmail] = useState(userData?.email);
  const [address, setAddress] = useState(userData?.address1);
  const [city, setCity] = useState(userData?.city);
  const [state, setState] = useState('Andhra Pradesh');
  const [pincode, setPincode] = useState(userData?.pincode);
  const [gst, setGST] = useState('');
  const [price, setPrice] = useState('');
  const [tax, setTax] = useState('');
  const [total, setTotal] = useState('');
  const [planDetails, setPlanDetails] = useState<PricingPlan>({
    plan_price: '0',
    plan_tax_percentage: '0',
  });
  const [tokenDetails, setTokenDetails] = useState<any>({});
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [type, setType] = React.useState<any | null>(indianStates[0]);

  const getPlanDetailsByPlanCode = async (plan_code) => {
    const planDetailsResponse = await getPlanDetails(plan_code);
    setPlanDetails(planDetailsResponse);
    const planPrice = planDetailsResponse?.plan_price || '0';
    const planTax = planDetailsResponse?.plan_tax_percentage || '0';
    setPrice(planPrice);
    setTax((parseInt(planPrice) * parseInt(planTax)) / 100 + '');
    setTotal(
      parseInt(planPrice) + (parseInt(planPrice) * parseInt(planTax)) / 100 + ''
    );
  };

  useEffect(() => {
    let plan_code = new URLSearchParams(location.search).get('plan_code');

    if (plan_code) {
      getPlanDetailsByPlanCode(plan_code);
    } else {
      const notification: Notification = {
        message: 'Please choose a plan to move forward',
        type: 'error',
      };
      Utility.notify(notification);
    }
  }, []);

  useEffect(() => {
    setShowLoader(false);
    if (showPayment && tokenDetails) {
      makePayment(tokenDetails, () => { setShowPayment(false); setTokenDetails({}) });
    }
  }, [showPayment, tokenDetails]);

  // Function to validate Email
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // Function to validate GST number
  const isValidGST = (gst: string): boolean => {
    const gstRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})?$/;
    return gstRegex.test(gst);
  };

  const validateForm = () => {
    return (
      firstName &&
      lastName &&
      email &&
      address &&
      city &&
      state &&
      pincode &&
      isValidEmail(email) && // Ensure email is valid
      isValidGST(gst) // Validate GST separately
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (validateForm()) {
        const orderDetails: OrderDetails = {
          created_for_type: 'User',
          co_code: '',
        };
        orderDetails.buyerFirstName = firstName;
        orderDetails.buyerLastName = lastName;
        orderDetails.buyerEmail = email;
        orderDetails.buyerAddress = address;
        orderDetails.buyerCity = city;
        orderDetails.buyerState = state;
        orderDetails.buyerCountry = 'IN';
        orderDetails.buyerPincode = pincode;
        orderDetails.plan_id = planDetails?._id;
        orderDetails.amount = total;

        orderDetails.currency = 'INR';
        // Show the loader while processing
        setShowLoader(true);
        const transactionResponse = await storeTransaction(orderDetails);
        const tokenResponse = await startTransaction(
          transactionResponse.data._id
        );
        setTokenDetails(tokenResponse.data);
        setShowPayment(true);
      }
    } catch (error) {
      console.error('Error during payment:', error);
      setShowLoader(false); // Hide the loader in case of an error
    }
  };

  const transectionFormRef = React.createRef<HTMLFormElement>();
  return (
    <>
      {showLoader && (
        <CircularProgress
          color="primary"
          size="30px"
          sx={{ marginLeft: 'calc(50% - 15px)', marginTop: '50%' }}
        />
      )}
      {!showLoader && (
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          flexDirection: { md: 'row', xs: 'column-reverse' },
          marginLeft: { md: 7, xs: 2 },
          marginRight: { md: 6, xs: 2 },
          paddingBottom: { md: 0, xs: 5 },
        }}
      >
        <form
          style={{ display: 'none' }}
          ref={transectionFormRef}
          method="post"
        >
          <input
            type="hidden"
            name="authorization"
            value={`Bearer ${Utility.getAuthToken()}`}
          />
        </form>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            background: '#F7FAFC',
            width: { md: '80%', xs: '86%' },
            paddingX: '7%',
            paddingY: '24px',
            borderRadius: '9px',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              color: '#0D0D0D',
            }}
          >
            Billing details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#686868',
                  }}
                >
                  First Name
                </Typography>
                <OutlinedInput
                  notched={false}
                  sx={{
                    background: '#E2E8F0',
                    '& fieldset': { border: 'none' },
                    borderRadius: '9px',
                  }}
                  value={firstName}
                  onChange={(e) =>
                    setFirstName(e.target.value.replace(/[^A-Za-z]/gi, ''))
                  }
                  inputProps={{ maxLength: '20' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography>{`${firstName?.length}/20`}</Typography>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#686868',
                  }}
                >
                  Last Name
                </Typography>
                <OutlinedInput
                  notched={false}
                  sx={{
                    background: '#E2E8F0',
                    '& fieldset': { border: 'none' },
                    borderRadius: '9px',
                  }}
                  value={lastName}
                  onChange={(e) =>
                    setLastName(e.target.value.replace(/[^A-Za-z]/gi, ''))
                  }
                  inputProps={{ maxLength: '20' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography>{`${lastName?.length}/20`}</Typography>
                    </InputAdornment>
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '#686868',
                }}
              >
                Email
              </Typography>
              <OutlinedInput
                notched={false}
                sx={{
                  background: '#E2E8F0',
                  '& fieldset': { border: 'none' },
                  borderRadius: '9px',
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // onChange={handleChange}
                inputProps={{ maxLength: '50' }}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography>{`${email?.length}/50`}</Typography>
                  </InputAdornment>
                }
              />
              {!isValidEmail(email) && (
                <Typography style={{ color: 'red', fontSize: '12px' }}>
                  Email is invalid
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '#686868',
                }}
              >
                Address
              </Typography>
              <OutlinedInput
                notched={false}
                sx={{
                  background: '#E2E8F0',
                  '& fieldset': { border: 'none' },
                  borderRadius: '9px',
                }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                inputProps={{ maxLength: '50' }}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography>{`${address?.length}/50`}</Typography>
                  </InputAdornment>
                }
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#686868',
                  }}
                >
                  City
                </Typography>
                <OutlinedInput
                  notched={false}
                  sx={{
                    background: '#E2E8F0',
                    '& fieldset': { border: 'none' },
                    borderRadius: '9px',
                  }}
                  value={city}
                  onChange={(e) =>
                    setCity(e.target.value.replace(/[^A-Za-z]/gi, ''))
                  }
                  inputProps={{ maxLength: '30' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography>{`${city?.length}/30`}</Typography>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#686868',
                  }}
                >
                  State
                </Typography>
                <Autocomplete
                  sx={{
                    background: '#E2E8F0',
                    borderRadius: '9px !important',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  value={type}
                  onChange={(event, newValue) => {
                    setType(newValue);
                  }}
                  inputValue={state}
                  onInputChange={(event, newInputValue) => {
                    setState(newInputValue);
                  }}
                  options={indianStates}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      sx={{ '& fieldset': { border: 'none' } }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#686868',
                  }}
                >
                  Pincode
                </Typography>
                <OutlinedInput
                  notched={false}
                  sx={{
                    background: '#E2E8F0',
                    '& fieldset': { border: 'none' },
                    borderRadius: '9px',
                  }}
                  value={pincode}
                  onChange={(e) =>
                    setPincode(e.target.value.replace(/[^0-9]/g, ''))
                  }
                  inputProps={{ maxLength: '6' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography>{`${pincode?.length}/6`}</Typography>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#686868',
                  }}
                >
                  GST No
                </Typography>
                <OutlinedInput
                  notched={false}
                  sx={{
                    background: '#E2E8F0',
                    '& fieldset': { border: 'none' },
                    borderRadius: '9px',
                  }}
                  value={gst}
                  onChange={(e) => setGST(e.target.value)}
                  inputProps={{ maxLength: '15' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography>{`${gst?.length}/15`}</Typography>
                    </InputAdornment>
                  }
                />
                {!isValidGST(gst) && (
                  <Typography style={{ color: 'red', fontSize: '12px' }}>
                    Invalid GST number
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 4,
            }}
          >
            <Button
              sx={{ fontWeight: '16px', color: '#686868' }}
              variant="text"
              onClick={() => navigate(`${PathNames.PLANS}`)}
            >
              Cancel order
            </Button>
            <Button
              sx={{
                fontWeight: '16px',
                width: '150px',
              }}
              variant="contained"
              type="submit"
              disabled={!validateForm()}
            >
              Pay now
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            background: '#F7FAFC',
            width: { xs: '100%', md: '70%' },
            height: '262px',
            borderRadius: '9px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '5%',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: '#0D0D0D',
                fontWeight: 700,
                fontSize: '16px',
              }}
            >
              Order Summary
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                sx={{
                  color: '#686868',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Plan Name
              </Typography>
              <Typography
                sx={{
                  color: '#0D0D0D',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                {planDetails.plan_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                sx={{
                  color: '#4A5568',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Sub total
              </Typography>
              <Typography
                sx={{
                  color: '#2D3748',
                  fontSize: '16px',
                }}
              >
                ₹ {planDetails.plan_price}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                sx={{
                  color: '#4A5568',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                GST @18%
              </Typography>
              <Typography sx={{
                            color: "#2D3748",
                            fontSize: "16px",
                        }}>₹ {tax}</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Typography
                sx={{
                  color: '#2D3748',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                Total
              </Typography>
              <Typography
                sx={{
                  color: '#2D3748',
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                ₹ {total}
              </Typography>
              
            </Box>
            {/* <Typography
                sx={{
                  color: '#4A5568',
                  fontSize: '16px',
                }}
              >
                (Inclusive of GST)
              </Typography> */}
          </Box>
        </Box>
        </Box>
        )}
    </>
  );
};

export default Premium;
