import * as React from 'react';
import CompanyWebsite from './CompanyWebsite';

const IFrame = (companyShort: any) => {

  return (
    <>
      <CompanyWebsite companyShort={companyShort}></CompanyWebsite>
    </>
  );
};

export default IFrame;
