import { IPriceFormatter } from "lightweight-charts"
import { marketplaceService } from "../appConfig/AxiosConfig"
import { ISellerProduct } from "../pages/MarketPlace/Models/SellerProduct"
import { ISellerBanner } from "../pages/MarketPlace/Models/SellerBanner";
import { ISellerCategory } from "../pages/MarketPlace/Models/SellerCategory";

export async function getAllProducts(sellerid: string, page: number, category?: string) {
    return await (await marketplaceService.get<{
        data: ISellerProduct[];
        total_records: string
    }>(`/products/getproducts?id=${sellerid}&page=${page}&pagesize=50&count=true${category ? "&category=" + category : ""}`)).data
}

export async function getProduct(sellerId: string, productId: string) {
    return await (await marketplaceService.get<ISellerProduct>(`/products/getproduct?id=${productId}`)).data
}

export async function getSellerBanner(sellerid: string) {
    return await (await marketplaceService.get<ISellerBanner>(`/storefrontuser/info?id=${sellerid}`)).data
}

export async function getSellerCategory(sellerid: string) {
    return await (await marketplaceService.get<ISellerCategory[]>(`/productcategories/all?id=${sellerid}`)).data
}