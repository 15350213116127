import React from 'react'
import ListedCompanyWebsite from './ListedCompanyWebsite';

const WebsiteComponent = (companyShort:any) => {
  return (
    <>
      <ListedCompanyWebsite companyShort={companyShort}></ListedCompanyWebsite>
    </>
  )
}

export default WebsiteComponent;