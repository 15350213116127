import { Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect } from 'react';
import { DateExtensions } from "../../utility/DateExtensions";
import useStyle from "./Styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 


function GetRatingsCard(props: { ratings: any, type: number, loading: boolean, updated_on?: string }) {
    const ColumnsDisplayName = {
        'agency': 'Agency',
        'instrument': 'Instrument',
        'period': 'Period',
        'rating': 'Rating Assigned',
        'rating_date': 'Rating Date'
    }

    const masterClasses = useStyle();

    const [rows, setRows] = React.useState<any>([]);

    const [columns, setColumns] = React.useState<any>([]);

    const [showContent, setShowContent] = React.useState<boolean>(false);

    const [updatedOn, setUpdatedOn] = React.useState<string>('');

    const isSM = useMediaQuery("(min-width:600px)");

    const [link,setLink]= React.useState(false)

    useEffect(() => {
        if (props.updated_on && props.updated_on.length > 0) {
            let dateString = DateExtensions.getDateStringUppercaseMonthFromString(props.updated_on, ' ', '');
            if (dateString?.trim()?.length > 0) {
                setUpdatedOn(`Updated On: ${dateString}`);
            }
        }
    }, [props.updated_on]);

    const showDiv = (): boolean => {
        if ((!props.loading) && (props.type === 9) && props.ratings && Array.isArray(props.ratings) && props.ratings.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        if (showDiv()) {
            setShowContent(showDiv());

            // Process Columns
            var columnOptions: any[] = [];
            columnOptions.push({
                field: 'agency',
                headerName: ColumnsDisplayName['agency'],
                width: 100
            });
            columnOptions.push({ field: 'instrument', headerName: ColumnsDisplayName['instrument'], flex: 1, minWidth: 150 });
            columnOptions.push({ field: 'period', headerName: ColumnsDisplayName['period'], width: 100 });
            columnOptions.push({ field: 'rating', headerName: ColumnsDisplayName['rating'], width: 150 });
            columnOptions.push({ field: 'rating_date', headerName: ColumnsDisplayName['rating_date'], width: 100 });

            setColumns(columnOptions);

            // Process Rows
            var rows: any[] = [];
            try {
                props.ratings.forEach((row) => {
                    if (row) {
                        var responseObject = {};

                        responseObject["agency"] = row.agency;
                        responseObject["instrument"] = row.instrument;
                        responseObject["period"] = row.period;
                        responseObject["rating"] = row.rating;
                        responseObject["rating_date"] = DateExtensions.getDateStringUppercaseMonthFromString(row.rating_date, " ");
                        rows.push(responseObject);
                    }

                    if (rows?.length > 0) {
                        setRows(rows);
                    }
                    else {
                        setRows([]);
                    }
                });
            }
            catch (error) {
                console.error(error);
                setRows([]);
            }
        }
    }, [props.ratings, props.loading]);

    try {
        return (
            <React.Fragment>
                {showContent &&
                    <Box className={masterClasses.root} sx={{ marginTop: '24px' }} id='tabdetail-ratings'>
                        <Card elevation={0} sx={{ paddingY: '24px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '8px' }}>
                            <CardHeader
                                classes={{ action: masterClasses.action, title: masterClasses.cardHeaderTitle, subheader: masterClasses.cardSubHeaderTitle }}
                                sx={{ marginX: { sm: "24px", xs: "24px" }, textAlign: 'left', paddingLeft: '0px', paddingRight: '8px', paddingTop: '0px', paddingBottom: '32px' }}
                                title={<Typography
                                    sx={{ fontSize: { xs: '16px', md: '24px' } }}
                                    // className={masterClasses.cardHeaderTitle}
                                >Ratings</Typography>}
                                subheader={updatedOn}
                                action={
                                    <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                                    <IconButton
                                            onClick={() => {
                                window.location.hash = 'tabdetail-ratings'
                                navigator.clipboard.writeText(window.location.href)
                                setLink(true)
                            }}
                        >
                            <ContentCopyIcon
                                sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                        </IconButton>
                    </Tooltip>
                                }
                                // action={
                                //     <DownloadButton variant="contained" color="primary" sx={{ height: '39px', width: '128px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none' }} />
                                // }
                            >
                            </CardHeader>
                            <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }} className={masterClasses.cardBodyText}>
                                <Box
                                    sx={{
                                        flexGrow: 1, bgcolor: '#F9F9F9', display: 'flex', paddingY: '0px', paddingX: { xs: '5px', sm: '5px', md: '24px' },
                                        '& .MuiDataGrid-pinnedColumnHeaders': {
                                            backgroundColor: '#F9F9F9 !important'
                                        },
                                        '& .MuiDataGrid-pinnedColumns': {
                                            backgroundColor: '#F9F9F9 !important'
                                        }
                                    }}
                                >
                                    <DataGridPro
                                        initialState={ isSM === true ? {} : { pinnedColumns: { left: ['agency'] } }}
                                        rows={rows}
                                        getRowId={(row) => `${row.agency}_${row.instrument}_${row.period}_${row.rating}_${row.rating_date}`}
                                        columns={columns}
                                        density={"compact"}
                                        autoHeight={true}
                                        pageSize={5}
                                        pagination={true}
                                        rowsPerPageOptions={[5]}
                                        columnVisibilityModel={{
                                            // Hide columns id, the other columns will remain visible
                                            id: false,
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                }
            </ React.Fragment>
        );
    }
    catch (error) {
        console.error(error);
        return (<React.Fragment></React.Fragment>);
    }
}

export default GetRatingsCard;