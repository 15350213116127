import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { ReduxStore } from '../../model/ReduxStore';
import jwt_decode from 'jwt-decode';
import { PathNames } from '../../appConfig/PathNames';
import { RoleUtility } from '../../utility/RoleUtility';
import { useState } from 'react';
import { refreshToken } from '../../services/AuthenticationService';
import CircularIndeterminate from '../Loading/Loading';
import React from 'react';

const AuthGuard = ({ children }) => {
  const userData = useSelector((state: ReduxStore) => state?.userData?.user);
  const { pathname, search } = useLocation();

  const PendingCoUserPathList = [
    PathNames.PLANS.toString(),
    PathNames.PREMIUM.toString(),
    PathNames.PAYMENTFAILURE.toString(),
    PathNames.PAYMENTSUCCESS.toString(),
    PathNames.PAYMENTREFUND.toString(),
  ];





  const token = useSelector((state: ReduxStore) => (state?.auth?.user as any)?.stsTokenManager?.accessToken);
  const [jwtExpiry, setJwtExpiry] = useState(null as any);
  const [refreshingToken, setRefreshingToken] = useState(false);
  const [tokenUpdatedOn, setTokenUpdatedOn] = useState<number>(Date.now());
  const TOKEN_CHK_INTERVAL = 5;

  function isValidToken(token: any) {
    let isValid = false;
    try {
      if (token) {
        let { exp }: any = jwt_decode(token);
        if (exp > Math.floor(Date.now() / 1000)) {
          isValid = true;
        }
      }
    }
    catch (e) {
      console.error("Token Validation Failed");
      console.error(e);
    }

    return isValid;
  };

  async function onRefreshToken() {
    try {
      let newToken = await refreshToken();
      if (isValidToken(newToken)) {
        setTokenUpdatedOn(Date.now());
      }
      else {
        console.error("AuthGuard Token Update Failed - Invalid Token");
        signOut();
      }
    }
    catch (e) {
      console.error("AuthGuard Token Update Failed");
      console.error(e);
      signOut();
    }
  }

  function signOut() {
    return <Navigate to={`${PathNames.SIGNOUT}?from=${pathname}`} replace={true} />;
  }

  React.useEffect(() => {
    if (isValidToken(token)) {
      setRefreshingToken(false);
      let { exp }: any = jwt_decode(token);
      setJwtExpiry((exp - Date.now() / 1000) | 0);
    }
  }, [token])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (jwtExpiry !== null && jwtExpiry > 0) {
        setJwtExpiry((prevSeconds: any) => prevSeconds - (TOKEN_CHK_INTERVAL * 60));
      }
      if (jwtExpiry !== null && jwtExpiry <= 6 * 60) {
        setRefreshingToken(true);
        onRefreshToken();
      }
    }, TOKEN_CHK_INTERVAL * 60 * 1000);
    return () => clearInterval(interval);
  }, [jwtExpiry]);

  if (token?.length > 0 && userData) {
    if (refreshingToken === true) {
      return <CircularIndeterminate />;
    }

    if (((Date.now() - tokenUpdatedOn) / 1000) >= 86400) {
      signOut();
    }

    if (isValidToken(token) === false) {
      setRefreshingToken(true);
      onRefreshToken();
      return <CircularIndeterminate />;
    }
  }






  if (RoleUtility.isPremiumCompanyUserPaymentPending(userData?.role) === true && !PendingCoUserPathList.includes(pathname)) {
    return <Navigate to={PathNames.PLANS} replace={true} />;
  }
  
  else if (RoleUtility.isAdmin(userData?.role) === false && pathname.toLocaleLowerCase() === PathNames.ADMIN_PANEL.toLocaleLowerCase()) {
    return <Navigate to={PathNames.FEED} replace={true} />;
  }
  else if (RoleUtility.isPremium(userData?.role) === true && (pathname.toLocaleLowerCase() === '/dashboard/default' || search === '/dashboard/default')) {
    return <Navigate to={`${PathNames.Dashboard}?openInNewWindow=${false}`} replace={true} />;
  }
  else {
    return <>{children}</>;
  }
};

export default AuthGuard;
