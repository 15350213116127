import React, { useEffect } from 'react';
import { Box, Button, Grid, Paper, Tab, Tabs, Typography, useMediaQuery,Tooltip,IconButton } from '@mui/material';
import useStyle from '../Styles';
import { Financials } from '../../../model/CompanyIndividualSummaryModel';
import {  withStyles } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { Dictionary } from 'lodash';
import { DateExtensions } from '../../../utility/DateExtensions';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { NumberExtensions } from '../../../utility/NumberExtensions';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../../model/ReduxStore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 

export interface IEventCardProps {
    co_code: string | number | undefined;
    displayType: string;
    recordType: string;
    exchange?: 'NSE' | 'BSE';
    pnl?: Dictionary<any>[];
    advancedTabCallback: Function;
    dataAvailable: boolean;
}

const ListedFinancialsCard = (props: IEventCardProps) => {
    const [tabValue, setTabValue] = React.useState<string>('revenue');
    const [finData, setFinData] = React.useState<Financials | undefined>(undefined);
    const currentUser = useSelector((state: ReduxStore) => state.userData.user);

    const classes = useStyle();
    const [link,setLink]= React.useState(false)

    const isSM = useMediaQuery("(min-width:600px)");

    const VerticalTab = withStyles((theme: any) => ({
        root: {
            backgroundColor: '#EBEBEB',
            border: '1px solid #F9F9F9',
            [theme.breakpoints.down('md')]: {
                width: '40%'
            },
            [theme.breakpoints.up('md')]: {
                width: '150px',
                height: "40px"
            },
            textTransform: "capitalize"
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    }))(Tab);

    const CustomTabPanel = withStyles((theme: any) => ({
        root: {
            width: '100%', backgroundColor: '#F9F9F9',
            [theme.breakpoints.down('md')]: {
                height: '350px',
                padding: '0px',
            },
            [theme.breakpoints.up('md')]: {
                height: '300px',
                padding: '20px',
                paddingBottom:'0px'
            },
        }
    }))(TabPanel);

    const CustomTabs = withStyles((theme: any) => ({
        root: {
            borderRight: 'none', borderColor: 'divider',
            display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
            backgroundColor: '#EBEBEB',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: '48px',
            },
            [theme.breakpoints.up('md')]: {
                width: '140px',
                maxHeight: '240px'
            },
        },
        selected: {
            backgroundColor: '#FFFFFF',
        },
    }))(Tabs);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue && !(tabValue === newValue)) {
            setTabValue(newValue);
        }
    };

    const getContent = (tabValue: string) => {
        if (props && props.dataAvailable && finData && finData[tabValue]) {
            try {
                let data: Dictionary<string> = finData[tabValue];
                let tempCat = Object.keys(data);
                tempCat.sort(function (a, b) {
                    return ('' + a).localeCompare(b);
                })

                let categories: any[] = [];
                let series: any[] = [];

                for (let cat of tempCat) {
                    let trimmedVal = cat.trim();
                    if (trimmedVal.startsWith('Y')) {
                        categories.push(DateExtensions.getDateStringForHeader(trimmedVal));
                        let strVal = data[trimmedVal];
                        if (NumberExtensions.isNumber(strVal)) {
                            series.push((Number(strVal)).toFixed(2));
                        }
                        else {
                            series.push(data[trimmedVal]);
                        }
                    }
                }

                const chartData: ApexOptions = {
                    chart: {
                        type: "bar",
                        id: `${tabValue}-chart`,
                        height: '360px',
                        toolbar: {
                            show: false
                        },
                        background: '#F9F9F9',
                        animations: {
                            enabled: false,
                            easing: 'easeinout',
                            speed: 800,
                            animateGradually: {
                                enabled: false,
                                delay: 0
                            },
                            dynamicAnimation: {
                                enabled: false,
                                speed: 800
                            }
                        },
                    },
                    grid: {
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    xaxis: {
                        categories: categories,
                        axisBorder: {
                            show: true
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            show: false,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        crosshairs: {
                            show: false,
                        },
                        labels: {
                            show: false
                        }
                    },
                    fill: {
                        colors: ['#0F9764', '#0F9764', '#0F9764']
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                            columnWidth: '60%',
                        },

                    },
                    colors: ['#0F9764'],
                    series: [
                        {
                            name: "Price (In Cr.)",
                            type: "bar",
                            data: series
                        }
                    ]
                };

                return <ReactApexChart options={chartData} series={chartData.series} type='bar' height='100%' width='100%' />;
            }
            catch (error) {
                console.error('error while get content', error);
                return (<React.Fragment />);
            }
        }
        else {
            return (<React.Fragment />);
        }
    }

    const getFinancialsFromPnL = (plData: Dictionary<any>[] | undefined): Financials | undefined => {
        let type = (props.displayType + props.recordType)?.toUpperCase();
        let plResp = props.pnl;

        if (props && props.pnl && props.pnl.length > 0) {
            let finObject: Financials = {
                revenue: {},
                expenses: {},
                pat: {},
                pbt: {},
                eps: {}
            };
            if (type && plResp) {
                if (type === "BANKS") {
                    let rev = plResp.filter((o) => o.rowno == "1");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "24");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "37");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "30");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "52");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "BANKC") {
                    let rev = plResp.filter((o) => o.rowno == "1");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "24");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "39");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "32");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "56");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "FINS") {
                    let rev = plResp.filter((o) => o.rowno == "1");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "39");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "52");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "45");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "62");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "FINC") {
                    let rev = plResp.filter((o) => o.rowno == "1");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "33");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "54");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "47");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "66");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "MANS") {
                    let rev = plResp.filter((o) => o.rowno == "1");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "22");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "35");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "28");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "45");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "MANC") {
                    let rev = plResp.filter((o) => o.rowno == "1");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "22");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "37");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "30");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "49");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "INSS") {
                    let rev = plResp.filter((o) => o.rowno == "2");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "58");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "135");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "126");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "152");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
                else if (type === "INSC") {
                    let rev = plResp.filter((o) => o.rowno == "2");
                    if (rev && rev.length > 0) {
                        finObject.revenue = rev[0];
                    }
                    let exp = plResp.filter((o) => o.rowno == "68");
                    if (exp && exp.length > 0) {
                        finObject.expenses = exp[0];
                    }
                    let pat = plResp.filter((o) => o.rowno == "137");
                    if (pat && pat.length > 0) {
                        finObject.pat = pat[0];
                    }
                    let pbt = plResp.filter((o) => o.rowno == "128");
                    if (pbt && pbt.length > 0) {
                        finObject.pbt = pbt[0];
                    }
                    let eps = plResp.filter((o) => o.rowno == "156");
                    if (eps && eps.length > 0) {
                        finObject.eps = eps[0];
                    }
                }
            }
            return finObject;
        }
        else {
            return undefined;
        }
    }

    useEffect(() => {
        setFinData(getFinancialsFromPnL(props.pnl));
    }, [props.pnl]);

    return props.dataAvailable ? <React.Fragment>
        <React.StrictMode>
            <Box className={classes.root}>
                <Box className={classes.header}>
                    <Typography className={classes.headerTypography}>
                        Financials
                    </Typography>
                    <br/>
                    <Typography sx={{ fontSize: "12px" }}>* Prices are in Cr. (₹)</Typography>
                    <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                        <IconButton
                            onClick={() => {
                                window.location.hash = 'tabdetail-fincard'
                                navigator.clipboard.writeText(window.location.href)
                                setLink(true)

                            }}
                        >
                            <ContentCopyIcon
                                sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                        </IconButton>
                    </Tooltip>
                    {currentUser && <Button variant="contained" color="primary" onClick={() => { props.advancedTabCallback && props.advancedTabCallback(); }}
                        sx={{ height: '35px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none', float: "right" }}>
                        Advanced
                    </Button> }
                </Box>
                <Paper elevation={0}>
                    <Box className={classes.cardBody}>
                        <Grid container spacing={2}>
                            <Grid item container order={isSM ? 0 : 1} xs={12} md={3}>
                                <CustomTabs
                                    orientation={isSM ? 'vertical' : 'horizontal'}
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="financials-card"
                                    sx={{
                                        borderRight: 'none', borderColor: 'divider',
                                        display: 'flex', justifyContent: 'flex-end', alignContent: 'flex-end',
                                        backgroundColor: '#EBEBEB',
                                    }}
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile={isSM ? false : true}
                                    variant={isSM ? 'fullWidth' : 'scrollable'}
                                >
                                    <VerticalTab label="Revenue" value="revenue" />
                                    <VerticalTab label="Expenses" value="expenses" />
                                    <VerticalTab label="PBT" value="pbt" />
                                    <VerticalTab label="PAT" value="pat" />
                                    <VerticalTab label="EPS" value="eps" />
                                </CustomTabs>
                            </Grid>
                            <Grid item container order={isSM ? 1 : 0} xs={12} md={9}>
                                <TabContext value={tabValue}>
                                    <CustomTabPanel value={tabValue}>
                                        {getContent(tabValue)}
                                    </CustomTabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </React.StrictMode>
    </React.Fragment> : <React.Fragment />;
}

export default ListedFinancialsCard;