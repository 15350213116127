import * as React from 'react';
import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Typography,
} from '@mui/material';
import { getListedWebsiteUrl } from '../../../services/CompanyService';

const ListedCompanyWebsite = (companyShort) => {
  const [url, setUrl] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);

  //   Fetch User Data
  React.useEffect(() => {
    if (companyShort?.companyShort?.companyShort?.id?.length > 0) {
      setLoading(true);
      getListedWebsiteUrl(companyShort?.companyShort?.companyShort?.id)
        .then((data) => {
          setUrl(data.website_url);
          setLoading(false);
        })
        .catch((ex) => {
          console.error(ex);
          setLoading(false);
        });
    }
    return () => {
      setUrl('');
    };
  }, [companyShort]);

  return (
    <React.Fragment>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: { md: '300px', xs: '150px' },
          }}
        >
          <CircularProgress color="primary" size={60} />
        </Box>
      ) : ((url?.length > 0) === true) ? (
        <Card
          variant="outlined"
          sx={{
            paddingY: '16px',
            paddingX: '16px',
            border: '1px solid #EBEBEB',
            background: '#F9F9F9',
            borderRadius: '12px',
            height: { md: '500px', xs: '500px' },
            width: '95%',
            minWidth: '85%',
            maxWidth: '95%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <CardMedia
            component="iframe"
            src={url}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '12px',
            }}
          />
        </Card>
      ) : (
        <React.Fragment>
          <Card
            variant="outlined"
            sx={{
              width: 'auto',
              borderRadius: '15px',
              height: { md: '300px', xs: '150px' },
              overflow: 'hidden',
              border: '1px solid #EBEBEB',
              background: '#F9F9F9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: '#525050',
                fontWeight: '700',
                fontFamily: 'Oxygen',
                fontStyle: 'bold',
                fontSize: { md: '24px', xs: '12px' },
                lineHeight: '14px',
                margin: '10px',
                textAlign: 'center',
              }}
            >
              Website unavailable for this company
            </Typography>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ListedCompanyWebsite;
