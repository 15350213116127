import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Avatar, CardActions, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import {
  getFeedById,
  postFeed,
  sharePost,
  updateFeed,
  updateSharePost,
} from "../../services/SocialMediaService";
import { PostData } from "../../model/FeedDataModel";
import RichTextField from "./RichTextField";
import { IPostMedia, MediaSelector } from "./MediaSelector";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    background: "#F9F9F9",
  },
}));

const newsFeedCreateStoryCardAction = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingY: "0px",
  height: "40%",
  marginTop: "15px",
  width: { xs: "250px", sm: "500px", md: "500px" },
};
const newsFeedCreateStoryIconButtons = {
  color: "#000000",
  fontFamily: "Oxygen",
  fontStyle: "normal",
  fontSize: "13px",
  lineHeight: "16.42px",
  margin: "0px",
  display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" },
};

const fileCountCss: React.CSSProperties = {
  width: "15px",
  color: "white",
  borderRadius: "6px",
  fontSize: "11px",
  marginTop: "-15px",
  background: "rgb(15, 151, 100)",
  fontWeight: "bold",
  marginLeft: "2px",
  padding: "1px",
};

export interface IPostEditorProps {
  postMode?: "Post" | "Article";
  onClose: (post: PostData | null) => void;
  isOpen: boolean;
  post: PostData | null;
  parentPost?: PostData | null; // parent post means sharing the post
}

enum MediaDialogType {
  Video,
  Image,
  None,
}
export default function PostEditor(props: IPostEditorProps) {
  const currentUser = useSelector((state: ReduxStore) => state.userData.user);

  const [title, setTitle] = React.useState(props.post ? props.post.title : "");
  const [description, setDescription] = React.useState(
    props.post ? props.post.description : ""
  );
  const [files, setFiles] = React.useState<IPostMedia[]>(
    props.post ? props.post.media : []
  );
  const [mediaDialogState, setMediaDialogState] =
    React.useState<MediaDialogType>(MediaDialogType.None);
  const [postType, setPostType] = React.useState<"Post" | "Article">(
    props.post && props.post.title ? "Article" : "Post"
  );
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const getUploadedFileCount = (fileType?: "image" | "video") => {
    if (fileType) {
      return files.filter((x) => x.file && x.type.indexOf(fileType) > -1)
        .length;
    } else {
      return files.filter((x) => x.file).length;
    }
  };

  const savePost = async () => {
    const mentions: Array<string> = [];
    const hashtags = description.match(/#\w*/g);

    try {
      const entities = JSON.parse(description).entityMap;
      for (let key in entities) {
        let entity = entities[key];
        if (entity.type == "mention") {
          mentions.push(
            `${entity.data.mention.type}/${entity.data.mention.id}`
          );
        }
      }
    } catch (e) { }

    if (props.parentPost) {
      // this is for share post
      try {
        setIsSaving(true);

        let shareResp;
        if (!props.post) {
          // for crete share post

          // handle reshare post. while resharing set the parent to original post
          shareResp = await sharePost(
            (props.parentPost.parent && props.parentPost.parent.id) ||
            props.parentPost.id,
            props.parentPost.type,
            description,
            mentions,
            hashtags ? hashtags : []
          );
        } else {
          // for update shared post
          shareResp = await updateSharePost(
            props.post.id,
            description,
            mentions,
            hashtags ? hashtags : []
          );
        }
        // props.onClose({} as PostData);
        props.onClose(shareResp as PostData);
      } catch (e) {
        props.onClose(null);
      } finally {
        clearForm();
        setIsSaving(false);
      }
    }
    else if (
      title ||
      (description &&
        JSON.parse(description).blocks.find((x) => x.text.length > 0)) ||
      files.length > 0
    ) {
      if (postType == "Article" && (!title || !description)) // for article the title and descripton is mandatory
        return;

      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      files.forEach((x) => x.file && formData.append("files", x.file));
      formData.append("media", JSON.stringify(files.filter((x) => !x.file)));
      formData.append("hashtags", JSON.stringify(hashtags ? hashtags : []));
      formData.append("mentions", JSON.stringify(mentions));

      try {
        setIsSaving(true);
        if (props.post) {
          await updateFeed(props.post.id, formData);
          let post = await getFeedById(props.post.id)
          props.onClose(post[0]);
        } else {
          let post = await postFeed(formData);
          props.onClose(post);
        }

      } catch (e) {
        props.onClose(null);
      } finally {
        clearForm();
        setIsSaving(false);
      }
    }
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setFiles([]);
    setPostType("Post");
  };

  React.useEffect(() => {
    if (props.post) {
      setDescription(props.post.description);
      setTitle(props.post.title);

      if (props.post.title) setPostType("Article");
      else setPostType("Post");

      setFiles(props.post.media);
    } else {
      clearForm();
      props.postMode && setPostType(props.postMode);
    }
  }, [props.isOpen]);

  const getDescriptionPlaceholder = () => {
    if (props.parentPost) {
      return "Say something about this";
    } else {
      return postType == "Article"
        ? "Description"
        : "What do you want to talk about?";
    }
  };

  return (
    <div id={'post-editor-id'}>
      <BootstrapDialog
        onClose={() => {
          clearForm();
          props.onClose(null);
        }}
        aria-labelledby="Create post"
        open={props.isOpen}
        sx={{}}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#686868" }}>
          {props.parentPost ? "Share post" : `Create a post`}
          <LoadingButton
            loading={isSaving}
            color="primary"
            onClick={() => savePost()}
            sx={{
              width: "15%",
              float: "right",
              background: "#FBFAFA",
              color: "#0F9764",
              border: "1px solid",
            }}
            type="submit"
            variant="contained"
          >
            {props.parentPost ? "Share" : ` Post`}
          </LoadingButton>
        </DialogTitle>
        <DialogContent dividers>
          <MediaSelector
            files={
              mediaDialogState == MediaDialogType.Image
                ? files.filter((x) => x.type.indexOf("image") > -1)
                : files.filter((x) => x.type.indexOf("image") == -1)
            }
            isOpen={mediaDialogState != MediaDialogType.None}
            placeholder="Select your Images to share"
            onClose={(mediaFiles) => {
              if (mediaFiles) {
                var updatedMediaList: IPostMedia[] = [];
                if (mediaDialogState == MediaDialogType.Image) {
                  updatedMediaList = files.filter(
                    (x) => x.type.indexOf("image") < 0
                  );
                  updatedMediaList = [...updatedMediaList, ...mediaFiles];
                } else {
                  updatedMediaList = files.filter(
                    (x) => x.type.indexOf("image") >= 0
                  );
                  updatedMediaList = [...updatedMediaList, ...mediaFiles];
                }
                setFiles(updatedMediaList);
              }
              setMediaDialogState(MediaDialogType.None);
            }}
            mediaType={
              mediaDialogState == MediaDialogType.Image ? "image" : "video"
            }
            title="Select your videos"
          ></MediaSelector>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "14px",
              justifyItems: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Avatar
              alt="User"
              src={currentUser?.profilepic}
              sx={{ width: 50, height: 50, borderRadius: "10px" }}
            />

            <Typography
              sx={{
                font: "Oxygen",
                fontSize: "15px",
                fontWeight: "700",
                textDecorationLine: "none",
              }}
              component="div"
            >
              {currentUser?.name}
            </Typography>
          </Grid>
          <Grid sx={{ marginTop: "28px" }}>
            {postType == "Article" && (
              <TextField
                value={title}
                fullWidth
                onChange={(e) => setTitle(e.currentTarget.value)}
                placeholder="Title"
                inputProps={{ maxLength: 50 }}
                sx={{
                  marginBottom: "10px",
                  border: "none",
                  borderRadius: "10px",
                }}
              />
            )}
            <RichTextField
              defaultValue={description}
              placeholder={getDescriptionPlaceholder()}
              onBlur={(e) => {
                setDescription(e);
              }}
            />
          </Grid>

          {/* In case of parent post dont show the action buttons */}
          {props.parentPost ? (
            <CardActions sx={newsFeedCreateStoryCardAction}></CardActions>
          ) : (
            <CardActions sx={newsFeedCreateStoryCardAction}>
              <IconButton
                aria-label="photo"
                sx={{ marginLeft: "0px" }}
                onClick={() => setMediaDialogState(MediaDialogType.Image)}
              >
                <img src="news-photo.svg" alt="Stocknocks" />

                <Typography sx={newsFeedCreateStoryIconButtons}>
                  &nbsp;&nbsp;&nbsp;Photo
                </Typography>
                {getUploadedFileCount("image") > 0 && (
                  <span style={fileCountCss}>
                    {getUploadedFileCount("image")}
                  </span>
                )}
              </IconButton>
              <IconButton
                aria-label="video"
                sx={{ marginLeft: "0px" }}
                onClick={() => setMediaDialogState(MediaDialogType.Video)}
              >
                <img src="news-video.svg" alt="Stocknocks" />
                <Typography sx={newsFeedCreateStoryIconButtons}>
                  &nbsp;&nbsp;&nbsp;Video
                </Typography>
                {getUploadedFileCount("video") > 0 && (
                  <span style={fileCountCss}>
                    {getUploadedFileCount("video")}
                  </span>
                )}
              </IconButton>
              <IconButton
                aria-label="write-article"
                sx={{ marginLeft: "0px" }}
                onClick={() => {
                  postType == "Article"
                    ? setPostType("Post")
                    : setPostType("Article");
                }}
              >
                <img src="news-article.svg" alt="Stocknocks" />
                <Typography sx={newsFeedCreateStoryIconButtons}>
                  &nbsp;&nbsp;&nbsp;
                  {postType == "Article" ? "Write post" : "Write article"}
                </Typography>
              </IconButton>
            </CardActions>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
