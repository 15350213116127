import React from "react";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import Loading from "./Loading";

const Loader = (): JSX.Element => {
    const loading = useSelector((state: ReduxStore) => state.app.loading);
    return loading ? <Loading /> : <div></div>
}

export default Loader;