import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CircularIndeterminate from '../../components/Loading/Loading';
import { ReduxStore } from '../../model/ReduxStore';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const currAuthUser = useSelector((state: ReduxStore) => state.auth.user)
  const { search } = useLocation();

  React.useEffect(() => {
    if (currAuthUser) {
      let data = {
        authUser: currAuthUser,
      };
      var asString = JSON.stringify(data);

      let openInNewWindow = true;
      if (search.length > 0) {
        openInNewWindow = (new URLSearchParams(search).get('openInNewWindow') || 'true') === 'true' ? true : false;
      }

      if (openInNewWindow) {
        let from = new URLSearchParams(search).get('from');
        if (from && from.length > 0) {
          navigate(from, { replace: true });
          window.open(`https://dashboard.stockknocks.com/Dashboard/default?state=${asString}`);
        }
        else {
          window.open(`https://dashboard.stockknocks.com/Dashboard/default?state=${asString}`);
          navigate(-1);
        }
      }
      else {
        window.location.replace(`https://dashboard.stockknocks.com/Dashboard/default?state=${asString}`);
      }
      
      // window.open(`https://stockknocks-prod.web.app/Dashboard/default?state=${asString}`);
      
    }
  }, [currAuthUser, navigate]);

  return (
    <React.Fragment>
      <CircularIndeterminate />
    </React.Fragment>
  );
};

export default Dashboard;
